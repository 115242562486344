import {
  Accordion,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  ApiInspectionStatus,
  ApiLocation,
} from "@operations-hero/lib-api-client";
import FuzzySearch from "fuzzy-search";
import { FC, useCallback, useMemo, useState } from "react";
import { MdAdd } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { ButtonLink } from "../../../components/buttons/LinkButton";
import { FilterBar } from "../../../components/filters/FilterBar";
import { AccountSearchBox } from "../../../components/inputs/SearchBox";
import { RootState, useThunkDispatch } from "../../../store";
import {
  InspectionFloorMap,
  LocationRoomStatus,
} from "../../../store/planning-hq/inspections/details/inspection-details.slice";
import { loadInspectionsDetails } from "../../../store/planning-hq/inspections/details/thunks/loadInspectionsDetails";
import { debounce } from "../../../utils/debounce";
import { AccountModal } from "../../account-settings/account-modal/AccountModal";
import { MiniRoomForm } from "../../account-settings/location-form/MiniRoomForm";
import {
  FloorAccordion,
  SectionAndRoomsAccordianState,
  SectionAndRoomsAccordianStateMap,
} from "./components/FloorAccordion";
import { StatusRoomInspectionFilter } from "./filters/StatusRoomInspectionFilter";

export type SectionWithRooms = ApiLocation & {
  rooms: { id?: string; name: string; squareFeet: number | null }[];
  section: string | null;
};

export const InspectionDetailsFloor: FC = () => {
  const thunkDispatch = useThunkDispatch();
  const { locationId, id } = useParams<{ locationId: string; id: string }>();
  const { apiClient } = useAuthentication();
  const { pathname } = useLocation();

  const {
    buildingMap,
    buildingToFloorsMap,
    floorToRoomsMap,
    loadingStatus,
    inspection,
    scoreConfigMap,
  } = useSelector((state: RootState) => state.inspectionDetailsSlice);

  const {
    isOpen: isOpenAddRoom,
    onOpen: onOpenAddRoom,
    onClose: onCloseAddRoom,
  } = useDisclosure();

  const [floorToEdit, setFloorToEdit] = useState<
    SectionAndRoomsAccordianState | undefined
  >();

  const location = useMemo(
    () => (!locationId ? null : buildingMap[locationId]),
    [buildingMap, locationId]
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState<LocationRoomStatus[]>([]);

  const handleNewRoom = useCallback(() => {
    setFloorToEdit(undefined);
    onOpenAddRoom();
  }, [onOpenAddRoom]);

  const handleOnChangeFilterSearch = useCallback((value: string) => {
    setSearchTerm(value);
  }, []);

  const reloadInspectionDetails = useCallback(() => {
    if (!id) return;
    thunkDispatch(
      loadInspectionsDetails({
        apiClient,
        inspectionId: id,
      })
    );
  }, [thunkDispatch, apiClient, id]);

  const debouncedChangeSearchValue = debounce(
    (value) => handleOnChangeFilterSearch(value),
    300
  );

  const floorsMap = useMemo<InspectionFloorMap>(
    () => (!location ? {} : buildingToFloorsMap[location.location.id] || {}),
    [location, buildingToFloorsMap]
  );

  const fuzzySearch = useMemo(() => {
    const allRooms = Object.values(floorsMap).flatMap((m) => {
      const roomMap = floorToRoomsMap[m.location.id];
      if (!roomMap) {
        return [];
      }

      return Object.values(roomMap);
    });
    return new FuzzySearch(Object.values(allRooms), ["location.name"], {
      sort: true,
    });
  }, [floorsMap, floorToRoomsMap]);

  const filteredRooms = useMemo(() => {
    const searchResults = fuzzySearch.search(searchTerm);
    if (statusFilter.length === 0) return searchResults;
    return searchResults.filter((location) => {
      if (statusFilter.includes(location.status)) {
        return true;
      }
      return false;
    });
  }, [searchTerm, statusFilter, fuzzySearch]);

  // this is scrubbed against the searches. its could use some optimization
  const floorAndRoomData = useMemo<SectionAndRoomsAccordianStateMap>(() => {
    if (!location) {
      return {};
    }
    const result: SectionAndRoomsAccordianStateMap = {};

    // First, add all floors to the result
    for (const floor of Object.values(floorsMap)) {
      result[floor.location.id] = {
        section: floor,
        rooms: [],
      };
    }

    for (const room of filteredRooms) {
      // nest buildings need to be ignored
      if (!room.location.treePath.includes(location.location.treePath)) {
        continue;
      }

      const ancestorIds = room.location.treePath
        .replace(location.location.treePath, "")
        .replace(`${room.location.id}.`, "")
        .split(".")
        .filter(Boolean)
        .reverse();

      let section: SectionAndRoomsAccordianState | null = null;

      let shouldContinue = false;
      // find its parent section
      for (const id of ancestorIds) {
        if (shouldContinue) continue;

        const entry = floorsMap[id];
        // ancestor is not a floor
        if (!entry) {
          if (buildingMap[id]) {
            shouldContinue = true;
          }
          continue;
        }

        section = result[id];
        // already prepped the section
        if (section) {
          continue;
        }

        // first time mapping this section
        section = result[id] = {
          section: entry,
          rooms: [],
        };
      }

      if (shouldContinue) {
        continue;
      }

      if (!section) {
        const allRooms = floorsMap["all-rooms"];
        section = result[allRooms.location.id];
        if (!section) {
          section = result[allRooms.location.id] = {
            section: allRooms,
            rooms: [],
          };
        }
      }

      section.rooms.push(room);
    }

    return result;
  }, [filteredRooms, floorsMap, location, buildingMap]);

  const isInspectorView = useMemo(
    () => !pathname.includes("/account"),
    [pathname]
  );

  if (loadingStatus === "pending") {
    return (
      <Flex justify="center" align="center" minHeight="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (!location || !inspection) return null;

  return (
    <VStack w="100%" gap={2} alignItems="flex-start">
      <Flex align="center" w="100%">
        <ButtonLink
          to={
            isInspectorView
              ? `/planning/inspection/${id}/details`
              : `/account/planning/inspection/${id}/details`
          }
        />
      </Flex>
      <Flex justify="space-between" align="center" w="100%">
        <Box>
          <Heading fontWeight="bold">{location.location.name}</Heading>
          <Text>
            Total Inspected Rooms{" "}
            {`${location.inspectedRooms} out of ${location.totalRooms}`}
          </Text>{" "}
        </Box>
        <Box>
          {inspection.status !== ApiInspectionStatus.submitted && (
            <Button
              mr={2}
              colorScheme="blue"
              size="sm"
              variant="outline"
              onClick={handleNewRoom}
            >
              <Icon as={MdAdd} mr={1} />
              Add Room
            </Button>
          )}
          {!isInspectorView && (
            <Button
              colorScheme="blue"
              size="sm"
              isDisabled={
                inspection.status !== ApiInspectionStatus.completed &&
                inspection.status !== ApiInspectionStatus.submitted
              }
            >
              View Report
            </Button>
          )}
        </Box>
      </Flex>

      <FilterBar>
        <Flex
          justify="space-between"
          flexDirection={["column", null, "row"]}
          w="100%"
        >
          <StatusRoomInspectionFilter
            value={statusFilter}
            onChange={setStatusFilter}
          />
          <AccountSearchBox
            onInputChange={(value: string) => debouncedChangeSearchValue(value)}
            rest={{ maxW: ["100%", null, "320px"] }}
            searchPlaceholder="Search by Locations"
          />
        </Flex>
      </FilterBar>
      <Accordion
        defaultIndex={Object.values(floorsMap).map((_, index) => index)}
        allowMultiple
        w="100%"
      >
        <FloorAccordion
          data={floorAndRoomData}
          inspection={inspection}
          scoreConfigMap={scoreConfigMap}
          onOpenAddRoom={onOpenAddRoom}
          setFloorToEdit={setFloorToEdit}
        />
      </Accordion>

      {isOpenAddRoom && locationId && (
        <AccountModal
          isOpen={isOpenAddRoom}
          onClose={onCloseAddRoom}
          title={floorToEdit ? "Edit Room" : "Add Room"}
          contentProps={{ maxW: "2xl" }}
          content={
            <MiniRoomForm
              onClose={onCloseAddRoom}
              locationId={locationId}
              reloadInspectionDetails={reloadInspectionDetails}
              initialData={floorToEdit}
              inspectionId={inspection.id}
            />
          }
        />
      )}
    </VStack>
  );
};
