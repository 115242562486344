import { ApiClient, ApiInspection } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../..";
import { InspectionDetailsSliceProps } from "../inspection-details.slice";

interface SubmitInspectionParams {
  apiClient: ApiClient;
  accountId: string;
  inspectionId: string;
}

export const submitInspection = createAsyncThunk<
  ApiInspection,
  SubmitInspectionParams
>("inspections-details/submit-inspection", async (params, thunkAPI) => {
  const { apiClient, accountId, inspectionId } = params;
  const state = thunkAPI.getState() as RootState;
  const { submitProps } = state.inspectionDetailsSlice;

  const submitData = {
    submitNotes: submitProps.submitNotes || undefined,
    eSign: submitProps.signature
      ? {
          uploadId: submitProps.signature.uploadId || "",
          name: submitProps.signature.name,
        }
      : undefined,
  };

  const result = await apiClient.submitAndSignInspection(
    accountId,
    inspectionId,
    submitData
  );

  return result;
});

export const submitInspectionHandler = (
  builder: ActionReducerMapBuilder<InspectionDetailsSliceProps>
) => {
  builder.addCase(submitInspection.fulfilled, (state, action) => {
    state.inspection = action.payload;
  });
};
