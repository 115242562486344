import { Text } from "@chakra-ui/react";
import {
  ApiInspection,
  ApiInspectionScoreConfig,
} from "@operations-hero/lib-api-client";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { CellProps } from "react-table";
import { StatusBadge } from "../../../../components/badges/StatusBadge";
import {
  DataTable,
  DataTableColumn,
} from "../../../../components/data-table/DataTable";
import { useScreenBreakpoints } from "../../../../hooks/useScreenBreakpoints";
import { InspectionRoomEntry } from "../../../../store/planning-hq/inspections/details/inspection-details.slice";
import { InspectionRoomButtons } from "./InspectionRoomButtons";
import { ViolationPill } from "./ViolationPill";

interface RoomAccordionProps {
  roomsData: InspectionRoomEntry[];
  inspection: ApiInspection;
  scoreConfigMap: Record<string, ApiInspectionScoreConfig>;
}

export const RoomDataTable: React.FC<RoomAccordionProps> = ({
  roomsData,
  inspection,
  scoreConfigMap,
}) => {
  const { isDesktop } = useScreenBreakpoints();
  const { pathname } = useLocation();

  const isInspectorView = useMemo(
    () => !pathname.includes("/account"),
    [pathname]
  );

  const roomColumns = useMemo<DataTableColumn<InspectionRoomEntry>[]>(() => {
    const columns: DataTableColumn<InspectionRoomEntry>[] = [
      {
        Header: "Rooms",
        accessor: (room) => room,
        disableSortBy: true,
        width: 120,
        maxWidth: 120,
        Cell: ({
          value,
        }: CellProps<InspectionRoomEntry, InspectionRoomEntry>) => {
          return <Text>{value.location.name}</Text>;
        },
      },
      {
        Header: "Status",
        width: 110,
        maxWidth: 110,
        accessor: (room) => room,
        disableSortBy: true,
        Cell: ({
          value,
        }: CellProps<InspectionRoomEntry, InspectionRoomEntry>) => {
          return value.status && <StatusBadge status={value.status} />;
        },
      },
    ];

    if (!isInspectorView) {
      columns.push({
        Header: "Rating",
        accessor: (room) => room,
        disableSortBy: true,
        width: 90,
        maxWidth: 90,
        Cell: ({
          value,
        }: CellProps<InspectionRoomEntry, InspectionRoomEntry>) => {
          return (
            <Text>
              {value.totalNumericalValue ? value.totalNumericalValue : "-"}
            </Text>
          );
        },
      });
    } else {
      columns.push({
        Header: "Violations",
        accessor: (room) => room,
        disableSortBy: true,
        width: 90,
        maxWidth: 90,
        Cell: ({
          value,
        }: CellProps<InspectionRoomEntry, InspectionRoomEntry>) => {
          return (
            <ViolationPill
              violations={value.violations}
              scoreConfigMap={scoreConfigMap}
            />
          );
        },
      });
    }

    columns.push({
      Header: "Actions",
      accessor: (room) => room,
      disableSortBy: true,
      width: 90,
      maxWidth: 90,
      Cell: ({
        value,
      }: CellProps<InspectionRoomEntry, InspectionRoomEntry>) => {
        return <InspectionRoomButtons room={value} inspection={inspection} />;
      },
    });

    return columns;
  }, [isInspectorView, scoreConfigMap, inspection]);

  if (!roomsData.length) return null;

  return (
    <DataTable
      columns={roomColumns}
      data={roomsData}
      hiddenHeader={isDesktop ? false : true}
    />
  );
};
