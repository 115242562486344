import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "../../../..";
import { InspectionListSliceProps } from "../inspection-list.slice";

export const updateFilters = createAsyncThunk(
  "inspection-filters/update",
  (filters: Partial<InspectionListSliceProps["filters"]>, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { inspectionListSlice } = state;
    const updated = {
      ...inspectionListSlice.filters,
      ...filters,
    };
    return updated;
  }
);

export const updateFiltersHandler = (
  builder: ActionReducerMapBuilder<InspectionListSliceProps>
) => {
  builder.addCase(updateFilters.fulfilled, (state, action) => {
    state.filters = action.payload;
  });
};
