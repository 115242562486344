import { useCallback } from "react";

export const useConvertBase64ToImageFile = () => {
  const getFileFromBase64 = useCallback(
    (dataURL: string, fileName?: string) => {
      const byteString = atob(dataURL.split(",")[1]);
      const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });

      // Creating the File from the Blob
      const file = new File([blob], generateFileName(fileName), {
        type: "image/jpeg",
      });
      return file;
    },
    []
  );

  return { getFileFromBase64 };
};

export const generateFileName = (
  fileName?: string,
  extension: string = "jpg"
): string => {
  if (fileName) {
    return `${fileName}.${extension}`;
  }
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `IMG_${year}${month}${day}_${hours}${minutes}${seconds}.${extension}`;
};
