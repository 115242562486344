import {
  Box,
  FormControl,
  FormLabel,
  FormLabelProps,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Editor } from "@operations-hero/react-draft-wysiwyg";
import "@operations-hero/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { FieldHelperProps, useField } from "formik";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "../../../utils/debounce";
import "./rich-text-editor-styles.css";

export const editorToolBar = {
  options: ["inline", "list", "link", "emoji"],
  emoji: { emojis: ["🎉", "✔", "👍", "👎", "💪", "💯", "🛠"] },
  inline: {
    options: ["bold", "italic", "underline", "monospace"],
  },
  list: { options: ["unordered", "ordered"] },
  link: {
    inDropdown: false,
    showOpenOptionOnHover: true,
    defaultTargetOption: "_self",
    options: ["link"],
    link: { className: "link-style" },
  },
};

interface TextEditorControlProps {
  label?: string;
  value: string | null;
  name: string;
  initialValue?: string | null;
  maxCharacters?: string;
  labelProps?: FormLabelProps;
  isDisabled?: boolean;
}

const setFormikValue = (
  newContent: EditorState,
  helper: FieldHelperProps<any>,
  currentValue: string
) => {
  let valueAsMarkDownFormat = "";
  if (newContent) {
    valueAsMarkDownFormat = draftToMarkdown(
      convertToRaw(newContent?.getCurrentContent())
    );
    if (valueAsMarkDownFormat !== currentValue) {
      helper.setValue(valueAsMarkDownFormat);
    }
  }
};

const debouceSetFormikValue = debounce(setFormikValue, 300);

export const TextEditorControl: React.FC<TextEditorControlProps> = ({
  label,
  name,
  value,
  maxCharacters = "1000",
  labelProps,
  isDisabled,
}) => {
  const [content, setContent] = useState<EditorState>();
  const [initialValue, setInitialValue] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const themeClass = useColorModeValue(
    "light-theme-rich-text-editor",
    "dark-theme-rich-text-editor"
  );
  const borderColor = useColorModeValue("#E2E8F0", "#4A5568");
  const errorColor = useColorModeValue("#E53E3E", "#FC8181");
  const focusColor = useColorModeValue("#3182CE", "#63B3ED");

  const [, meta, helper] = useField({
    name: name || "",
    value: value || "",
  });

  const wrapperStyle = useCallback(
    () => ({
      borderColor:
        isOpen || (meta.error && meta.touched) ? "transparent" : borderColor,
      boxShadow: isOpen
        ? `0px 0px 0px 2px ${focusColor}`
        : meta.error && meta.touched
          ? `0px 0px 0px 2px ${errorColor}`
          : "0px 0px 0px 2px transparent",
    }),
    [isOpen, meta, errorColor, borderColor, focusColor]
  );

  const handleOnChange = (newContent: EditorState) => {
    if (content !== newContent) {
      setContent(newContent);
      debouceSetFormikValue(newContent, helper, value || "");
    }
  };

  const handleOnBlur = () => {
    onClose();
    helper.setTouched(true);
  };

  useEffect(() => {
    if (!initialValue && value && !isOpen) {
      const convertedValue = EditorState.createWithContent(
        convertFromRaw(markdownToDraft(value))
      );
      setContent(convertedValue);
      setInitialValue(true);
    }
  }, [value, initialValue, isOpen]);

  return (
    <FormControl>
      <FormLabel {...labelProps}>{label}</FormLabel>
      <Box className={themeClass}>
        <Editor
          onFocus={onOpen}
          editorState={content}
          onBlur={handleOnBlur}
          toolbar={editorToolBar}
          wrapperStyle={wrapperStyle()}
          onEditorStateChange={handleOnChange}
          toolbarHidden={isDisabled}
          readOnly={isDisabled}
        />
      </Box>
      {meta.touched && meta.error ? (
        <Text color={errorColor} fontSize="sm" mt={1}>
          {meta.error}
        </Text>
      ) : (
        <Text fontSize="sm" color="gray.500">
          {maxCharacters} character max
        </Text>
      )}
    </FormControl>
  );
};
