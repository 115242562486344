import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  ApiAttachment,
  ApiFundingSource,
  CreateApiFundingSource,
} from "@operations-hero/lib-api-client";
import { Form, Formik, FormikProps } from "formik";
import { FC, MutableRefObject, useCallback, useMemo, useState } from "react";
import * as yup from "yup";
import {
  Attachment,
  Attachments,
} from "../../../components/attachments/Attachments";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import {
  ColorPicker,
  ColorPickerEventArgs,
} from "../../../components/color-picker/ColorPicker";
import { DEFAULT_COLOR_CODES } from "../../../components/color-picker/defaults";
import { CurrencyInputControl } from "../../../components/form-helpers/CurrencyInputControl";
import { FiscalYearsControl } from "../../../components/form-helpers/FiscalYearControl";
import { TextInputControl } from "../../../components/form-helpers/TextInputControl";
import { useShowToast } from "../../../hooks/showToast";
import { FiscalYearRange } from "../../../store/planning-hq/types";

const PHONE_NUMBER_REGEX =
  /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*(?:\s*x\d+)?$/g;

export type NewFundingSourceProps = {
  name: string;
  fiscalYear: FiscalYearRange;
  funds: number;
  contactName: string;
  contactPosition: string;
  contactEmail: string;
  contactPhone: string;
};
export type NewFundingSourceFormProps = {
  onSubmit: (
    fundingSource: CreateApiFundingSource,
    attachments: Attachment[]
  ) => Promise<{
    fundingSource: ApiFundingSource;
    attachments: ApiAttachment[];
  } | void>;
  formRef: MutableRefObject<FormikProps<NewFundingSourceProps> | null>;
};

export const NewFundingSourceForm: FC<NewFundingSourceFormProps> = ({
  onSubmit,
  formRef,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const toast = useShowToast();
  const [colorId, setColorId] = useState<string | null>(null);
  const initialValues: NewFundingSourceProps = {
    name: "",
    fiscalYear: { start: "", end: "" },
    funds: 0,
    contactName: "",
    contactPosition: "",
    contactEmail: "",
    contactPhone: "",
  };

  const [workingAttachments, setWorkingAttachments] = useState<Attachment[]>(
    []
  );

  const createFundingSourceAttachments = useCallback(() => {
    const attachmentsToCreate = workingAttachments.filter(
      (attachment) => attachment.isNew === true
    );
    return attachmentsToCreate;
  }, [workingAttachments]);

  const handleOnSubmit = useCallback(
    (values: NewFundingSourceProps) => {
      const {
        name,
        contactName,
        contactEmail,
        contactPhone,
        contactPosition,
        funds,
        fiscalYear,
      } = values;

      const fundingSource: CreateApiFundingSource = {
        name,
        funds,
        fiscalYearStart: fiscalYear.start,
        fiscalYearEnd: fiscalYear.end,
        contactName,
        contactEmail,
        contactPhone,
        contactPosition,
        colorId: colorId ?? DEFAULT_COLOR_CODES[0],
      };

      return onSubmit(fundingSource, createFundingSourceAttachments());
    },
    [onSubmit, createFundingSourceAttachments, colorId]
  );

  const handleNewAttachments = useCallback(
    async (attachments: Attachment[]) => {
      Promise.all(
        attachments.map(async (item) => {
          const uploadFile = await apiClient.createUploadAndMoveAttachment(
            currentAccount.id,
            item
          );

          return { ...item, uploadId: uploadFile.id };
        })
      )
        .then((formatedAttachments) => {
          setWorkingAttachments([
            ...workingAttachments,
            ...formatedAttachments,
          ]);
        })
        .catch(() => {
          toast(
            "error",
            "Cannot submit attachments at this moment. Try again later."
          );
        });
    },
    [apiClient, currentAccount.id, workingAttachments, toast]
  );

  const handleOnRemoveAttachment = useCallback(
    async (attachment: Attachment) => {
      const index = workingAttachments.findIndex(
        (item) => item.uploadId === attachment.uploadId
      );
      if (index !== -1) {
        const attachmentsCopy = workingAttachments;
        await attachmentsCopy.splice(index, 1);
        setWorkingAttachments([...attachmentsCopy]);
      }
    },
    [workingAttachments]
  );

  const validationSchema = useMemo(() => {
    const schema = yup.object().shape({
      name: yup.string().nullable().required("Name is a required field"),
      fiscalYear: yup
        .object()
        .test("fiscal-year", "Fiscal Year is required", (value) => {
          if (!value.start || !value.end) return false;
          return true;
        }),
      funds: yup
        .string()
        .test("funds", "Funds amount is required", (value) => {
          const asNumber = Number.parseFloat(value ?? "");
          if (isNaN(asNumber) || asNumber === 0) return false;
          return true;
        })
        .nullable(),
      contactName: yup.string().nullable().required("Contact Name is required"),
      contactPhone: yup
        .string()
        .nullable()
        .matches(PHONE_NUMBER_REGEX, "Invalid phone number format"),
    });
    return schema;
  }, []);

  const handleOnColorSelected = useCallback((args: ColorPickerEventArgs) => {
    const { selectedColor } = args;
    if (selectedColor) {
      setColorId(selectedColor);
    }
  }, []);

  return (
    <Formik
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      innerRef={formRef}
      validationSchema={validationSchema}
      validateOnChange={true}
    >
      {({ values, errors, submitCount }) => {
        return (
          <Form>
            <VStack w="full" justify="stretch" gap={3} alignItems="start">
              <HStack w="full" alignItems="start">
                <TextInputControl
                  name="name"
                  value={values.name}
                  label="Name *"
                />
                <FormControl w="max-content">
                  <FormLabel w="max-content">Color ID *</FormLabel>
                  <ColorPicker onColorSelected={handleOnColorSelected} />
                </FormControl>
              </HStack>
              <Text fontSize="small" color="gray">
                The Color ID will be used as a label to be displayed in
                statistics and charts throughout the application.
              </Text>
              <FiscalYearsControl
                value={{
                  start: values.fiscalYear.start,
                  end: values.fiscalYear.end,
                }}
                label="Fiscal Year *"
                name="fiscalYear"
              />
              <CurrencyInputControl
                name="funds"
                value={values.funds}
                label="Funds *"
              />
              <Box w="full">
                <Attachments
                  attachments={workingAttachments}
                  onNewAttachments={handleNewAttachments}
                  onDeleteAttachment={handleOnRemoveAttachment}
                />
              </Box>
              <VStack align="stretch" w="full">
                <Text fontSize="large" fontWeight="bold">
                  Contact info
                </Text>
                <TextInputControl
                  name="contactName"
                  value={values.contactName}
                  label="Contact Name *"
                />
                <TextInputControl
                  name="contactPosition"
                  value={values.contactPosition}
                  label="Position"
                />
                <TextInputControl
                  name="contactEmail"
                  value={values.contactEmail}
                  label="Email"
                />
                <TextInputControl
                  name="contactPhone"
                  value={values.contactPhone}
                  label="Phone"
                />
              </VStack>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};
