import {
  Box,
  Heading,
  HStack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  ApiInspection,
  ApiInspectionConfig,
  ApiInspectionScoreConfig,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useMemo } from "react";
import { RatingCard } from "../../../../../components/cards/RatingCard";
import { ScoreCard } from "../../../../../components/cards/ScoreCards";
import { getColor } from "../../../../../hooks/useInspectionRatingUtil";
import { ScoresRecord } from "../../../../../store/planning-hq/inspections/details/thunks/loadInspectionsDetails";

type ScoreCount = { count: number; config: ApiInspectionScoreConfig };

export const TotalScoreDetails: FC<{
  scores: ScoresRecord;
  scoreConfigMap: Record<string, ApiInspectionScoreConfig>;
  inspectionConfig: ApiInspectionConfig;
  inspection: ApiInspection;
  showRatingCard?: boolean;
}> = ({
  inspectionConfig,
  inspection,
  scoreConfigMap,
  scores,
  showRatingCard,
}) => {
  const cardBg = useColorModeValue("white", "gray.700");

  const ratingsCounts = useMemo<ScoreCount[]>(() => {
    if (!inspection || !inspectionConfig) {
      return [];
    }

    // prep the result;
    let result = Object.values(scoreConfigMap).reduce<
      Record<string, ScoreCount>
    >((map, item) => {
      map[item.id] = {
        count: 0,
        config: item,
      };
      return map;
    }, {});

    if (inspectionConfig.allowSkippingCategories) {
      result["NA"] = {
        count: 0,
        config: {
          id: "NA",
          justificationRequired: false,
          name: "N/A",
          value: -1,
          zerosOutCategoryInRollup: false,
          zerosOutGroupInRollup: false,
        },
      };
    }

    for (const scoreEntry of Object.values(scores)) {
      let entry = result[scoreEntry.scoreConfigId || "NA"];
      // this shouldn't happen due the the prep above
      if (!entry) continue;

      entry.count += 1;
    }

    // sort by score desc, name asc
    return Object.values(result).sort((a, b) => {
      if (a.config.value === b.config.value) {
        if (a.config.name === b.config.name) return 0;
        return a.config.name > b.config.name ? 1 : -1;
      }
      return a.config.value > b.config.value ? -1 : 1;
    });
  }, [inspection, inspectionConfig, scores, scoreConfigMap]);

  const getChildrenTitle = useCallback(
    (configName: string, scoreConfigName: string) => {
      if (!configName.includes("FIT")) return null;

      switch (scoreConfigName) {
        case "OK":
          return "Good Repair";
        case "D":
          return "Deficiency";
        case "X":
          return "Extreme Deficiency";
        case "N/A":
          return "Not Applicable";
        default:
          return null;
      }
    },
    []
  );

  return (
    <VStack w="100%" alignItems="flex-start" p={2}>
      <Heading size="md" py={2}>
        Total Score Details
      </Heading>
      <HStack w="100%" flexWrap="wrap" justifyItems="flex-start">
        {showRatingCard && <RatingCard inspection={inspection} />}
        {ratingsCounts.map((rating, index) => (
          <ScoreCard
            key={`stats:${rating.config.id}`}
            title={rating.config.name}
            bgColor={cardBg}
            childrenTitle={getChildrenTitle(
              inspectionConfig.name,
              rating.config.name
            )}
          >
            <Box mt={3} textAlign="center">
              <Text
                as="span"
                color={
                  rating.config.justificationRequired
                    ? getColor(index, ratingsCounts.length)
                    : undefined
                }
                fontSize="4xl"
                fontWeight="bold"
                lineHeight="1"
                pt={3}
              >
                {rating.count}
              </Text>
            </Box>
          </ScoreCard>
        ))}
      </HStack>
    </VStack>
  );
};
