import { Flex, Tag, Text } from "@chakra-ui/react";
import { ApiInventoryItemStorageLocation } from "@operations-hero/lib-api-client";
import { AsyncSelect } from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { debounce } from "../../utils/debounce";
import { useAuthentication } from "../auth/AuthProvider";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomOptionsProps,
  CustomSelectComponentProp,
  SingleValueSelect,
} from "./select-overrides-types";

export interface InventoryItemLocationAutocompleteProps {
  name?: string;
  isInvalid?: boolean;
  allowEmpty?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  inventoryItemId?: string;
  value: ApiInventoryItemStorageLocation | null;
  showQuantity?: boolean;
  onChange: (location: ApiInventoryItemStorageLocation | null) => void;
  selectedLocations?: ApiInventoryItemStorageLocation[];
  catalogId?: string;
}

const InventoryLocationBadge = ({
  value,
}: {
  value: ApiInventoryItemStorageLocation;
}) => {
  return <Text>{value.storageLocation.name}</Text>;
};

const CustomOptionComponent = createOptionComponent(InventoryLocationBadge);

const CustomSingleValueComponent = (
  props: SingleValueSelect<ApiInventoryItemStorageLocation>,
  showQuantity?: boolean
) => {
  const { data, innerProps } = props;
  return showQuantity ? (
    <Flex {...innerProps} gap={2} alignItems="center" width="97%">
      <InventoryLocationBadge value={data} />
      <Tag colorScheme="blue" fontWeight="semibold">{`${
        data.quantity
      } ${data.units.name.toLocaleLowerCase()}`}</Tag>
    </Flex>
  ) : (
    <InventoryLocationBadge value={data} />
  );
};

export const InventoryItemLocationSelect = ({
  value,
  onChange,
  allowEmpty = true,
  isInvalid,
  isDisabled,
  placeholder,
  showQuantity,
  selectedLocations,
  inventoryItemId,
  catalogId,
}: InventoryItemLocationAutocompleteProps) => {
  const { catalogPolicyMap } = useSelector(
    (state: RootState) => state.localCache
  );
  const { currentAccount, apiClient, isInventoryAdmin } = useAuthentication();

  const allowedStorageLocations = useMemo(() => {
    if (!catalogId) {
      return [];
    }
    const policy = catalogPolicyMap[catalogId];
    const { admin, issuer } = policy;

    if (isInventoryAdmin || admin || issuer === true) {
      return [];
    }

    const restrictedLocations =
      (typeof issuer !== "boolean" && issuer.storageLocations) || [];

    return restrictedLocations;
  }, [isInventoryAdmin, catalogPolicyMap, catalogId]);

  const loadOptions = useCallback(
    (inputValue: string, cb: any) => {
      if (!inventoryItemId) return;
      apiClient
        .findInventoryItemStorageLocations(currentAccount.id, inventoryItemId, {
          pageSize: 50,
        })
        .then((response) => {
          const storageLocations =
            allowedStorageLocations.length > 0
              ? response.data.filter(
                  (loc) => !allowedStorageLocations.includes(loc.id)
                )
              : response.data;

          if (selectedLocations) {
            const filtered = storageLocations.filter(
              (loc) =>
                !selectedLocations.some(
                  (item) =>
                    item.storageLocation.name === loc.storageLocation.name
                )
            );
            cb(filtered);
          } else {
            cb(storageLocations);
          }
        });
    },
    [
      inventoryItemId,
      apiClient,
      currentAccount.id,
      allowedStorageLocations,
      selectedLocations,
    ]
  );

  const debouncedLoadOptions = debounce(loadOptions, 300);

  const handleChange = useCallback(
    (newValue: ApiInventoryItemStorageLocation | null) => {
      if (!allowEmpty && newValue === null) {
        return;
      }
      onChange(newValue);
    },
    [onChange, allowEmpty]
  );

  const getOptionValue = useCallback(
    (location: ApiInventoryItemStorageLocation) => location.id,
    []
  );

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: CustomOptionsProps<ApiInventoryItemStorageLocation>) =>
        CustomOptionComponent(props),
      SingleValue: (
        props: SingleValueSelect<ApiInventoryItemStorageLocation>
      ) => CustomSingleValueComponent(props, showQuantity),
    };
  }, [showQuantity]);

  const key = useMemo(
    () =>
      selectedLocations ? selectedLocations.map((sl) => sl.id).join(",") : "",
    [selectedLocations]
  );

  return (
    <AsyncSelect
      key={`${inventoryItemId}::${key}`}
      name="inventoryItemLocationSelect"
      defaultOptions={true}
      chakraStyles={commonStyles}
      value={value}
      cacheOptions={true}
      getOptionValue={getOptionValue}
      loadOptions={isDisabled ? undefined : debouncedLoadOptions}
      onChange={handleChange}
      components={components}
      isClearable={false}
      isInvalid={isInvalid}
      isMulti={false}
      placeholder={placeholder}
      isDisabled={isDisabled}
    />
  );
};
