import { Box, useDisclosure, VStack } from "@chakra-ui/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import { FC, PropsWithChildren } from "react";
import { AccountModal } from "../../../../account-settings/account-modal/AccountModal";
import { RequestInfo } from "../../../../calendar/requests/RequestInfo";
import { RequestModalHeader } from "../../../../calendar/requests/RequestInfoHeader";

type WithRequestDetailsModalProps = PropsWithChildren & {
  request: ApiRequest;
};

export const WithRequestDetailsModal: FC<WithRequestDetailsModalProps> = ({
  children,
  request,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      {isOpen && (
        <AccountModal
          content={
            <VStack pt={3} align="stretch" gap={12}>
              <RequestModalHeader request={request} />
              <RequestInfo request={request} onCloseModal={onClose} />
            </VStack>
          }
          header={false}
          contentProps={{ maxW: "xl" }}
          onClose={onClose}
          isOpen={isOpen}
          closeButton={false}
        />
      )}
    </>
  );
};
