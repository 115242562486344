import { HStack, Icon, Text, TextProps, VStack } from "@chakra-ui/react";
import { ApiBudget, ApiBudgetSummary } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { RiWallet3Line } from "react-icons/ri";

type BudgetBadgeProps = {
  value: ApiBudget | ApiBudgetSummary;
  codeProps?: TextProps;
  nameProps?: TextProps;
};

export const BudgetBadge: FC<BudgetBadgeProps> = ({
  value,
  codeProps,
  nameProps,
}) => {
  const { code, name } = value;
  return (
    <HStack w="full" gap="3">
      <Icon as={RiWallet3Line} boxSize="20px" />
      <VStack w="full" align="stretch" gap={0}>
        <Text
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          casing="uppercase"
          {...codeProps}
        >
          {code}
        </Text>
        <Text
          fontSize="sm"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          {...nameProps}
        >
          {name}
        </Text>
      </VStack>
    </HStack>
  );
};
