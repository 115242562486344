import { SearchIcon } from "@chakra-ui/icons";
import { Flex, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { ApiProject } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useThunkDispatch } from "../../../../../../store";
import { updateFilters } from "../../../../../../store/planning-hq/requests/updateFilters.thunk";
import { DateFields } from "../../../../../../store/request-list.slice";
import { DateFilter, RequestDateFilter } from "./DateFilter";
import { GeneralFilters } from "./GeneralFilters";
import { SortFilter } from "./SortFilter";
type RequestFiltersProps = {
  project: ApiProject;
};
export const RequestFilters: FC<RequestFiltersProps> = ({ project }) => {
  const thunkDispatch = useThunkDispatch();
  const { filters } = useSelector((state: RootState) => state.requestList);
  const [requestsFilters, setRequestsFilters] = useState(filters);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      thunkDispatch(updateFilters({ search: e.target.value }));
    },
    [thunkDispatch]
  );

  const onDateFieldChange = useCallback(
    (dateField: DateFields | undefined) => {
      const currentDate = requestsFilters.date;
      if (!currentDate || !currentDate.value || !dateField) return;
      thunkDispatch(
        updateFilters({
          date: {
            ...currentDate,
            field: dateField,
          },
        })
      );
    },
    [requestsFilters.date, thunkDispatch]
  );

  const onDateRangeChange = useCallback(
    (date: DateFilter) => {
      thunkDispatch(updateFilters({ date }));
    },
    [thunkDispatch]
  );

  useEffect(() => {
    setRequestsFilters(filters);
  }, [filters]);

  return (
    <Flex w="full" justifyContent="end" gap={2}>
      <InputGroup maxW={350} size="sm">
        <InputLeftElement children={<SearchIcon color="gray.300" />} />
        <Input
          type="text"
          placeholder="Request #/Summary"
          onChange={handleSearchChange}
        />
      </InputGroup>
      <RequestDateFilter
        onDateFieldChange={onDateFieldChange}
        onDateRangeChange={onDateRangeChange}
        date={requestsFilters.date}
        menuProps={{ placement: "auto", preventOverflow: true, flip: true }}
      />
      <SortFilter project={project} />
      <GeneralFilters project={project} filters={requestsFilters} />
    </Flex>
  );
};
