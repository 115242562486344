import { Button, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { FC, useMemo } from "react";
import { FiHeart } from "react-icons/fi";

interface MySearchesProps {
  onSearchModalOpen: () => void;
  onSaveModalOpen: () => void;
  canSave: boolean;
}

export const MySearches: FC<MySearchesProps> = ({
  onSearchModalOpen,
  onSaveModalOpen,
  canSave,
}) => {
  const isMobileMode = useBreakpointValue({ base: true, md: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });
  const isDesktop = useMemo(
    () => !isMobileMode && !isTablet,
    [isMobileMode, isTablet]
  );

  return (
    <>
      <Button
        ml={isDesktop ? 2 : 0}
        mr={2}
        colorScheme="blue"
        variant="ghost"
        onClick={onSearchModalOpen}
        size={isDesktop ? "md" : "sm"}
        _focus={{ boxShadow: "none" }}
      >
        {isMobileMode ? "Searches" : "My Searches"}
      </Button>

      <IconButton
        colorScheme="blue"
        aria-label="Save filter"
        variant="outline"
        icon={<FiHeart />}
        onClick={onSaveModalOpen}
        isDisabled={!canSave}
        size={isDesktop ? "md" : "sm"}
      />
    </>
  );
};
