import { IconButton, Tooltip } from "@chakra-ui/react";
import { FC } from "react";
import { FaRegQuestionCircle } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";

export const QAButton: FC = () => {
  return (
    <Tooltip label="Resources" hasArrow gutter={12}>
      <IconButton
        aria-label="Resources"
        icon={<FaRegQuestionCircle />}
        size="sm"
        fontSize="18px"
        variant="outline"
        colorScheme="blue"
        as={RouterLink}
        to={"/resources"}
      />
    </Tooltip>
  );
};
