import { useMemo } from "react";
import { SubscriptionContextInterface } from "../../../components/shell/AppShell";
import { useAdminRoutes } from "./AccountRoutes";
import { useEventRoutes } from "./EventsRoutes";
import { useInvoiceRoutes } from "./InvoicesRoutes";
import { usePlanningRoutes } from "./PlanningRoutes";
import { useRequestRoutes } from "./RequestRoutes";

export const useMainAccountRoutes = (
  subscriptions: SubscriptionContextInterface
) => {
  const { hasRequests, hasEvents, hasPlanning } = subscriptions;
  const { routes: adminRoutes } = useAdminRoutes();
  const { routes: requestsRoutes } = useRequestRoutes(hasRequests);
  const { routes: eventRoutes } = useEventRoutes(hasEvents);
  const { routes: invoiceRoutes } = useInvoiceRoutes(hasEvents);
  const { routes: planningRoutes } = usePlanningRoutes(hasPlanning);

  const accountRoutes = useMemo(
    () => [
      ...adminRoutes,
      ...requestsRoutes,
      ...eventRoutes,
      ...invoiceRoutes,
      ...planningRoutes,
    ],

    [adminRoutes, eventRoutes, invoiceRoutes, requestsRoutes, planningRoutes]
  );

  return { accountRoutes };
};
