import { Button, ButtonProps, useDisclosure } from "@chakra-ui/react";
import {
  ApiBudget,
  ApiFundingSource,
  CreateApiBudgetFundingSource,
} from "@operations-hero/lib-api-client";
import { FC, useCallback } from "react";
import { RiAddLine } from "react-icons/ri";
import { useAllocationsContext } from "../../../../../components/allocations/AllocationsContext";
import { useAuthentication } from "../../../../../components/auth/AuthProvider";
import { useShowToast } from "../../../../../hooks/showToast";
import { useThunkDispatch } from "../../../../../store";
import { findBudgetFundingSources } from "../../../../../store/planning-hq/budgets/budget-page.slice";
import { NewFundingSourceAllocationModal } from "./NewAllocationModal";
type AddAllocationButtonProps = {
  budget: ApiBudget;
  buttonProps?: ButtonProps;
};

export const AddAllocationButton: FC<AddAllocationButtonProps> = ({
  budget,
  buttonProps,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const toast = useShowToast();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const { setAllocations } = useAllocationsContext<ApiFundingSource>();

  const thunkDispatch = useThunkDispatch();
  const handleNewAllocationSubmit = useCallback(
    async (allocation: CreateApiBudgetFundingSource) => {
      const newAllocation = await apiClient
        .createBudgetFundingSource(currentAccount.id, allocation)
        .then((response) => {
          if (!response.success) throw new Error("Funds not allocated");

          thunkDispatch(
            findBudgetFundingSources({
              apiClient,
              budgetId: budget.id,
            })
          )
            .unwrap()
            .then((response) => {
              setAllocations(
                response.map((alloc) => ({
                  source: {
                    ...(alloc.fundingSource as ApiFundingSource),
                    unallocatedAmount: (alloc.fundingSource as ApiFundingSource)
                      .unallocatedFunds,
                  },
                  amount: alloc.amountAllocated,
                }))
              );
            });
          toast("success", "Funds allocated successfully");
          return response;
        })
        .catch((e) => {
          toast("error", "An error occured while allocating funds");
        })
        .finally(() => {
          onClose();
        });
      return newAllocation;
    },
    [
      apiClient,
      budget.id,
      currentAccount.id,
      onClose,
      setAllocations,
      thunkDispatch,
      toast,
    ]
  );

  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={<RiAddLine />}
        colorScheme="blue"
        variant="outline"
        size="sm"
        {...buttonProps}
      >
        Add Fund
      </Button>

      <NewFundingSourceAllocationModal
        budget={budget}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleNewAllocationSubmit}
      />
    </>
  );
};
