import { Text, useColorModeValue } from "@chakra-ui/react";
import {
  ApiInspection,
  ApiInspectionStatus,
} from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import { FC, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import { LocationTwoLine } from "../../../components/badges/LocationTwoLine";
import { RatingBadge } from "../../../components/badges/RatingBadge";
import { StatusBadge } from "../../../components/badges/StatusBadge";
import { UserBadge } from "../../../components/badges/UserBadge";
import {
  DataTable,
  DataTableColumn,
} from "../../../components/data-table/DataTable";
import { useScreenBreakpoints } from "../../../hooks/useScreenBreakpoints";
import { removeTimezone } from "../../../utils/timezone";
import { InspectionButtons } from "./components/InspectionsButtons";

export type InspectionsTableProps = {
  inspections: ApiInspection[];
};
const FISCAL_YEAR_FORMAT_DATE_FNS = "MMMM yyyy";
const DATE_FORMAT = "MMM dd, yyyy";
export const InspectionTable: FC<InspectionsTableProps> = ({ inspections }) => {
  const navigate = useNavigate();

  const { isDesktop } = useScreenBreakpoints();
  const { pathname } = useLocation();
  const rowBg = useColorModeValue("gray.100", "blue.800");

  const isInspectorView = useMemo(
    () => !pathname.includes("/account"),
    [pathname]
  );
  let columns = useMemo<DataTableColumn<ApiInspection>[]>(
    () => [
      {
        Header: "School",
        accessor: (inspection) => inspection,
        disableSortBy: true,
        width: 120,
        maxWidth: 120,
        Cell: ({ value }: CellProps<ApiInspection, ApiInspection>) => {
          return <LocationTwoLine value={value.location} />;
        },
      },
    ],
    []
  );
  if (!isInspectorView) {
    columns.push({
      Header: "Inspector",
      accessor: (inspection) => inspection,
      width: 70,
      maxWidth: 70,
      disableSortBy: true,
      Cell: ({ value }: CellProps<ApiInspection, ApiInspection>) => {
        return !value.inspector ? (
          <Text fontSize="sm" fontStyle="italic">
            Unassigned
          </Text>
        ) : (
          <UserBadge value={value.inspector} />
        );
      },
    });
  }

  columns.push(
    {
      Header: "Due Date",
      accessor: (inspection) => inspection,
      disableSortBy: true,
      width: 90,
      maxWidth: 90,
      Cell: ({ value }: CellProps<ApiInspection, ApiInspection>) => {
        return !value.dueDate ? (
          <Text>-</Text>
        ) : (
          <Text>{format(new Date(value.dueDate), DATE_FORMAT)}</Text>
        );
      },
    },
    {
      Header: "Fiscal Year",
      width: 150,
      maxWidth: 150,
      accessor: (inspection) => {
        const fiscalYearStart = inspection.fiscalYearStart
          ? format(
              removeTimezone(inspection.fiscalYearStart),
              FISCAL_YEAR_FORMAT_DATE_FNS
            )
          : null;

        const fiscalYearEnd = inspection.fiscalYearEnd
          ? format(
              removeTimezone(inspection.fiscalYearEnd),
              FISCAL_YEAR_FORMAT_DATE_FNS
            )
          : null;

        return {
          fiscalYearStart,
          fiscalYearEnd,
          id: inspection.id,
        };
      },
      disableSortBy: true,
      Cell: ({
        value,
      }: CellProps<
        ApiInspection,
        {
          fiscalYearStart: string | null;
          fiscalYearEnd: string | null;
          id: string;
        }
      >) => {
        return value.id === "new" ||
          !value.fiscalYearStart ||
          !value.fiscalYearEnd ? (
          <Text>-</Text>
        ) : (
          <Text>{`${value.fiscalYearStart} -  ${value.fiscalYearEnd}`}</Text>
        );
      },
    }
  );

  if (!isInspectorView) {
    columns.push(
      {
        Header: "Status",
        width: 110,
        maxWidth: 110,
        accessor: (inspection) => inspection,
        disableSortBy: true,
        Cell: ({ value }: CellProps<ApiInspection, ApiInspection>) => {
          return value.id === "new" ? null : (
            <StatusBadge
              status={value.status}
              colorScheme={
                value.status === ApiInspectionStatus.submitted
                  ? "green"
                  : undefined
              }
            />
          );
        },
      },
      {
        Header: "Actions",
        accessor: (inspection) => inspection,
        width: 100,
        maxWidth: 100,
        disableSortBy: true,
        Cell: ({ value }: CellProps<ApiInspection, ApiInspection>) => {
          return <InspectionButtons inspection={value} />;
        },
      }
    );
  } else if (isInspectorView) {
    columns.push(
      {
        Header: "Rooms",
        accessor: (inspection) => inspection,
        disableSortBy: true,
        width: 90,
        maxWidth: 90,
        Cell: ({ value }: CellProps<ApiInspection, ApiInspection>) => {
          return (
            <Text>{`${value.roomsInspected} of ${value.totalRooms}`}</Text>
          );
        },
      },
      {
        Header: "Rating",
        accessor: (inspection) => inspection,
        disableSortBy: true,
        width: 90,
        maxWidth: 90,
        Cell: ({ value }: CellProps<ApiInspection, ApiInspection>) => {
          return value.status === ApiInspectionStatus.completed ||
            value.status === ApiInspectionStatus.submitted ? (
            <RatingBadge inspection={value} />
          ) : (
            <Text>N/A</Text>
          );
        },
      },
      {
        Header: "Status",
        width: 110,
        maxWidth: 110,
        accessor: (inspection) => inspection,
        disableSortBy: true,
        Cell: ({ value }: CellProps<ApiInspection, ApiInspection>) => {
          return value.id === "new" ? null : (
            <StatusBadge
              status={value.status}
              colorScheme={
                value.status === ApiInspectionStatus.submitted
                  ? "green"
                  : undefined
              }
            />
          );
        },
      }
    );
  }

  const onRowClick = useCallback(
    (inspection: ApiInspection) => {
      if (inspection.id === "new") return;
      navigate(`${pathname}/${inspection.id}/details`);
    },
    [navigate, pathname]
  );

  if (!inspections?.length) return null;

  return (
    <DataTable
      columns={columns}
      data={inspections}
      hiddenHeader={isDesktop ? false : true}
      onRowClick={onRowClick}
      rowProps={{
        cursor: "pointer",
        _hover: isDesktop
          ? {
              bg: rowBg,
            }
          : undefined,
      }}
    />
  );
};
