import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Link,
} from "@chakra-ui/react";
import { ApiUserSummary } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { useCallback } from "react";
import { useAuthentication } from "../auth/AuthProvider";
import { UserAutocomplete } from "../selects/UserAutocomplete";

export interface UserAutocompleteControlProps {
  label: string;
  name: string;
  value: ApiUserSummary | null;
  assignSelf?: string;
  emptyText?: string;
  labelProps?: FormLabelProps;
  bgColor?: string;
  isDisabled?: boolean;
}

export const UserAutocompleteControl = ({
  name,
  value,
  label,
  assignSelf,
  emptyText,
  labelProps,
  bgColor,
  isDisabled,
}: UserAutocompleteControlProps) => {
  const { submitCount } = useFormikContext();
  const { currentUser } = useAuthentication();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
  });

  const handleOnChange = useCallback(
    (user: ApiUserSummary | null) => {
      helper.setTouched(true);
      helper.setValue(user || null);
    },
    [helper]
  );

  const handleAssignSelf = useCallback(() => {
    handleOnChange(currentUser);
  }, [currentUser, handleOnChange]);

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <Flex justifyContent="space-between" alignContent="flex-start">
        <FormLabel htmlFor={name} {...labelProps}>
          {label}
        </FormLabel>
        {assignSelf && (
          <Link color="blue.400" onClick={handleAssignSelf}>
            {assignSelf}
          </Link>
        )}
      </Flex>
      <UserAutocomplete
        {...field}
        onChange={handleOnChange}
        name={name}
        allowEmpty={false}
        emptyText={emptyText}
        bgColor={bgColor}
        isDisabled={isDisabled}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
