import {
  Box,
  Container,
  GridItem,
  Heading,
  SimpleGrid,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  CumulioDashboard,
  CumulioDashboardComponent,
} from "@cumul.io/react-cumulio-dashboard";
import {
  ApiDashboardSimpleStats,
  ApiRequestsQueryFilter,
  ApiRequestStatus,
  ApiRequestType,
  ApiWorkflow,
} from "@operations-hero/lib-api-client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { DatesFilter } from "../../../components/filters/DateFilter";
import {
  LocationFilter,
  LocationFilterValue,
} from "../../../components/filters/LocationFilter";
import { StatCard } from "../../../components/stat-card/stat-card";
import { useAllowedLocations } from "../../../hooks/useAllowedLocation";
import useCumulioTheme from "../../../hooks/useCumulioTheme";
import { useQueryStringFilter } from "../../../hooks/useQueryStringFilter";
import { RootState } from "../../../store";
import {
  RelativeDateFilter,
  RequestListFilterState,
  setRequestListTitle,
} from "../../../store/request-list.slice";
import { filtersInitialState } from "../../../store/requests/defaults";
import { WorkflowFilter } from "../../requests/filters/WorkflowFilter";

interface DashboardFilters {
  workflows: string[];
  locations: string[];
  due: string[];
  start: string[];
  created: string[];
  updated: string[];
  statusUpdated: string[];
  completed: string[];
}
const DEFAULT_FILTERS: DashboardFilters = {
  workflows: [],
  locations: [],
  due: [],
  start: [],
  created: [],
  updated: [],
  statusUpdated: [],
  completed: [],
};

export const WORKFLOW_FILTERS = "workflow-filters";

const DashboardConfig = {
  collectionId: "5422840d-565d-4cb2-89e1-88852c11714b",
  dashboardId: "1cde28a4-8226-448d-a537-928b65e41b84",
  datasetId: "34db516f-6db0-4dd1-b462-1492b8843e56",
  cols: {
    workflowId: "b01e1106-1575-49ed-9293-95cd66e0c59d",
    locationId: "a113edc5-6eca-4533-bce1-2cfabaa592be",
  },
  charts: {
    createdTimeline: "4da722c2-c421-4c70-975f-e934ba295ecc",
    completedTimeline: "a715d771-9412-4181-bad7-cdd76214f028",
    categoryBars: "c8ba68cb-d82c-4171-97cf-9a46f8bac1ac",
    categorySunburst: "f4c3f986-c879-414c-88fe-440f0f7bdd81",
  },
};

export default function LandingPage() {
  const luzmoUrl = process.env.REACT_APP_LUZMO_URL || "";
  const starburstRef = useRef<CumulioDashboard>(null);
  const {
    apiClient,
    currentAccount,
    currentUser: user,
    isProductAdmin,
  } = useAuthentication();

  const { policyMap, workflows, isViewerOnly } = useSelector(
    (state: RootState) => state.localCache
  );
  const { allowedLocations } = useAllowedLocations();
  const filtersContainerColor = useColorModeValue("blue.50", "blue.900");

  const defaultValue: DashboardFilters = useMemo(() => {
    const defaultFilters = { ...DEFAULT_FILTERS };
    const workflowFilters = localStorage.getItem(WORKFLOW_FILTERS);
    if (workflowFilters) {
      const validWorkflowFilters: string[] = JSON.parse(workflowFilters);
      if (Array.isArray(validWorkflowFilters)) {
        defaultFilters.workflows = workflows
          .map((wf) => wf.id)
          .filter((vwf) => validWorkflowFilters.includes(vwf));
      }
    }
    return defaultFilters;
  }, [workflows]);

  const { filter, filterInitialized, updateQueryString } = useQueryStringFilter(
    { qsParamName: "filters", defaultValue }
  );

  const { widgetTheme, loader } = useCumulioTheme();

  const [stats, setStats] = useState<ApiDashboardSimpleStats>();
  const [loading, setIsLoading] = useState(false);
  const [cumulio, setCumulio] = useState({ id: "", token: "" });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // # of New/Approved Requests: number of requests that are New or Approved status (thinking we combine these since some workflows will not require approval)
  // # of Queued Request: number of requests that are in Queued status
  // # of Started Requests: number of requests that are in Started status
  // Percent Open: % of Queued and Started requests with a status of Started
  //      this is % of queued and started vs  in hold / new / review, ignoring hold new
  const reviewerPlusWorkflows = useMemo(() => {
    const wfIds = Object.keys(policyMap);
    return isProductAdmin
      ? wfIds
      : wfIds.filter((key) => {
          const p = policyMap[key];
          return p.viewer !== false || p.reviewer !== false || p.admin === true;
        });
  }, [policyMap, isProductAdmin]);

  const data = useMemo(() => {
    if (!stats) {
      return [];
    }

    const statusMap = stats.statusCounts.reduce<Record<string, number>>(
      (result, item) => {
        result[item.status] = item.count;
        return result;
      },
      {}
    );

    const counts = {
      [ApiRequestStatus.new]: statusMap[ApiRequestStatus.new] || 0,
      [ApiRequestStatus.approved]: statusMap[ApiRequestStatus.approved] || 0,
      [ApiRequestStatus.queued]: statusMap[ApiRequestStatus.queued] || 0,
      [ApiRequestStatus.started]: statusMap[ApiRequestStatus.started] || 0,
      [ApiRequestStatus.review]: statusMap[ApiRequestStatus.review] || 0,
      [ApiRequestStatus.hold]: statusMap[ApiRequestStatus.hold] || 0,
    };

    return [
      {
        label: "New/Approved Requests",
        value: counts[ApiRequestStatus.new] + counts.approved,
        filters: {
          ...filtersInitialState,
          ...filter,
          quickFilter: ApiRequestsQueryFilter.ALL,
          statuses: [ApiRequestStatus.new, ApiRequestStatus.approved],
        },
      },
      {
        label: "Queued Requests",
        value: counts.queued,
        filters: {
          ...filtersInitialState,
          ...filter,
          quickFilter: ApiRequestsQueryFilter.ALL,
          statuses: [ApiRequestStatus.queued],
        },
      },
      {
        label: "Started Requests",
        value: counts.started,
        filters: {
          ...filtersInitialState,
          ...filter,
          quickFilter: ApiRequestsQueryFilter.ALL,
          statuses: [ApiRequestStatus.started],
        },
      },
      {
        label: "Routing Requests",
        //@ts-ignore
        value: stats.routedRequests,
        filters: {
          ...filtersInitialState,
          ...filter,
          quickFilter: ApiRequestsQueryFilter.ROUTED_REQUESTS,
          statuses: [],
          // this does not need reviewerPlusWorkflows link, it already has policies applied.
        },
      },
      {
        label: "OVERDUE Requests",
        value: stats.overdueCount.count || 0,
        filters: {
          ...filtersInitialState,
          ...filter,
          quickFilter: ApiRequestsQueryFilter.OVERDUE,
          statuses: [],
        },
      },
      {
        label: "Closed last 30 days",
        value: stats.closedLast30.count || 0,
        filters: {
          ...filtersInitialState,
          ...filter,
          quickFilter: ApiRequestsQueryFilter.RECENTLY_COMPLETED_CLOSED,
          statuses: [],
        },
      },
      {
        label: "Closed Reactive in 7 days",
        value:
          stats.reactive7days.total === 0
            ? "N/A"
            : `${Math.round(
                (stats.reactive7days.under7 / stats.reactive7days.total) * 100
              )}%`,
        filters: {
          ...filtersInitialState,
          ...filter,
          quickFilter: ApiRequestsQueryFilter.RECENTLY_COMPLETED_CLOSED,
          types: [ApiRequestType.corrective],
          date: {
            field: "completed",
            type: "relative",
            value: "next7d",
          } as RelativeDateFilter,
          statuses: [],
        },
      },
      {
        label: "Closed Preventative in 30 days",
        value:
          stats.preventative30days.total === 0
            ? "N/A"
            : `${Math.round(
                (stats.preventative30days.under30 /
                  stats.preventative30days.total) *
                  100
              )}%`,
        filters: {
          ...filtersInitialState,
          ...filter,
          quickFilter: ApiRequestsQueryFilter.RECENTLY_COMPLETED_CLOSED,
          types: [ApiRequestType.scheduled],
          statuses: [],
        },
      },
    ];
  }, [stats, filter]);

  const redirectToRequests = useCallback(
    (filters: RequestListFilterState, title: string) => {
      const filterBtoa = btoa(JSON.stringify(filters));
      const url = `/requests?filter=${filterBtoa}`;
      navigate(url);
      dispatch(setRequestListTitle(title));
    },
    [navigate, dispatch]
  );

  useEffect(() => {
    if (!filterInitialized) {
      return;
    }
    const filters: any[] = [];

    filters.push({
      clause: "where",
      origin: "global",
      securable_id: DashboardConfig.datasetId,
      column_id: DashboardConfig.cols.workflowId,
      expression: "? in ?",
      value:
        filter.workflows && filter.workflows.length
          ? filter.workflows
          : reviewerPlusWorkflows,
    });

    filters.push({
      clause: "where",
      origin: "global",
      securable_id: DashboardConfig.datasetId,
      column_id: DashboardConfig.cols.locationId,
      expression: "? in ?",
      value:
        filter.locations && filter.locations.length
          ? filter.locations
          : allowedLocations.map((x) => x.id),
    });

    if (process.env.REACT_APP_NODE_ENV !== "development" && !isViewerOnly) {
      apiClient
        .getEmbeddedToken(currentAccount.id, DashboardConfig.collectionId, {
          theme: { ...widgetTheme, margins: [0, 0] },
          filters,
        })
        .then((result) => {
          setCumulio(result);
        });
    }
  }, [
    apiClient,
    currentAccount,
    widgetTheme,
    filter,
    filterInitialized,
    reviewerPlusWorkflows,
    allowedLocations,
    isViewerOnly,
  ]);

  useEffect(() => {
    setIsLoading(true);
    apiClient
      .getDashboardSimpleStats(currentAccount.id, {
        ...filter,
        workflow: filter.workflows,
        location: filter.locations,
      })
      .then((result) => {
        setStats(result);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [allowedLocations, apiClient, currentAccount, filter]);

  const updateWorkflowFilter = useCallback(
    (values: ApiWorkflow[]) => {
      const workflows = values.map((item) => item.id);
      updateQueryString({
        ...filter,
        workflows,
      });
      localStorage.setItem(WORKFLOW_FILTERS, JSON.stringify(workflows));
    },
    [filter, updateQueryString]
  );

  const updateLocationFilter = useCallback(
    ({ value }: LocationFilterValue) => {
      updateQueryString({
        ...filter,
        locations: value.map((loc) => loc.id).sort(),
      });
    },
    [updateQueryString, filter]
  );

  const handleOnChangeFilters = useCallback(
    (dateFilters: Record<string, Array<string | null> | undefined>) => {
      const updatedFilters: Partial<DashboardFilters> = {};

      if (dateFilters.due) {
        updatedFilters.due = dateFilters.due.filter(
          (date): date is string => date !== null
        );
      }
      if (dateFilters.start) {
        updatedFilters.start = dateFilters.start.filter(
          (date): date is string => date !== null
        );
      }
      if (dateFilters.created) {
        updatedFilters.created = dateFilters.created.filter(
          (date): date is string => date !== null
        );
      }
      if (dateFilters.updated) {
        updatedFilters.updated = dateFilters.updated.filter(
          (date): date is string => date !== null
        );
      }
      if (dateFilters.statusUpdated) {
        updatedFilters.statusUpdated = dateFilters.statusUpdated.filter(
          (date): date is string => date !== null
        );
      }
      if (dateFilters.completed) {
        updatedFilters.completed = dateFilters.completed.filter(
          (date): date is string => date !== null
        );
      }

      updateQueryString(updatedFilters as DashboardFilters);
    },
    [updateQueryString]
  );

  useEffect(() => {
    if (!filterInitialized) updateQueryString(defaultValue);
  }, [defaultValue, updateQueryString, filterInitialized]);

  return (
    <Container maxWidth="8xl" display="flex" gap={4} flexDirection="column">
      <Box>
        <Heading size="sm">
          {user.firstName}, you are currently logged into {currentAccount.name}
        </Heading>
      </Box>
      <Box
        p={2}
        gap={4}
        minW="100%"
        display="flex"
        flexWrap="wrap"
        bgColor={filtersContainerColor}
      >
        <WorkflowFilter
          value={filter.workflows || []}
          onChange={updateWorkflowFilter}
          optionsIds={reviewerPlusWorkflows}
        />
        <LocationFilter
          value={filter.locations || []}
          allowedLocations={allowedLocations}
          onChange={updateLocationFilter}
        />
        <Box flex="1" />
        <DatesFilter
          dateFilters={{
            due: filter.due,
            start: filter.start,
            created: filter.created,
            updated: filter.updated,
            statusUpdated: filter.statusUpdated,
            completed: filter.completed,
          }}
          dateTypes={{
            due: "Due Date",
            start: "Start date",
            created: "Created",
            updated: "Updated",
            statusUpdated: "Status Updated",
            completed: "Completed",
          }}
          defaultType={"created"}
          onChangeFilters={handleOnChangeFilters}
          predefinedRanges={true}
        />
      </Box>

      <Box as="section" pt={4}>
        {loading && <Spinner />}
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing="6">
          {data.map((stat, idx) => (
            <StatCard
              key={idx + stat.label}
              data={stat}
              onCardClick={(filter) => redirectToRequests(filter, stat.label)}
            />
          ))}
          {!isViewerOnly && cumulio.token && cumulio.id && (
            <>
              <GridItem
                colSpan={{ base: 1, md: 2, lg: 2 }}
                h="260px"
                rounded="8px"
              >
                <CumulioDashboardComponent
                  authKey={cumulio.id}
                  authToken={cumulio.token}
                  loaderBackground={loader.bg}
                  loaderSpinnerColor={loader.spinner}
                  appServer={luzmoUrl}
                  dashboardId={DashboardConfig.dashboardId}
                  itemId={DashboardConfig.charts.createdTimeline}
                  timezoneId={
                    user.timeZone ||
                    currentAccount.timeZone ||
                    "America/New_York"
                  }
                />
              </GridItem>
              <GridItem
                colSpan={{ base: 1, md: 2, lg: 2 }}
                h="260px"
                rounded="8px"
              >
                <CumulioDashboardComponent
                  authKey={cumulio.id}
                  authToken={cumulio.token}
                  loaderBackground={loader.bg}
                  loaderSpinnerColor={loader.spinner}
                  appServer={luzmoUrl}
                  dashboardId={DashboardConfig.dashboardId}
                  itemId={DashboardConfig.charts.completedTimeline}
                  timezoneId={
                    user.timeZone ||
                    currentAccount.timeZone ||
                    "America/New_York"
                  }
                ></CumulioDashboardComponent>
              </GridItem>
              <GridItem colSpan={{ base: 1, md: 2, lg: 2 }} rounded="8px">
                <CumulioDashboardComponent
                  authKey={cumulio.id}
                  authToken={cumulio.token}
                  loaderBackground={loader.bg}
                  loaderSpinnerColor={loader.spinner}
                  appServer={luzmoUrl}
                  dashboardId={DashboardConfig.dashboardId}
                  itemId={DashboardConfig.charts.categoryBars}
                  timezoneId={
                    user.timeZone ||
                    currentAccount.timeZone ||
                    "America/New_York"
                  }
                ></CumulioDashboardComponent>
              </GridItem>
              <GridItem colSpan={{ base: 1, md: 2, lg: 2 }} rounded="8px">
                {/* Category Starburst */}
                <CumulioDashboardComponent
                  ref={starburstRef}
                  authKey={cumulio.id}
                  authToken={cumulio.token}
                  loaderBackground={loader.bg}
                  loaderSpinnerColor={loader.spinner}
                  appServer={luzmoUrl}
                  dashboardId={DashboardConfig.dashboardId}
                  itemId={DashboardConfig.charts.categorySunburst}
                  timezoneId={
                    user.timeZone ||
                    currentAccount.timeZone ||
                    "America/New_York"
                  }
                ></CumulioDashboardComponent>
              </GridItem>
            </>
          )}
        </SimpleGrid>
      </Box>
    </Container>
  );
}
