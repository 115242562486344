import {
  ApiClient,
  ApiInspection,
  ApiInspectionStatus,
  ApiPagedResult,
  ApiPagingInspectionOptions,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../..";
import { getTopLevelBuildingLocationsFunction } from "../../../../../utils/locationUtils";
import { InspectionListSliceProps } from "../inspection-list.slice";

type LoadInspectionsParams = {
  apiClient: ApiClient;
  filters?: ApiPagingInspectionOptions & {
    total: number;
  };
};

export const loadInspections = createAsyncThunk<
  ApiPagedResult<ApiInspection> & {
    hasConfigs: boolean;
  },
  LoadInspectionsParams
>("inspections/load", async ({ apiClient, filters }, thunkAPI) => {
  const { auth, inspectionListSlice, localCache } =
    thunkAPI.getState() as RootState;
  const { currentAccount } = auth;
  // first run check to see if we need to import or navigate to the inspection creator
  const configs = await apiClient.findInspectionsConfig(currentAccount.id, {
    pageSize: 1,
  });

  const { locationMap } = localCache;
  const locations = getTopLevelBuildingLocationsFunction(locationMap);
  const { filters: filtersSlice } = inspectionListSlice;
  const options = {
    ...filtersSlice,
    ...filters,
  };

  const response = await apiClient.findInspections(currentAccount.id, options); //load 2024-2025 params
  const inspections =
    options.showUnassigned && !options.isInspector
      ? locations
          .filter(
            (loc) => !response.data.some((insp) => insp.location.id === loc.id)
          )
          .map((loc) => {
            const inspection: ApiInspection = {
              id: "new",
              configId: "",
              location: loc,
              fiscalYearStart: "", //2024 2025
              fiscalYearEnd: "",
              inspector: null,
              dueDate: "",
              submittedDate: null,
              status: ApiInspectionStatus.pending,
              totalRooms: 0,
              roomsInspected: 0,
              rating: 0,
              ratingCeiling: 0,
              eSign: null,
              submitNotes: "",
              created: "",
              updated: null,
              createdBy: {
                firstName: "user",
                lastName: "",
                id: "null-user",
                email: "",
                phone: "",
                profileImage: "",
                timeZone: null,
              },
              updatedBy: null,
              isActive: true,
            };

            return inspection;
          })
      : [];

  return {
    hasConfigs: configs.data.length > 0,
    ...response,
    data: [...inspections, ...response.data],
  };
});

export const loadInspectionsHandler = (
  builder: ActionReducerMapBuilder<InspectionListSliceProps>
) => {
  builder.addCase(loadInspections.pending, (state) => {
    state.loadingStatus = "pending";
  });
  builder.addCase(loadInspections.rejected, (state) => {
    state.loadingStatus = "rejected";
  });
  builder.addCase(loadInspections.fulfilled, (state, action) => {
    state.loadingStatus = "fulfilled";
    const { data, total } = action.payload;
    state.inspections = data;
    state.filters = {
      ...state.filters,
    };
    state.total = total;
    state.hasConfigs = action.payload.hasConfigs;
  });
};
