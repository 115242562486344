import { Box, Flex, HStack, Text, useColorMode } from "@chakra-ui/react";
import { FC } from "react";
import { priorityColorMap } from "../../../../components/badges/PriorityBadge";
import { StatusBadge } from "../../../../components/badges/StatusBadge";
import { AssigneesBadge } from "../../components/AssigneeBadge";
import { TimelineItemProps } from "./types";

type LargeTimelineItemProps = TimelineItemProps;

export const LargeTimelineItem: FC<LargeTimelineItemProps> = ({ request }) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      borderRadius="md"
      color="black"
      position="relative"
      height="5.8rem"
      borderColor="gray.500"
      backgroundColor={colorMode === "light" ? "white" : "gray.700"}
    >
      <Flex
        w="full"
        height="full"
        gap={2}
        alignItems="start"
        borderRadius="md"
        borderWidth="thin"
        pl={2}
        pr={2}
        py={2}
        boxShadow="base"
      >
        <Box h="full">
          <Box
            h="full"
            background={priorityColorMap[request.priority]}
            borderRadius="md"
            w="8px"
          />
        </Box>

        <Flex
          flexDir="column"
          minWidth={0}
          maxW="500px"
          position="sticky"
          left="0px"
          gap={1}
        >
          <HStack
            w="full"
            gap={2}
            alignItems="baseline"
            divider={
              <Text
                color={colorMode === "light" ? "gray.600" : "gray.500"}
                fontSize="md"
                fontWeight="thin"
              >{`|`}</Text>
            }
          >
            <Text
              isTruncated
              color={colorMode === "light" ? "gray.600" : "white"}
              fontWeight="bold"
            >
              {request.key}
            </Text>
            <Box
              backgroundColor={priorityColorMap[request.priority]}
              w="fit-content"
              px={1}
              borderRadius="sm"
              isTruncated
            >
              <Text fontSize="xs" fontWeight="bold" color="white" isTruncated>
                {request.priority.toUpperCase()}
              </Text>
            </Box>
          </HStack>
          <HStack w="full" gap={2}>
            <StatusBadge
              status={request.status}
              badgeProps={{
                w: "fit-content",
                fontSize: "small",
                borderRadius: "sm",
                color: colorMode === "light" ? "gray.500" : "white",
                isTruncated: true,
              }}
            />

            <Text
              flexShrink="99999"
              isTruncated
              color={colorMode === "light" ? "black" : "gray.50"}
            >{`${request.summary}`}</Text>
          </HStack>

          <HStack w="full" gap={3} alignContent="center">
            <AssigneesBadge
              assignees={request.assignees}
              avatarProps={{
                borderWidth: "1px",
                size: "xs",
                borderColor: "white",
              }}
              avatarGroupProps={{
                max: 3,
                spacing: -1.5,
                overflow: "hidden",
              }}
            />
          </HStack>
        </Flex>
      </Flex>
    </Box>
  );
};
