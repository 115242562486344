import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../..";
import {
  InspectionListFilters,
  InspectionListSliceProps,
} from "../inspection-list.slice";

type SortFilter = Pick<InspectionListFilters, "sort" | "direction">;
type UpdateSortFiltersParams = Partial<SortFilter>;

export const updateSortFilters = createAsyncThunk<
  SortFilter,
  UpdateSortFiltersParams
>("inspections-sort/update", async (delta, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const {
    filters: { sort, direction },
  } = state.inspectionListSlice;
  const filtersToUpdate = {
    sort,
    direction,
  };
  if ("sort" in delta) {
    filtersToUpdate.sort = delta.sort;
  }

  if ("direction" in delta) {
    filtersToUpdate.direction = delta.direction;
  }

  return filtersToUpdate;
});

export const updateSortFiltersHandler = (
  builder: ActionReducerMapBuilder<InspectionListSliceProps>
) => {
  builder.addCase(updateSortFilters.fulfilled, (state, action) => {
    state.filters = {
      ...state.filters,
      ...action.payload,
    };
  });
};
