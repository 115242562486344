import { Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import { FC } from "react";

interface ESigntaurePreviewProps {
  base64Image: string | null;
}
export const ESignaturePreview: FC<ESigntaurePreviewProps> = ({
  base64Image,
}) => {
  const signBorderColor = useColorModeValue("blue.500", "blue.300");

  return (
    <Flex
      p={4}
      height="140px"
      borderRadius={8}
      border="2px dashed"
      justifyContent="center"
      borderColor={signBorderColor}
    >
      {base64Image === null && (
        <Text opacity={0.7} display="inline-flex" alignItems="center">
          E-Signature preview
        </Text>
      )}
      {base64Image !== null && (
        <Image src={base64Image} objectFit="contain" w="100%" />
      )}
    </Flex>
  );
};
