import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { ApiBudget, ApiBudgetSummary } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { FC, useCallback, useEffect, useRef } from "react";

import { ProjectBudgetsAutocomplete } from "../selects/ProjectBudgetsAutocomplete";

type ProjectBudgetsAutocompleteControlProps = {
  value: ApiBudget | ApiBudgetSummary | null;
  name: string;
  projectId: string;

  label: string;
  placeholder?: string;
  isDisabled?: boolean;
  allowEmpty?: boolean;
  helperText?: string;
};

export const ProjectBudgetsAutocompleteControl: FC<
  ProjectBudgetsAutocompleteControlProps
> = ({
  label,
  name,
  value,
  projectId,
  placeholder,
  isDisabled = false,
  allowEmpty = false,
  helperText,
}) => {
  const { submitCount } = useFormikContext();

  const init = useRef<boolean>(false);

  // @ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
    placeholder,
  });

  const handleOnChange = useCallback(
    (budget: ApiBudget | ApiBudgetSummary | null) => {
      helper.setTouched(true);
      helper.setValue(budget || null);
    },
    [helper]
  );

  useEffect(() => {
    // reset the value when projectId changes
    // skip for the first initialization

    if (!init.current) {
      init.current = true;
      return;
    }

    helper.setValue(null);
  }, [projectId, helper]);

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <ProjectBudgetsAutocomplete
        {...field}
        projectId={projectId}
        name={name}
        onChange={handleOnChange}
        isDisabled={isDisabled}
        isClearable={allowEmpty}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}

      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
