import {
  ApiAccountSettings,
  ApiClient,
  ApiInvoice,
  FindInvoicesOptions,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { SaveDataObject } from "../pages/requests/list-headers/RequestListActions";

export const INVOICE_LIST_FILTERS = "invoice-list-filters";

interface LoadEventGroupsThunkParams {
  apiClient: ApiClient;
  accountId: string;
  filters?: FindInvoicesOptions;
}

export interface UpdatedSavedFilterProps {
  apiClient: ApiClient;
  accountId: string;
  savedFilters: ApiAccountSettings[];
}

export const loadInvoices = createAsyncThunk(
  "invoices",
  async (params: LoadEventGroupsThunkParams, ThunkAPI) => {
    const { filters } = (ThunkAPI.getState() as RootState).invoicesList;
    const newFilters = params.filters
      ? { ...filters, ...params.filters }
      : filters;
    const { apiClient, accountId } = params;

    const response = await apiClient.findInvoices(accountId, newFilters);

    const settings = await apiClient.getCurrentUserSettings(accountId, [
      INVOICE_LIST_FILTERS,
    ]);
    const userSavedFilters = settings[INVOICE_LIST_FILTERS] || [];
    return { response, userSavedFilters };
  }
);

export const updatedSavedFilters = createAsyncThunk(
  "events-list/update-saved-filters",
  async ({ apiClient, accountId, savedFilters }: UpdatedSavedFilterProps) => {
    return apiClient.updateCurrentUserSettings(accountId, {
      "invoice-list-filters": savedFilters,
    });
  }
);

export interface InvoicesListState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  data: ApiInvoice[];
  total: number;
  filters: FindInvoicesOptions;
  savedSearchFilters: SaveDataObject[];
}

export const DEFAULT_INVOICES_FILTERS: FindInvoicesOptions = {
  page: 1,
  pageSize: 20,
  direction: "desc",
};

export const invoicesList = createSlice({
  name: "invoicesList",
  initialState: {
    loading: "idle",
    data: [],
    total: 1,
    filters: { ...DEFAULT_INVOICES_FILTERS },
    savedSearchFilters: [],
  } as InvoicesListState,
  reducers: {
    unloadInvoices: (state: InvoicesListState) => {
      state.loading = "idle";
      state.data = [];
      state.total = 0;
      state.filters = DEFAULT_INVOICES_FILTERS;
      state.savedSearchFilters = [];
    },
    updateFilters: (
      state: InvoicesListState,
      action: PayloadAction<FindInvoicesOptions>
    ) => {
      if (
        (!action.payload.page || action.payload.search) &&
        state.filters.page !== 1
      ) {
        state.filters = {
          ...state.filters,
          ...action.payload,
          ...{ page: 1 },
        };
        return;
      }
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    updateInvoice: (
      state: InvoicesListState,
      action: PayloadAction<{ value: Partial<ApiInvoice>; invoiceId: string }>
    ) => {
      const { value, invoiceId } = action.payload;
      const index = state.data.findIndex((invoice) => invoice.id === invoiceId);
      if (index !== -1) {
        const invoiceCopy = { ...state.data[index], ...value };
        state.data[index] = invoiceCopy;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadInvoices.fulfilled, (state, action) => {
      const { data, total, options } = action.payload.response;
      state.loading = "succeeded";
      state.filters.page = options.page;
      state.data = data;
      state.total = total;
      state.savedSearchFilters = action.payload.userSavedFilters as [];
    });
    builder.addCase(loadInvoices.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(loadInvoices.rejected, (state) => {
      state.loading = "failed";
      state.data = [];
    });
    builder.addCase(updatedSavedFilters.fulfilled, (state, action) => {
      state.savedSearchFilters = action.payload[INVOICE_LIST_FILTERS] as [];
    });
  },
});

export const { unloadInvoices, updateFilters, updateInvoice } =
  invoicesList.actions;

export default invoicesList.reducer;
