import { Text, TextProps, Tooltip } from "@chakra-ui/react";
import { format, formatRelative } from "date-fns";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";

const shortNumberDateFormat = "MM/dd/yyy";
const shortStringDateFormat = "MMMM d y";
const timeFormat = "h:mmaaa";
export interface DateBadgeProps {
  date?: null | string | Date;
  showRelative?: boolean;
  hideTime?: boolean;
  showTimeTooltip?: boolean;
  numberFormat?: boolean;
  rest?: TextProps;
}

export const DateBadge = ({
  date,
  showRelative = true,
  hideTime = false,
  showTimeTooltip = false,
  numberFormat = false,
  rest,
}: DateBadgeProps) => {
  const dateFormat = numberFormat
    ? shortNumberDateFormat
    : shortStringDateFormat;

  let dateToShow: string | null = null;
  if (date) {
    dateToShow = showRelative
      ? formatRelative(new Date(date), new Date())
      : format(
          new Date(date),
          hideTime ? dateFormat : `${dateFormat} 'at' ${timeFormat}`
        );
  }
  let timeToShow: string | null = null;
  if (showTimeTooltip && date) {
    timeToShow = format(new Date(date), timeFormat);
  }
  return !date ? null : (
    <>
      {showTimeTooltip ? (
        <Tooltip label={timeToShow}>
          <Text {...rest}>
            {dateToShow && capitalizeFirstLetter(dateToShow)}
          </Text>
        </Tooltip>
      ) : (
        <Text {...rest}>{dateToShow && capitalizeFirstLetter(dateToShow)}</Text>
      )}
    </>
  );
};
