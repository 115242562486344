import {
  Badge,
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  ApiInspection,
  ApiLocationBuilding,
} from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import { FC, useMemo } from "react";
import { BsPrinter } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  BasicAddress,
  PhysicalAddress,
} from "../../../components/address/Address";
import { ButtonLink } from "../../../components/buttons/LinkButton";
import { useInspectionRatingUtil } from "../../../hooks/useInspectionRatingUtil";
import { RootState } from "../../../store";
import { AccountModal } from "../../account-settings/account-modal/AccountModal";
import { DownloadInspectionButton } from "./components/report/DownloadInspectionButton";
import { InspectionReportSection } from "./components/report/InspectionReportSection";
import ViolationsSection from "./components/report/ViolationsSection";

export const InspectionReport: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();

  const { locationMap } = useSelector((state: RootState) => state.localCache);
  const {
    inspection,
    inspectionConfig,
    scoreConfigMap,
    scores,
    roomContextMap,
  } = useSelector((state: RootState) => state.inspectionDetailsSlice);

  const { percentage, ratingType, ratingColor } = useInspectionRatingUtil(
    inspection ? inspection : ({} as ApiInspection)
  );
  const {
    isOpen: isPrintModalOpen,
    onOpen: onPrintModalOpen,
    onClose: onPrintModalClose,
  } = useDisclosure();

  const downloadName = useMemo(
    () =>
      `${inspectionConfig?.name.split(" ").join("-")}_${format(
        new Date(),
        "y-MM-dd"
      )}.pdf`,
    [inspectionConfig?.name]
  );

  const isInspectorView = useMemo(
    () => !pathname.includes("/account"),
    [pathname]
  );

  const inspectionAddress = useMemo<PhysicalAddress | undefined>(() => {
    if (!inspection) {
      return undefined;
    }

    const location = locationMap[inspection.location.id] as ApiLocationBuilding;
    if (!location) {
      return undefined;
    }

    return {
      address1: location.address1,
      address2: location.address2,
      city: location.city,
      state: location.state,
      postalCode: location.postalCode,
    };
  }, [inspection, locationMap]);

  // no inspection no element
  if (!inspection || !id || !inspectionConfig) {
    return null;
  }

  return (
    <VStack w="100%" gap={4} alignItems="flex-start">
      {/* colored header section, needs gap removed */}
      <VStack w="100%" gap={0}>
        {/* back button header */}
        <Flex
          align="center"
          w="100%"
          justifyContent="space-between"
          p={4}
          bgColor={ratingColor}
          opacity={0.9}
          color="white"
        >
          <ButtonLink
            to={
              isInspectorView
                ? "/planning/inspection"
                : "/account/planning/inspection"
            }
            props={{ color: "white", fontWeight: "bold" }}
          />
          <Text fontWeight="bold">Summary Report</Text>
          <IconButton
            icon={<Icon as={BsPrinter} />}
            aria-label="print"
            onClick={onPrintModalOpen}
          />
        </Flex>
        {/* title row */}
        <VStack
          w="100%"
          p={4}
          bgColor={ratingColor}
          color="white"
          alignItems="flex-start"
        >
          <Heading flex={1} aria-label={inspection.location.name} isTruncated>
            {inspection.location.name}
          </Heading>
          {inspectionAddress && <BasicAddress address={inspectionAddress} />}
          {/* Rating Section */}
          <Box mt={4} w="100%">
            <Text fontWeight="bold" fontSize="2xl">
              Rating:
            </Text>
            <Flex alignItems="center" justifyContent="center" mt={2}>
              <Badge
                variant="outline"
                fontSize="sm"
                colorScheme="white"
                color="white"
                border="1px solid white"
                mr={2}
              >
                {ratingType}
              </Badge>
              <Text my={2} fontSize="6xl" fontWeight="bold">
                {percentage.toFixed(2)}%
              </Text>
            </Flex>
          </Box>
        </VStack>
      </VStack>
      {/* Report Section */}
      <InspectionReportSection
        inspection={inspection}
        inspectionConfig={inspectionConfig}
        scoreConfigMap={scoreConfigMap}
        scores={scores}
        showRatingCard={false}
      />
      {/* Violations */}
      <ViolationsSection
        inspection={inspection}
        roomContextMap={roomContextMap}
        scoreConfigMap={scoreConfigMap}
        scores={scores}
        inspectionConfig={inspectionConfig}
      />
      {/* modals */}
      {isPrintModalOpen && (
        <AccountModal
          isOpen={isPrintModalOpen}
          onClose={onPrintModalClose}
          title={"Generate report for printing"}
          titleProps={{ size: "md" }}
          content={
            <Stack spacing={4} mb={4}>
              {/* <Text>Which format would you like to use?</Text> */}
              {/* <Button size="lg" flexDir="column" py={6} gap={1}>
                <Text fontWeight="bold" fontSize="md">
                  Standard Template
                </Text>
                <Text fontSize="sm">Out of the box report format</Text>
              </Button> */}
              <DownloadInspectionButton
                inspectionId={inspection.id}
                downloadName={downloadName}
              />
            </Stack>
          }
        />
      )}
    </VStack>
  );
};
