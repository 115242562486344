import {
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import {
  ApiInventoryItemStorageLocation,
  ApiInventoryOrderSummary,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useState } from "react";
import { MdWarning } from "react-icons/md";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { InventoryOrderItem } from "../../../../store/inventory/inventory-order-item-list.slice";
import { InventoryOrderValues } from "../InventoryOrderForm";

interface ReviewInventoryOrderRowItemFormProps {
  items: InventoryOrderItem[];
  values: InventoryOrderValues;
  inventoryOrder: ApiInventoryOrderSummary;
}

export const ReviewInventoryOrderRowItemForm: FC<
  ReviewInventoryOrderRowItemFormProps
> = ({ items, values, inventoryOrder }) => {
  const { apiClient, currentAccount } = useAuthentication();
  const isDesktop = useBreakpointValue({ xs: false, sm: false, md: true });
  const [reviewAdjustments, setReviewAdjustments] = useState<
    | {
        inventoryOrderItemId: string;
        storageLocationRestock: Record<
          string,
          ApiInventoryItemStorageLocation & { restock: number }
        >;
      }
    | undefined
  >();
  const getAdjustmentForItemOrder = useCallback(
    (itemId: string) => {
      if (!inventoryOrder) return;
      apiClient
        .findInventoryItemAdjustments(currentAccount.id, {
          item: [itemId],
          inventoryOrder: inventoryOrder.id,
        })
        .then((res) => {
          const adjustments = res.data;
          const storageLocationRestock = adjustments.reduce(
            (acc, adjustment) => {
              const { itemLocation, quantity } = adjustment;
              const storageLocationId = itemLocation.id;

              if (!acc[storageLocationId]) {
                acc[storageLocationId] = {
                  ...itemLocation,
                  restock: quantity,
                };
                return acc;
              }
              acc[storageLocationId].restock += quantity;

              return acc;
            },
            {} as Record<
              string,
              ApiInventoryItemStorageLocation & { restock: number }
            >
          );
          setReviewAdjustments({
            inventoryOrderItemId: itemId,
            storageLocationRestock,
          });
        });
    },
    [inventoryOrder, currentAccount.id, apiClient]
  );

  return (
    <>
      {items.map((item, index) => (
        <Flex
          key={`item-order:"${item.item.id}`}
          flexDir="column"
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          cursor={item.delivered !== 0 ? "pointer" : undefined}
          onClick={() => getAdjustmentForItemOrder(item.item.id)}
        >
          <Flex w="100%" flexDir="row">
            <Flex gap={2} w="30%" alignItems="center">
              <Image boxSize="40px" src={item.item.defaultImage?.url} />
              <Flex flexDir="column" justifyContent="center">
                <Text fontWeight="bold">{item.item.name}</Text>
                {item.item.identifiers.externalId && (
                  <Text>#{item.item.identifiers.externalId}</Text>
                )}
                {item.delivered === 0 && (
                  <Text fontWeight="bold" textColor="red">
                    REMOVED
                  </Text>
                )}
                {item.requested - item.delivered > 0 &&
                  item.delivered !== 0 && (
                    <>
                      <Text
                        fontWeight="bold"
                        textColor="red"
                        fontSize={isDesktop ? "m" : "xs"}
                      >
                        BACKORDER
                      </Text>
                      <Text
                        fontWeight="bold"
                        textColor="red"
                        fontSize={isDesktop ? "m" : "xs"}
                      >
                        REMOVED: {item.requested - item.delivered}
                      </Text>
                    </>
                  )}
              </Flex>
            </Flex>
            <Flex w="17.5%" flexDir="column" alignItems="center" flexShrink={0}>
              <Text fontSize={isDesktop ? "m" : "xs"}>Requested</Text>
              <Text mt={4} fontWeight="bold">
                {values.items[index].requested}
              </Text>
            </Flex>
            <Flex w="17.5%" flexDir="column" alignItems="center" flexShrink={0}>
              <Text fontSize={isDesktop ? "m" : "xs"}>Delivered</Text>
              <Text mt={4} fontWeight="bold">
                {values.items[index].delivered}
              </Text>
            </Flex>
            <Flex w="17.5%" flexDir="column" alignItems="center" flexShrink={0}>
              <Text fontSize={isDesktop ? "m" : "xs"}>Unit Cost</Text>
              <Text mt={4} fontWeight="bold">
                {values.items[index].unitCost.toFixed(4)}
              </Text>
            </Flex>
            <Flex w="17.5%" flexDir="column" alignItems="center" flexShrink={0}>
              <Text fontSize={isDesktop ? "m" : "xs"}>Total Cost</Text>
              <Text mt={4} fontWeight="bold">
                $
                {(
                  values.items[index].unitCost * values.items[index].delivered
                ).toFixed(2)}
              </Text>
            </Flex>
          </Flex>
          {reviewAdjustments &&
            reviewAdjustments.inventoryOrderItemId === item.item.id && (
              <VStack bgColor="gray.50" w="100%">
                <HStack w="100%">
                  <Box w="85%">
                    <Text fontWeight="bold">Storage Locations</Text>
                  </Box>
                  <Box justifyItems="center" w="15%">
                    <Text fontWeight="bold">Restock</Text>
                  </Box>
                </HStack>

                {Object.values(reviewAdjustments.storageLocationRestock).map(
                  (loc) => (
                    <HStack key={loc.storageLocation.id} w="100%">
                      <Box w="85%">
                        <Flex>
                          {loc.levels?.low && loc.quantity < loc.levels.low && (
                            <Icon
                              as={MdWarning}
                              boxSize="24px"
                              color="yellow.300"
                            />
                          )}
                          <Text>{loc.storageLocation.name}</Text>
                        </Flex>
                        <Text fontWeight="300">
                          Ideal Stock: {loc.levels?.idealStock}
                        </Text>
                      </Box>
                      <Box justifyItems="center" w="15%">
                        <Text>{loc.restock}</Text>
                      </Box>
                    </HStack>
                  )
                )}
              </VStack>
            )}
        </Flex>
      ))}
    </>
  );
};
