import { Box, useColorModeValue } from "@chakra-ui/react";
import {
  ApiLocation,
  ApiLocationReference,
  ApiLocationSummary,
} from "@operations-hero/lib-api-client";
import { GroupBase, MenuListProps, Select } from "chakra-react-select";
import FuzzySearch from "fuzzy-search";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { MenuPlacement } from "react-select";
import type { ListRowProps } from "react-virtualized";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import { useLocationsUtils } from "../../hooks/locations/useLocationsUtils";
import { Products } from "../../pages/account-settings/location-list/LocationList";
import { RootState } from "../../store";
import { LocationTwoLine } from "../badges/LocationTwoLine";
import { useLocationsContext } from "../locations/LocationsContext";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomSelectComponentProp,
  LocationSingleValueSelect,
} from "./select-overrides-types";

export interface LocationAutocompleteProps {
  value: ApiLocationReference | null;
  onChange: (location: ApiLocationSummary | null) => void;
  onBlur?: () => void;
  allowedLocations?: string[];
  allowEmpty?: boolean;
  autoSelectWhenOneOption?: boolean;
  hideLocations?: string[];
  isDisabled?: boolean;
  isInvalid?: boolean;
  name?: string;
  placeholder?: string;
  ref?: any;
  bgColor?: string;
  productName?: keyof typeof Products;
  menuPlacement?: MenuPlacement;
  hideSelectedOptions?: boolean;
  filterOption?: (option: ApiLocationSummary) => boolean;
}
const VirtualizedList = (
  props: MenuListProps<ApiLocation, false, GroupBase<ApiLocation>>
) => {
  const { children } = props;
  const rows = children;
  const bgColor = useColorModeValue("white", "gray.700");
  const cellCache = useMemo(() => {
    return new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 37.5,
    });
  }, []);

  if (!Array.isArray(rows)) {
    return <>{children}</>;
  }

  const rowRenderer = ({ key, parent, index, style }: ListRowProps) => {
    const child = rows[index];
    const uniqueKey = `${key}::${index}`;
    const adjustedStyle = {
      ...style,
      display: "flex",
      justifyContent: "center",
    };
    return (
      <CellMeasurer
        cache={cellCache}
        key={uniqueKey}
        columnIndex={0}
        rowIndex={index}
        parent={parent}
      >
        <Box key={uniqueKey} style={adjustedStyle}>
          {child}
        </Box>
      </CellMeasurer>
    );
  };

  return (
    <Box
      style={{ height: "300px" }}
      bgColor={bgColor}
      boxShadow="md"
      borderRadius="8px"
    >
      <AutoSizer>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            deferredMeasurementCache={cellCache}
            rowHeight={60}
            rowCount={rows.length}
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </Box>
  );
};

const CustomSingleValueComponent = (props: LocationSingleValueSelect) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <LocationTwoLine value={data} />
    </Box>
  );
};

const LocationOptionComponent = createOptionComponent(LocationTwoLine, {
  py: 6,
});

export const LocationAutocomplete = ({
  value: location,
  onChange,
  onBlur,
  hideLocations,
  allowEmpty = true,
  allowedLocations,
  isDisabled,
  isInvalid,
  name,
  productName,
  menuPlacement,
  placeholder,
  filterOption,
}: LocationAutocompleteProps) => {
  const { locationMap: allLocationMap } = useSelector(
    (state: RootState) => state.localCache
  );

  const { locations, descendantsMap } = useLocationsContext();
  const { findAllChildrenForNodes } = useLocationsUtils();

  const [searchOptions, setSearchOptions] = useState<ApiLocation[]>([]);

  const options = useMemo(() => {
    if (!searchOptions || searchOptions.length === 0) {
      return locations;
    }

    return searchOptions;
  }, [locations, searchOptions]);

  const selectedLocation = useMemo(() => {
    const sanitized =
      location != null
        ? allLocationMap[
            typeof location === "string" ? location : location.id
          ] || null
        : null;
    return sanitized;
  }, [location, allLocationMap]);

  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(locations, ["name", "address"], {
        sort: true,
      }),
    [locations]
  );

  const handleChange = useCallback(
    (value: ApiLocationSummary | null) => {
      onChange(value);
    },
    [onChange]
  );

  const getOptionValue = useCallback(
    (location: ApiLocationSummary) => location.id,
    []
  );

  const getOptionLabel = useCallback(
    (location: ApiLocationSummary) => location.name,
    []
  );

  const customComponents = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      SingleValue: CustomSingleValueComponent,
      Option: LocationOptionComponent,
      MenuList: VirtualizedList,
    };
  }, []);

  const inputChange = useCallback(
    (value: string) => {
      if (!value) {
        setSearchOptions([]);
        return value;
      }

      const found = fuzzySearch.search(value);
      const nodes = findAllChildrenForNodes(found, descendantsMap);
      setSearchOptions(nodes);
      return value;
    },
    [fuzzySearch, findAllChildrenForNodes, descendantsMap]
  );

  return (
    <Select
      components={customComponents}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      isClearable={allowEmpty === true}
      isDisabled={isDisabled}
      isOptionDisabled={(option: ApiLocationSummary, selected) => {
        const selectedOpts = selected.map((s) => s.id);
        return selectedOpts.includes(option.id);
      }}
      isInvalid={isInvalid}
      name={name}
      onChange={handleChange}
      options={options}
      placeholder={placeholder || "Search Locations..."}
      value={selectedLocation}
      onBlur={onBlur}
      onInputChange={inputChange}
      isSearchable={true}
      filterOption={(opt) => {
        if (filterOption) {
          return filterOption(opt.data);
        }
        return true;
      }}
      chakraStyles={commonStyles}
      menuPlacement={menuPlacement}
    />
  );
};
