import {
  Box,
  Flex,
  IconButton,
  Progress,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiInspection,
  ApiInspectionScoreConfig,
  ApiInspectionStatus,
  ApiLocationBuilding,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useMemo } from "react";
import { MdEdit } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { ViolationPill } from "../../pages/planning-hq/inspection/components/ViolationPill";
import { InspectionBuildingEntry } from "../../store/planning-hq/inspections/details/inspection-details.slice";
import { StatusBadge } from "../badges/StatusBadge";

export const LocationCard: FC<{
  inspection: ApiInspection;
  location: InspectionBuildingEntry;
  scoreConfigMap: Record<string, ApiInspectionScoreConfig>;
  onEdit: (location: InspectionBuildingEntry) => void;
}> = ({ inspection, location, scoreConfigMap, onEdit }) => {
  const navigate = useNavigate();
  const iconsColor = useColorModeValue("gray.500", "gray.300");
  const { pathname } = useLocation();

  const isInspectorView = useMemo(
    () => !pathname.includes("/account"),
    [pathname]
  );

  const navigateSubBuildindDetails = useCallback(() => {
    navigate(
      isInspectorView
        ? `/planning/inspection/${inspection.id}/details/${location.location.id}`
        : `/account/planning/inspection/${inspection.id}/details/${location.location.id}`
    );
  }, [navigate, inspection, location, isInspectorView]);

  return (
    <Flex
      direction="column"
      p={4}
      borderRadius="md"
      boxShadow="md"
      gap={2}
      justify="space-between"
      height="auto"
      cursor="pointer"
      onClick={() => navigateSubBuildindDetails()}
    >
      <Flex justify="space-between" align="center">
        <Text fontWeight="bold">{location.location.name}</Text>
        <Box display="flex">
          <ViolationPill
            violations={location.violations}
            scoreConfigMap={scoreConfigMap}
          />

          {inspection.status !== ApiInspectionStatus.submitted && (
            <IconButton
              ml={2}
              variant="ghost"
              icon={<MdEdit />}
              aria-label="Edit Location"
              onClick={(e) => {
                e.stopPropagation();
                onEdit(location);
              }}
              color={iconsColor}
            />
          )}
        </Box>
      </Flex>
      <Text fontSize="sm">
        Square Feet:{" "}
        {(location.location as ApiLocationBuilding)?.squareFeet || 0}
      </Text>
      <Text fontSize="sm">Sections: {location.sections}</Text>
      <Flex justify="space-between" align="center">
        <StatusBadge status={location.status} />
        <Box>
          <Text fontSize="sm">
            Inspected Rooms: {location.inspectedRooms}/{location.totalRooms}
          </Text>
          <Progress
            value={
              location.totalRooms > 0
                ? (location.inspectedRooms / location.totalRooms) * 100
                : 0
            }
            size="sm"
            colorScheme="blue"
            mb={2}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
