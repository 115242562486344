import { Box } from "@chakra-ui/react";
import { FC, Suspense } from "react";
import { FallbackSpinner } from "../../../components/code-splitting/FallbackSpinner";
import { LocationsByProductProvider as LocationsProvider } from "../../../components/locations/LocationsByProductProvider";

import { Products } from "../../../components/products/ProductContext";

interface AccountRouteWrapperProps {
  Element: React.FC | React.LazyExoticComponent<() => JSX.Element>;
  product?: keyof typeof Products;
}

export const AccountWrapper: FC<AccountRouteWrapperProps> = ({
  Element,
  product,
}) => {
  return (
    <Box flex="1">
      <Suspense fallback={<FallbackSpinner />}>
        {!product ? (
          <Element />
        ) : (
          <LocationsProvider product={product}>
            <Element />
          </LocationsProvider>
        )}
      </Suspense>
    </Box>
  );
};
