import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import {
  ApiWorkflowReasonSummary,
  ApiWorkflowReference,
} from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { useCallback } from "react";
import { ReasonAutocomplete } from "../selects/ReasonAutocomplete";

export interface ReasonAutocompleteControlProps {
  label?: string;
  name: string;
  value: ApiWorkflowReasonSummary | null;
  workflow: ApiWorkflowReference;
  filterOptions?: string[];
  defaultValue?: string;
  isDisabled?: boolean;
  includeInactiveWorkflow?: boolean;
}

export const ReasonAutocompleteControl = ({
  name,
  value,
  label,
  workflow,
  filterOptions,
  defaultValue,
  isDisabled,
  includeInactiveWorkflow,
}: ReasonAutocompleteControlProps) => {
  const { submitCount } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
  });

  const handleOnChange = useCallback(
    (reason: ApiWorkflowReasonSummary | null) => {
      helper.setTouched(true);
      helper.setValue(reason);
    },
    [helper]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <ReasonAutocomplete
        {...field}
        onChange={handleOnChange}
        name={name}
        workflow={workflow}
        isClearable={false}
        defaultValue={defaultValue}
        filterOptions={filterOptions}
        isDisabled={isDisabled}
        includeInactiveWorkflow={includeInactiveWorkflow}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
