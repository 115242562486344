import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { Products } from "../../components/products/ProductContext";
import { RootState } from "../../store";
import { useLocationsByProduct } from "./useLocationsByProduct";
import { useLocationsUtils } from "./useLocationsUtils";

export const useHeroHqAllowedLocations = () => {
  const {
    policyMap,
    workflowMap,
    locationMap,
    locationsByProduct: { HeroHQ },
  } = useSelector((state: RootState) => state.localCache);
  const { isProductAdmin } = useAuthentication();
  const { getChildrenId } = useLocationsUtils();
  const { descendantsMap } = useLocationsByProduct(Products.HeroHQ);

  const heroHQLocationsIds = useMemo(() => {
    const { hiddenLocationIds, visibleLocationIds } = HeroHQ;

    if (hiddenLocationIds.length > 0) return visibleLocationIds;

    return Object.keys(locationMap);
  }, [HeroHQ, locationMap]);

  const filterVisibleLocations = useCallback(
    (locationsToFilter: string[]) => {
      const { hiddenLocationIds } = HeroHQ;

      // no need to check if hidden locations is zero
      if (hiddenLocationIds.length === 0) {
        return locationsToFilter;
      }

      // to filter visible locations we check which array is smaller.
      // minimizing the amount of loops
      if (locationsToFilter.length <= hiddenLocationIds.length) {
        const hiddenLocationsSet = new Set(hiddenLocationIds);
        return locationsToFilter.filter((loc) => !hiddenLocationsSet.has(loc));
      } else {
        const locationsToFilterSet = new Set(locationsToFilter);
        hiddenLocationIds.forEach((loc) => {
          if (locationsToFilterSet.has(loc)) {
            locationsToFilterSet.delete(loc);
          }
        });
        return Array.from(locationsToFilterSet);
      }
    },
    [HeroHQ]
  );

  const getAllowedLocationsIds = useCallback(() => {
    if (isProductAdmin) {
      return heroHQLocationsIds;
    }

    let allLocationsAllowed: boolean = false;
    let allowedLocationsIds: string[] = [];

    // Check each workflow policy for restrictions
    Object.keys(workflowMap).forEach((workflow) => {
      // no need to check extra workflows if the user is eligible for other locations
      if (allLocationsAllowed) {
        return;
      }

      const policy = policyMap[workflow];
      const {
        admin,
        reviewer,
        technician,
        approver,
        viewer,
        contractor,
        requester,
      } = policy;

      if (admin) {
        allLocationsAllowed = true;
        return;
      }

      const locationRestrictedFields = [
        reviewer,
        technician,
        approver,
        viewer,
        contractor,
        requester,
      ];
      locationRestrictedFields.forEach((x) => {
        // we found a premissive role in the policy already
        if (allLocationsAllowed === true) {
          return;
        }

        // no-restriction policies are stored in a boolean format i.e `reviewer: true`
        if (typeof x === "boolean") {
          if (x === true) {
            allLocationsAllowed = x === true;
          }
          return;
        }
        // not a bool not an array, this is bad
        if (!Array.isArray(x?.locations)) {
          return;
        }

        // empty array is assumed "All"
        if (x.locations.length === 0) {
          allLocationsAllowed = true;
        } else {
          allowedLocationsIds.push(...x.locations);
        }
      });
    });

    // prevent tree traversal if not needed.
    if (allLocationsAllowed) {
      return heroHQLocationsIds;
    }

    // not sure how we get here
    if (allowedLocationsIds.length === 0) {
      return [];
    }

    // unique up the ids we need to fetch
    allowedLocationsIds = Array.from(new Set(allowedLocationsIds));

    return allowedLocationsIds;
  }, [heroHQLocationsIds, isProductAdmin, policyMap, workflowMap]);

  const getAllowedAndVisibleLocationsIds = useCallback(() => {
    const allowedLocationsIds = getAllowedLocationsIds();
    const allowedAndVisibleLocationsIds =
      filterVisibleLocations(allowedLocationsIds);

    const allAllowedAndVisibleLocationIds = [...allowedAndVisibleLocationsIds];

    for (let locId of allowedAndVisibleLocationsIds) {
      const childAndFatherLocationIds = getChildrenId([locId], descendantsMap);
      allAllowedAndVisibleLocationIds.push(...childAndFatherLocationIds);
    }

    const uniqueLocationIds = new Set(allAllowedAndVisibleLocationIds);

    return Array.from(uniqueLocationIds);
  }, [
    descendantsMap,
    filterVisibleLocations,
    getAllowedLocationsIds,
    getChildrenId,
  ]);

  return { getAllowedLocationsIds, getAllowedAndVisibleLocationsIds };
};
