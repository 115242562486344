import { Divider, Heading, VStack } from "@chakra-ui/react";
import {
  ApiInspection,
  ApiInspectionCategory,
  ApiInspectionCategoryGroup,
  ApiInspectionConfig,
  ApiInspectionScoreConfig,
} from "@operations-hero/lib-api-client";
import { FC, useMemo } from "react";
import {
  InspectionBuildingEntry,
  InspectionFloorEntry,
  InspectionRoomContextMap,
  ScoresRecord,
} from "../../../../../store/planning-hq/inspections/details/inspection-details.slice";
import ViolationsDataTable, { ViolationsData } from "./ViolationsDataTable";

export interface ViolationsSectionProps {
  inspection: ApiInspection;
  inspectionConfig: ApiInspectionConfig;
  roomContextMap: InspectionRoomContextMap;
  scoreConfigMap: Record<string, ApiInspectionScoreConfig>;
  scores: ScoresRecord;
}

type ViolationsByBuildingAndFloor = Record<
  string,
  {
    building: InspectionBuildingEntry;
    violationCount: number;
    floors: Record<
      string,
      {
        floor: InspectionFloorEntry;
        violations: ViolationsData[];
      }
    >;
  }
>;

type ViolationsDisplayData = {
  building: InspectionBuildingEntry;
  violationCount: number;
  floors: {
    floor: InspectionFloorEntry;
    violations: ViolationsData[];
  }[];
};

const ViolationsSection: FC<ViolationsSectionProps> = ({
  inspection,
  inspectionConfig,
  scoreConfigMap,
  scores,
  roomContextMap,
}) => {
  /* used to lookup which score configs count as violations */
  const violationScoreConfigIds = useMemo(() => {
    return Object.values(scoreConfigMap)
      .filter((c) => c.justificationRequired === true)
      .reduce<Record<string, ApiInspectionScoreConfig>>((map, item) => {
        map[item.id] = item;
        return map;
      }, {});
  }, [scoreConfigMap]);

  const data = useMemo<ViolationsDisplayData[]>(() => {
    const categoryMap = inspectionConfig.categoryGroups.reduce(
      (map, group) => {
        for (let category of group.categories) {
          map[category.id] = { group, category };
        }
        return map;
      },
      {} as Record<
        string,
        { group: ApiInspectionCategoryGroup; category: ApiInspectionCategory }
      >
    );

    const result = Object.values(scores).reduce<ViolationsByBuildingAndFloor>(
      (map, score) => {
        // null config or a config not requiring justification is a ignored.
        const violatingScoreConfig = score.scoreConfigId
          ? violationScoreConfigIds[score.scoreConfigId]
          : undefined;

        // not a violation skip it.
        if (!violatingScoreConfig) {
          return map;
        }

        const roomContext = roomContextMap[score.locationId];
        if (!roomContext) {
          console.error(
            "Violating score location is not found withing roomContextMap"
          );
          return map;
        }

        let building = map[roomContext.building.location.id];
        if (!building) {
          building = map[roomContext.building.location.id] = {
            building: roomContext.building,
            violationCount: 0,
            floors: {},
          };
        }

        let floor = building.floors[roomContext.floor.location.id];
        if (!floor) {
          floor = building.floors[roomContext.floor.location.id] = {
            floor: roomContext.floor,
            violations: [],
          };
        }

        let categorization = categoryMap[score.inspectionCategoryId];

        building.violationCount++;
        floor.violations.push({
          config: violatingScoreConfig,
          room: roomContext.room,
          score: score,
          group: categorization.group,
          category: categorization.category,
        });

        return map;
      },
      {}
    );

    // well this is awkward, but converts the maps to sorted arrays for display
    return Object.values(result)
      .sort((a, b) =>
        a.building.location.name < b.building.location.name ? 1 : -1
      )
      .map<ViolationsDisplayData>((item) => ({
        building: item.building,
        violationCount: item.violationCount,
        floors: Object.values(item.floors)
          .sort((a, b) =>
            a.floor.location.name < b.floor.location.name ? 1 : -1
          )
          .map((x) => ({
            floor: x.floor,
            violations: x.violations.sort((a, b) =>
              a.room.location.name < b.room.location.name ? 1 : -1
            ),
          })),
      }));
  }, [violationScoreConfigIds, scores, roomContextMap, inspectionConfig]);

  if (Object.keys(data).length === 0) {
    return (
      <VStack w="100%" alignItems="flex-start" p={2}>
        <Heading size="md" py={2}>
          No violations found during inspection!
        </Heading>
      </VStack>
    );
  }

  return (
    <VStack w="100%" alignItems="flex-start" p={2}>
      <Heading size="md" py={2}>
        Violations
      </Heading>
      <VStack gap={2} divider={<Divider />} w="100%" alignItems="flex-start">
        {data.map((building) => (
          <VStack
            gap={2}
            w="100%"
            alignItems="flex-start"
            key={`violation:${building.building.location.id}`}
          >
            <Heading size="sm" py={2}>
              {building.building.location.name} ({building.violationCount})
            </Heading>
            {building.floors.map((floor) => (
              <VStack
                gap={2}
                w="100%"
                alignItems="flex-start"
                key={`violation:${floor.floor.location.id}`}
              >
                <Heading size="sm" fontWeight="normal">
                  {floor.floor.location.name}
                </Heading>
                <ViolationsDataTable
                  inspection={inspection}
                  data={floor.violations}
                />
              </VStack>
            ))}
          </VStack>
        ))}
      </VStack>
    </VStack>
  );
};

export default ViolationsSection;
