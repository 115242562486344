import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import {
  ApiInspectionStatus,
  ApiPagingInspectionOptions,
} from "@operations-hero/lib-api-client";
import { Country, State } from "country-state-city";
import { FC, useCallback, useMemo } from "react";
import { RiFilterOffLine } from "react-icons/ri";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { QuickFilterButton } from "../../../../components/buttons/QuickFilterButton";
import { useAccountDetails } from "../../../../hooks/useAccountDetails";
import { FiscalYearRange } from "../../../../store/planning-hq/types";
import { debounce } from "../../../../utils/debounce";
import { FiscalYearsFilter } from "../../funding-sources/filters/FiscalYearsFilter";
import { StatusInspectionFilter } from "../filters/StatusInspectionFilter";

export type HeaderProps = {
  filters: ApiPagingInspectionOptions;
  updateFilters: (filter: Partial<ApiPagingInspectionOptions>) => void;
  clearFilters: () => void;
  onNewSchoolClick: () => any;
  isInspectorView?: boolean;
};

export const InspectionHeader: FC<HeaderProps> = ({
  filters,
  updateFilters,
  clearFilters,
  onNewSchoolClick,
  isInspectorView,
}) => {
  const { colorMode } = useColorMode();
  const { currentAccount } = useAuthentication();
  const { accountDetails } = useAccountDetails();

  const formattedAddress = useMemo(() => {
    if (!accountDetails?.address) return "";

    const { city, state, country } = accountDetails.address;
    const stateName =
      state && country
        ? State.getStateByCodeAndCountry(state, country)?.name
        : "";
    const countryName = country ? Country.getCountryByCode(country)?.name : "";

    return `${city}, ${stateName}, ${countryName}`;
  }, [accountDetails]);

  const handleOnSearchChange = useCallback(
    (value: string) => {
      updateFilters({ showUnassigned: false, search: value });
    },
    [updateFilters]
  );

  const handleOnStatusChange = useCallback(
    (status: ApiInspectionStatus[]) => {
      updateFilters({ showUnassigned: false, status });
    },
    [updateFilters]
  );

  const handleOnFiscalChange = useCallback(
    (fiscalYearRanges: FiscalYearRange[]) => {
      const { fiscalYearStart } = fiscalYearRanges.reduce<{
        fiscalYearStart: string[];
        fiscalYearEnd: string[];
      }>(
        (map, item) => {
          map.fiscalYearStart.push(item.start);
          map.fiscalYearEnd.push(item.end);
          return map;
        },
        {
          fiscalYearStart: [],
          fiscalYearEnd: [],
        }
      );

      updateFilters({
        showUnassigned: false,
        fiscalYearStart: fiscalYearStart,
      });
    },
    [updateFilters]
  );

  const debouncedSearchChange = debounce(handleOnSearchChange, 300);

  return (
    <VStack gap={6}>
      <HStack
        justify="space-between"
        w="full"
        wrap={["wrap-reverse", "wrap-reverse", "wrap-reverse", "nowrap"]}
        rowGap={3}
        columnGap={10}
        alignContent="center"
      >
        <Box>
          <Heading mr={4} fontSize="3xl" w="max-content">
            {currentAccount.name}
          </Heading>
          <Text fontWeight="400" textColor="gray.700">
            District: {formattedAddress}
          </Text>
        </Box>
        {!isInspectorView && (
          <Button
            leftIcon={<AddIcon />}
            colorScheme="blue"
            variant="solid"
            onClick={onNewSchoolClick}
          >
            Add School
          </Button>
        )}
      </HStack>
      <HStack
        justify="space-between"
        w="full"
        wrap={"nowrap"}
        rowGap={3}
        columnGap={10}
      >
        <Box
          display="flex"
          flexDir="row"
          flexGrow={1}
          gap={6}
          alignItems="center"
        >
          <StatusInspectionFilter
            value={filters.status || ([] as ApiInspectionStatus[])}
            onChange={handleOnStatusChange}
          />
          <FiscalYearsFilter
            value={filters.fiscalYearStart ?? []}
            onChange={handleOnFiscalChange}
            isInspection
          />

          {!isInspectorView && (
            <QuickFilterButton
              activeText="Show Unassigned"
              isDisabled={
                Boolean(filters.search) ||
                (filters.status?.length ?? 0) > 0 ||
                (filters.fiscalYearStart?.length ?? 0) > 0
              }
              isActive={filters.showUnassigned || false}
              onClick={() =>
                updateFilters({
                  showUnassigned: !filters.showUnassigned,
                })
              }
            />
          )}
        </Box>
        <HStack justifyContent="flex-end" w={"fit-content"}>
          <Box>
            <InputGroup
              flexGrow={1}
              bg={colorMode === "light" ? "white" : "unset"}
            >
              <InputLeftElement children={<SearchIcon color="gray.300" />} />
              <Input
                type="text"
                placeholder="Search by School..."
                defaultValue={filters.search ?? ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  debouncedSearchChange(e.target.value);
                }}
              />
            </InputGroup>
          </Box>
          <IconButton
            icon={<Icon as={RiFilterOffLine} />}
            aria-label="Sort"
            colorScheme="blue"
            variant="outline"
            size="md"
            onClick={clearFilters}
          />
        </HStack>
      </HStack>
    </VStack>
  );
};
