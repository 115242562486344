import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Spinner,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  ApiEquipmentItemStatus,
  ApiInventoryItem,
  ApiInventoryItemAdjustment,
  ApiInventoryType,
  ApiItemAdjustmentReason,
  ApiItemAdjustmentType,
  CreateApiInventoryItemAdjustment,
} from "@operations-hero/lib-api-client";
import { Form, Formik } from "formik";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { MdQrCode2 } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { DateBadge } from "../../../../components/badges/DateBadge";
import { UserBadge } from "../../../../components/badges/UserBadge";
import { DatePickerControl } from "../../../../components/form-helpers/DatePickerControl";
import { NumberInputControl } from "../../../../components/form-helpers/NumberInputControl";
import { RadioButtonsControl } from "../../../../components/form-helpers/RadioButtonsControl";
import { TextEditorControl } from "../../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import { StatusCheckinSelectControl } from "../../../../components/form-helpers/StatusCheckinSelectControl";
import { AccountSearchBox } from "../../../../components/inputs/SearchBox";
import { PageData } from "../../../../components/pager/PageData";
import { Pager } from "../../../../components/pager/Pager";
import { QrQuickScanModal } from "../../../../components/qr-quick-scan/QrQuickScanModal";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../../store";
import {
  loadInventoryItemCheckoutCheckinModal,
  removeCheckout,
  setCheckoutCheckinFiltersModal,
  unloadInventoryCheckoutCheckinModal,
} from "../../../../store/inventory/inventory-item-checkout-checkin.slice";
import { reloadItemStorageLocations } from "../../../../store/inventory/inventory-item-form.slice";
import { loadInventoryItems } from "../../../../store/inventory/inventory-item-list.slice";
import { CheckoutCheckinFormValues } from "./CheckoutForm";

const CheckinFormSchema = yup.object().shape({
  returnDate: yup.date().required("Return Date is required").nullable(),
  status: yup.string().required("Status is required").nullable(),
});

interface ChangeQuantityFormProps {
  onClose: () => void;
  adjustment?: ApiInventoryItemAdjustment;
}

const PAGE_SIZE = 10;
const TEMPLATE_AREAS = `
  'radio requestedBy requestedByValue'
  'radio itemId serialNumber'
  'radio assignedTo assignedToValue'
  'radio issuedDate issuedDateValue'
  'radio returnDate returnDateValue'
  'radio workOrder workOrderValue'
  'radio notes notesValue'
`;

export const CheckinForm: FC<ChangeQuantityFormProps> = ({
  adjustment,
  onClose,
}) => {
  const { apiClient, currentAccount, currentUser } = useAuthentication();
  const { workingItem: itemSlice } = useSelector(
    (state: RootState) => state.inventoryItemsListSlice
  );
  const { item, includeInactiveLocations } = useSelector(
    (state: RootState) => state.inventoryItemSlice
  );
  const location = useLocation();
  const {
    dataModal: dataModalSlice,
    filtersModal,
    loadingModal: loadingModalSlice,
    totalModal,
    requestsMapModal,
  } = useSelector(
    (state: RootState) => state.inventoryItemCheckoutCheckinSlice
  );
  const { page, pageSize } = filtersModal;
  const [itemLoaded, setItemLoaded] = useState<ApiInventoryItem | null>(null);
  const [loadingModal, setLoadingModal] = useState(
    adjustment ? "pending" : loadingModalSlice
  );
  const [dataModal, setDataModal] = useState(
    adjustment ? [adjustment] : dataModalSlice
  );

  const [prevFiltersModal, setPrevFiltersModal] = useState(filtersModal);
  const [originalCheckout, setOriginalCheckout] =
    useState<ApiInventoryItemAdjustment>();

  const showToast = useShowToast();
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const {
    isOpen: isOpenQRItem,
    onClose: onCloseQRItem,
    onOpen: onOpenQRItem,
  } = useDisclosure();

  const textColor = useColorModeValue("gray.400", "whiteAlpha.500");
  const isDesktop = useBreakpointValue({ xs: false, sm: false, md: true });
  const qrColor = useColorModeValue("black", "white");

  const workingItem = useMemo(() => {
    if (itemLoaded && adjustment) return itemLoaded;
    return itemSlice;
  }, [itemLoaded, itemSlice, adjustment]);

  const isCheckoutPage = useMemo(
    () => location.pathname.includes("/checkouts"),
    [location.pathname]
  );

  const initialValues: CheckoutCheckinFormValues | null = useMemo(() => {
    if (!workingItem || !workingItem.storageLocations[0]) return null;
    const [firstLocation] = workingItem.storageLocations;

    const values: CheckoutCheckinFormValues = {
      item: workingItem.id,
      itemLocation: firstLocation,
      quantity: workingItem?.type === ApiInventoryType.checkout ? 1 : 0,
      deliveryLocationId: null,
      type: ApiItemAdjustmentType.restock,
      unitCost: workingItem.cost,
      units: workingItem.units,
      requester: currentUser,
      date: new Date(),
      returnDate: new Date(),
      assignedTo: null,
      request: null,
      notes: null,
      reasonType: ApiItemAdjustmentReason.checkin,
      serialNumber: null,
      inventoryAdjustmentToCheckOut: null,
      status: ApiEquipmentItemStatus.normal,
      bulkCheckinItems:
        workingItem?.type === ApiInventoryType.bulkCheckout ? [] : undefined,
      budget: null,
    };
    return values;
  }, [workingItem, currentUser]);

  const mapValues = useCallback(
    (formValues: CheckoutCheckinFormValues) => {
      const normalQuantity =
        formValues.bulkCheckinItems && formValues.bulkCheckinItems[0]
          ? formValues.bulkCheckinItems[0] * 1
          : 0;

      const lostQuantity =
        formValues.bulkCheckinItems && formValues.bulkCheckinItems[1]
          ? formValues.bulkCheckinItems[1] * 1
          : 0;
      const damagedQuantity =
        formValues.bulkCheckinItems && formValues.bulkCheckinItems[2]
          ? formValues.bulkCheckinItems[2] * 1
          : 0;

      const totalQuantity = normalQuantity + lostQuantity + damagedQuantity;

      const values: CreateApiInventoryItemAdjustment = {
        item: formValues.item,
        itemLocation: formValues.itemLocation.id,
        type: formValues.type,
        quantity:
          workingItem?.type === ApiInventoryType.bulkCheckout
            ? totalQuantity
            : 1,
        unitCost: formValues.unitCost,
        units: formValues.units,
        inventoryAdjustmentToCheckOut: formValues.inventoryAdjustmentToCheckOut,
        date:
          typeof formValues.date === "string"
            ? formValues.date
            : formValues.date.toISOString(),
        returnDate: formValues.returnDate
          ? typeof formValues.returnDate === "string"
            ? formValues.returnDate
            : formValues.returnDate.toISOString()
          : null,
        notes: formValues.notes,
        requestId: formValues.request ? formValues.request.id : null,
        requester: formValues.requester
          ? formValues.requester.id
          : currentUser.id,
        purchaseOrder: null,
        invoiceNumber: null,
        serialNumber: formValues.serialNumber,
        assignedTo: formValues.assignedTo,
        budget: formValues.budget,
        supplier: null,
        reason: formValues.reasonType,
        deliveryLocationId: null,
        issuanceTransactionId: null,
        status: formValues.status,
        associatedAdjustmentId: null,
        bulkCheckinItems:
          workingItem?.type === ApiInventoryType.bulkCheckout &&
          totalQuantity > 0
            ? [
                {
                  status: ApiEquipmentItemStatus.normal,
                  quantity: normalQuantity,
                },
                {
                  status: ApiEquipmentItemStatus.damaged,
                  quantity: damagedQuantity,
                },
                { status: ApiEquipmentItemStatus.lost, quantity: lostQuantity },
              ]
            : null,
      };
      return values;
    },
    [currentUser.id, workingItem?.type]
  );

  const handleCreateAdjustment = useCallback(
    (values: CreateApiInventoryItemAdjustment) => {
      apiClient
        .createInventoryItemAdjustments(currentAccount.id, values)
        .then(() => {
          showToast("success", "Check In was created successfully");
          thunkDispatch(
            loadInventoryItems({ apiClient, accountId: currentAccount.id })
          );

          if (item.id) {
            thunkDispatch(
              loadInventoryItemCheckoutCheckinModal({
                apiClient,
                accountId: currentAccount.id,
                itemId: item.id,
              })
            );
            thunkDispatch(
              reloadItemStorageLocations({
                apiClient,
                accountId: currentAccount.id,
                itemId: item.id,
                includeInactive: !includeInactiveLocations,
              })
            );
          }
          onClose();
        })
        .catch(() => {
          showToast("error", "Something went wrong while creating a Check In");
        });
    },
    [
      apiClient,
      currentAccount.id,
      onClose,
      showToast,
      thunkDispatch,
      includeInactiveLocations,
      item,
    ]
  );

  const handleOnSubmit = useCallback(
    (values: CheckoutCheckinFormValues) => {
      const newValues = mapValues(values);
      handleCreateAdjustment(newValues);
      if (isCheckoutPage && values.inventoryAdjustmentToCheckOut) {
        dispatch(removeCheckout(values.inventoryAdjustmentToCheckOut));
      }
    },
    [handleCreateAdjustment, mapValues, dispatch, isCheckoutPage]
  );

  const handleQrItem = useCallback(
    (value: string) => {
      if (!workingItem) return;
      dispatch(
        setCheckoutCheckinFiltersModal({
          serialNumber: value,
        })
      );
      onCloseQRItem();
    },
    [workingItem, onCloseQRItem, dispatch]
  );
  const handleSearch = useCallback(
    (search: string) => {
      if (!workingItem) return;
      dispatch(
        setCheckoutCheckinFiltersModal({
          search,
        })
      );
    },
    [workingItem, dispatch]
  );

  const handleCheckinPageChange = useCallback(
    (page: number) => {
      dispatch(setCheckoutCheckinFiltersModal({ page }));
    },
    [dispatch]
  );

  const handleClickRow = useCallback(
    (value: string, setFieldValue: (field: string, value: string) => void) => {
      setFieldValue("inventoryAdjustmentToCheckOut", value);
    },
    []
  );

  const loadItems = useCallback(() => {
    if (!workingItem) return;
    thunkDispatch(
      loadInventoryItemCheckoutCheckinModal({
        apiClient,
        accountId: currentAccount.id,
        itemId: workingItem.id,
      })
    );
  }, [apiClient, currentAccount.id, thunkDispatch, workingItem]);

  useEffect(() => {
    dispatch(
      setCheckoutCheckinFiltersModal({
        type: ApiItemAdjustmentType.issued,
        reason: ApiItemAdjustmentReason.checkout,
        isCheckedOutItem: true,
        pageSize: PAGE_SIZE,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!adjustment) {
      return;
    }
    // Note: This might be confusing because it appears as one row on the table of checkin
    // mockups show that checkin is always a find but sometimes behaviour as one row checkin
    // Bulk checkout control quantity independently.
    // Need to search inversely as we do: Is this checkout already checked in?

    if (adjustment.item.type === ApiInventoryType.checkout) {
      apiClient
        .findInventoryItemAdjustments(currentAccount.id, {
          inventoryAdjustmentCheckOutId: adjustment.id,
        })
        .then((res) => {
          if (res.data.length > 0) {
            //already checkin
            setDataModal([]);
            return;
          }
        })
        .catch(() =>
          showToast("error", "Something went wrong while loading a Check In")
        );
    }
    apiClient
      .getInventoryItem(currentAccount.id, adjustment.item.id)
      .then((res) => {
        setItemLoaded(res);
        setLoadingModal("succeeded");
      })
      .catch(() =>
        showToast("error", "Something went wrong while loading a Check In")
      );
  }, [adjustment, apiClient, currentAccount.id, showToast, dataModalSlice]);

  useEffect(() => {
    if (!adjustment) {
      setDataModal(dataModalSlice);
      setLoadingModal(loadingModalSlice);
    }
  }, [dataModalSlice, adjustment, loadingModalSlice]);

  useEffect(() => {
    if (prevFiltersModal !== filtersModal) {
      loadItems();
      setPrevFiltersModal(filtersModal);
    }
  }, [loadItems, filtersModal, prevFiltersModal]);

  useEffect(() => {
    return () => {
      dispatch(unloadInventoryCheckoutCheckinModal());
    };
  }, [dispatch]);

  if (!initialValues || !workingItem)
    return adjustment ? null : (
      <Flex flexDir="column" gap={4}>
        <Alert status="error">
          <AlertIcon />
          Something happened while loading checkout items. Please try again
        </Alert>
        <Button
          size="sm"
          maxW="80px"
          variant="outline"
          colorScheme="blue"
          onClick={onClose}
        >
          Cancel
        </Button>
      </Flex>
    );

  return (
    <VStack mt={-6} alignItems="flex-start">
      <Text mt={2} textColor={textColor}>
        {workingItem.identifiers.externalId} {workingItem.name}
      </Text>
      <Divider />
      <Flex w="100%" alignItems="center" justifyContent="space-between">
        {isDesktop && (
          <PageData total={totalModal} currentPage={page} pageSize={pageSize} />
        )}

        <Box
          w={isDesktop ? "33%" : "100%"}
          display="flex"
          alignItems="center"
          justifySelf="flex-end"
        >
          <AccountSearchBox
            searchPlaceholder="Search Requester"
            onInputChange={handleSearch}
          />

          <Button
            w={7}
            ml={2}
            onClick={onOpenQRItem}
            color={qrColor}
            onTouchEnd={onOpenQRItem}
            zIndex={100}
          >
            <Icon h={7} w={7} as={MdQrCode2} />
          </Button>
        </Box>
      </Flex>
      {loadingModal === "pending" ? (
        <Center w="100%">
          <Spinner color="blue.500" />
        </Center>
      ) : dataModal.length === 0 ? (
        <Flex w="100%" flexDir="column" gap={4}>
          <Alert status="warning">
            <AlertIcon />
            No checkout items found
          </Alert>
          <Button
            size="sm"
            maxW="80px"
            variant="outline"
            colorScheme="blue"
            onClick={onClose}
          >
            Cancel
          </Button>
        </Flex>
      ) : (
        <Box w="100%">
          <Formik
            onSubmit={handleOnSubmit}
            initialValues={initialValues}
            validationSchema={CheckinFormSchema}
          >
            {({ values, setFieldValue }) => {
              const normalQuantity =
                values.bulkCheckinItems && values.bulkCheckinItems[0]
                  ? values.bulkCheckinItems[0] * 1
                  : 0;

              const lostQuantity =
                values.bulkCheckinItems && values.bulkCheckinItems[1]
                  ? values.bulkCheckinItems[1] * 1
                  : 0;
              const damagedQuantity =
                values.bulkCheckinItems && values.bulkCheckinItems[2]
                  ? values.bulkCheckinItems[2] * 1
                  : 0;

              const totalQuantity =
                normalQuantity + lostQuantity + damagedQuantity;

              return (
                <Form>
                  <VStack
                    gap="8px"
                    flexDir="column"
                    alignItems="flex-start"
                    w="100%"
                    my={4}
                  >
                    {isDesktop && (
                      <Flex fontWeight="bold" w="100%" gap="8px" mt={2}>
                        <Box w="calc(5% - 8px)"></Box>
                        <Box w="calc(25% - 8px)">Requested By</Box>
                        <Box w="calc(20% - 8px)">Serial N°</Box>
                        <Box w="calc(25% - 8px)">Assigned To</Box>
                        <Box w="calc(25% - 8px)">Issued Date</Box>
                      </Flex>
                    )}
                    <Divider />

                    {dataModal.map((checkoutItem, index) => {
                      return isDesktop ? (
                        <Flex
                          w="100%"
                          gap="8px"
                          key={checkoutItem.id}
                          alignItems="center"
                          mx={4}
                          mt={4}
                          cursor="pointer"
                          onClick={() => {
                            setOriginalCheckout(checkoutItem);
                            handleClickRow(checkoutItem.id, setFieldValue);
                          }}
                        >
                          <Box w="calc(5% - 8px)">
                            <RadioButtonsControl
                              name="inventoryAdjustmentToCheckOut"
                              value={values.inventoryAdjustmentToCheckOut || ""}
                              radioOptions={[
                                {
                                  schemeColor: "blue",
                                  value: checkoutItem.id,
                                },
                              ]}
                            />
                          </Box>
                          <Box w="calc(25% - 8px)">
                            <UserBadge value={checkoutItem.requester} />
                          </Box>

                          <Box w="calc(20% - 8px)">
                            <Text>
                              {checkoutItem.serialNumber
                                ? checkoutItem.serialNumber
                                : "-"}
                            </Text>
                          </Box>

                          <Box w="calc(25% - 8px)">
                            <Text>
                              {checkoutItem.assignedTo
                                ? `${checkoutItem.assignedTo.firstName} ${checkoutItem.assignedTo.lastName}`
                                : "no assignee"}
                            </Text>
                          </Box>

                          <Box w="calc(25% - 8px)">
                            <DateBadge
                              showRelative={false}
                              hideTime
                              numberFormat
                              date={checkoutItem.date}
                            />
                          </Box>
                        </Flex>
                      ) : (
                        <Box
                          border="2px solid"
                          borderColor={
                            values.inventoryAdjustmentToCheckOut ===
                            checkoutItem.id
                              ? "blue.600"
                              : "transparent"
                          }
                          borderRadius={6}
                          p={2}
                          pr={4}
                        >
                          <Grid
                            w="100%"
                            templateColumns="auto 1fr 1fr"
                            gap="8px"
                            key={checkoutItem.id}
                            alignItems="start"
                            my={4}
                            mx={2}
                            cursor="pointer"
                            border="1px solid"
                            borderRadius={6}
                            borderColor={
                              values.inventoryAdjustmentToCheckOut ===
                              checkoutItem.id
                                ? "blue.500"
                                : "gray.300"
                            }
                            onClick={() =>
                              handleClickRow(checkoutItem.id, setFieldValue)
                            }
                            templateAreas={TEMPLATE_AREAS}
                          >
                            <GridItem ml={2} mt={2} gridArea="radio">
                              <RadioButtonsControl
                                name="inventoryAdjustmentToCheckOut"
                                value={
                                  values.inventoryAdjustmentToCheckOut || ""
                                }
                                radioOptions={[
                                  {
                                    schemeColor: "blue",
                                    value: checkoutItem.id,
                                  },
                                ]}
                              />
                            </GridItem>

                            <GridItem gridArea="requestedBy">
                              <Text fontWeight="semibold">Requested By:</Text>
                            </GridItem>

                            <GridItem gridArea="requestedByValue">
                              <UserBadge
                                value={checkoutItem.requester}
                                includeAvatar={false}
                              />
                            </GridItem>

                            <GridItem gridArea="itemId">
                              <Text fontWeight="semibold">Serial N°:</Text>
                            </GridItem>

                            <GridItem gridArea="serialNumber">
                              <Text>
                                {checkoutItem.serialNumber
                                  ? checkoutItem.serialNumber
                                  : "-"}
                              </Text>
                            </GridItem>

                            <GridItem gridArea="assignedTo">
                              <Text fontWeight="semibold">Assigned To:</Text>
                            </GridItem>

                            <GridItem gridArea="assignedToValue">
                              <Text>
                                {checkoutItem.assignedTo
                                  ? `${checkoutItem.assignedTo.firstName} ${checkoutItem.assignedTo.lastName}`
                                  : "no assignee"}
                              </Text>
                            </GridItem>

                            <GridItem gridArea="issuedDate">
                              <Text fontWeight="semibold">Issued Date:</Text>
                            </GridItem>

                            <GridItem gridArea="issuedDateValue">
                              <DateBadge
                                showRelative={false}
                                hideTime
                                numberFormat
                                date={checkoutItem.date}
                              />
                            </GridItem>

                            <GridItem gridArea="returnDate">
                              <Text fontWeight="semibold">Return Date:</Text>
                            </GridItem>

                            <GridItem gridArea="returnDateValue">
                              {checkoutItem.returnDate ? (
                                <DateBadge
                                  showRelative={false}
                                  hideTime
                                  numberFormat
                                  date={checkoutItem.returnDate}
                                />
                              ) : (
                                <Text>-</Text>
                              )}
                            </GridItem>

                            <GridItem gridArea="workOrder">
                              <Text fontWeight="semibold">Work Order:</Text>
                            </GridItem>

                            <GridItem gridArea="workOrderValue">
                              <Text>
                                {requestsMapModal[checkoutItem.requestId || ""]
                                  ?.key || "-"}
                              </Text>
                            </GridItem>

                            <GridItem gridArea="notes">
                              <Text fontWeight="semibold">Notes:</Text>
                            </GridItem>

                            <GridItem gridArea="notesValue">
                              <Text>{checkoutItem.notes || "-"}</Text>
                            </GridItem>
                          </Grid>
                        </Box>
                      );
                    })}
                  </VStack>
                  <Flex
                    my={8}
                    gap={4}
                    flexDirection={isDesktop ? "row" : "column"}
                    justifyContent="space-between"
                  >
                    <DatePickerControl
                      value={values.returnDate}
                      name="returnDate"
                      label="Return Date"
                      placeholder="mm/dd/yy"
                    />
                    {workingItem?.type === ApiInventoryType.checkout && (
                      <StatusCheckinSelectControl
                        label="Item Status"
                        name="status"
                        value={values.status ? values.status : undefined}
                        placeholder="Select a status"
                      />
                    )}
                  </Flex>
                  {workingItem?.type === ApiInventoryType.bulkCheckout &&
                    originalCheckout && (
                      <Box my={2}>
                        <Text mb={2} fontSize="2xl" fontWeight="bold">
                          Item Statuses
                        </Text>

                        <Flex
                          w="100%"
                          alignItems="center"
                          justifyContent="space-between"
                          gap={2}
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            gap={2}
                          >
                            <Text whiteSpace="nowrap">Normal Conditions</Text>
                            <NumberInputControl
                              showStepper
                              precision={0}
                              min={0}
                              max={
                                workingItem?.type ===
                                ApiInventoryType.bulkCheckout
                                  ? originalCheckout.remainingQuantity || 0
                                  : originalCheckout.quantity
                              }
                              name="bulkCheckinItems[0]" //value normal
                              value={
                                values.bulkCheckinItems &&
                                values.bulkCheckinItems[0]
                                  ? values.bulkCheckinItems[0]
                                  : 0
                              }
                            />
                          </Flex>
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            gap={2}
                          >
                            Lost
                            <NumberInputControl
                              showStepper
                              precision={0}
                              min={0}
                              max={
                                workingItem?.type ===
                                ApiInventoryType.bulkCheckout
                                  ? originalCheckout.remainingQuantity || 0
                                  : originalCheckout.quantity
                              }
                              name="bulkCheckinItems[1]" //value lost
                              value={
                                values.bulkCheckinItems &&
                                values.bulkCheckinItems[1]
                                  ? values.bulkCheckinItems[1]
                                  : 0
                              }
                            />
                          </Flex>
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            gap={2}
                          >
                            Damaged
                            <NumberInputControl
                              showStepper
                              precision={0}
                              min={0}
                              max={
                                workingItem?.type ===
                                ApiInventoryType.bulkCheckout
                                  ? originalCheckout.remainingQuantity || 0
                                  : originalCheckout.quantity
                              }
                              name="bulkCheckinItems[2]" //value damaged
                              value={
                                values.bulkCheckinItems &&
                                values.bulkCheckinItems[2]
                                  ? values.bulkCheckinItems[2]
                                  : 0
                              }
                            />
                          </Flex>

                          <Text fontWeight="bold">
                            {totalQuantity}/
                            {workingItem?.type === ApiInventoryType.bulkCheckout
                              ? originalCheckout.remainingQuantity
                              : originalCheckout.quantity}
                          </Text>
                        </Flex>
                      </Box>
                    )}
                  {workingItem?.type === ApiInventoryType.bulkCheckout &&
                    originalCheckout &&
                    originalCheckout.remainingQuantity &&
                    totalQuantity - originalCheckout.remainingQuantity !==
                      0 && (
                      <Flex w="100%" flexDir="column" gap={4}>
                        <Alert status="warning">
                          <AlertIcon />
                          This is a partial checkin, remaining items will still
                          appear in the order.
                        </Alert>
                      </Flex>
                    )}
                  {values.status === ApiEquipmentItemStatus.lost ||
                    (values.bulkCheckinItems &&
                      values.bulkCheckinItems[1] > 0 && (
                        <Flex w="100%" flexDir="column" gap={4}>
                          <Alert status="warning">
                            <AlertIcon />
                            Item will not be returned to storage location if
                            it's lost
                          </Alert>
                        </Flex>
                      ))}
                  <TextEditorControl
                    value={values.notes}
                    name="notes"
                    label="Notes"
                  />

                  {totalModal > (pageSize || PAGE_SIZE) && (
                    <Pager
                      currentPage={page || 1}
                      pageSize={pageSize || PAGE_SIZE}
                      total={totalModal}
                      onPageChange={handleCheckinPageChange}
                    />
                  )}

                  <Flex mt={8} justifyContent="space-between">
                    <Button
                      size="sm"
                      variant="outline"
                      colorScheme="blue"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button size="sm" colorScheme="blue" type="submit">
                      {workingItem?.type === ApiInventoryType.bulkCheckout
                        ? "Bulk Check In"
                        : "Check In"}
                    </Button>
                  </Flex>

                  {isOpenQRItem && (
                    <QrQuickScanModal
                      isOpen={isOpenQRItem}
                      onClose={onCloseQRItem}
                      onData={(dataModal) => handleQrItem(dataModal)}
                    />
                  )}
                </Form>
              );
            }}
          </Formik>
        </Box>
      )}
    </VStack>
  );
};
