import { ApiClient, ApiInspectionScore } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../..";
import { updateRoomAndBuildingToProgressStatus } from "../../../../../utils/calcalateStatusInspection";
import { InspectionDetailsSliceProps } from "../inspection-details.slice";

export const updateAllCategories = createAsyncThunk<
  {
    updatedScores: ApiInspectionScore[];
    roomId: string;
  },
  {
    apiClient: ApiClient;
    locationId: string;
    categories: string[];
    scoreConfigId: string | null;
  }
>(
  "inspections-details/update-all-categories",
  async ({ apiClient, locationId, categories, scoreConfigId }, thunkAPI) => {
    const { auth, inspectionDetailsSlice } = thunkAPI.getState() as RootState;
    const { currentAccount } = auth;
    const { inspection } = inspectionDetailsSlice;

    if (!inspection) {
      return thunkAPI.rejectWithValue([]);
    }

    const updatedScores = await Promise.all(
      categories.map((categoryId) =>
        apiClient.updateInspectionScore(currentAccount.id, inspection.id, {
          locationId,
          inspectionCategoryId: categoryId,
          scoreConfigId,
          justification: null,
        })
      )
    );

    return { updatedScores, roomId: locationId };
  }
);

export const updateAllCategoriesHandler = (
  builder: ActionReducerMapBuilder<InspectionDetailsSliceProps>
) => {
  builder.addCase(updateAllCategories.fulfilled, (state, action) => {
    const { updatedScores, roomId } = action.payload;

    updatedScores.forEach((score) => {
      const key = `${score.locationId}::${score.inspectionCategoryId}`;
      state.scores[key] = score;
    });

    updateRoomAndBuildingToProgressStatus(
      roomId,
      state.roomContextMap,
      state.floorToRoomsMap,
      state.buildingMap,
      state.inspection!
    );
  });
};
