import {
  ApiInspection,
  ApiInspectionConfig,
  ApiInspectionScore,
  ApiInspectionScoreConfig,
  ApiInspectionStatus,
  ApiLocationArea,
  ApiLocationBuilding,
  ApiLocationField,
  ApiLocationFloor,
  ApiLocationRoom,
  ApiLocationStorage,
  ApiLocationWarehouse,
  ApiPagingInspectionScoreOptions,
  ApiRequest,
} from "@operations-hero/lib-api-client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Attachment } from "../../../../components/attachments/Attachments";
import { SignModes } from "../../../../components/e-sign/E-Sign";
import { LoadingStatus } from "../../project-list";
import { addRequestToInspectionHandler } from "./thunks/addRequestToInspection";
import { clearFiltersHandler } from "./thunks/clearFilters";
import { loadInspectionsDetailsHandler } from "./thunks/loadInspectionsDetails";
import { saveAndCompleteHandler } from "./thunks/saveAndComplete";
import { submitInspectionHandler } from "./thunks/submitInspection";
import { updateAllCategoriesHandler } from "./thunks/updateAllCategories";
import { updateInspectionScoreHandler } from "./thunks/updateInspectionScore";

// uniqueness on hash come from room.id::subCategory.id
export type ScoresRecord = Record<string, ApiInspectionScore>;

interface SubmitProps {
  eSignMode: SignModes;
  requiresESign: boolean;
  isSignCanvaOpen: boolean;
  isDeviceLandScape: boolean;
  displayESignSection: boolean;

  signature: Attachment | null;
  signatureFullName: string | null;

  submitNotes: string | null;
}
export enum LocationSubBuildingStatus {
  pending = "pending",
  inProgress = "in progress",
  completed = "completed",
}

export enum LocationRoomStatus {
  pending = "pending",
  inProgress = "in progress",
  completed = "completed",
}

export type InspectionBuildingEntry = {
  location: ApiLocationBuilding | ApiLocationWarehouse;
  sections: number;
  inspectedRooms: number;
  totalRooms: number;
  status: LocationSubBuildingStatus;
  violations: Record<string, number>;
};

export type InspectionFloorEntry = {
  location: ApiLocationFloor;
  inspectedRooms: number;
  totalRooms: number;
};

export type InspectionRoomEntry = {
  location:
    | ApiLocationRoom
    | ApiLocationArea
    | ApiLocationStorage
    | ApiLocationField;
  totalNumericalValue: number;
  status: LocationRoomStatus;
  //string is inspection score config id, number is amount
  violations: Record<string, number>;
};

export interface InspectionBuildingMap {
  [key: string]: InspectionBuildingEntry;
}

/*
{
  [building.id]: {
    [floor.id] : {location + scores},
    [floor2.id] : {location + scores},
  }
}
  */
export type InspectionBuildingToFloorsMap = Record<string, InspectionFloorMap>;

/*
{
  [floor.id] : {location + scores},
  [floor2.id] : {location + scores},
}
*/
export type InspectionFloorMap = Record<string, InspectionFloorEntry>;

/*
{
  [floor.id]: {
    [room.id] : {location + scores},
    [room2.id] : {location + scores},
  }
}
*/
export type InspectionFloorToRoomsMap = Record<string, InspectionRoomMap>;

/*
{
  [room.id] : {location + scores},
  [room2.id] : {location + scores},
}
*/
export type InspectionRoomMap = Record<string, InspectionRoomEntry>;

export type InspectionRoomContext = {
  floor: InspectionFloorEntry;
  building: InspectionBuildingEntry;
  room: InspectionRoomEntry;
};

export type InspectionRoomContextMap = Record<string, InspectionRoomContext>;

export type InspectionListFilters = ApiPagingInspectionScoreOptions;

export type InspectionDetailsSliceProps = {
  inspection: ApiInspection | null;
  inspectionConfig: ApiInspectionConfig | null;
  filters: InspectionListFilters;
  loadingStatus: LoadingStatus;
  totalScores: number;
  scores: ScoresRecord;
  requestMap: Record<string, ApiRequest>;
  // Calculated maps
  scoreConfigMap: Record<string, ApiInspectionScoreConfig>;
  buildingMap: InspectionBuildingMap;
  buildingToFloorsMap: InspectionBuildingToFloorsMap;
  floorToRoomsMap: InspectionFloorToRoomsMap;
  roomContextMap: InspectionRoomContextMap;

  submitProps: SubmitProps;
};

export const DEFAULT_SUBMIT_VALUES: SubmitProps = {
  signature: null,
  eSignMode: "off",
  requiresESign: false,
  isSignCanvaOpen: false,
  signatureFullName: null,
  isDeviceLandScape: false,
  displayESignSection: false,
  submitNotes: null,
};

export const initialState: InspectionDetailsSliceProps = {
  inspection: null,
  inspectionConfig: null,
  loadingStatus: "idle",
  filters: {
    page: 1,
    pageSize: 20,
    sort: "name",
    direction: "asc",
    search: "",
  },
  totalScores: 0,
  scores: {},
  scoreConfigMap: {},
  buildingMap: {},
  buildingToFloorsMap: {},
  floorToRoomsMap: {},
  roomContextMap: {},
  requestMap: {},
  submitProps: { ...DEFAULT_SUBMIT_VALUES },
};

const inspectionDetailsSlice = createSlice({
  name: "inspections-details",
  initialState,
  reducers: {
    unload: (state) => {
      state.inspection = null;
      state.inspectionConfig = null;
      state.filters = initialState.filters;
      state.loadingStatus = "idle";
      state.totalScores = 0;
      state.scores = {};
      /* maps */
      state.scoreConfigMap = {};
      state.buildingMap = {};
      state.buildingToFloorsMap = {};
      state.floorToRoomsMap = {};
      state.roomContextMap = {};
    },
    updateResolvedViolation(state, action: PayloadAction<ApiInspectionScore>) {
      const updatedScore = action.payload;
      if (!updatedScore) return;

      const roomId = updatedScore.locationId;
      const key = `${roomId}::${updatedScore.inspectionCategoryId}`;
      state.scores[key] = updatedScore;
    },
    updateInspectionAndBuildingStatusOnNewRoom: (
      state,
      action: PayloadAction<{ roomId: string }>
    ) => {
      const { roomId } = action.payload;
      const { inspection, roomContextMap } = state;
      const context = roomContextMap[roomId];
      if (!context) return;
      const { building } = context;
      if (building.status === LocationSubBuildingStatus.completed) {
        building.status = LocationSubBuildingStatus.inProgress;
      }
      if (!inspection) return;
      if (inspection.status === ApiInspectionStatus.completed) {
        inspection.status = ApiInspectionStatus.started;
      }
    },
    setDeliverProps: (
      state: InspectionDetailsSliceProps,
      action: PayloadAction<Partial<SubmitProps>>
    ) => {
      state.submitProps = { ...state.submitProps, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    loadInspectionsDetailsHandler(builder);
    clearFiltersHandler(builder);
    updateInspectionScoreHandler(builder);
    updateAllCategoriesHandler(builder);
    saveAndCompleteHandler(builder);
    submitInspectionHandler(builder);
    addRequestToInspectionHandler(builder);
  },
});

export const {
  unload,
  setDeliverProps,
  updateResolvedViolation,
  updateInspectionAndBuildingStatusOnNewRoom,
} = inspectionDetailsSlice.actions;
export default inspectionDetailsSlice.reducer;
