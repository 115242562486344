import { Button, Text } from "@chakra-ui/react";
import { ApiGeneratePdfReturnType } from "@operations-hero/lib-api-client";
import { useCallback, useState } from "react";
import { useAuthentication } from "../../../../../components/auth/AuthProvider";
import { useShowToast } from "../../../../../hooks/showToast";
import { fromBase64ToBlob } from "../../../../../utils/pdf";

export interface DownloadInspectionButtonProps {
  inspectionId: string;
  downloadName: string;
}

export const DownloadInspectionButton = ({
  inspectionId,
  downloadName,
}: DownloadInspectionButtonProps) => {
  const { currentAccount, apiClient } = useAuthentication();
  const showToast = useShowToast();
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);

  const downloadPDF = useCallback(() => {
    setIsDownloadingPDF(true);
    apiClient
      .generateInspectionPdf(currentAccount.id, inspectionId, {
        returnType: ApiGeneratePdfReturnType.base64,
      })
      .then((base64STR) => {
        const blob = fromBase64ToBlob(base64STR);
        const pdfUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = downloadName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsDownloadingPDF(false);
        URL.revokeObjectURL(pdfUrl);
      })
      .catch(() => {
        showToast("error", "Something went wrong, please try again");
        setIsDownloadingPDF(false);
      });
  }, [
    currentAccount.id,
    downloadName,
    inspectionId,
    setIsDownloadingPDF,
    apiClient,
    showToast,
  ]);

  return (
    <Button
      size="lg"
      flexDir="column"
      py={6}
      gap={1}
      onClick={downloadPDF}
      isLoading={isDownloadingPDF}
    >
      <Text fontWeight="bold" fontSize="md">
        California FIT Template
      </Text>
      <Text fontSize="sm">FIT PDF report for the state</Text>
    </Button>
  );
};
