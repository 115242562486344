import { ApiBudget } from "@operations-hero/lib-api-client";
import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import {
  AllocationsContext,
  AllocationsContextProps,
} from "./AllocationsContext";
import { Allocation } from "./types";

type AllocationsProviderProps<T> = {
  allocations: Allocation<T>[];
  setAllocations: (allocations: Allocation<T>[]) => void;
  setAllocation: (index: number, allocation: Allocation<T>) => void;
  totalAllocated: number;
};

type BudgetAllocationsProviderProps = {
  initialAllocations?: Allocation<ApiBudget>[];
  onChange?: (allocations: Allocation<ApiBudget>[]) => void;
  children: (props: AllocationsProviderProps<ApiBudget>) => ReactNode;
};

export const BudgetAllocationProvider: FC<BudgetAllocationsProviderProps> = ({
  initialAllocations,
  onChange,
  children,
}) => {
  const [allocations, setAllocationsState] = useState(initialAllocations ?? []);

  const setAllocations = useCallback(
    (allocations: Allocation<ApiBudget>[]) => {
      onChange && onChange(allocations);
      setAllocationsState(allocations);
    },
    [onChange]
  );

  const setAllocation = useCallback(
    (index: number, allocation: Allocation<ApiBudget>) => {
      const tempAllocations = [...allocations];
      tempAllocations[index] = allocation;
      setAllocations(tempAllocations);
    },
    [allocations, setAllocations]
  );

  const totalAllocated = useMemo(() => {
    return allocations.reduce<number>((acc, allocation) => {
      const amountQty = Number.parseFloat(allocation.amount + "");
      if (Number.isNaN(amountQty)) return acc;
      return acc + amountQty;
    }, 0);
  }, [allocations]);

  const value: AllocationsContextProps<ApiBudget> = useMemo(() => {
    return {
      allocations,
      setAllocation,
      setAllocations,
      totalAllocated,
    };
  }, [allocations, setAllocation, totalAllocated, setAllocations]);
  return (
    <AllocationsContext.Provider value={value}>
      {children({ allocations, setAllocations, setAllocation, totalAllocated })}
    </AllocationsContext.Provider>
  );
};
