import { Box, Flex, useColorMode } from "@chakra-ui/react";
import { FC } from "react";
import { priorityColorMap } from "../../../../components/badges/PriorityBadge";
import { TimelineItemProps } from "./types";

type BaseTimelineItemProps = TimelineItemProps;

export const BaseTimelineItem: FC<BaseTimelineItemProps> = ({ request }) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      borderRadius="md"
      color="black"
      position="relative"
      height="5.8rem"
      borderColor="gray.500"
      backgroundColor={colorMode === "light" ? "white" : "gray.700"}
      w="full"
      alignSelf="center"
    >
      <Flex
        w="full"
        height="full"
        gap={2}
        alignItems="start"
        borderRadius="md"
        borderWidth="thin"
        p={1}
        boxShadow="base"
      >
        <Box h="full" w="full" justifyItems="center">
          <Box
            h="full"
            background={priorityColorMap[request.priority]}
            borderRadius="md"
            w="full"
          />
        </Box>
      </Flex>
    </Box>
  );
};
