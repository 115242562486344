import {
  Box,
  Button,
  Divider,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  ApiComment,
  ApiLaborTransaction,
  ApiTransactionType,
  ApiWorkflowFieldDataType,
  ApiWorkflowSchemaField,
  LaborType,
  UpdateApiRequest,
} from "@operations-hero/lib-api-client";
import { Form, Formik, FormikErrors, FormikProps } from "formik";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { AssigneeAutocompleteControl } from "../../../components/form-helpers/AssigneeAutocompleteControl";
import { CustomFieldInputControl } from "../../../components/form-helpers/CustomFieldInputControl";
import { ProjectAutocompleteControl } from "../../../components/form-helpers/ProjectAutocompleteControl";
import { ReasonAutocompleteControl } from "../../../components/form-helpers/ReasonAutocompleteControl";
import { FormikObserver } from "../../../hooks/formikObserver";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import {
  clearBulkEditDataAsync,
  FieldsToUpdate,
  setBulkEditStep,
  setFieldsToUpdate,
  setUpdateStatus,
  setValidationStatus,
  updateRequestsBulkAsync,
} from "../../../store/request-form/request-bulk-actions.slice";
import { areObjectsEquals, isObjectEmpty } from "../../../utils/compareObjects";
import { CommentForm } from "../../request-form/comments/CommentForm";
import { RequestStatusSelectControl } from "./../../../components/form-helpers/bulk-actions/RequestStatusSelectControl";
import { ReportingCategoryAutocompleteControl } from "./../../../components/form-helpers/ReportingCategoryAutocompleteControl";
import { BulkValues } from "./BulkEditModal";
import { EditFormSection } from "./EditFormSection";
import { LaborForm } from "./LaborForm";

export const BulkEditForm = () => {
  const { currentAccount, apiClient, currentUser } = useAuthentication();

  const {
    workflow,
    schemaFields,
    fieldsToUpdate: bulkCommonPayload,
    updateStatus,
    totalSelected,
    nonCompliantRequestsByField,
    validationStatus,
    bulkEditStep,
  } = useSelector((root: RootState) => root.requestsBulkActionsSlice);

  const [customSchemaFields, setCustomSchemaFields] = useState<
    ApiWorkflowSchemaField[]
  >([]);
  const [formHasChanges, setFormHasChanges] = useState(false);
  const [newComment, setNewComment] = useState<ApiComment>();
  const now = new Date().toISOString();
  const initialTransaction = useMemo(() => {
    return {
      id: "",
      created: now,
      createdBy: currentUser,
      datePerformed: now,
      hours: 0,
      laborer: currentUser,
      type: ApiTransactionType.labor,
      updated: now,
      updatedBy: currentUser,
      laborType: LaborType.regular,
      attachmentCount: 0,
      budget: null,
      hourlyRate: 0,
      total: 0,
      requestId: "",
      requestKey: "",
    } as ApiLaborTransaction;
  }, [currentUser, now]);
  const [transaction, setTransaction] =
    useState<ApiLaborTransaction>(initialTransaction);
  const bulkEditForm = useRef<FormikProps<BulkValues>>(null);

  const dispatch = useThunkDispatch();
  const qtyColor = useColorModeValue("blackAlpha.700", "white");
  const showToast = useShowToast();
  const errorColor = useColorModeValue("red.500", "red.300");

  const [requiredSystemFields, setRequiredSystemFields] = useState<string[]>(
    []
  );

  const initialValues = useMemo(() => {
    return {
      status: null,
      assignTo: [],
      category: null,
      reason: null,
      transaction: initialTransaction,
      comment: {} as ApiComment,
      metadata: {},
      project: null,
    } as BulkValues;
  }, [initialTransaction]);

  function createShapeValidationObject(
    shape: any,
    metadata: Record<string, any>,
    properties: string[]
  ) {
    let newShape = { ...shape };
    for (const property of properties) {
      if (metadata[property]) {
        newShape = {
          ...newShape,
          [property]: metadata[property],
        };
      }
    }
    return newShape;
  }

  const validationSchema = useMemo(() => {
    const metadataShape = nonCompliantRequestsByField.reduce<
      Record<string, any>
    >((result, item) => {
      const field = item[0];
      let key = "";
      switch (field.key) {
        case "SYSTEM-CATEGORY":
          key = "category";
          break;
        case "SYSTEM-REASON":
          key = "reason";
          break;
        case "SYSTEM-ASSIGN-TO":
          key = "assignTo";
          break;
        default:
          key = field.key;
          break;
      }

      let name = "";
      switch (field.key) {
        case "SYSTEM-CATEGORY":
          name = "Category";
          break;
        case "SYSTEM-REASON":
          name = "Reason";
          break;
        case "SYSTEM-ASSIGN-TO":
          name = "Assign To";
          break;
        default:
          name = field.name;
          break;
      }
      result[key] = (
        field.dataType === ApiWorkflowFieldDataType.checkbox
          ? yup.boolean()
          : field.dataType === ApiWorkflowFieldDataType.date
            ? yup.date()
            : field.dataType === ApiWorkflowFieldDataType.location
              ? yup.object()
              : field.dataType === ApiWorkflowFieldDataType.number
                ? yup.number()
                : field.dataType === ApiWorkflowFieldDataType.selection
                  ? yup.object()
                  : field.dataType === ApiWorkflowFieldDataType.user
                    ? yup.object()
                    : field.dataType === ApiWorkflowFieldDataType.system &&
                        (field.key === "SYSTEM-CATEGORY" ||
                          field.key === "SYSTEM-REASON")
                      ? yup.object()
                      : field.dataType === ApiWorkflowFieldDataType.system &&
                          field.key === "SYSTEM-ASSIGN-TO"
                        ? yup.array().min(1, "Assign To is required")
                        : yup.string()
      )
        .nullable()
        .required(`${name} is required`);
      return result;
    }, {});
    const newMetadataShape: Record<string, any> = {};
    const newValues = [...requiredSystemFields];
    Object.keys(metadataShape).forEach((key) => {
      if (
        key.substring(0, 6) !== "SYSTEM" &&
        key !== "category" &&
        key !== "reason" &&
        key !== "assignTo"
      ) {
        newMetadataShape[key] = metadataShape[key];
      }

      if (
        key.substring(0, 6) === "SYSTEM" &&
        !requiredSystemFields.includes(key)
      ) {
        newValues.push(key);
      }
    });
    setRequiredSystemFields(newValues);
    const shape: any = {};
    if (Object.keys(newMetadataShape).length > 0) {
      shape.metadata = yup.object().shape(newMetadataShape);
    }

    const newShape = createShapeValidationObject(shape, metadataShape, [
      "category",
      "reason",
      "assignTo",
    ]);

    return yup.object().shape(newShape);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonCompliantRequestsByField]);

  const { invalidLabor, invalidComment } = useMemo(() => {
    return {
      invalidLabor:
        requiredSystemFields.includes("SYSTEM-LABOR") &&
        transaction.hours === 0,
      invalidComment:
        requiredSystemFields.includes("SYSTEM-COMMENTS") &&
        (!newComment || newComment?.comment.length < 2),
    };
  }, [newComment, requiredSystemFields, transaction.hours]);

  const handleOnSubmit = useCallback(
    (values: BulkValues) => {
      if (invalidLabor || invalidComment) return;
      Object.keys(values.metadata).forEach((customField) => {
        if (!values.metadata[customField]) delete values.metadata[customField];
      });
      const fieldsToUpdate: FieldsToUpdate = {
        status: values.status || undefined,
        assignees:
          values.assignTo && values.assignTo.length
            ? values.assignTo
            : undefined,
        reportingCategory: values.category || undefined,
        reason: values.reason || undefined,
        metadata: isObjectEmpty(values.metadata) ? undefined : values.metadata,
        transaction:
          transaction.hours > 0
            ? { ...transaction, laborer: transaction.laborer.id }
            : undefined,
        comment:
          newComment && newComment.comment.length > 1
            ? { comment: newComment.comment, isPublic: newComment.isPublic }
            : undefined,
        projectId: values.project ? values.project.id : null,
      };

      Object.keys(fieldsToUpdate).forEach((field) => {
        const key = field as keyof Partial<UpdateApiRequest>;
        if (!fieldsToUpdate[key]) delete fieldsToUpdate[key];
      });

      dispatch(setFieldsToUpdate(fieldsToUpdate));
    },
    [dispatch, newComment, transaction, invalidComment, invalidLabor]
  );

  const handleClose = useCallback(() => {
    dispatch(setBulkEditStep("none"));
  }, [dispatch]);

  const handleCancelSection = useCallback(
    (section: keyof BulkValues) => {
      const { current } = bulkEditForm;

      if (!current) return;
      const { setValues, values } = current;
      const valuesToUpdate = { ...values };
      valuesToUpdate[section] = initialValues[section];
      switch (section) {
        case "status":
          dispatch(setValidationStatus("canceled"));
          valuesToUpdate["metadata"] = initialValues["metadata"];
          break;
        case "comment":
          setNewComment(undefined);
          break;
        case "transaction":
          setTransaction(initialTransaction);
          break;
      }
      setValues({ ...valuesToUpdate }, true);
    },
    [dispatch, initialValues, initialTransaction]
  );

  const triggerFormValidation = useCallback(async () => {
    const fieldToTouch: Record<string, boolean> = {};
    if (
      nonCompliantRequestsByField.length === 0 &&
      bulkEditForm.current?.errors
    )
      await bulkEditForm.current.validateForm();

    const fields = nonCompliantRequestsByField.reduce((touched, item) => {
      touched[item[0].key] = true;
      return touched;
    }, fieldToTouch);

    bulkEditForm.current?.setTouched(fields, true);
  }, [nonCompliantRequestsByField]);

  const onChangeFormValues = useCallback(
    (values: BulkValues) => {
      const currentValues = bulkEditForm.current?.values;

      const hasChanges = areObjectsEquals(currentValues, initialValues)
        ? false
        : true;
      setFormHasChanges(hasChanges);

      if (values["status"]) triggerFormValidation();
    },
    [initialValues, triggerFormValidation]
  );

  const customFieldsAreNonCompliant = useCallback(
    (errors: FormikErrors<BulkValues>) => {
      const customFieldsAreRequired = nonCompliantRequestsByField.some(
        (field) => field[0].dataType !== ApiWorkflowFieldDataType.system
      );
      return (
        validationStatus === "failed" &&
        !isObjectEmpty(errors) &&
        customFieldsAreRequired
      );
    },
    [nonCompliantRequestsByField, validationStatus]
  );

  useEffect(() => {
    if (updateStatus === "in-progress") {
      if (isObjectEmpty(bulkCommonPayload)) {
        showToast("info", "Nothing to update");
        setUpdateStatus("idle");
      } else {
        dispatch(
          updateRequestsBulkAsync({
            apiClient: apiClient,
            accountId: currentAccount.id,
          })
        );
      }
    }
  }, [
    updateStatus,
    apiClient,
    currentAccount.id,
    dispatch,
    bulkCommonPayload,
    showToast,
  ]);

  useEffect(() => {
    if (validationStatus === "failed" || validationStatus === "idle") {
      triggerFormValidation();
    }

    if (validationStatus === "canceled") {
      dispatch(clearBulkEditDataAsync());
    }
  }, [
    validationStatus,
    nonCompliantRequestsByField,
    triggerFormValidation,
    dispatch,
  ]);

  useEffect(() => {
    const fields = schemaFields.filter(
      (field) => field.field.dataType !== ApiWorkflowFieldDataType.system
    );
    setCustomSchemaFields(fields);
  }, [schemaFields]);

  return (
    workflow && (
      <>
        <Box
          style={{
            visibility: bulkEditStep === "edit-form" ? "visible" : "hidden",
            height: bulkEditStep === "edit-form" ? "auto" : 0,
          }}
        >
          <ModalHeader>
            <Text>
              Bulk Edits
              <Text
                as="span"
                color={qtyColor}
                fontSize="medium"
              >{` (${totalSelected})`}</Text>
            </Text>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{ ...initialValues }}
              onSubmit={handleOnSubmit}
              validationSchema={validationSchema}
              validateOnMount={true}
              innerRef={bulkEditForm}
            >
              {({ values, errors }) => {
                return (
                  <Form id="edit-form">
                    <FormikObserver cb={onChangeFormValues} />
                    <VStack spacing="3" divider={<Divider />}>
                      <EditFormSection
                        type="status"
                        onCancel={() => handleCancelSection("status")}
                      >
                        <RequestStatusSelectControl
                          name="status"
                          value={values.status}
                          showError={invalidLabor || invalidComment}
                        />
                      </EditFormSection>
                      <EditFormSection
                        type="assignTo"
                        onCancel={() => handleCancelSection("assignTo")}
                        isOpenCondition={validationStatus === "failed"}
                      >
                        <AssigneeAutocompleteControl
                          name="assignTo"
                          workflow={workflow}
                          value={values.assignTo}
                        />
                      </EditFormSection>

                      <EditFormSection
                        type="category"
                        onCancel={() => handleCancelSection("category")}
                        isOpenCondition={validationStatus === "failed"}
                      >
                        <ReportingCategoryAutocompleteControl
                          name="category"
                          value={values.category}
                        />
                      </EditFormSection>

                      <EditFormSection
                        type="reason"
                        onCancel={() => handleCancelSection("reason")}
                        isOpenCondition={validationStatus === "failed"}
                      >
                        <ReasonAutocompleteControl
                          name="reason"
                          value={values.reason}
                          workflow={workflow}
                        />
                      </EditFormSection>
                      <EditFormSection
                        type="project"
                        onCancel={() => handleCancelSection("project")}
                        isOpenCondition={validationStatus === "failed"}
                      >
                        <ProjectAutocompleteControl
                          name="project"
                          value={values.project}
                        />
                      </EditFormSection>
                      <EditFormSection
                        type="labor"
                        onCancel={() => {
                          handleCancelSection("transaction");
                        }}
                        isOpenCondition={validationStatus === "failed"}
                      >
                        <LaborForm
                          key={transaction.hours.toString()}
                          transaction={transaction}
                          onChange={setTransaction}
                          workflow={workflow}
                        />
                        {invalidLabor && (
                          <Text color={errorColor} fontSize="sm">
                            Labor is required
                          </Text>
                        )}
                      </EditFormSection>
                      {requiredSystemFields.includes("SYSTEM-COMMENTS") && (
                        <EditFormSection
                          type="comment"
                          onCancel={() => handleCancelSection("comment")}
                          isOpenCondition={validationStatus === "failed"}
                        >
                          <CommentForm
                            comment={newComment}
                            autoFocusComment={false}
                            commentFrom="request"
                            onSave={() => {}}
                            setCurrentComment={setNewComment}
                          />
                          {invalidComment && (
                            <Text color={errorColor} fontSize="sm">
                              Comments are required
                            </Text>
                          )}
                        </EditFormSection>
                      )}

                      {customSchemaFields.length > 0 && (
                        <EditFormSection
                          type="customField"
                          requiresValidation={true}
                          isNotCompliantToValidation={customFieldsAreNonCompliant(
                            errors
                          )}
                          isOpenCondition={validationStatus === "failed"}
                          onCancel={() => handleCancelSection("metadata")}
                        >
                          <VStack spacing="4">
                            {customSchemaFields.map((schemaField) => (
                              <CustomFieldInputControl
                                value={
                                  values.metadata[schemaField.field.key] || null
                                }
                                name={`metadata.${schemaField.field.key}`}
                                field={schemaField.field}
                                showErrorOnValidation={true}
                                key={`metadata::${schemaField.field.key}`}
                              />
                            ))}
                          </VStack>
                        </EditFormSection>
                      )}
                    </VStack>
                  </Form>
                );
              }}
            </Formik>
          </ModalBody>
          <ModalFooter>
            <Box mt="6">
              <Button
                variant="outline"
                colorScheme="blue"
                mr={3}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                variant="solid"
                type="submit"
                form="edit-form"
                isDisabled={
                  !formHasChanges || validationStatus === "in-progress"
                }
                isLoading={updateStatus === "in-progress"}
                loadingText="Saving"
              >
                Save
              </Button>
            </Box>
          </ModalFooter>
        </Box>
      </>
    )
  );
};
