import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { ApiLocationRoom } from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { FC, useCallback, useMemo } from "react";
import { IoCheckmark } from "react-icons/io5";
import { MdArrowForward } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { LocationTwoLine } from "../../../components/badges/LocationTwoLine";
import { ButtonLink } from "../../../components/buttons/LinkButton";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import { saveAndComplete } from "../../../store/planning-hq/inspections/details/thunks/saveAndComplete";
import { updateAllCategories } from "../../../store/planning-hq/inspections/details/thunks/updateAllCategories";
import { capitalizeWords } from "../../../utils/capitalizeFirstLetter";
import { ScoreCategoryInspection } from "./components/ScoreCategoryInspection";

const dateFormat = "MMM, yyyy";
export const InspectionRoomForm: FC = () => {
  const { roomId, locationId, id } = useParams<{
    roomId: string;
    locationId: string;
    id: string;
  }>();

  const navigate = useNavigate();
  const showToast = useShowToast();
  const { search, pathname } = useLocation();
  const thunkDispatch = useThunkDispatch();
  const { apiClient } = useAuthentication();
  const queryParams = new URLSearchParams(search);
  const partb = queryParams.get("partb") === "true";

  const isReadMode = useMemo(() => pathname.includes("/account"), [pathname]);

  const {
    loadingStatus,
    buildingMap: locationsScores,
    inspectionConfig,
    scores,
  } = useSelector((state: RootState) => state.inspectionDetailsSlice);
  const { locationMap } = useSelector((state: RootState) => state.localCache);

  const location = useMemo(
    () => (!locationId ? null : locationsScores[locationId]),
    [locationsScores, locationId]
  );

  const room = useMemo(
    () => (!roomId ? null : locationMap[roomId]),
    [locationMap, roomId]
  );

  const filteredCategoryGroups = useMemo(
    () =>
      inspectionConfig?.categoryGroups.filter((group) =>
        partb ? !group.includeInRollup : group.includeInRollup
      ),
    [inspectionConfig, partb]
  );

  const areAllScoresChecked = useMemo(
    () =>
      filteredCategoryGroups?.every((group) =>
        group.categories.every((category) => {
          const scoreKey = `${roomId}::${category.id}`;
          return scoreKey in scores;
        })
      ) ?? false,
    [filteredCategoryGroups, roomId, scores]
  );

  const handleMarkAllAsNA = useCallback(() => {
    if (!filteredCategoryGroups || !roomId) {
      return;
    }

    const categories = filteredCategoryGroups.flatMap((group) =>
      group.categories.map((category) => category.id)
    );

    thunkDispatch(
      updateAllCategories({
        apiClient,
        locationId: roomId,
        categories,
        scoreConfigId: null,
      })
    )
      .then(unwrapResult)
      .catch(() => showToast("error", "Something went wrong updating a score"));
  }, [apiClient, roomId, thunkDispatch, filteredCategoryGroups, showToast]);

  const handleSaveAndFinish = useCallback(
    (roomId: string) => {
      thunkDispatch(
        saveAndComplete({
          roomId,
        })
      ).then(() =>
        navigate(`/planning/inspection/${id}/details/${locationId}`)
      );
    },
    [thunkDispatch, navigate, id, locationId]
  );

  const generatePath = useCallback(
    (partb: boolean, roomId?: string) => {
      const basePath = pathname.includes("/account")
        ? `/account/planning/inspection/${id}/details/${locationId}`
        : `/planning/inspection/${id}/details/${locationId}`;

      if (!roomId) {
        return basePath;
      }

      return partb
        ? `${basePath}/room/${roomId}?partb=true`
        : `${basePath}/room/${roomId}`;
    },
    [pathname, id, locationId]
  );

  if (loadingStatus === "pending") {
    return (
      <Flex justify="center" align="center" minHeight="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (!location || !room || !inspectionConfig || !filteredCategoryGroups) {
    return null;
  }

  return (
    <Box w="100%" flex={1} pl={4}>
      <Flex justifyContent="space-between" align="center" mb={5}>
        <ButtonLink
          labelText={partb ? "Previous" : "Back"}
          to={partb ? generatePath(false, room.id) : generatePath(false)}
        />
        {!partb ? (
          <ButtonLink
            labelText="Continue"
            to={generatePath(true, room.id)}
            icon={MdArrowForward}
            invertedIconText
            isDisabled={!areAllScoresChecked}
          />
        ) : (
          !isReadMode && (
            <Button
              colorScheme="blue"
              onClick={() => handleSaveAndFinish(room.id)}
              isDisabled={!areAllScoresChecked}
            >
              Save & Finish
            </Button>
          )
        )}
      </Flex>

      <Flex justify="space-between" align="center" mb={4} gap={4}>
        <Box mb={4}>
          <Box>
            <Heading fontWeight="bold" mb={2}>
              {room?.name}
            </Heading>
            {location && (
              <LocationTwoLine
                value={location.location}
                displayAncestors={false}
              />
            )}
          </Box>

          <Box mt={4}>
            <Flex direction="row" gap={32}>
              <Box>
                <Text>Square Feet:</Text>

                <Text fontWeight="bold">
                  {(room as ApiLocationRoom).squareFeet}
                </Text>
              </Box>

              {(room as ApiLocationRoom).built && (
                <Box>
                  <Text>Built:</Text>

                  <Text fontWeight="bold">
                    {format(
                      new Date((room as ApiLocationRoom).built || ""),
                      dateFormat
                    )}
                  </Text>
                </Box>
              )}
            </Flex>
          </Box>
        </Box>
      </Flex>

      {!partb && (
        <Flex mt={8} justifyContent="space-between" alignItems="center">
          <Text fontSize="2xl" fontWeight="bold">
            Part IIa:{" "}
            <Text as="span" fontWeight="normal">
              Evaluation Detail
            </Text>
          </Text>
          {!isReadMode && (
            <Button
              variant="outline"
              colorScheme="blue"
              onClick={handleMarkAllAsNA}
            >
              <Icon mr={2} as={IoCheckmark} /> Mark All as NA
            </Button>
          )}
        </Flex>
      )}

      <Grid gap={4}>
        {filteredCategoryGroups.map((group) => {
          return (
            <GridItem key={`categoryGroup::${group.id}`} flex={1}>
              {!partb ? (
                <Text fontSize="2xl" fontWeight="bold">
                  {capitalizeWords(group.name)}
                </Text>
              ) : (
                <Flex mt={8} justifyContent="space-between" alignItems="center">
                  <Text fontSize="2xl" fontWeight="bold">
                    {group.name}
                  </Text>
                  {!isReadMode && (
                    <Button
                      variant="outline"
                      colorScheme="blue"
                      onClick={handleMarkAllAsNA}
                    >
                      <Icon mr={2} as={IoCheckmark} /> Mark All as NA
                    </Button>
                  )}
                </Flex>
              )}

              <Box mt={4} gap={1} display="flex" flexDirection="column">
                {group.categories.map((category) => {
                  return (
                    <ScoreCategoryInspection
                      key={`inspection-category::${category.id}`}
                      category={category}
                      locationId={room.id}
                      isReadMode={isReadMode}
                    />
                  );
                })}
              </Box>
            </GridItem>
          );
        })}
      </Grid>
      <Box mt={4} justifySelf="flex-end">
        {!partb ? (
          <ButtonLink
            labelText="Continue"
            to={generatePath(true, room.id)}
            icon={MdArrowForward}
            invertedIconText
            isDisabled={!areAllScoresChecked}
          />
        ) : (
          !isReadMode && (
            <Button
              colorScheme="blue"
              onClick={() => handleSaveAndFinish(room.id)}
              isDisabled={!areAllScoresChecked}
            >
              Save & Finish
            </Button>
          )
        )}
      </Box>
    </Box>
  );
};
