import { Box } from "@chakra-ui/layout";
import {
  ApiBudget,
  ApiBudgetSummary,
  ApiPagingBudgetWithFundingSourceOptions,
} from "@operations-hero/lib-api-client";
import { AsyncSelect } from "chakra-react-select";
import { FocusEventHandler, useCallback, useMemo } from "react";
import { useAuthentication } from "../auth/AuthProvider";
import { BudgetBadge } from "../badges/BudgetBadge";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  BudgetOptionpProp,
  BudgetSingleValueSelect,
  CustomSelectComponentProp,
} from "./select-overrides-types";

export interface BudgetWithFundingSourcesAutocompleteProps {
  value: ApiBudget | null;
  onChange: (value: ApiBudget | null) => void;
  name?: string;
  placeholder?: string;
  onBlur?: FocusEventHandler;
  defaultOptions?: ApiBudget[] | boolean;
  loadOptionParams?: Partial<ApiPagingBudgetWithFundingSourceOptions>;
  isDisabled?: boolean;
  isClearable?: boolean;
}

const CustomOptionComponent = createOptionComponent(
  ({ value }: { value: ApiBudget | ApiBudgetSummary }) => (
    <Box alignContent="center">
      <BudgetBadge value={value} />
    </Box>
  )
);

const CustomSingleValueComponent = (props: BudgetSingleValueSelect) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps} maxW="95%" minW={0}>
      <BudgetBadge value={data} />
    </Box>
  );
};

export const BudgetWithFundingSourcesAutocomplete = ({
  onChange,
  onBlur,
  name,
  placeholder,
  value,
  defaultOptions = true,
  loadOptionParams,
  isDisabled,
  isClearable,
}: BudgetWithFundingSourcesAutocompleteProps) => {
  const { currentAccount, apiClient } = useAuthentication();

  const handleChange = useCallback(
    (newValue: ApiBudget | null) => {
      onChange(newValue);
    },
    [onChange]
  );

  const loadOptions = useCallback(
    (inputValue: string, cb: any) => {
      apiClient
        .findBudgetsWithFundingSources(currentAccount.id, {
          search: inputValue,
          pageSize: 10,
          ...loadOptionParams,
        })
        .then((results) => {
          cb(results.data);
        });
    },
    [apiClient, currentAccount, loadOptionParams]
  );

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: BudgetOptionpProp) => CustomOptionComponent(props),
      SingleValue: (props: BudgetSingleValueSelect) =>
        CustomSingleValueComponent(props),
    };
  }, []);

  return (
    <AsyncSelect
      cacheOptions={true}
      components={components}
      defaultOptions={defaultOptions}
      isDisabled={isDisabled}
      loadOptions={loadOptions}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      placeholder={placeholder || "Budget code or keyword"}
      value={value}
      getOptionValue={(item: ApiBudgetSummary | ApiBudget) => item.id}
      isClearable={isClearable}
      chakraStyles={commonStyles}
    />
  );
};
