import { createContext, useContext } from "react";

export enum Products {
  HeroHQ = "HeroHQ",
  Events = "Events",
  InventoryHQ = "InventoryHQ",
}

export type ProductsContextProps = {
  product?: keyof typeof Products;
};

export const ProductsContext = createContext<ProductsContextProps | null>(null);

export function useProductsContext() {
  const value = useContext(ProductsContext);
  if (!value) return {};
  return value;
}
