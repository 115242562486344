import { Box, HStack, Text } from "@chakra-ui/react";
import { ApiInspectionScoreConfig } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { getColor } from "../../../../hooks/useInspectionRatingUtil";

interface ViolationsPillsProps {
  violations: Record<string, number>;
  scoreConfigMap: Record<string, ApiInspectionScoreConfig>;
}

export const ViolationPill: FC<ViolationsPillsProps> = ({
  violations,
  scoreConfigMap,
}) => {
  const violationEntries = Object.entries(violations);
  const totalViolations = violationEntries.length;

  return (
    <HStack spacing={2}>
      {violationEntries.map(([configId, amount], index) => {
        const config = scoreConfigMap[configId];
        if (!config) return null;
        const color = getColor(index, totalViolations);

        return (
          <Box
            key={configId}
            bg={color}
            borderRadius="md"
            px={2}
            py={1}
            color="white"
          >
            <Text fontSize="sm">
              {config.name}: {amount}
            </Text>
          </Box>
        );
      })}
    </HStack>
  );
};
