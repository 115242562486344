import {
  ApiClient,
  CreateApiEventReminder,
  UpdateApiEventReminder,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { LocalEventDetailsSliceState } from "../event-details.slice";

export interface LoadEventReminderParams {
  apiClient: ApiClient;
  accountId: string;
  eventId: string;
}

export const loadEventReminders = createAsyncThunk(
  "event/reminders",
  async (params: LoadEventReminderParams, thunkAPI) => {
    const { apiClient, accountId, eventId } = params;
    const { filters } = (thunkAPI.getState() as RootState).eventDetails
      .reminders;
    const result = await apiClient.findEventReminders(
      accountId,
      eventId,
      filters
    );
    return result;
  }
);

export const loadEventRemindersHandler = (
  builder: ActionReducerMapBuilder<LocalEventDetailsSliceState>
) => {
  builder.addCase(loadEventReminders.fulfilled, (state, action) => {
    if (state.event) {
      state.reminders.loading = "succeeded";
      if (action.payload) {
        state.reminders.data = action.payload.data;
        state.reminders.total = action.payload.total;
        state.reminders.filters = action.payload.options;
      }
    }
  });
  builder.addCase(loadEventReminders.pending, (state, action) => {
    state.reminders.loading = "pending";
  });
  builder.addCase(loadEventReminders.rejected, (state, action) => {
    state.reminders.loading = "failed";
  });
};

export interface CreateEventReminderParams {
  apiClient: ApiClient;
  accountId: string;
  eventId: string;
  reminder: CreateApiEventReminder;
}

export const createEventReminder = createAsyncThunk(
  "events/reminder/create",
  async (params: CreateEventReminderParams, thunkAPI) => {
    const { apiClient, accountId, eventId, reminder } = params;

    const result = await apiClient.createEventReminder(
      accountId,
      eventId,
      reminder
    );
    return result;
  }
);

export const createEventReminderHandler = (
  builder: ActionReducerMapBuilder<LocalEventDetailsSliceState>
) => {
  builder.addCase(createEventReminder.fulfilled, (state, action) => {
    if (state.event) {
      state.reminders.data.push(action.payload);
    }
  });
};

export interface UpdateEventReminderParams {
  apiClient: ApiClient;
  accountId: string;
  eventId: string;
  reminder: UpdateApiEventReminder;
  reminderId: string;
}

export const updateEventReminder = createAsyncThunk(
  "events/reminder/update",
  async (params: UpdateEventReminderParams, thunkAPI) => {
    const { apiClient, accountId, eventId, reminder, reminderId } = params;

    return await apiClient.updateEventReminder(
      accountId,
      eventId,
      reminder,
      reminderId
    );
  }
);

export const updateEventReminderHandler = (
  builder: ActionReducerMapBuilder<LocalEventDetailsSliceState>
) => {
  builder.addCase(updateEventReminder.fulfilled, (state, action) => {
    if (state.event) {
      const idx = state.reminders.data.findIndex(
        (data) => data.id === action.payload.id
      );
      if (idx !== -1) {
        const newState = [...state.reminders.data];
        newState[idx] = action.payload;
        state.reminders.data = newState;
      }
    }
  });
};
