import { Flex, Image, Text } from "@chakra-ui/react";
import { FC } from "react";
import { InventoryOrderItem } from "../../../../store/inventory/inventory-order-item-list.slice";

interface RepeatOrCreateInventoryOrderRowItemFormProps {
  items: InventoryOrderItem[];
}

export const RepeatOrCreateInventoryOrderRowItemForm: FC<
  RepeatOrCreateInventoryOrderRowItemFormProps
> = ({ items }) => {
  return (
    <>
      {items.map((item) => (
        <Flex
          key={`item-order:"${item.item.id}`}
          flexDir="row"
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Flex gap={2} w="42%" alignItems="center">
            <Image boxSize="40px" src={item.item.defaultImage?.url} />
            <Flex flexDir="column" justifyContent="center">
              <Text fontWeight="bold">{item.item.name}</Text>
              {item.item.identifiers.externalId && (
                <Text>#{item.item.identifiers.externalId}</Text>
              )}
            </Flex>
          </Flex>
          <Flex w="16%" flexDir="column" alignItems="center" flexShrink={0}>
            <Text fontSize="sm">Qty</Text>
            <Text fontSize="sm" fontWeight="bold">
              {item.requested}
            </Text>
          </Flex>
          <Flex w="16%" flexDir="column" alignItems="center" flexShrink={0}>
            <Text fontSize="sm">Unit Cost</Text>
            <Text fontSize="sm" fontWeight="bold">
              ${item.unitCost.toFixed(4)}
            </Text>
          </Flex>
          <Flex w="16%" flexDir="column" alignItems="center" flexShrink={0}>
            <Text fontSize="sm">Total Cost</Text>
            <Text fontSize="sm" fontWeight="bold">
              ${(item.requested * item.unitCost).toFixed(2)}
            </Text>
          </Flex>
        </Flex>
      ))}
    </>
  );
};
