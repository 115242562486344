import {
  ApiClient,
  ApiInventoryPurchaseOrder,
  ApiPagingInventoryPurchaseOrderOptions,
  ApiPurchaseOrderAttachmentType,
  ApproveApiInventoryPurchaseOrder,
  CreateApiPurchaseOrderAttachment,
  DeclineApiInventoryPurchaseOrder,
  DeliveryApiInventoryPurchaseOrder,
  UpdateApiInventoryPurchaseOrder,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { Attachment } from "../../../components/attachments/Attachments";
import { SignModes } from "../../../components/e-sign/E-Sign";
import { CustomCameraMode } from "../../../components/images/camera/CustomCamera";
import { LoadingStatus } from "../../../types/redux-slices-types";

interface DeliverProps {
  eSignMode: SignModes;
  requiresESign: boolean;
  isSignCanvaOpen: boolean;
  isDeviceLandScape: boolean;
  cameraMode: CustomCameraMode;
  displayESignSection: boolean;
  signature: Attachment | null;
  signatureFullName: string | null;
  attachments: Attachment[];
  deliveryNotes: string | null;
}

export interface InventoryPurchaseOrdersSlice {
  loading: LoadingStatus;
  data: ApiInventoryPurchaseOrder[];
  total: number;
  pageSize: number;
  isloading: boolean;

  editMode: boolean;
  isOpenApprovalModal: boolean;
  isOpenDeliveryModal: boolean;
  isOpenDeclineModal: boolean;
  workingPurchaseOrder: ApiInventoryPurchaseOrder | null;

  deliverProps: DeliverProps;
}

export const DEFAULT_DELIVER_PURCHASE_ORDER_VALUES: DeliverProps = {
  signature: null,
  eSignMode: "off",
  cameraMode: "off",
  requiresESign: false,
  isSignCanvaOpen: false,
  signatureFullName: null,
  isDeviceLandScape: false,
  displayESignSection: false,
  attachments: [],
  deliveryNotes: null,
};

const DEFAULT_PURCHASE_ORDER_VALUES: InventoryPurchaseOrdersSlice = {
  data: [],
  total: 0,
  pageSize: 20,
  loading: "idle",
  isloading: false,

  editMode: false,
  isOpenApprovalModal: false,
  isOpenDeliveryModal: false,
  isOpenDeclineModal: false,
  workingPurchaseOrder: null,

  deliverProps: { ...DEFAULT_DELIVER_PURCHASE_ORDER_VALUES },
};

export const inventoryPurchaseOrdersSlice = createSlice({
  name: "inventory-puurchase-orders",
  initialState: { ...DEFAULT_PURCHASE_ORDER_VALUES },
  reducers: {
    unloadPurchaseOrders: (state: InventoryPurchaseOrdersSlice) => {
      state = { ...DEFAULT_PURCHASE_ORDER_VALUES };
    },
    setIsOpenApproveModal: (
      state: InventoryPurchaseOrdersSlice,
      action: PayloadAction<ApiInventoryPurchaseOrder | null>
    ) => {
      state.workingPurchaseOrder = action.payload;
      action.payload
        ? (state.isOpenApprovalModal = true)
        : (state.isOpenApprovalModal = false);
    },
    setIsOpenDeliverModal: (
      state: InventoryPurchaseOrdersSlice,
      action: PayloadAction<ApiInventoryPurchaseOrder | null>
    ) => {
      state.workingPurchaseOrder = action.payload;
      action.payload
        ? (state.isOpenDeliveryModal = true)
        : (state.isOpenDeliveryModal = false);
    },
    setIsOpenDeclineModal: (
      state: InventoryPurchaseOrdersSlice,
      action: PayloadAction<ApiInventoryPurchaseOrder | null>
    ) => {
      state.workingPurchaseOrder = action.payload;
      action.payload
        ? (state.isOpenDeclineModal = true)
        : (state.isOpenDeclineModal = false);
    },
    setEditMode: (
      state: InventoryPurchaseOrdersSlice,
      action: PayloadAction<boolean>
    ) => {
      state.editMode = action.payload;
    },
    setDeliverProps: (
      state: InventoryPurchaseOrdersSlice,
      action: PayloadAction<Partial<DeliverProps>>
    ) => {
      state.deliverProps = { ...state.deliverProps, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadPurchaseOrders.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data;
      state.total = action.payload.total;
    });

    /* Update */
    builder.addCase(updatePurchaseOrder.fulfilled, (state, action) => {
      const index = state.data.findIndex(
        (purchaseOrder) => purchaseOrder.id === action.payload.id
      );

      if (index !== -1) {
        state.data[index] = action.payload;
      }
      state.workingPurchaseOrder = action.payload;
      state.isloading = false;
    });

    builder.addCase(updatePurchaseOrder.pending, (state, action) => {
      state.isloading = true;
    });

    /* Approve */
    builder.addCase(approvePurchaseOrder.fulfilled, (state, action) => {
      const index = state.data.findIndex(
        (purchaseOrder) => purchaseOrder.id === action.payload.id
      );

      if (index !== -1) {
        state.data[index] = action.payload;
      }
      state.isloading = false;
    });

    builder.addCase(approvePurchaseOrder.pending, (state, action) => {
      state.isloading = true;
    });

    /* Decline */
    builder.addCase(declinePurchaseOrder.fulfilled, (state, action) => {
      const index = state.data.findIndex(
        (purchaseOrder) => purchaseOrder.id === action.payload.id
      );

      if (index !== -1) {
        state.data[index] = action.payload;
      }
      state.isloading = false;
    });

    builder.addCase(declinePurchaseOrder.pending, (state, action) => {
      state.isloading = true;
    });

    builder.addCase(declinePurchaseOrder.rejected, (state, action) => {
      state.isloading = false;
    });

    /* Deliver */
    builder.addCase(deliverPurchaseOrder.fulfilled, (state, action) => {
      const index = state.data.findIndex(
        (purchaseOrder) => purchaseOrder.id === action.payload.id
      );

      if (index !== -1) {
        state.data[index] = action.payload;
      }
      state.isloading = false;
    });

    builder.addCase(deliverPurchaseOrder.pending, (state, action) => {
      state.isloading = true;
    });

    builder.addCase(deliverPurchaseOrder.rejected, (state, action) => {
      state.isloading = false;
    });
  },
});

export const {
  setEditMode,
  setDeliverProps,
  unloadPurchaseOrders,
  setIsOpenApproveModal,
  setIsOpenDeliverModal,
  setIsOpenDeclineModal,
} = inventoryPurchaseOrdersSlice.actions;

export default inventoryPurchaseOrdersSlice.reducer;

/* Extra Reducers */
interface LoadPurchaseOrdersParams {
  accountId: string;
  apiClient: ApiClient;
  filters: ApiPagingInventoryPurchaseOrderOptions;
}

export const loadPurchaseOrders = createAsyncThunk(
  "inventory/load-purchase-orders",
  async (params: LoadPurchaseOrdersParams, ThunkAPI) => {
    const { apiClient, accountId, filters } = params;

    const state = (ThunkAPI.getState() as RootState)
      .inventoryPurchaseOrdersSlice;

    const response = await apiClient.findInventoryPurchaseOrders(accountId, {
      ...filters,
      pageSize: state.pageSize,
    });

    return response;
  }
);

export interface UpdateInventoryPurchaseOrdersThunkParams {
  accountId: string;
  apiClient: ApiClient;
  purchaseOrderId: string;
  purchaseOrder: UpdateApiInventoryPurchaseOrder;
}

export const updatePurchaseOrder = createAsyncThunk(
  "inventory/update-purchase-order",
  async (params: UpdateInventoryPurchaseOrdersThunkParams) => {
    const { apiClient, accountId, purchaseOrder, purchaseOrderId } = params;

    const updatedPurchaseOrder = await apiClient.updateInventoryPurchaseOrder(
      accountId,
      purchaseOrderId,
      purchaseOrder
    );

    return updatedPurchaseOrder;
  }
);

export interface ApproveInventoryPurchaseOrderThunkParams {
  accountId: string;
  apiClient: ApiClient;
  purchaseOrderId: string;
  purchaseOrder: ApproveApiInventoryPurchaseOrder;
}

export const approvePurchaseOrder = createAsyncThunk(
  "inventory/approve-purchase-order",
  async (params: ApproveInventoryPurchaseOrderThunkParams) => {
    const { apiClient, accountId, purchaseOrder, purchaseOrderId } = params;
    const approvedPurchaseOrder =
      await apiClient.approveAndPurchaseInventoryPurchaseOrder(
        accountId,
        purchaseOrderId,
        purchaseOrder
      );

    return approvedPurchaseOrder;
  }
);

export interface DeclineInventoryPurchaseOrderThunkParams {
  accountId: string;
  apiClient: ApiClient;
  purchaseOrderId: string;
  purchaseOrder: DeclineApiInventoryPurchaseOrder;
}

export const declinePurchaseOrder = createAsyncThunk(
  "inventory/decline-purchase-order",
  async (params: DeclineInventoryPurchaseOrderThunkParams) => {
    const { apiClient, accountId, purchaseOrder, purchaseOrderId } = params;
    const declinedPurchaseOrder = await apiClient.declineInventoryPurchaseOrder(
      accountId,
      purchaseOrderId,
      purchaseOrder
    );

    return declinedPurchaseOrder;
  }
);

export interface DeliverInventoryPurchaseOrderThunkParams {
  accountId: string;
  apiClient: ApiClient;
}

export const deliverPurchaseOrder = createAsyncThunk(
  "inventory/deliver-purchase-order",
  async (params: DeliverInventoryPurchaseOrderThunkParams, thunkAPI) => {
    const { apiClient, accountId } = params;
    const { deliverProps, workingPurchaseOrder } = (
      thunkAPI.getState() as RootState
    ).inventoryPurchaseOrdersSlice;

    if (!workingPurchaseOrder) {
      return Promise.reject("not provided purchase order");
    }

    const deliverPurchaseOrderData: DeliveryApiInventoryPurchaseOrder = {
      deliveryNotes: deliverProps.deliveryNotes || undefined,
      eSign: deliverProps.signature
        ? {
            uploadId: deliverProps.signature.uploadId || "",
            name: deliverProps.signature.name,
          }
        : undefined,
    };

    const result = await apiClient.deliverInventoryPurchaseOrder(
      accountId,
      workingPurchaseOrder.id,
      deliverPurchaseOrderData
    );

    if (deliverProps.attachments.length > 0) {
      const attachmentsToCreate = deliverProps.attachments.map(
        (att) =>
          ({
            name: att.name,
            uploadId: att.uploadId,
            type: ApiPurchaseOrderAttachmentType.purchaseDelivery,
          }) as CreateApiPurchaseOrderAttachment
      );

      await Promise.all(
        attachmentsToCreate.map((att) =>
          apiClient.createInventoryPurchaseOrderAttachment(
            accountId,
            workingPurchaseOrder.id,
            att
          )
        )
      );
    }

    return result;
  }
);
