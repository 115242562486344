import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  ApiBudget,
  ApiProject,
  CreateApiProjectBudget,
} from "@operations-hero/lib-api-client";
import { Form, Formik, FormikProps } from "formik";
import { FC, MutableRefObject, useCallback, useMemo } from "react";
import * as yup from "yup";
import { useAllocationsContext } from "../../../../../components/allocations/AllocationsContext";
import { AllocationSource } from "../../../../../components/allocations/types";
import { CurrencyInput } from "../../../../../components/inputs/CurrencyInput";
import { BudgetWithFundingSourcesAutocomplete } from "../../../../../components/selects/BudgetWithFundingSourcesAutocomplete";
import { formatCurrency } from "../../../../../utils/formatCurrency";

type NewBudgetAllocationFormProps = {
  project: ApiProject;
  onSubmit: (allocation: CreateApiProjectBudget) => Promise<any>;
  formRef: MutableRefObject<FormikProps<NewBudgetAllocationProps> | null>;
};

export type NewBudgetAllocationProps = {
  source: AllocationSource<ApiBudget> | null;
  amount: number;
};

export const NewBudgetAllocationForm: FC<NewBudgetAllocationFormProps> = ({
  project,
  onSubmit,
  formRef,
}) => {
  const { allocations } = useAllocationsContext();

  const initialValues: NewBudgetAllocationProps = useMemo(() => {
    return {
      source: null,
      amount: 0,
    };
  }, []);

  const handleOnSubmit = useCallback(
    (values: NewBudgetAllocationProps) => {
      const { source, amount } = values;
      if (!source) return;

      const allocation: CreateApiProjectBudget = {
        budgetId: source?.id,
        projectId: project?.id,
        amountToAllocate: amount,
      };

      return onSubmit(allocation);
    },
    [onSubmit, project.id]
  );

  const validationSchema = useMemo(() => {
    const schema = yup.object().shape({
      source: yup.object().nullable().required("Budget is required"),
      amount: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .test("allocated-amount-required", "Amount is required", (value) => {
          if (Boolean(value) === false) return false;
          return true;
        })
        .test(
          "allocated-amount-max",
          "Allocation exceeds funding source limit",
          (value, context) => {
            if (!value) return true;

            return value <= parseFloat(context.parent.source.unallocatedFunds);
          }
        ),
    });
    return schema;
  }, []);

  const excludeIds = useMemo(() => {
    const ids = allocations.map((alloc) => alloc.source?.id);
    return ids.filter((value): value is string => Boolean(value));
  }, [allocations]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, errors, submitCount }) => {
        return (
          <Form>
            <VStack align="stretch">
              <FormControl
                isInvalid={Boolean(errors.source) && submitCount > 0}
              >
                <FormLabel>From Budget</FormLabel>
                <BudgetWithFundingSourcesAutocomplete
                  name="source"
                  value={values.source}
                  onChange={(value) => {
                    setFieldValue("source", value);
                  }}
                  loadOptionParams={{
                    excludeIds: excludeIds,
                    includeFullyAllocated: false,
                  }}
                />

                {values.source && (
                  <Text
                    ml={4}
                    fontWeight="bold"
                    fontSize="small"
                  >{`Available funds ${formatCurrency(values.source?.unallocatedFunds ?? 0)}`}</Text>
                )}

                <FormErrorMessage>{errors.source}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={Boolean(errors.amount) && submitCount > 0}
              >
                <FormLabel>Amount to allocate</FormLabel>
                <CurrencyInput
                  value={values.amount}
                  onChange={(valueAsString, valueAsNumber) => {
                    setFieldValue("amount", valueAsNumber);
                  }}
                  isDisabled={Boolean(values.source) === false}
                />
                <FormErrorMessage>{errors.amount}</FormErrorMessage>
              </FormControl>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};
