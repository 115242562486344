import { useCallback, useEffect, useRef, useState } from "react";
import "../../App.css";
import { useConvertBase64ToImageFile } from "../../hooks/useConvertBase64ToFile";

export const useESignatureGenerator = (fullName?: string) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageBase64, setImageBase64] = useState<string | null>(null);

  const { getFileFromBase64 } = useConvertBase64ToImageFile();

  const generate = useCallback(() => {
    if (!canvasRef.current || !fullName || fullName === "") {
      setImageBase64(null);
      setImageFile(null);
      return;
    }
    const canvas = canvasRef.current;
    canvas.width = 700;
    canvas.height = 200;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.font = '40px "Rock Salt"';
    ctx.fillStyle = "#000";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    ctx.fillText(fullName, canvas.width / 2, canvas.height / 2);

    const base64 = canvas.toDataURL("image/png");
    setImageBase64(base64);
    const newFile = getFileFromBase64(base64, fullName);
    setImageFile(newFile);
  }, [fullName, getFileFromBase64]);

  useEffect(() => {
    generate();
  }, [generate]);

  return { canvasRef, imageBase64, imageFile };
};
