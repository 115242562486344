import { Select } from "chakra-react-select";
import { FieldProps } from "formik";
import { FC } from "react";
import { MultiValue, SingleValue } from "react-select";
interface Option {
  label: string;
  value: string;
}
interface SelectControlProps extends FieldProps {
  options: Option[];
  isMulti?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  allowEmpty?: boolean;
}

export const SelectControl: FC<SelectControlProps> = ({
  placeholder,
  field,
  form,
  options,
  isMulti = false,
  isDisabled,
  allowEmpty,
}) => {
  const onChange = (option: SingleValue<Option> | MultiValue<Option>) => {
    if (allowEmpty && !option) {
      form.setFieldValue(field.name, isMulti ? [] : null);
      return;
    }
    form.setFieldValue(
      field.name,
      isMulti
        ? (option as Option[]).map((item: Option) => item.value)
        : (option as Option).value
    );
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : ("" as any);
    }
  };

  return (
    <Select
      name={field.name}
      value={getValue()}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      isDisabled={isDisabled}
      isClearable={allowEmpty === true}
    />
  );
};
