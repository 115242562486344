import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Products } from "../../pages/account-settings/location-list/LocationList";
import { RootState } from "../../store";

export const useLocationsByProduct = (product?: keyof typeof Products) => {
  const { descendantsMap: globalDescendantMap, locationsByProduct } =
    useSelector((root: RootState) => root.localCache);

  const descendantsMap = useMemo(() => {
    if (!product) return globalDescendantMap;
    return locationsByProduct[product].descendantsMap;
  }, [product, locationsByProduct, globalDescendantMap]);

  return { descendantsMap };
};
