import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import {
  initialState,
  InspectionListSliceProps,
} from "../inspection-list.slice";

export const clearFilters = createAsyncThunk(
  "inspections-filters/clear",
  (params, thunkAPI) => {
    const clearedFilters = { ...initialState.filters };
    return clearedFilters;
  }
);

export const clearFiltersHandler = (
  builder: ActionReducerMapBuilder<InspectionListSliceProps>
) => {
  builder.addCase(clearFilters.fulfilled, (state, action) => {
    state.filters = {
      ...action.payload,
    };
  });
};
