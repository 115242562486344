import { createContext, useContext } from "react";
import { Allocation } from "./types";

export type AllocationsContextProps<T> = {
  allocations: Allocation<T>[];
  totalAllocated: number;
  setAllocations: (allocations: Allocation<T>[]) => void;
  setAllocation: (index: number, allocation: Allocation<T>) => void;
};

export const AllocationsContext =
  createContext<AllocationsContextProps<any> | null>(null);

export function useAllocationsContext<T>() {
  const value = useContext(AllocationsContext);
  if (!value) throw new Error("Allocation context provider");
  return value as AllocationsContextProps<T>;
}
