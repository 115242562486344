import { ApiLocation } from "@operations-hero/lib-api-client";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const useLocationsUtils = () => {
  const { locationMap } = useSelector((root: RootState) => root.localCache);

  const findAllChildrenForNodes = useCallback(
    (values: ApiLocation[], descendantsMap: { [key: string]: string[] }) => {
      const locationsAndChildren: Record<string, ApiLocation> = {};

      for (let value of values) {
        if (!value) {
          continue;
        }
        locationsAndChildren[value.id] = value;

        // get decendants id and convert to location
        const decendants = descendantsMap[value.id] || [];

        decendants.forEach((loc) => {
          const location = locationMap[loc];
          if (!location) return;
          locationsAndChildren[loc] = location;
        });
      }

      return Object.values(locationsAndChildren);
    },
    [locationMap]
  );

  const findAllChildrenIdsForNodes = useCallback(
    (values: ApiLocation[], descendantsMap: { [key: string]: string[] }) => {
      const locationsAndChildren = new Set<string>();

      for (let value of values) {
        if (!value) {
          continue;
        }
        locationsAndChildren.add(value.id);

        const decendants = descendantsMap[value.id] || [];

        decendants.forEach((loc) => {
          locationsAndChildren.add(loc);
        });
      }

      return Array.from(locationsAndChildren);
    },
    []
  );

  const getChildrenId = useCallback(
    (values: string[], descendantsMap: { [key: string]: string[] }) => {
      let children: string[] = [];
      values.forEach((val) => {
        children.push(val);
        descendantsMap[val]?.forEach((c) => {
          children.push(c);
        });
      });

      return children;
    },
    []
  );

  return {
    findAllChildrenForNodes,
    findAllChildrenIdsForNodes,

    getChildrenId,
  };
};
