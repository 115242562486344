import {
  ApiAvailableTo,
  ApiClient,
  ApiFindQuestionsOptions,
  ApiQuestion,
  ApiQuestionSummary,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

interface loadQuestionsThunkParams {
  apiClient: ApiClient;
  accountId: string;
}

export const loadVenuesQuestions = createAsyncThunk(
  "event/questions/loadVenues",
  async (params: loadQuestionsThunkParams, ThunkAPI) => {
    const { filtersVenues } = (ThunkAPI.getState() as RootState).questionList;
    const { apiClient, accountId } = params;
    const response = await apiClient.findQuestions(accountId, filtersVenues);
    return { ...response };
  }
);

export const loadSpacesQuestions = createAsyncThunk(
  "event/questions/loadSpaces",
  async (params: loadQuestionsThunkParams, ThunkAPI) => {
    const { filtersSpaces } = (ThunkAPI.getState() as RootState).questionList;
    const { apiClient, accountId } = params;
    const response = await apiClient.findQuestions(accountId, filtersSpaces);
    return { ...response };
  }
);

export const loadServicesQuestions = createAsyncThunk(
  "event/questions/loadServices",
  async (params: loadQuestionsThunkParams, ThunkAPI) => {
    const { filtersServices } = (ThunkAPI.getState() as RootState).questionList;
    const { apiClient, accountId } = params;
    const response = await apiClient.findQuestions(accountId, filtersServices);
    return { ...response };
  }
);

export const loadVenuesQuestionsDetailed = createAsyncThunk(
  "event/questions/loadVenuesDetails",
  async (params: loadQuestionsThunkParams, ThunkAPI) => {
    const { filtersVenues } = (ThunkAPI.getState() as RootState).questionList;
    const { apiClient, accountId } = params;
    const response = await apiClient.findQuestionsDetails(
      accountId,
      filtersVenues
    );
    return { ...response };
  }
);

export const loadSpacesQuestionsDetailed = createAsyncThunk(
  "event/questions/loadSpacesDetails",
  async (params: loadQuestionsThunkParams, ThunkAPI) => {
    const { filtersSpaces } = (ThunkAPI.getState() as RootState).questionList;
    const { apiClient, accountId } = params;
    const response = await apiClient.findQuestionsDetails(
      accountId,
      filtersSpaces
    );
    return { ...response };
  }
);

export const loadServicesQuestionsDetailed = createAsyncThunk(
  "event/questions/loadServicesDetails",
  async (params: loadQuestionsThunkParams, ThunkAPI) => {
    const { filtersServices } = (ThunkAPI.getState() as RootState).questionList;
    const { apiClient, accountId } = params;
    const response = await apiClient.findQuestionsDetails(
      accountId,
      filtersServices
    );
    return { ...response };
  }
);

export interface QuestionListSliceState {
  dataVenues: ApiQuestionSummary[];
  dataVenuesDetail: ApiQuestion[];
  dataSpaces: ApiQuestionSummary[];
  dataServices: ApiQuestionSummary[];
  dataServicesDetail: ApiQuestion[];
  dataSpacesDetail: ApiQuestion[];
  totalVenues: number;
  totalVenuesDetail: number;
  totalSpaces: number;
  totalSpacesDetail: number;
  totalServices: number;
  totalServicesDetail: number;
  filtersVenues: ApiFindQuestionsOptions;
  filtersSpaces: ApiFindQuestionsOptions;
  filtersServices: ApiFindQuestionsOptions;
}

const DEFAULT_QUESTION_VENUES_FILTERS: ApiFindQuestionsOptions = {
  page: 1,
  search: "",
  pageSize: 20,
  types: undefined,
  availableTo: ApiAvailableTo.venues,
  includeInactive: false,
};

const DEFAULT_QUESTION_SPACES_FILTERS: ApiFindQuestionsOptions = {
  page: 1,
  search: "",
  pageSize: 20,
  types: undefined,
  availableTo: ApiAvailableTo.spaces,
  includeInactive: false,
};

const DEFAULT_QUESTION_SERVICES_FILTERS: ApiFindQuestionsOptions = {
  page: 1,
  search: "",
  pageSize: 20,
  types: undefined,
  availableTo: ApiAvailableTo.services,
  includeInactive: false,
};

export const questionList = createSlice({
  name: "questionList",
  initialState: {
    dataVenues: [],
    dataSpaces: [],
    dataServices: [],
    totalVenues: 0,
    totalSpaces: 0,
    totalServices: 0,
    dataVenuesDetail: [],
    dataSpacesDetail: [],
    dataServicesDetail: [],
    totalVenuesDetail: 0,
    totalSpacesDetail: 0,
    totalServicesDetail: 0,
    filtersVenues: { ...DEFAULT_QUESTION_VENUES_FILTERS },
    filtersSpaces: { ...DEFAULT_QUESTION_SPACES_FILTERS },
    filtersServices: { ...DEFAULT_QUESTION_SERVICES_FILTERS },
  } as QuestionListSliceState,
  reducers: {
    unloadQuestionsVenues: (state: QuestionListSliceState) => {
      state.dataVenues = [];
      state.totalVenues = 0;
      state.filtersVenues = DEFAULT_QUESTION_VENUES_FILTERS;
    },
    unloadQuestionsSpaces: (state: QuestionListSliceState) => {
      state.dataSpaces = [];
      state.totalSpaces = 0;
      state.filtersSpaces = DEFAULT_QUESTION_SPACES_FILTERS;
    },
    unloadQuestionsServices: (state: QuestionListSliceState) => {
      state.dataServices = [];
      state.totalServices = 0;
      state.filtersServices = DEFAULT_QUESTION_SERVICES_FILTERS;
    },
    unloadQuestionsDetailsVenues: (state: QuestionListSliceState) => {
      state.dataVenuesDetail = [];
      state.totalVenuesDetail = 0;
      state.filtersVenues = DEFAULT_QUESTION_VENUES_FILTERS;
    },
    unloadQuestionsDetailsSpaces: (state: QuestionListSliceState) => {
      state.dataSpacesDetail = [];
      state.totalSpacesDetail = 0;
      state.filtersSpaces = DEFAULT_QUESTION_SPACES_FILTERS;
    },
    unloadQuestionsDetailsServices: (state: QuestionListSliceState) => {
      state.dataServicesDetail = [];
      state.totalServicesDetail = 0;
      state.filtersServices = DEFAULT_QUESTION_SERVICES_FILTERS;
    },
    updateFiltersVenues: (
      state: QuestionListSliceState,
      action: PayloadAction<ApiFindQuestionsOptions>
    ) => {
      state.filtersVenues = {
        ...state.filtersVenues,
        ...action.payload,
      };
    },
    updateFiltersSpaces: (
      state: QuestionListSliceState,
      action: PayloadAction<ApiFindQuestionsOptions>
    ) => {
      state.filtersSpaces = {
        ...state.filtersSpaces,
        ...action.payload,
      };
    },
    updateFiltersServices: (
      state: QuestionListSliceState,
      action: PayloadAction<ApiFindQuestionsOptions>
    ) => {
      state.filtersServices = {
        ...state.filtersServices,
        ...action.payload,
      };
    },
    deactivateReactivateQuestionVenue: (
      state: QuestionListSliceState,
      action: PayloadAction<{ questionId: string }>
    ) => {
      const index = state.dataVenues.findIndex(
        (question) => question.id === action.payload.questionId
      );
      if (index !== -1) {
        state.dataVenues[index].active = !state.dataVenues[index].active;
      }

      if (!state.filtersVenues.includeInactive) {
        state.dataVenues.splice(index, 1);
      }
    },
    deactivateReactivateQuestionSpace: (
      state: QuestionListSliceState,
      action: PayloadAction<{ questionId: string }>
    ) => {
      const index = state.dataSpaces.findIndex(
        (question) => question.id === action.payload.questionId
      );
      if (index !== -1) {
        state.dataSpaces[index].active = !state.dataSpaces[index].active;
      }

      if (!state.filtersSpaces.includeInactive) {
        state.dataSpaces.splice(index, 1);
      }
    },
    deactivateReactivateQuestionService: (
      state: QuestionListSliceState,
      action: PayloadAction<{ questionId: string }>
    ) => {
      const index = state.dataServices.findIndex(
        (question) => question.id === action.payload.questionId
      );
      if (index !== -1) {
        state.dataServices[index].active = !state.dataServices[index].active;
      }

      if (!state.filtersServices.includeInactive) {
        state.dataServices.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadVenuesQuestions.fulfilled, (state, action) => {
      const { data, total, options } = action.payload;
      state.filtersVenues.page = options.page;
      state.dataVenues = data;
      state.totalVenues = total;
    });
    builder.addCase(loadSpacesQuestions.fulfilled, (state, action) => {
      const { data, total, options } = action.payload;
      state.filtersSpaces.page = options.page;
      state.dataSpaces = data;
      state.totalSpaces = total;
    });
    builder.addCase(loadServicesQuestions.fulfilled, (state, action) => {
      const { data, total, options } = action.payload;
      state.filtersServices.page = options.page;
      state.dataServices = data;
      state.totalServices = total;
    });
    builder.addCase(loadVenuesQuestionsDetailed.fulfilled, (state, action) => {
      const { data, total, options } = action.payload;
      state.filtersVenues.page = options.page;
      state.dataVenuesDetail = data;
      state.totalVenuesDetail = total;
    });
    builder.addCase(loadSpacesQuestionsDetailed.fulfilled, (state, action) => {
      const { data, total, options } = action.payload;
      state.filtersSpaces.page = options.page;
      state.dataSpacesDetail = data;
      state.totalSpacesDetail = total;
    });
    builder.addCase(
      loadServicesQuestionsDetailed.fulfilled,
      (state, action) => {
        const { data, total, options } = action.payload;
        state.filtersServices.page = options.page;
        state.dataServicesDetail = data;
        state.totalServicesDetail = total;
      }
    );
  },
});

export const {
  unloadQuestionsVenues,
  unloadQuestionsSpaces,
  unloadQuestionsServices,
  updateFiltersVenues,
  updateFiltersSpaces,
  updateFiltersServices,
  deactivateReactivateQuestionVenue,
  deactivateReactivateQuestionSpace,
  deactivateReactivateQuestionService,
  unloadQuestionsDetailsVenues,
  unloadQuestionsDetailsSpaces,
  unloadQuestionsDetailsServices,
} = questionList.actions;

export default questionList.reducer;
