import {
  ApiClient,
  ApiInspectionScore,
  UpdateApiInspectionScore,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../..";
import { updateRoomAndBuildingToProgressStatus } from "../../../../../utils/calcalateStatusInspection";
import { InspectionDetailsSliceProps } from "../inspection-details.slice";

export const updateInspectionScore = createAsyncThunk<
  {
    updatedScore: ApiInspectionScore;
  } | null,
  UpdateApiInspectionScore & { apiClient: ApiClient }
>(
  "inspections-details/update-score",
  async (
    {
      apiClient,
      locationId,
      inspectionCategoryId,
      scoreConfigId,
      justification,
    },
    thunkAPI
  ) => {
    const { auth, inspectionDetailsSlice } = thunkAPI.getState() as RootState;
    const { currentAccount } = auth;
    const { inspection } = inspectionDetailsSlice;
    if (!inspection) {
      return null;
    }

    const score = {
      locationId,
      inspectionCategoryId,
      scoreConfigId,
      justification,
    };

    const updatedScore = await apiClient.updateInspectionScore(
      currentAccount.id,
      inspection.id,
      score
    );
    return { updatedScore };
  }
);

export const updateInspectionScoreHandler = (
  builder: ActionReducerMapBuilder<InspectionDetailsSliceProps>
) => {
  builder.addCase(updateInspectionScore.fulfilled, (state, action) => {
    if (action.payload === null) return;
    const { updatedScore } = action.payload;
    const roomId = updatedScore.locationId;
    const key = `${roomId}::${updatedScore.inspectionCategoryId}`;
    const previousScore = state.scores[key];
    state.scores[key] = updatedScore;

    updateRoomAndBuildingToProgressStatus(
      roomId,
      state.roomContextMap,
      state.floorToRoomsMap,
      state.buildingMap,
      state.inspection!,
      previousScore,
      updatedScore,
      state.scoreConfigMap
    );
  });
};
