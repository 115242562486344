import { useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import {
  ApiInventoryPurchaseOrder,
  ApiInventoryPurchaseOrderStatus,
  ApiPurchaseTransaction,
  UpdateApiPurchaseTransaction,
} from "@operations-hero/lib-api-client";
import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { RootState } from "../../../../store";
import { RemoveTransactionModal } from "../RemoveTransactionModal";
import { ExpensesTable } from "./ExpensesTable";
import { MobileExpensesTable } from "./MobileExpensesTable";

export interface ExpensesDataProps {
  workingPurchase: UpdateApiPurchaseTransaction | null;
  setWorkingPurchase: Dispatch<SetStateAction<ApiPurchaseTransaction | null>>;
  onOpenEditModal: () => void;
  openRemoveModal?: () => void;
  canEditAndDelete?: (purchase: ApiPurchaseTransaction) => boolean;
}

export const ExpensesData: React.FC<ExpensesDataProps> = ({
  workingPurchase,
  setWorkingPurchase,
  onOpenEditModal,
}) => {
  const breakPoint = useBreakpointValue({
    base: false,
    md: true,
  });

  const { currentUser, isProductAdmin } = useAuthentication();
  const { policy } = useSelector((state: RootState) => state.requestForm);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { inventoryPurchaseOrders } = useSelector(
    (state: RootState) => state.requestForm
  );

  const inventoryPurchaseOrdersHash = useMemo(() => {
    return inventoryPurchaseOrders.data.reduce(
      (hash, purchaseOrder) => {
        purchaseOrder.items.forEach((item) => {
          hash[item.id] = purchaseOrder;
        });
        return hash;
      },
      {} as Record<string, ApiInventoryPurchaseOrder>
    );
  }, [inventoryPurchaseOrders.data]);

  const canEditAndDelete = useCallback(
    (purchase: ApiPurchaseTransaction) => {
      if (!policy) {
        return false;
      }
      // if puchase-transaction comes from inventory-purchase-order and it was delivered
      if (
        purchase.inventoryPurchaseOrderItemId &&
        inventoryPurchaseOrdersHash[purchase.inventoryPurchaseOrderItemId]
      ) {
        return !(
          inventoryPurchaseOrdersHash[purchase.inventoryPurchaseOrderItemId]
            .status === ApiInventoryPurchaseOrderStatus.delivered
        );
      }

      return (
        isProductAdmin ||
        policy.admin ||
        policy.reviewer === true ||
        purchase.createdBy.id === currentUser.id
      );
    },
    [currentUser.id, inventoryPurchaseOrdersHash, isProductAdmin, policy]
  );

  return (
    <>
      {breakPoint ? (
        <ExpensesTable
          workingPurchase={workingPurchase}
          setWorkingPurchase={setWorkingPurchase}
          onOpenEditModal={onOpenEditModal}
          openRemoveModal={onOpen}
          canEditAndDelete={canEditAndDelete}
        />
      ) : (
        <MobileExpensesTable
          workingPurchase={workingPurchase}
          setWorkingPurchase={setWorkingPurchase}
          onOpenEditModal={onOpenEditModal}
          openRemoveModal={onOpen}
          canEditAndDelete={canEditAndDelete}
        />
      )}
      <RemoveTransactionModal
        transaction={workingPurchase as ApiPurchaseTransaction}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};
