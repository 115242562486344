import {
  Badge,
  Box,
  HStack,
  Icon,
  Link,
  Square,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { ApiLocation } from "@operations-hero/lib-api-client";
import { useCallback, useMemo } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { LocationTypeIconMap } from "../../../components/badges/LocationBadge";
import { RootState, useThunkDispatch } from "../../../store";
import { reloadLocations } from "../../../store/local-cache.slice";
import {
  expandLocations,
  updateLocationsProducts,
} from "../../../store/locations.slice";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { useLocationUtils } from "../../../utils/locationUtils";
import { Products } from "./LocationList";

export interface LocationTree {
  node: ApiLocation;
  children: LocationTree[];
  matched: boolean;
}

export interface LocationTreeListProps {
  tree: LocationTree[];
  level?: number;
  showHidden?: boolean;
}

export const LocationTreeList = ({
  level = 0,
  tree,
  showHidden,
}: LocationTreeListProps) => {
  const dispatch = useDispatch();
  const { getChildrenId } = useLocationUtils();
  const { expandedMap, locationsProducts } = useSelector(
    (state: RootState) => state.locations
  );
  const { subscriptions } = useSelector((state: RootState) => state.localCache);
  const { currentAccount, apiClient } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const asteriskColor = useColorModeValue("#E53E3E", "#FC8181");
  const isDesktop = useBreakpointValue({
    base: false,
    sm: true,
    md: true,
    lg: true,
  });

  const nextLevel = useMemo(() => level + 1, [level]);

  const enabledProducts = useMemo(() => {
    return subscriptions.filter((s) =>
      Object.values(Products).includes(s.product.name as Products)
    );
  }, [subscriptions]);

  const expandNode = useCallback(
    (branch: LocationTree) => () => {
      const expanded = expandedMap[branch.node.id];

      const allLocationsToExpand = getChildrenId([branch.node]);

      dispatch(expandLocations({ locations: allLocationsToExpand, expanded }));
    },
    [expandedMap, getChildrenId, dispatch]
  );

  const switchHiddenProducts = useCallback(
    (location: ApiLocation, productName: string) => {
      const newHiddenProducts = [...locationsProducts[location.id]];
      if (
        locationsProducts[location.id].some(
          (product) => product === productName
        )
      ) {
        const index = newHiddenProducts.indexOf(productName);
        if (index !== -1) {
          newHiddenProducts.splice(index, 1);
        }
      } else {
        newHiddenProducts.push(productName);
      }
      const children = getChildrenId([location]);

      const newLocationProducts = { ...locationsProducts };
      newLocationProducts[location.id] = [...newHiddenProducts];
      children.forEach((child) => {
        newLocationProducts[child] = newHiddenProducts;
      });
      apiClient.updateLocation(currentAccount.id, location.id, {
        hiddenProducts: newHiddenProducts,
      });
      dispatch(
        updateLocationsProducts({ locationsProducts: newLocationProducts })
      );

      thunkDispatch(reloadLocations({ apiClient, account: currentAccount }));
    },
    [
      apiClient,
      currentAccount,
      dispatch,
      thunkDispatch,
      getChildrenId,
      locationsProducts,
    ]
  );

  return (
    <VStack width="100%">
      {tree.map((branch, index) => (
        <Box key={branch.node.id} width="100%">
          <HStack
            width="100%"
            backgroundColor={
              level > 0 && level % 2 === 0
                ? bgColor
                : level === 0 && index % 2 === 0
                  ? bgColor
                  : "inherit"
            }
            paddingY={2}
          >
            <HStack
              pl={4 * level}
              width={["50%", "40%"]}
              key={`locationTree::${branch.node.id}`}
            >
              <Box display="inline-block" w="16px">
                {branch && branch.children && branch.children.length > 0 ? (
                  <Icon
                    as={
                      expandedMap[branch.node.id]
                        ? IoMdArrowDropdown
                        : IoMdArrowDropright
                    }
                    onClick={expandNode(branch)}
                  />
                ) : (
                  " "
                )}
              </Box>
              {locationsProducts[branch.node.id]?.length !==
              subscriptions.filter((s) =>
                Object.values(Products).includes(s.product.name as Products)
              ).length ? (
                <Link
                  as={RouterLink}
                  to={`/account/locations/${branch.node.id}`}
                  isTruncated
                  fontSize={["small", "unset"]}
                >
                  <Text
                    display="inline"
                    color={branch.matched === true ? "yellow.500" : undefined}
                  >
                    {branch.node.name}
                    {branch.node.hiddenProducts?.length > 0 && (
                      <span style={{ color: asteriskColor }}>*</span>
                    )}
                  </Text>
                  {!branch.node.active && (
                    <Badge colorScheme="gray" ml={3}>
                      Inactive
                    </Badge>
                  )}
                </Link>
              ) : (
                <Text
                  display="inline"
                  color={branch.matched === true ? "yellow.500" : "gray.500"}
                  isTruncated
                  fontSize={["small", "unset"]}
                >
                  {branch.node.name}{" "}
                </Text>
              )}
            </HStack>
            {showHidden && locationsProducts[branch.node.id].length === 0 ? (
              <></>
            ) : (
              <HStack width={["50%", "60%"]}>
                <Box width={`${100 / (enabledProducts.length + 2)}%`}>
                  <Square
                    rounded="md"
                    size="25px"
                    bg={branch.node?.colorId}
                    cursor={"pointer"}
                    borderWidth="1px"
                    borderStyle="solid"
                    borderColor="blue.800"
                  />
                </Box>
                {isDesktop ? (
                  <Text
                    width={`${100 / (enabledProducts.length + 2)}%`}
                    isTruncated
                    fontSize={["small", "unset"]}
                  >
                    {capitalizeFirstLetter(branch.node.type)}
                  </Text>
                ) : (
                  <Icon as={LocationTypeIconMap[branch.node.type]} mr={2} />
                )}
                {enabledProducts.map((prod, index) => (
                  <Icon
                    key={`iconProducts::${prod}${branch.node.id}${index}`}
                    onClick={() => {
                      if (
                        !branch.node.parent ||
                        !locationsProducts[branch.node.parent].some(
                          (p) => p === prod.product.name
                        )
                      ) {
                        switchHiddenProducts(branch.node, prod.product.name);
                      }
                    }}
                    as={
                      locationsProducts[branch.node.id]?.some(
                        (productName) => productName === prod.product.name
                      )
                        ? AiOutlineEyeInvisible
                        : AiOutlineEye
                    }
                    width={`${100 / (enabledProducts.length + 2)}%`}
                  />
                ))}
              </HStack>
            )}
          </HStack>

          {branch.children &&
          branch.children.length > 0 &&
          expandedMap[branch.node.id] ? (
            <LocationTreeList
              tree={branch.children}
              level={nextLevel}
              showHidden={showHidden}
            />
          ) : null}
        </Box>
      ))}
    </VStack>
  );
};
