import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorMode,
} from "@chakra-ui/react";
import { ApiProjectStatus } from "@operations-hero/lib-api-client";
import { FC, useCallback, useMemo } from "react";
import { RiFilterOffLine } from "react-icons/ri";
import { QuickFilterButton } from "../../../../components/buttons/QuickFilterButton";
import { UsersFilter } from "../../../../components/filters/UserFilter";
import { debounce } from "../../../../utils/debounce";
import { ProjectStatusFilter } from "../filters/StatusFilter";
import { HeaderProps } from "./Header";

type DesktopHeaderProps = HeaderProps;

export const DesktopHeader: FC<DesktopHeaderProps> = ({
  filters,
  updateFilters,
  clearFilters,
  onNewProjectClick,
}) => {
  const { colorMode } = useColorMode();

  const getAssigneeValues = useMemo(() => {
    if (!filters.supervisor) return [];
    if (Array.isArray(filters.supervisor)) return filters.supervisor;
    return [filters.supervisor];
  }, [filters.supervisor]);

  const handleOnSearchChange = useCallback(
    (value: string) => {
      updateFilters({ search: value });
    },
    [updateFilters]
  );

  const debouncedSearchChange = debounce(handleOnSearchChange, 300);

  return (
    <>
      <HStack justify="space-between" w="full">
        <Box
          display="flex"
          flexDir="row"
          flexGrow={1}
          gap={2}
          alignItems="center"
        >
          <Box flexBasis={["40%", "40%", "40%", "40%", "30%"]}>
            <InputGroup
              flexGrow={1}
              bg={colorMode === "light" ? "white" : "unset"}
            >
              <InputLeftElement children={<SearchIcon color="gray.300" />} />
              <Input
                type="text"
                placeholder="Search"
                defaultValue={filters.search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  debouncedSearchChange(e.target.value);
                }}
              />
            </InputGroup>
          </Box>
          <UsersFilter
            value={getAssigneeValues}
            onChange={(value) => {
              updateFilters({ supervisor: value.map((v) => v.id) });
            }}
            filterOptions={{
              excludeRole: "Portal User",
            }}
          />
          <ProjectStatusFilter
            value={filters.status ? (filters.status as ApiProjectStatus[]) : []}
            onChange={(value) => updateFilters({ status: value })}
            status={[
              ApiProjectStatus.approved,
              ApiProjectStatus.completed,
              ApiProjectStatus.pending,
              ApiProjectStatus.started,
            ]}
          />
          <QuickFilterButton
            activeText="Include Inactive"
            isActive={filters.includeInactive || false}
            onClick={() =>
              updateFilters({
                includeInactive: !filters.includeInactive,
              })
            }
          />
        </Box>
        <HStack>
          <IconButton
            icon={<Icon as={RiFilterOffLine} />}
            aria-label="Sort"
            colorScheme="blue"
            variant="outline"
            size="md"
            onClick={clearFilters}
          />
          <Button
            leftIcon={<AddIcon />}
            colorScheme="blue"
            variant="solid"
            onClick={onNewProjectClick}
          >
            Add Project
          </Button>
        </HStack>
      </HStack>
    </>
  );
};
