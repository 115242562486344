import { GridItem } from "@chakra-ui/react";
import { ApiAssignee } from "@operations-hero/lib-api-client";
import RequestRowAssignees from "./RequestRowAssignees";

export const UserColumn = ({ assignees }: { assignees: ApiAssignee[] }) => (
  <GridItem
    colSpan={[6, null, 4, 2]}
    isTruncated
    justifyContent={["right", "right", "left"]}
  >
    <RequestRowAssignees assignees={assignees} />
  </GridItem>
);
