import { Text, VStack } from "@chakra-ui/react";
import { FC, useMemo } from "react";

export interface PhysicalAddress {
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  country?: string | null;
  postalCode: string | null;
}

export const BasicAddress: FC<{
  address: PhysicalAddress;
}> = ({ address }) => {
  const line3 = useMemo(() => {
    let result = "";
    if (address.city) {
      result += address.city;
    }
    if (address.state) {
      result += result.length ? ", " : "";
      result += address.state;
    }
    if (address.postalCode) {
      result += result.length ? " " : "";
      result += address.postalCode;
    }
    return result;
  }, [address]);

  return (
    <VStack alignItems="flex-start" gap={0}>
      {address.address1 && <Text>{address.address1}</Text>}
      {address.address2 && <Text>{address.address2}</Text>}
      {line3 && <Text>{line3}</Text>}
      {address.country && <Text>{address.country}</Text>}
    </VStack>
  );
};
