import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  ApiInspectionStatus,
  ApiLocation,
  ApiLocationBuilding,
} from "@operations-hero/lib-api-client";
import FuzzySearch from "fuzzy-search";
import moment from "moment";
import { FC, useCallback, useMemo, useState } from "react";
import { MdAdd } from "react-icons/md";
import { RiMapPinLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import {
  BasicAddress,
  PhysicalAddress,
} from "../../../components/address/Address";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { StatusBadge } from "../../../components/badges/StatusBadge";
import { UserBadge } from "../../../components/badges/UserBadge";
import { ButtonLink } from "../../../components/buttons/LinkButton";
import { LocationCard } from "../../../components/cards/LocationCard";
import { FilterBar } from "../../../components/filters/FilterBar";
import { AccountSearchBox } from "../../../components/inputs/SearchBox";
import { RootState, useThunkDispatch } from "../../../store";
import {
  InspectionBuildingEntry,
  LocationSubBuildingStatus,
} from "../../../store/planning-hq/inspections/details/inspection-details.slice";
import { loadInspectionsDetails } from "../../../store/planning-hq/inspections/details/thunks/loadInspectionsDetails";
import { debounce } from "../../../utils/debounce";
import { AccountModal } from "../../account-settings/account-modal/AccountModal";
import { MiniLocationAndSectionForm } from "../../account-settings/location-form/MiniLocationAndSectionForm";
import { useAvailableFiscalYear } from "../funding-source/useAvailableFiscalYears";
import { InspectionReportSection } from "./components/report/InspectionReportSection";
import { StatusSubBuildingInspectionFilter } from "./filters/StatusSubBuildingInspectionFilter";

export const InspectionDetails: FC = () => {
  const thunkDispatch = useThunkDispatch();
  const { id } = useParams<{ id: string }>();
  const { apiClient } = useAuthentication();
  const { ranges } = useAvailableFiscalYear(false);
  const { locationMap } = useSelector((state: RootState) => state.localCache);
  const {
    inspection,
    buildingMap,
    buildingToFloorsMap,
    inspectionConfig,
    scoreConfigMap,
    scores,
  } = useSelector((state: RootState) => state.inspectionDetailsSlice);
  const { pathname } = useLocation();

  const {
    isOpen: isOpenAddLocation,
    onOpen: onOpenAddLocation,
    onClose: onCloseAddLocation,
  } = useDisclosure();

  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState<LocationSubBuildingStatus[]>(
    []
  );
  const [locationToEdit, setLocationToEdit] = useState<
    (ApiLocation & { sections: { id?: string; name: string }[] }) | undefined
  >();

  const handleNewLocation = useCallback(() => {
    setLocationToEdit(undefined);
    onOpenAddLocation();
  }, [onOpenAddLocation]);

  const handleEditLocation = useCallback(
    (location: InspectionBuildingEntry) => {
      const floorsLoc = Object.values(
        buildingToFloorsMap[location.location.id] || {}
      ).map((val) => ({
        id: val.location.id,
        name: val.location.name,
      }));
      setLocationToEdit({
        ...(location.location as ApiLocation),
        sections: floorsLoc,
      });
      onOpenAddLocation();
    },
    [onOpenAddLocation, buildingToFloorsMap]
  );

  const handleOnChangeFilterSearch = useCallback((value: string) => {
    setSearchTerm(value);
  }, []);

  const reloadInspectionDetails = useCallback(() => {
    if (!id) return;
    thunkDispatch(
      loadInspectionsDetails({
        apiClient,
        inspectionId: id,
      })
    );
  }, [thunkDispatch, apiClient, id]);

  const debouncedChangeSearchValue = debounce(
    (value) => handleOnChangeFilterSearch(value),
    300
  );

  const fiscalYearStart = useMemo(() => {
    return moment(
      new Date(ranges[1]?.start || inspection?.fiscalYearStart || "")
    ).format("MMMM YYYY");
  }, [ranges, inspection?.fiscalYearStart]);

  const fiscalYearEnd = useMemo(() => {
    return moment(
      new Date(ranges[1]?.end || inspection?.fiscalYearEnd || "")
    ).format("MMMM YYYY");
  }, [ranges, inspection?.fiscalYearEnd]);

  const formattedDueDate = useMemo(() => {
    return moment(inspection?.dueDate).format("MMM DD, YYYY");
  }, [inspection?.dueDate]);

  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(Object.values(buildingMap), ["location.name"], {
        sort: true,
      }),
    [buildingMap]
  );

  const filteredLocations = useMemo(() => {
    const searchResults = fuzzySearch.search(searchTerm);
    if (statusFilter.length === 0) return searchResults;
    return searchResults.filter((location) => {
      if (statusFilter.includes(location.status)) {
        return true;
      }
      return false;
    });
  }, [searchTerm, statusFilter, fuzzySearch]);

  const inspectionAddress = useMemo<PhysicalAddress | undefined>(() => {
    if (!inspection) {
      return undefined;
    }

    const location = locationMap[inspection.location.id] as ApiLocationBuilding;
    if (!location) {
      return undefined;
    }

    return {
      address1: location.address1,
      address2: location.address2,
      city: location.city,
      state: location.state,
      postalCode: location.postalCode,
    };
  }, [inspection, locationMap]);

  const isInspectorView = useMemo(
    () => !pathname.includes("/account"),
    [pathname]
  );

  // no inspection no element
  if (!inspection || !id || !inspectionConfig) {
    return null;
  }

  return (
    <VStack w="100%" gap={2} alignItems="flex-start">
      {/* back button */}
      <Flex align="center" mb={5}>
        <ButtonLink
          to={
            isInspectorView
              ? "/planning/inspection"
              : "/account/planning/inspection"
          }
        />
      </Flex>
      {/* title row */}
      <HStack w="100%" justifyContent="space-between">
        <Heading flex={1} aria-label={inspection.location.name} isTruncated>
          {inspection.location.name}
        </Heading>
        {inspection.status === ApiInspectionStatus.submitted && (
          <Box>
            <Button
              colorScheme="blue"
              size="sm"
              as={RouterLink}
              to={
                isInspectorView
                  ? `/planning/inspection/${inspection.id}/report`
                  : `/account/planning/inspection/${inspection.id}/report`
              }
            >
              View Report
            </Button>
          </Box>
        )}
        {isInspectorView &&
          inspection.status === ApiInspectionStatus.completed && (
            <Box>
              <Button
                colorScheme="blue"
                size="sm"
                as={RouterLink}
                to={`/planning/inspection/${inspection.id}/details/submit`}
              >
                Submit & Sign
              </Button>
            </Box>
          )}
      </HStack>
      {/* details row */}
      <Stack
        w="100%"
        flexDirection={["column", null, "row"]}
        justifyContent="space-between"
      >
        <VStack gap={2} alignItems="flex-start">
          {inspectionAddress && <BasicAddress address={inspectionAddress} />}
          <StatusBadge
            status={inspection.status}
            colorScheme={
              inspection.status === ApiInspectionStatus.submitted
                ? "green"
                : undefined
            }
          />
        </VStack>
        <Box>
          {inspectionConfig && (
            <SimpleFieldText label="Type:" value={inspectionConfig.name} />
          )}
          <SimpleFieldText
            label="Fiscal Year:"
            value={`${fiscalYearStart} -  ${fiscalYearEnd}`}
          />
          {inspection.inspector && (
            <SimpleFieldText
              label="Inspector:"
              value={<UserBadge value={inspection.inspector} />}
            />
          )}
          {inspection.status === ApiInspectionStatus.completed ? (
            <SimpleFieldText
              label="Completed:"
              value={inspection.submittedDate!}
            />
          ) : (
            <SimpleFieldText label="Due Date:" value={formattedDueDate} />
          )}
        </Box>
      </Stack>
      {/* report section on completed */}
      <InspectionReportSection
        inspection={inspection}
        inspectionConfig={inspectionConfig}
        scoreConfigMap={scoreConfigMap}
        scores={scores}
        showRatingCard={inspection.status === ApiInspectionStatus.submitted}
      />
      {/* filter bar */}
      <FilterBar>
        <Stack justify="space-between" flexDirection={["column", null, "row"]}>
          <StatusSubBuildingInspectionFilter
            value={statusFilter}
            onChange={setStatusFilter}
          />
          <AccountSearchBox
            onInputChange={(value: string) => debouncedChangeSearchValue(value)}
            rest={{ maxW: ["100%", null, "320px"] }}
            searchPlaceholder="Search by Locations"
          />
        </Stack>
      </FilterBar>
      {/* list header */}
      <Flex justify="space-between" my={2} w="100%">
        <Heading size="md">Locations ({filteredLocations.length})</Heading>
        {inspection.status !== ApiInspectionStatus.submitted && (
          <Button
            colorScheme="blue"
            variant="outline"
            onClick={handleNewLocation}
            leftIcon={<Icon as={MdAdd} mr={1} />}
            size="sm"
          >
            Add Location
          </Button>
        )}
      </Flex>
      {/* location list */}
      {Object.keys(buildingMap).length === 0 && (
        <Flex flexDir="column" align="center" w="100%" gap={4}>
          <Icon as={RiMapPinLine} color="gray.400" w="32px" h="32px" />
          <Text textColor="gray.400">No locations added</Text>
        </Flex>
      )}
      <Grid
        templateColumns={[
          "repeat(1, 1fr)",
          null,
          "repeat(2, 1fr)",
          null,
          "repeat(3, 1fr)",
        ]}
        gap={4}
        w="100%"
      >
        {filteredLocations.map((location) => (
          <LocationCard
            key={location.location.id}
            inspection={inspection}
            location={location}
            scoreConfigMap={scoreConfigMap}
            onEdit={handleEditLocation}
          />
        ))}
      </Grid>
      {/* modals */}
      {isOpenAddLocation && inspection.location.id && (
        <AccountModal
          isOpen={isOpenAddLocation}
          onClose={onCloseAddLocation}
          title={locationToEdit ? "Edit Location" : "Add Location"}
          contentProps={{ maxW: "2xl" }}
          content={
            <MiniLocationAndSectionForm
              locationId={inspection.location.id}
              onClose={onCloseAddLocation}
              reloadInspectionDetails={reloadInspectionDetails}
              initialData={locationToEdit}
            />
          }
        />
      )}
    </VStack>
  );
};

export const SimpleFieldText: FC<{
  label: string;
  value: string | JSX.Element;
}> = ({ label, value }) => {
  return (
    <HStack gap={2} w="100%">
      <Text fontWeight="bold">{label}</Text>
      <Box flex={1}>{value}</Box>
    </HStack>
  );
};
