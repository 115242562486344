import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorMode,
} from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { RiFilterOffLine } from "react-icons/ri";
import { QuickFilterButton } from "../../../../components/buttons/QuickFilterButton";
import { FiscalYearRange } from "../../../../store/planning-hq/types";
import { debounce } from "../../../../utils/debounce";
import { ContactsFilter } from "../filters/ContactsFilter";
import { FiscalYearsFilter } from "../filters/FiscalYearsFilter";
import { SortFilter } from "../filters/SortFilter";
import { HeaderProps } from "./Header";

type DesktopHeaderProps = HeaderProps;

export const DesktopHeader: FC<DesktopHeaderProps> = ({
  filters,
  updateFilters,
  clearFilters,
  onNewFundingSourceClick,
}) => {
  const { colorMode } = useColorMode();

  const handleOnChange = useCallback(
    (fiscalYearRanges: FiscalYearRange[]) => {
      const { fiscalYearStart } = fiscalYearRanges.reduce<{
        fiscalYearStart: string[];
        fiscalYearEnd: string[];
      }>(
        (map, item) => {
          map.fiscalYearStart.push(item.start);
          map.fiscalYearEnd.push(item.end);
          return map;
        },
        {
          fiscalYearStart: [],
          fiscalYearEnd: [],
        }
      );

      updateFilters({ fiscalYearStart: fiscalYearStart });
    },
    [updateFilters]
  );

  const handleOnSearchChange = useCallback(
    (value: string) => {
      updateFilters({ search: value });
    },
    [updateFilters]
  );

  const debouncedSearchChange = debounce(handleOnSearchChange, 300);

  return (
    <HStack
      justify="space-between"
      w="full"
      wrap={["wrap-reverse", "wrap-reverse", "wrap-reverse", "nowrap"]}
      rowGap={3}
      columnGap={10}
    >
      <Box
        display="flex"
        flexDir="row"
        flexGrow={1}
        gap={2}
        alignItems="center"
      >
        <Box flexBasis={["60%", "60%", "60%", "60%", "40%"]}>
          <InputGroup
            flexGrow={1}
            bg={colorMode === "light" ? "white" : "unset"}
          >
            <InputLeftElement children={<SearchIcon color="gray.300" />} />
            <Input
              type="text"
              placeholder="Search funding source..."
              defaultValue={filters.search ?? ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                debouncedSearchChange(e.target.value);
              }}
            />
          </InputGroup>
        </Box>

        <ContactsFilter
          value={filters.contactName ?? []}
          onChange={(value) => {
            updateFilters({
              contactName: value,
            });
          }}
        />

        <FiscalYearsFilter
          value={filters.fiscalYearStart ?? []}
          onChange={handleOnChange}
        />

        <QuickFilterButton
          activeText="Include Inactive"
          isActive={filters.includeInactive || false}
          onClick={() =>
            updateFilters({
              includeInactive: !filters.includeInactive,
            })
          }
        />
      </Box>
      <HStack
        justifyContent="flex-end"
        w={["full", "full", "full", "fit-content"]}
      >
        <SortFilter
          value={{
            sort: filters.sort ?? null,
            direction: filters.direction ?? null,
          }}
        />
        <IconButton
          icon={<Icon as={RiFilterOffLine} />}
          aria-label="Sort"
          colorScheme="blue"
          variant="outline"
          size="md"
          onClick={clearFilters}
        />
        <Button
          leftIcon={<AddIcon />}
          colorScheme="blue"
          variant="solid"
          onClick={onNewFundingSourceClick}
        >
          Add Funding Source
        </Button>
      </HStack>
    </HStack>
  );
};
