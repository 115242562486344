import {
  Box,
  HStack,
  IconButton,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ExportType } from "@operations-hero/lib-api-client";
import { useCallback, useMemo } from "react";
import { IoDownloadOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { ExportModal } from "../../../components/export/ExportModal";
import { MySearches } from "../../../components/searches/MySearches";
import { SavedSearches } from "../../../components/searches/SavedSearches";
import { SearchForm } from "../../../components/searches/SearchForm";
import { RootState, useThunkDispatch } from "../../../store";
import {
  getRequestFilter,
  setRequestListTitle,
  updatedSavedFilters,
  updateRequestFilters,
} from "../../../store/request-list.slice";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { AccountModal } from "../../account-settings/account-modal/AccountModal";
import { BulkPrintButton } from "../print/BulkPrintButton";

export interface SaveDataObject {
  name: string;
  created: string;
  filter: string;
}

const RequestListActions = () => {
  const { apiClient, currentAccount } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();

  const {
    isOpen: isSaveModalOpen,
    onOpen: onSaveModalOpen,
    onClose: onSaveModalClose,
  } = useDisclosure();
  const {
    isOpen: isSearchModalOpen,
    onOpen: onSearchModalOpen,
    onClose: onSearchModalClose,
  } = useDisclosure();
  const {
    isOpen: isExportModalOpen,
    onOpen: onExportModalOpen,
    onClose: onExportModalClose,
  } = useDisclosure();

  const {
    savedSearchFilters,
    queryStringFilter,
    totalRequests,
    filters,
    sort,
  } = useSelector((state: RootState) => state.requestList);
  const { displayMode } = useSelector(
    (state: RootState) => state.requestsSlice
  );

  const options = getRequestFilter(sort, filters);

  const isMobileMode = useBreakpointValue({ base: true, md: false });
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });
  const isDesktop = useMemo(
    () => !isMobileMode && !isTablet,
    [isMobileMode, isTablet]
  );

  const onDelete = useCallback(
    (filtertoDelete: SaveDataObject) => {
      const updatedFilters = savedSearchFilters.filter((filter) => {
        return filter !== filtertoDelete;
      });
      thunkDispatch(
        updatedSavedFilters({
          apiClient,
          accountId: currentAccount.id,
          savedFilters: updatedFilters as [],
        })
      );
    },
    [apiClient, currentAccount.id, savedSearchFilters, thunkDispatch]
  );

  const onSave = useCallback(
    (searchName: string) => {
      if (queryStringFilter !== "") {
        const saveFilter: SaveDataObject = {
          name: searchName,
          created: new Date().toISOString(),
          filter: queryStringFilter,
        };
        const savedFiltersArray = [saveFilter, ...savedSearchFilters];
        thunkDispatch(
          updatedSavedFilters({
            apiClient,
            accountId: currentAccount.id,
            savedFilters: savedFiltersArray as [],
          })
        );
      }
      onSaveModalClose();
    },
    [
      queryStringFilter,
      onSaveModalClose,
      savedSearchFilters,
      thunkDispatch,
      apiClient,
      currentAccount.id,
    ]
  );

  const canSave = useMemo(() => {
    return queryStringFilter === "" ? false : true;
  }, [queryStringFilter]);

  const handleOnClickLink = useCallback(
    (filterObj: string, filterName?: string) => {
      onSearchModalClose();
      filterName &&
        dispatch(setRequestListTitle(capitalizeFirstLetter(filterName)));
      const decodedFilter = JSON.parse(decodeURIComponent(atob(filterObj)));
      dispatch(updateRequestFilters(decodedFilter));
    },
    [onSearchModalClose, dispatch]
  );

  return (
    <Box>
      <HStack>
        <MySearches
          onSearchModalOpen={onSearchModalOpen}
          onSaveModalOpen={onSaveModalOpen}
          canSave={canSave}
        />

        {(isTablet || isDesktop) && (
          <>
            <IconButton
              colorScheme="blue"
              aria-label="Export"
              variant="outline"
              icon={<IoDownloadOutline />}
              onClick={onExportModalOpen}
              size={isDesktop ? "md" : "sm"}
              isDisabled={displayMode === "column"}
            />
            <BulkPrintButton isDisabled={displayMode === "column"} />
          </>
        )}
      </HStack>

      {/* Save Search Modal */}
      {isSaveModalOpen && (
        <AccountModal
          onClose={onSaveModalClose}
          isOpen={isSaveModalOpen}
          title="Save this search"
          content={
            <SearchForm
              onClose={onSaveModalClose}
              onSave={onSave}
              placeholder="Ex. HVAC Requests in York County"
            />
          }
        />
      )}

      {/* My Searches Modal */}
      {isSearchModalOpen && (
        <AccountModal
          isOpen={isSearchModalOpen}
          onClose={onSearchModalClose}
          title="Your Saved Searches"
          content={
            <SavedSearches
              savedSearchFilters={savedSearchFilters}
              handleOnClickLink={handleOnClickLink}
              onDelete={onDelete}
            />
          }
        />
      )}

      {/* export modal, this modal require remount to reset state */}
      {isExportModalOpen && (
        <ExportModal
          isOpen={isExportModalOpen}
          onClose={onExportModalClose}
          total={totalRequests}
          options={options}
          type={ExportType.request}
        />
      )}
    </Box>
  );
};

export default RequestListActions;
