import {
  Button,
  Center,
  Container,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { Pager } from "../../../components/pager/Pager";
import { useQueryString } from "../../../hooks/useQueryString";
import { RootState, useThunkDispatch } from "../../../store";

import { ApiLocationType } from "@operations-hero/lib-api-client";
import { unload as unloadInspectionsPage } from "../../../store/planning-hq/funding-sources/funding-sources-page.slice";
import {
  InspectionListFilters,
  unload as unloadInspectionsList,
} from "../../../store/planning-hq/inspections/list/inspection-list.slice";
import { clearFilters } from "../../../store/planning-hq/inspections/list/thunks/clearFilters";
import { importInspectionTemplate } from "../../../store/planning-hq/inspections/list/thunks/importInspectionTemplate";
import { loadInspections } from "../../../store/planning-hq/inspections/list/thunks/loadInspections";
import { updateFilters } from "../../../store/planning-hq/inspections/list/thunks/updateFilters";
import { useLocationUtils } from "../../../utils/locationUtils";
import { AccountModal } from "../../account-settings/account-modal/AccountModal";
import { LocationForm } from "../../account-settings/location-form/LocationForm";
import { InspectionsSkeleton } from "./components/InspectionsSkeleton";
import { InspectionHeader } from "./header/InspectionHeader";
import { InspectionTable } from "./InspectionsTable";

export default function Inspections() {
  const { apiClient } = useAuthentication();

  const { getTopLevelRegionLocations } = useLocationUtils();
  const { loadingStatus, inspections, filters, total, hasConfigs } =
    useSelector((state: RootState) => state.inspectionListSlice);

  const { queryStringFilter } = useSelector(
    (state: RootState) => state.inspectionsPageSlice
  );

  const { query } = useQueryString({});
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();

  const init = useRef<{
    triggered: boolean;
    status: "idle" | "pending" | "fulfilled";
  }>({ triggered: false, status: "idle" });

  const {
    isOpen: isNewSchoolModalOpen,
    onClose: onNewSchoolModalClose,
    onOpen: onNewSchoolModalOpen,
  } = useDisclosure();

  const isInspectorView = useMemo(
    () => !pathname.includes("/account"),
    [pathname]
  );

  const handleOnClose = useCallback(() => {
    thunkDispatch(loadInspections({ apiClient })).finally(() => {
      onNewSchoolModalClose();
    });
  }, [onNewSchoolModalClose, thunkDispatch, apiClient]);
  const handleUpdateFilters = useCallback(
    (value: Partial<InspectionListFilters>) => {
      thunkDispatch(
        updateFilters({
          ...value,
          showUnassigned: isInspectorView ? false : value.showUnassigned,
          isInspector: isInspectorView,
        })
      );
    },
    [thunkDispatch, isInspectorView]
  );

  const handleClearFilters = useCallback(() => {
    thunkDispatch(clearFilters());
  }, [thunkDispatch]);

  const importTemplateForFIT = useCallback(async () => {
    thunkDispatch(importInspectionTemplate({ apiClient, templateKey: "FIT" }));
  }, [thunkDispatch, apiClient]);

  useEffect(() => {
    if (init.current["triggered"]) return;
    init.current = {
      triggered: true,
      status: "pending",
    };

    let filterObj;
    try {
      filterObj = JSON.parse(atob(query.get("filter") || ""));
    } catch (e) {
      filterObj = null;
    }

    if (filterObj) {
      thunkDispatch(
        updateFilters({ ...filterObj, isInspector: isInspectorView })
      ).then(() => {
        thunkDispatch(loadInspections({ apiClient })).then(() => {
          init.current["status"] = "fulfilled";
        });
      });
    } else {
      thunkDispatch(
        loadInspections({
          apiClient,
          filters: filterObj ? filterObj : { isInspector: isInspectorView },
        })
      ).then(() => {
        init.current = {
          triggered: true,
          status: "fulfilled",
        };
      });
    }
  }, [thunkDispatch, apiClient, query, navigate, dispatch, isInspectorView]);

  useEffect(() => {
    if (init.current["triggered"] && init.current["status"] !== "fulfilled")
      return;
    thunkDispatch(loadInspections({ apiClient }));
  }, [
    thunkDispatch,
    apiClient,
    filters.page,
    filters.search,
    filters.showUnassigned,
    filters.sort,
    filters.direction,
    filters.status,
    filters.fiscalYearStart,
    filters.fiscalYearEnd,
  ]);

  useEffect(() => {
    if (!queryStringFilter) {
      return;
    }

    navigate(`${pathname}?filter=${queryStringFilter}`, {
      replace: true,
    });
  }, [queryStringFilter, navigate, pathname]);

  useEffect(() => {
    return () => {
      dispatch(unloadInspectionsList());
      dispatch(unloadInspectionsPage());
    };
  }, [dispatch]);

  return (
    <Container
      maxWidth="8xl"
      display="flex"
      gap={4}
      flexDirection="column"
      p={0}
    >
      {hasConfigs === true && (
        <InspectionHeader
          filters={filters}
          updateFilters={handleUpdateFilters}
          clearFilters={handleClearFilters}
          onNewSchoolClick={onNewSchoolModalOpen}
          isInspectorView={isInspectorView}
        />
      )}

      {loadingStatus === "pending" && <InspectionsSkeleton />}

      {loadingStatus === "fulfilled" &&
        hasConfigs === true &&
        isInspectorView &&
        inspections?.length === 0 && (
          <Center gap={6} mt={8} flexDirection="column">
            <Heading size="sm">
              No inspections currently assigned to you
            </Heading>
          </Center>
        )}

      {loadingStatus === "fulfilled" &&
        hasConfigs === true &&
        inspections?.length > 0 && (
          <>
            <InspectionTable inspections={inspections} />

            {filters.page && filters.pageSize && (
              <Pager
                showDetails
                total={total}
                currentPage={filters.page}
                pageSize={filters.pageSize}
                onPageChange={(page: number) => {
                  thunkDispatch(
                    updateFilters({
                      page,
                    })
                  );
                }}
              />
            )}
          </>
        )}

      {loadingStatus === "fulfilled" && hasConfigs === false && (
        <>
          <Center gap={6} mt={8} flexDirection="column">
            {/* 
              <Heading>To start using inspections, please setup an inspection template.</Heading> 
              <Link to="/setttings/...">Setup inspection templates</Link>
            */}
            <Heading size="md">
              To get started, please enable the FIT inspection template
            </Heading>

            <Button colorScheme="blue" onClick={importTemplateForFIT}>
              Enable FIT template
            </Button>
          </Center>
        </>
      )}

      {isNewSchoolModalOpen && (
        <AccountModal
          isOpen={isNewSchoolModalOpen}
          onClose={onNewSchoolModalClose}
          title={"Add School"}
          contentProps={{ maxW: "xl" }}
          content={
            <LocationForm
              locationId="new"
              type={ApiLocationType.building}
              fixedParentOptions={getTopLevelRegionLocations()}
              onClose={handleOnClose}
              isFromModal
            />
          }
        />
      )}
    </Container>
  );
}
