import { ApiInspectionStatus } from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../..";
import {
  InspectionDetailsSliceProps,
  LocationRoomStatus,
  LocationSubBuildingStatus,
} from "../inspection-details.slice";

export const saveAndComplete = createAsyncThunk<
  {
    roomId: string;
  },
  { roomId: string },
  { state: RootState }
>("inspections-details/save-and-complete", async ({ roomId }, thunkAPI) => {
  return { roomId };
});

export const saveAndCompleteHandler = (
  builder: ActionReducerMapBuilder<InspectionDetailsSliceProps>
) => {
  builder.addCase(saveAndComplete.fulfilled, (state, action) => {
    const { roomId } = action.payload;
    const { buildingMap, floorToRoomsMap, scores, roomContextMap } = state;

    const buildingId = roomContextMap[roomId].building.location.id;
    const floorId = roomContextMap[roomId].floor.location.id;

    const room =
      floorToRoomsMap["all-rooms"]?.[roomId] ??
      floorToRoomsMap[floorId]?.[roomId];

    if (!room) return;

    if (room.status === LocationRoomStatus.completed) return;

    room.status = LocationRoomStatus.completed;

    const building = buildingMap[buildingId];
    if (building && building.status !== LocationSubBuildingStatus.completed) {
      const allRooms = Object.values(roomContextMap)
        .filter((context) => context.building.location.id === buildingId)
        .map((context) => context.room);

      const allRoomsHaveScores = allRooms.every((room) =>
        Object.keys(scores).some((key) => key.includes(room.location.id))
      );
      building.inspectedRooms += 1;

      if (allRoomsHaveScores) {
        building.status = LocationSubBuildingStatus.completed;
        //if it last building completed it change inspection to completed
        if (
          state.inspection &&
          Object.values(buildingMap).every(
            (build) => build.status === LocationSubBuildingStatus.completed
          )
        ) {
          state.inspection.status = ApiInspectionStatus.completed;
        }
      }
    }
  });
};
