import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { FC, useRef } from "react";
import { useSelector } from "react-redux";
import { useScreenBreakpoints } from "../../../../hooks/useScreenBreakpoints";
import { RootState } from "../../../../store";
import { Header } from "./Header";
import { Requests } from "./request-list/Requests";
import { DesktopScheduling } from "./Scheduling.desktop";
import { TabletScheduling } from "./Scheduling.tablet";
import { SchedulingRefHandle, Timeline } from "./Timeline";

type SchedulingProps = {};
export const Scheduling: FC<SchedulingProps> = () => {
  const { colorMode } = useColorMode();
  const { isDesktop } = useScreenBreakpoints();

  const { project } = useSelector((state: RootState) => state.projectPageSlice);
  const schedulingTimelineRef = useRef<SchedulingRefHandle | null>(null);

  return (
    <>
      {project && (
        <>
          <Header project={project} />
          <Divider my={3} />
          {isDesktop ? (
            <DesktopScheduling
              onExpandedCompleted={() => {
                if (!schedulingTimelineRef.current) return;
                schedulingTimelineRef.current.updateSize();
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  gap: 4,
                  w: "100%",
                }}
              >
                <Requests project={project} />
              </Box>
              {!project.start || !project.end ? (
                <Flex
                  gap={2}
                  h="100%"
                  flexDirection="column"
                  bg={colorMode === "light" ? "gray.50" : "gray.900"}
                >
                  <VStack color="gray" mt={6}>
                    <InfoIcon />
                    <Text>
                      Project start and end dates required to display the
                      timeline
                    </Text>
                  </VStack>
                </Flex>
              ) : (
                <Flex
                  gap={2}
                  h="100%"
                  flexDirection="column"
                  className="oh-planning-scheduling"
                >
                  <Timeline project={project} ref={schedulingTimelineRef} />
                </Flex>
              )}
            </DesktopScheduling>
          ) : (
            <TabletScheduling>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  gap: 4,
                  w: "100%",
                }}
              >
                <Requests project={project} />
              </Box>
              {!project.start || !project.end ? (
                <Flex
                  gap={2}
                  h="100%"
                  flexDirection="column"
                  bg={colorMode === "light" ? "gray.50" : "gray.900"}
                >
                  <VStack
                    color="gray"
                    mt={6}
                    w="full"
                    align="stretch"
                    h="full"
                    alignItems="center"
                  >
                    <InfoIcon />
                    <Text>
                      Project start and end dates required to display the
                      timeline
                    </Text>
                  </VStack>
                </Flex>
              ) : (
                <Flex gap={2} h="100%" flexDirection="column">
                  <Box
                    gap={2}
                    h="100%"
                    pt="10"
                    className="oh-planning-scheduling"
                  >
                    <Timeline project={project} ref={schedulingTimelineRef} />
                  </Box>
                </Flex>
              )}
            </TabletScheduling>
          )}
        </>
      )}
    </>
  );
};
