import moment from "moment-timezone";
import { useMemo } from "react";
import { useAccountDetails } from "../../../hooks/useAccountDetails";

export const NullValue: FiscalYearRangeWithLabel = {
  start: "",
  end: "",
  label: "To be determined",
};

type FiscalYearRangeWithLabel = {
  start: string;
  end: string;
  label: string;
};

export const useAvailableFiscalYear = (allowNull?: boolean) => {
  const { accountDetails } = useAccountDetails();
  const { fiscalStartMonth } = accountDetails
    ? accountDetails
    : { fiscalStartMonth: null };
  const ranges = useMemo(() => {
    if (!fiscalStartMonth) return [];

    const month = moment(fiscalStartMonth + "", "MM").format("MMMM");
    let yearStart = moment(new Date()).subtract({ year: 1 }).year();
    // momentjs starts the month count in 0
    const startMonth = fiscalStartMonth - 1;
    const ranges: FiscalYearRangeWithLabel[] = [...Array(10)].map(
      (x, index) => {
        const start = moment({
          month: startMonth,
          years: yearStart,
        })
          .startOf("month")
          .toISOString();

        const end = moment(start).add({ year: 1 }).toISOString();

        const label = `${month} ${yearStart} - ${month} ${yearStart + 1} `;

        yearStart++;

        return {
          start,
          end,
          label,
        };
      }
    );

    if (allowNull) {
      return [NullValue, ...ranges];
    }

    return ranges;
  }, [fiscalStartMonth, allowNull]);
  return { ranges };
};
