import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  HStack,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import {
  ApiAttachment,
  ApiBudget,
  ApiProject,
  ApiProjectStatus,
  ApiUserReference,
  ApiUserSummary,
  CreateApiProject,
} from "@operations-hero/lib-api-client";
import { Form, Formik, FormikProps } from "formik";
import { FC, MutableRefObject, useCallback, useMemo, useState } from "react";
import * as yup from "yup";
import { BudgetAllocationProvider } from "../../../components/allocations/BudgetsAllocationsProvider";
import { Allocation } from "../../../components/allocations/types";
import {
  Attachment,
  Attachments,
} from "../../../components/attachments/Attachments";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { DatePickerControl } from "../../../components/form-helpers/DatePickerControl";
import { FiscalYearsControl } from "../../../components/form-helpers/FiscalYearControl";
import { MultiUserAutocompleteControl } from "../../../components/form-helpers/MultiUserAutoCompleteControl";
import { ProjectStatusSelectControl } from "../../../components/form-helpers/ProjectStatusSelectControl";
import { TextEditorControl } from "../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import { TextInputControl } from "../../../components/form-helpers/TextInputControl";
import { MultiUserAutocompleteProps } from "../../../components/selects/MultiUserAutocomplete";
import { useShowToast } from "../../../hooks/showToast";
import { FiscalYearRange } from "../../../store/planning-hq/types";
import { formatCurrency } from "../../../utils/formatCurrency";
import { AddAllocationButton } from "./new-project/budget-allocations/AddAllocationButton";
import { AllocationAmountControl } from "./new-project/budget-allocations/AllocationAmountControl";
import { AllocationBudgetControl } from "./new-project/budget-allocations/AllocationBudgetControl";
import { RemoveAllocationButton } from "./new-project/budget-allocations/RemoveAllocationButton";

export type NewProjectProps = {
  name: string;
  assignee: ApiUserSummary[] | null;
  description: string;
  budgetAllocations: [];
  status: ApiProjectStatus | null;
  start: string | null;
  end: string | null;
  estimatedCost: number;
  fiscalYear: FiscalYearRange;
  allocations: Allocation<ApiBudget>[];
};

export type NewProjectFormProps = {
  onSubmit: (
    project: CreateApiProject,
    attachments: Attachment[]
  ) => Promise<{ project: ApiProject; attachments: ApiAttachment[] } | void>;
  formRef: MutableRefObject<FormikProps<NewProjectProps> | null>;
};

const multiUserFieldOptions: Partial<MultiUserAutocompleteProps> = {
  filterOptions: {
    excludeRole: "Portal User",
  },
};

export const NewProjectForm: FC<NewProjectFormProps> = ({
  onSubmit,
  formRef,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const toast = useShowToast();
  const { colorMode } = useColorMode();

  const initialValues: NewProjectProps = {
    name: "",
    assignee: [],
    description: "",
    budgetAllocations: [],
    status: null,
    start: null,
    end: null,
    estimatedCost: 0,
    fiscalYear: { start: "", end: "" },
    allocations: [],
  };

  const [workingAttachments, setWorkingAttachments] = useState<Attachment[]>(
    []
  );

  const createProjectAttachments = useCallback(() => {
    const attachmentsToCreate = workingAttachments.filter(
      (attachment) => attachment.isNew === true
    );
    return attachmentsToCreate;
  }, [workingAttachments]);

  const handleOnSubmit = useCallback(
    (values: NewProjectProps) => {
      const {
        assignee,
        name,
        description,
        start,
        end,
        status,
        fiscalYear,
        allocations,
      } = values;

      let supervisors: ApiUserReference[] = [];
      if (assignee) {
        supervisors = assignee.map<ApiUserReference>((x) => x.id);
      }
      const project: CreateApiProject = {
        supervisors,
        name,
        description,
        start: start ? new Date(start).toISOString() : null,
        end: end ? new Date(end).toISOString() : null,
        status: status || ApiProjectStatus.pending,
        estimatedCost: 0,
        fiscalYearStart: fiscalYear.start ?? null,
        fiscalYearEnd: fiscalYear.end ?? null,
        budgetsAndAmount: allocations.reduce((list, alloc) => {
          if (alloc.source && alloc.amount) {
            list.push({
              budgetId: alloc.source.id,
              amountAllocated: alloc.amount,
            });
          }
          return list;
        }, new Array<{ budgetId: string; amountAllocated: number }>()),
      };
      return onSubmit(project, createProjectAttachments());
    },
    [onSubmit, createProjectAttachments]
  );

  const handleNewAttachments = useCallback(
    async (attachments: Attachment[]) => {
      Promise.all(
        attachments.map(async (item) => {
          const uploadFile = await apiClient.createUploadAndMoveAttachment(
            currentAccount.id,
            item
          );

          return { ...item, uploadId: uploadFile.id };
        })
      )
        .then((formatedAttachments) => {
          setWorkingAttachments([
            ...workingAttachments,
            ...formatedAttachments,
          ]);
        })
        .catch(() => {
          toast(
            "error",
            "Cannot submit attachments at the moment. Try again later."
          );
        });
    },
    [apiClient, currentAccount.id, workingAttachments, toast]
  );

  const handleOnRemoveAttachment = useCallback(
    async (attachment: Attachment) => {
      const index = workingAttachments.findIndex(
        (item) => item.uploadId === attachment.uploadId
      );
      if (index !== -1) {
        const attachmentsCopy = workingAttachments;
        await attachmentsCopy.splice(index, 1);
        setWorkingAttachments([...attachmentsCopy]);
      }
    },
    [workingAttachments]
  );

  const validationSchema = useMemo(() => {
    const schema = yup.object().shape({
      name: yup.string().required("Name is a required field"),
      status: yup.string().nullable().required("Status is a required field"),
      start: yup
        .string()
        .nullable()
        .when("status", {
          is: (status: ApiProjectStatus) =>
            status !== ApiProjectStatus.pending && status !== null,
          then: yup.string().nullable().required("Start date is required"),
        }),
      end: yup
        .string()
        .nullable()
        .when("status", {
          is: (status: ApiProjectStatus) =>
            status !== ApiProjectStatus.pending && status !== null,
          then: yup.string().nullable().required("Start date is required"),
        }),
      allocations: yup.array().of(
        yup.object().shape({
          source: yup.object().nullable().required("Budget is required"),
          amount: yup
            .number()
            .test(
              "allocated-amount-required",
              "Amount is required",
              (value) => {
                if (Boolean(value) === false) return false;
                return true;
              }
            )
            .test(
              "allocated-amount-max",
              "Allocation exceeds budget limit",

              (value, context) => {
                if (!value) return true;

                return (
                  value <= parseFloat(context.parent.source.unallocatedFunds)
                );
              }
            ),
        })
      ),
    });
    return schema;
  }, []);

  return (
    <Formik
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      innerRef={formRef}
      validationSchema={validationSchema}
      validateOnChange={true}
    >
      {({ values, errors, submitCount, setFieldValue }) => {
        return (
          <Form>
            <VStack gap={4} align="stretch">
              <TextInputControl
                name="name"
                value={values.name}
                label="Project Name"
              />
              <ProjectStatusSelectControl
                name="status"
                label="Status"
                value={values.status}
              />
              <MultiUserAutocompleteControl
                label="Assignee"
                name="assignee"
                value={values.assignee}
                fieldProps={multiUserFieldOptions}
              />
              <TextEditorControl
                name="description"
                label="Description"
                value={values.description}
              />
              <FiscalYearsControl
                value={{
                  start: values.fiscalYear.start,
                  end: values.fiscalYear.end,
                }}
                label="Fiscal Year"
                name="fiscalYear"
                allowNull={true}
              />
              <HStack w="full" alignItems="start">
                <DatePickerControl
                  name="start"
                  label="Start Date"
                  value={values.start}
                  hasError={!!errors.start && submitCount > 0}
                />
                <DatePickerControl
                  name="end"
                  label="End Date"
                  value={values.end}
                  hasError={!!errors.end && submitCount > 0}
                />
              </HStack>
              <Box w="full">
                <Attachments
                  attachments={workingAttachments}
                  onNewAttachments={handleNewAttachments}
                  onDeleteAttachment={handleOnRemoveAttachment}
                />
              </Box>
              <BudgetAllocationProvider
                onChange={(allocations) => {
                  setFieldValue("allocations", allocations);
                }}
                initialAllocations={[{ source: null, amount: 0 }]}
              >
                {({ allocations, totalAllocated }) => {
                  return (
                    <>
                      <HStack w="full" justify="space-between">
                        <Text>Budgets</Text>
                        <AddAllocationButton />
                      </HStack>
                      {allocations.length === 0 ? (
                        <Box w="full">
                          <Text
                            color="gray.500"
                            fontStyle="italic"
                            justifySelf="center"
                          >
                            Add funds from budgets
                          </Text>
                        </Box>
                      ) : (
                        allocations.map((allocation, index) => (
                          <HStack
                            key={`allocation::${index}::${allocation.source?.id ?? ""}`}
                            alignItems="start"
                          >
                            <HStack flex={1} alignItems="start">
                              <Flex direction="column" flex="40%" minW={0}>
                                <AllocationBudgetControl
                                  value={allocation.source}
                                  index={index}
                                />
                              </Flex>
                              <ArrowForwardIcon boxSize="5" mt={3} />
                              <Flex direction="column" flex="40%">
                                <AllocationAmountControl
                                  value={allocation.amount}
                                  index={index}
                                  isDisabled={allocation.source === null}
                                />
                              </Flex>
                            </HStack>
                            <RemoveAllocationButton index={index} />
                          </HStack>
                        ))
                      )}
                      <VStack
                        w="full"
                        backgroundColor={
                          colorMode === "light" ? "gray.50" : "gray.700"
                        }
                        justify="stretch"
                        alignItems="end"
                        py="4"
                        px="6"
                        rounded="md"
                        border={colorMode === "light" ? "none" : "solid"}
                        borderWidth="thin"
                        borderColor="gray.600"
                      >
                        <Text
                          fontSize="large"
                          fontWeight="bold"
                        >{`${formatCurrency(totalAllocated)}`}</Text>
                        <Text>Total Amount Allocated</Text>
                      </VStack>
                    </>
                  );
                }}
              </BudgetAllocationProvider>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};
