import { Flex, Icon, Text } from "@chakra-ui/react";
import {
  ApiInventoryPurchaseOrder,
  ApiInventoryPurchaseOrderStatus,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useMemo } from "react";
import { IoTrashSharp } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { CellProps } from "react-table";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { StatusBadge } from "../../../../components/badges/StatusBadge";
import { UserBadge } from "../../../../components/badges/UserBadge";
import { OutlinedIconButton } from "../../../../components/custom-icons/OutlinedIconButton";
import {
  DataTable,
  DataTableColumn,
} from "../../../../components/data-table/DataTable";
import { RootState, useThunkDispatch } from "../../../../store";
import {
  handleSetIsOpenPurchseOrderForm,
  handleSetIsOpenPurchseOrderRemoveModal,
  handleSetWorkingPurchaseOrder,
} from "../../../../store/request-form/request-form.slice";
import { loadInventoryPurchaseOrders } from "../../../../store/request-form/thunks/inventoryPurchaseOrders.thunk";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { RemovePurchaseOrderModal } from "./RemovePurchaseOrderModal";

export const RequestPurchaseOrderList: FC = () => {
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();

  const { currentAccount, apiClient } = useAuthentication();
  const { inventoryPurchaseOrders, request } = useSelector(
    (state: RootState) => state.requestForm
  );

  useEffect(() => {
    if (!request) {
      return;
    }

    thunkDispatch(
      loadInventoryPurchaseOrders({
        apiClient,
        requestKey: request.key,
        accountId: currentAccount.id,
        options: { page: inventoryPurchaseOrders.page },
      })
    );
  }, [
    apiClient,
    currentAccount.id,
    inventoryPurchaseOrders.page,
    request,
    thunkDispatch,
  ]);

  const handleOnClickEdit = useCallback(
    (value: ApiInventoryPurchaseOrder | null) => {
      dispatch(handleSetWorkingPurchaseOrder(value));
      dispatch(handleSetIsOpenPurchseOrderForm(true));
    },
    [dispatch]
  );

  const handleOnClickRemove = useCallback(
    (value: ApiInventoryPurchaseOrder) => {
      dispatch(handleSetWorkingPurchaseOrder(value));
      dispatch(handleSetIsOpenPurchseOrderRemoveModal(true));
    },
    [dispatch]
  );

  const columns = useMemo<DataTableColumn<ApiInventoryPurchaseOrder>[]>(
    () => [
      {
        Header: "Request #",
        minWidth: 100,
        maxWidth: 100,
        Cell: (cell: CellProps<ApiInventoryPurchaseOrder>) => {
          return (
            <Text isTruncated>{cell.row.original.purchaseOrderNumber}</Text>
          );
        },
      },
      {
        Header: "Requester",
        width: 200,
        minWidth: 180,
        Cell: (cell: CellProps<ApiInventoryPurchaseOrder>) => (
          <UserBadge value={cell.row.original.requester} />
        ),
      },
      {
        Header: "Items",
        maxWidth: 220,
        minWidth: 220,
        Cell: (cell: CellProps<ApiInventoryPurchaseOrder>) => (
          <Flex gap={2}>
            <Text fontWeight="semibold">
              {cell.row.original.items[0].approvedQuantity ||
                cell.row.original.items[0].requestedQuantity}
            </Text>
            <Text wordBreak="break-word" isTruncated>
              {cell.row.original.items[0].name}
            </Text>
          </Flex>
        ),
      },
      {
        Header: "Status",
        maxWidth: 100,
        minWidth: 100,
        Cell: (cell: CellProps<ApiInventoryPurchaseOrder>) => (
          <StatusBadge status={cell.row.original.status} />
        ),
      },
      {
        Header: "Price",
        minWidth: 90,
        maxWidth: 90,
        Cell: (cell: CellProps<ApiInventoryPurchaseOrder>) => (
          <Text width="10%">
            {formatCurrency(cell.row.original.items[0].unitCost)}
          </Text>
        ),
      },
      {
        Header: " ",
        width: 80,
        Cell: (cell: CellProps<ApiInventoryPurchaseOrder>) => (
          <Flex gap={2} w="100%" justifyContent="flex-end">
            {cell.row.original.status ===
              ApiInventoryPurchaseOrderStatus.pending && (
              <>
                <OutlinedIconButton
                  icon={<Icon as={IoTrashSharp} boxSize={5} />}
                  onClick={() => handleOnClickRemove(cell.row.original)}
                />
                <OutlinedIconButton
                  icon={<Icon as={MdEdit} boxSize={5} />}
                  onClick={() => handleOnClickEdit(cell.row.original)}
                />
              </>
            )}
          </Flex>
        ),
      },
    ],
    [handleOnClickEdit, handleOnClickRemove]
  );

  return (
    <Flex w="100%" gap={4}>
      {inventoryPurchaseOrders.data.length === 0 ? (
        <Text pb={6} pt={2} width="100%">
          Add an item that is not listed on inventory.
        </Text>
      ) : (
        <Flex w="100%">
          <DataTable columns={columns} data={inventoryPurchaseOrders.data} />
        </Flex>
      )}

      {inventoryPurchaseOrders.isOpenRemoveModal &&
        inventoryPurchaseOrders.workingPurchaseOrder && (
          <RemovePurchaseOrderModal />
        )}
    </Flex>
  );
};
