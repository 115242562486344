import {
  Flex,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
} from "@chakra-ui/react";
import { FC } from "react";

interface CronNumberInputProps {
  name: string | undefined;
  min: number;
  max?: number;
  label: string;
  value: number | null;
  step?: number;
  defaultValue?: number;
  numberInputProps?: NumberInputProps;
  onChange: (value: number, name?: string | undefined) => void;
  rightTextComponent?: React.ReactNode;
}

export const CronNumberInput: FC<CronNumberInputProps> = ({
  min,
  max,
  name,
  label,
  step = 1,
  onChange,
  value,
  numberInputProps,
  rightTextComponent,
}) => {
  return (
    <Flex flexDir="column">
      <FormLabel fontSize="sm">{label}</FormLabel>
      <Flex gap={4} alignItems="center">
        <NumberInput
          min={min}
          max={max}
          step={step}
          value={value || 0}
          {...numberInputProps}
          onChange={(value) => onChange(+value, name)}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        {rightTextComponent && rightTextComponent}
      </Flex>
    </Flex>
  );
};
