import { TriangleDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Collapse,
  Divider,
  Icon,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiEventOccurrence,
  ApiEventOccurrenceConflict,
  ApiLocationSummary,
  ApiSpaceSummary,
  ApiVenue,
  ApiVenueSummary,
} from "@operations-hero/lib-api-client";
import FuzzySearch from "fuzzy-search";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { MdModeEditOutline, MdWarning } from "react-icons/md";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { AccountSearchBox } from "../../../../components/inputs/SearchBox";
import { VenueAutocomplete } from "../../../../components/selects/VenueAutocomplete";
import { sortArrayByDesc } from "../../../../utils/sortArrayByDesc";
import { SpaceTwoLine } from "../../events-list/SpacesTwoLine";
import { ConflictAlert } from "../form-components/ConflictsAlert";
import { LimitVenueInfo } from "../form-components/LimitVenueInfo";
import { UpdateLocalSpaces } from "./EventDate";

const BORDER_MOBILE = "solid 1px #A0AEC0";

type ChooseVenueProperties = {
  isSingle?: boolean;
  isSelectedAll: boolean;
  occurrenceId?: string;
  venue: ApiVenueSummary | null;
  spaces: ApiSpaceSummary[] | null;
  venuesOptions?: ApiVenueSummary[];
  conflictProp?: ApiEventOccurrenceConflict;
  start?: string;
  end?: string;
  selectedOccurrences: string[];
  occurrences: ApiEventOccurrence[];
  hasMultipleSelections?: boolean;
  cleanIsCheckandCheckAll: (cleanCheckAll?: boolean) => void;
  updateVenuesAndSpaces: (params: UpdateLocalSpaces) => void;
  setSelectedVenueHours?: () => {
    venueStartHour: number;
    venueStartMinutes: number;
    venueEndHour: number;
    venueEndMinutes: number;
  } | null;
  limitByVenue?: boolean;
  setVenueChangeSelected?: React.Dispatch<
    React.SetStateAction<ApiVenueSummary | null>
  >;
  isInactive?: boolean;
};

export const ChooseVenue: FC<ChooseVenueProperties> = ({
  isSingle,
  isSelectedAll,
  occurrenceId,
  venue,
  spaces,
  start,
  end,
  venuesOptions,
  conflictProp,
  selectedOccurrences,
  hasMultipleSelections,
  updateVenuesAndSpaces,
  cleanIsCheckandCheckAll,
  setSelectedVenueHours,
  limitByVenue = false,
  setVenueChangeSelected,
  isInactive,
}) => {
  const newVenue = useMemo(
    () => (isSelectedAll ? null : venue),
    [isSelectedAll, venue]
  );
  const [conflict, setConflict] = useState(conflictProp);
  const [selectedVenue, setSelectedVenue] = useState(newVenue);
  const [selectedVenueDetail, setSelectedVenueDetail] = useState<ApiVenue>();
  const [selectedSpaces, setSelectedSpaces] = useState<string[] | null>(
    spaces ? spaces.map((space) => space.id) : null
  );
  const { apiClient, currentAccount } = useAuthentication();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const errorColor = useColorModeValue("red.500", "red.300");
  const boxBgColor = useColorModeValue(
    ["gray.50", "gray.50", "transparent"],
    "transparent"
  );

  const textColor = useColorModeValue("inherit", "whiteAlpha.900");

  const handleOnSave = useCallback(() => {
    if (selectedVenue && selectedVenueDetail && selectedSpaces) {
      const newSpaces = selectedVenueDetail.spaces.filter((space) =>
        selectedSpaces.some((item) => item === space.id)
      );
      updateVenuesAndSpaces({
        conflicts: conflict || ({} as ApiEventOccurrenceConflict),
        occurrenceId,
        venue: selectedVenue,
        spaces: newSpaces,
      });
      limitByVenue &&
        setVenueChangeSelected &&
        setVenueChangeSelected(selectedVenue);
      onClose();
    }
  }, [
    conflict,
    occurrenceId,
    onClose,
    selectedSpaces,
    selectedVenue,
    selectedVenueDetail,
    limitByVenue,
    updateVenuesAndSpaces,
    setVenueChangeSelected,
  ]);

  const handleOnChangeVenue = useCallback(
    (value: ApiVenueSummary | null) => {
      setSelectedVenue(value);
      setSelectedSpaces([]);
      limitByVenue && setSelectedVenueHours && setSelectedVenueHours();
      onOpen();
    },
    [limitByVenue, setSelectedVenueHours, onOpen]
  );

  const handleOnChangeSpaces = useCallback((values: string[]) => {
    setSelectedSpaces(values);
  }, []);

  const conflictMessage = useMemo(() => {
    if (conflict && selectedVenue !== null && selectedVenueDetail) {
      const firstConflict = conflict.conflicting[0];
      const firstSpaceWithConflict = selectedVenueDetail.spaces.find(
        (item) => item.id === firstConflict
      );
      if (firstSpaceWithConflict) {
        const conflictingLength = conflict.conflicting.length;
        return `${firstSpaceWithConflict.location.name} ${
          conflictingLength > 1
            ? `and ${conflictingLength - 1} spaces more`
            : ""
        } has conflicts. Consider changing your space or venue.`;
      }
    }
    return "Some spaces has conflicts, please choose another space";
  }, [conflict, selectedVenue, selectedVenueDetail]);

  const checkSingleOccurrenceConflict = useCallback(async () => {
    if (selectedVenue && start && end && selectedSpaces) {
      const newOccurrenceToCheck = {
        id: occurrenceId,
        start: start,
        end: end,
        venue: selectedVenue.id,
        spaces: selectedSpaces,
      };
      const [first] = await apiClient.checkEventConflicts(currentAccount.id, [
        newOccurrenceToCheck,
      ]);
      setConflict(first);
    }
  }, [
    apiClient,
    currentAccount.id,
    end,
    occurrenceId,
    selectedSpaces,
    selectedVenue,
    start,
  ]);

  const handleOnClose = useCallback(() => {
    limitByVenue && setSelectedVenue(venue);
    limitByVenue &&
      setSelectedSpaces(spaces ? spaces.map((space) => space.id) : null);
    onClose();
  }, [onClose, venue, spaces, limitByVenue]);

  useEffect(() => {
    isOpen && cleanIsCheckandCheckAll(isSingle ? true : undefined);
  }, [isOpen, cleanIsCheckandCheckAll, isSingle]);

  useEffect(() => {
    if (!selectedVenue) {
      setSelectedVenueDetail(undefined);
      return;
    }

    // selection and detail state are in sync already
    if (selectedVenue && selectedVenueDetail?.id === selectedVenue.id) {
      return;
    }

    // clear it first to prevent some race conditions
    setSelectedVenueDetail(undefined);
    apiClient
      .getVenue(currentAccount.id, selectedVenue.id)
      .then((venueDetail) => {
        setSelectedVenueDetail(venueDetail);
      });
  }, [apiClient, currentAccount, selectedVenue, selectedVenueDetail]);

  useEffect(() => {
    if (!isOpen) return;
    if (isSelectedAll || selectedOccurrences.length > 0) {
      return;
    }
    checkSingleOccurrenceConflict();
  }, [
    checkSingleOccurrenceConflict,
    selectedOccurrences,
    isSelectedAll,
    isOpen,
  ]);

  return (
    <Popover
      isOpen={isOpen}
      onOpen={!isInactive ? onOpen : undefined}
      onClose={onClose}
    >
      {!hasMultipleSelections ? (
        <PopoverTrigger>
          <Box
            display="flex"
            flexDirection="row"
            w={["100%", "100%", "45%"]}
            alignItems="center"
            bgColor={boxBgColor}
            p={[2, 2, 0]}
            border={[BORDER_MOBILE, BORDER_MOBILE, "inherit"]}
            mb={[3, 3, "inherit"]}
            borderRadius={5}
            cursor={!isInactive ? "pointer" : undefined}
          >
            <Box
              display="flex"
              flexDirection="column"
              w={["100%", "100%", "180px"]}
            >
              {spaces && (
                <SpaceTwoLine
                  value={spaces.map((s) => s.location)}
                  showAncestor={false}
                  maxItemsToShow={1}
                />
              )}

              <Text w="100%" as="span" fontWeight="sm">
                {spaces && spaces.length > 1
                  ? "Multiple Spaces"
                  : "Single Space"}
              </Text>
            </Box>
            {!isInactive && <Icon as={TriangleDownIcon} boxSize={3} ml={2} />}
          </Box>
        </PopoverTrigger>
      ) : (
        <PopoverTrigger>
          <Box w="38%" fontWeight="bold" color={"blue.500"}>
            <Text textColor={textColor} as="span">
              <Text
                display={["none", "none", "inherit"]}
                as="span"
                cursor="pointer"
                _hover={{ textDecoration: "underline" }}
              >
                Change Location <Icon as={MdModeEditOutline} ml={2} />
              </Text>
              <Text
                display={["inherit", "inherit", "none"]}
                as="span"
                cursor="pointer"
                _hover={{ textDecoration: "underline" }}
              >
                Location <Icon as={MdModeEditOutline} ml={2} />
              </Text>
            </Text>
          </Box>
        </PopoverTrigger>
      )}
      {isOpen && (
        <PopoverContent maxH="400px" overflowY="scroll">
          <PopoverHeader>
            <Box w="100%">
              <Collapse in={conflict && conflict.hasConflict} unmountOnExit>
                <ConflictAlert
                  includeDescription={false}
                  title={conflictMessage}
                  alertTitleProps={{ fontWeight: "normal" }}
                />
              </Collapse>

              <Box>
                <Text fontWeight="medium" fontSize="smaller" p={4} pl={1}>
                  All selected items will be updated to reflect the locations
                  selected below.
                </Text>
              </Box>

              <Divider my={1} />

              <Box>
                <Text fontWeight="bold" p="5px 5px 1px 1px">
                  Choose a venue
                </Text>
              </Box>

              {venuesOptions && venuesOptions.length > 0 && (
                <Box>
                  <Box py={2}>
                    <Text fontSize="sm" py={1}>
                      Select a main venue
                    </Text>
                    <VenueAutocomplete
                      name="venue"
                      value={selectedVenue}
                      isDisabled={false}
                      onChange={handleOnChangeVenue}
                      options={venuesOptions}
                    />
                  </Box>
                </Box>
              )}

              {limitByVenue && (
                <Box py={2}>
                  <LimitVenueInfo />
                </Box>
              )}

              <Divider my={2} />

              <Box>
                <Text fontWeight="bold" pb={2}>
                  Choose space(s)
                </Text>

                <CheckboxGroup
                  value={selectedSpaces || []}
                  onChange={handleOnChangeSpaces}
                >
                  <Stack spacing={4} direction="column">
                    {selectedVenueDetail && (
                      <SpacesCheckboxConflicts
                        start={start}
                        end={end}
                        occurrenceId={occurrenceId}
                        venue={selectedVenueDetail}
                      />
                    )}
                  </Stack>
                </CheckboxGroup>
                {selectedSpaces?.length === 0 && (
                  <Text mt={2} color={errorColor} fontSize="sm">
                    You must select at least 1 space
                  </Text>
                )}
              </Box>

              <Divider my={2} />

              <Box pt={5}>
                <Button
                  float="left"
                  variant="outline"
                  borderColor="blue.500"
                  borderRadius={5}
                  onClick={handleOnClose}
                >
                  Cancel
                </Button>
                <Button
                  float="right"
                  colorScheme="blue"
                  onClick={handleOnSave}
                  disabled={selectedSpaces?.length === 0}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </PopoverHeader>
        </PopoverContent>
      )}
    </Popover>
  );
};

interface SpacesCheckboxConflictsProps {
  venue: ApiVenue;
  start?: string;
  end?: string;
  occurrenceId?: string;
}

export const SpacesCheckboxConflicts: FC<SpacesCheckboxConflictsProps> = ({
  venue,
  start,
  end,
  occurrenceId,
}) => {
  const [spacesConflicts, setSpacesConflicts] =
    useState<ApiEventOccurrenceConflict>();
  const [searchSpaces, setSearchSpaces] = useState("");
  const [spacesToAdd, setSpacesToAdd] = useState<ApiSpaceSummary[]>([]);

  const { apiClient, currentAccount } = useAuthentication();
  const textColor = useColorModeValue("inherit", "whiteAlpha.900");

  const checkConflictForVenue = useCallback(async () => {
    if (!start || !end) return;
    const occurrence = {
      id: occurrenceId,
      start,
      end,
      venue: venue.id,
      spaces: venue.spaces.map((item) => item.id),
    };
    const [first] = await apiClient.checkEventConflicts(currentAccount.id, [
      occurrence,
    ]);
    setSpacesConflicts(first);
  }, [
    apiClient,
    currentAccount.id,
    end,
    occurrenceId,
    start,
    venue.id,
    venue.spaces,
  ]);

  const options = useMemo(() => {
    return spacesToAdd.map((space) => ({
      ...space,
      hasConflict: spacesConflicts?.conflicting.some(
        (spaceId) => spaceId === space.id
      ),
    }));
  }, [spacesConflicts?.conflicting, spacesToAdd]);

  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(
        Object.values(venue ? venue.spaces : []),
        ["name", "address"],
        {
          sort: true,
        }
      ),
    [venue]
  );

  useEffect(() => {
    checkConflictForVenue();
  }, [checkConflictForVenue]);

  useEffect(() => {
    const foundLocations = fuzzySearch.search(searchSpaces);
    setSpacesToAdd([...foundLocations]);
  }, [searchSpaces, fuzzySearch]);

  return (
    <>
      <AccountSearchBox
        isUniqueElement={false}
        onInputChange={setSearchSpaces}
        searchPlaceholder="Search Locations"
      />
      {options
        .sort((a, b) =>
          sortArrayByDesc<ApiLocationSummary & { hasConflict?: boolean }>(
            a.location,
            b.location
          )
        )
        .map((venueSpace, idx) => (
          <Checkbox
            value={venueSpace.id}
            key={venueSpace.id + idx}
            color={venueSpace.hasConflict ? "orange.500" : textColor}
            colorScheme="blue"
            display="flex"
            alignItems="center"
          >
            {venueSpace.hasConflict && <Icon as={MdWarning} mx={1} />}
            <Text as="span">{venueSpace.location.name}</Text>
          </Checkbox>
        ))}
    </>
  );
};
