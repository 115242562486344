import { ApiLocation } from "@operations-hero/lib-api-client";
import { FC, ReactNode, useMemo } from "react";
import { useLocations } from "../../hooks/locations/useLocations";
import { useProductsContext } from "../products/ProductContext";
import { LocationsContext, LocationsContextProps } from "./LocationsContext";

export type LocationsProviderProps = {
  children: ReactNode;
  hideLocation?: (location: ApiLocation) => boolean;
  hideLocationChildren?: boolean;
  sorted?: "tree" | "alphabetical";
  includeInactive?: boolean;
};

export const LocationsProvider: FC<LocationsProviderProps> = ({
  children,
  hideLocation,
  hideLocationChildren = false,
  sorted = "alphabetical",
  includeInactive = false,
}) => {
  const { product } = useProductsContext();

  const { getLocations, descendantsMap } = useLocations(
    product,
    hideLocation,
    hideLocationChildren,
    sorted,
    includeInactive
  );

  const { locations, locationsIds, locationMap } = useMemo(() => {
    return getLocations();
  }, [getLocations]);

  const value: LocationsContextProps = useMemo(() => {
    return {
      locations,
      locationsIds,
      locationMap,
      descendantsMap,
    };
  }, [locations, descendantsMap, locationsIds, locationMap]);

  return (
    <LocationsContext.Provider value={value}>
      {children}
    </LocationsContext.Provider>
  );
};
