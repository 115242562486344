import {
  Box,
  Button,
  Flex,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiInspectionCategory } from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { format } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../../store";
import { updateInspectionScore } from "../../../../store/planning-hq/inspections/details/thunks/updateInspectionScore";
import { capitalizeWords } from "../../../../utils/capitalizeFirstLetter";

interface ScoreCategoryInspectionProps {
  category: ApiInspectionCategory;
  locationId: string;
  isReadMode?: boolean;
}

const dateFormat = "MMMM dd, yyyy 'at' hh:mm a";

export const ScoreCategoryInspection: React.FC<
  ScoreCategoryInspectionProps
> = ({ category, locationId, isReadMode }) => {
  const thunkDispatch = useThunkDispatch();
  const { scores, scoreConfigMap } = useSelector(
    (state: RootState) => state.inspectionDetailsSlice
  );
  const showToast = useShowToast();
  const { apiClient } = useAuthentication();
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const bgColorText = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("gray.500", "white");
  const scoreKey = `${locationId}::${category.id}`;

  const currentScore = useMemo(() => scores[scoreKey], [scoreKey, scores]);

  const [selectedScoreId, setSelectedScoreId] = useState<
    string | undefined | null
  >(currentScore?.scoreConfigId === null ? null : currentScore?.scoreConfigId);
  const [justification, setJustification] = useState<string | null>(
    currentScore?.justification || ""
  );
  const [editMode, setEditMode] = useState<boolean>(false);

  const selectedScore = useMemo(() => {
    if (!selectedScoreId) return null;
    return scoreConfigMap[selectedScoreId];
  }, [scoreConfigMap, selectedScoreId]);

  const justificationDate = useMemo(() => {
    return currentScore?.updated || currentScore?.created || null;
  }, [currentScore]);

  const handleScoreChange = useCallback(
    (scoreConfigId: string | null) => {
      setSelectedScoreId(scoreConfigId);

      const scoreConfig = scoreConfigId ? scoreConfigMap[scoreConfigId] : null;

      if (scoreConfig && scoreConfig?.justificationRequired) {
        setEditMode(true);
        return;
      }

      thunkDispatch(
        updateInspectionScore({
          apiClient,
          locationId,
          inspectionCategoryId: category.id,
          scoreConfigId,
          justification: null,
        })
      )
        .then(unwrapResult)
        .catch(() =>
          showToast("error", "Something went wrong updating a score")
        );
    },
    [
      apiClient,
      category.id,
      locationId,
      thunkDispatch,
      scoreConfigMap,
      showToast,
    ]
  );

  const handleJustificationChange = useCallback((justification: string) => {
    setJustification(justification);
  }, []);

  const handleSaveJustification = useCallback(() => {
    thunkDispatch(
      updateInspectionScore({
        apiClient,
        locationId,
        inspectionCategoryId: category.id,
        scoreConfigId: selectedScoreId || null,
        justification: justification || null,
      })
    );
    setEditMode(false);
  }, [
    apiClient,
    category.id,
    justification,
    locationId,
    selectedScoreId,
    thunkDispatch,
  ]);

  useEffect(() => {
    setSelectedScoreId(
      currentScore?.scoreConfigId === null ? null : currentScore?.scoreConfigId
    );
    setJustification(currentScore?.justification || "");
  }, [currentScore]); //fix for handleMarkAllAsNA

  return (
    <Flex
      direction="column"
      key={category.id}
      bgColor={bgColor}
      p={4}
      borderRadius={8}
      borderColor={borderColor}
      borderWidth={1}
    >
      <Flex justifyContent="space-between" flexWrap="wrap">
        <Text>{capitalizeWords(category.name)}</Text>
        <RadioGroup
          onChange={(value) =>
            !isReadMode && handleScoreChange(value === "null" ? null : value)
          }
          value={selectedScoreId === null ? "null" : selectedScoreId}
          style={isReadMode ? { pointerEvents: "none" } : undefined}
        >
          <Stack
            gap={4}
            direction={{ base: "column", md: "row" }}
            overflowX="auto"
          >
            {Object.values(scoreConfigMap).map((score) => (
              <Radio key={score.id} value={score.id}>
                {score.name}
              </Radio>
            ))}
            <Radio key="null" value="null">
              N/A
            </Radio>
          </Stack>
        </RadioGroup>
      </Flex>
      {selectedScore?.justificationRequired && (
        <Box mt={4}>
          {editMode && !isReadMode ? (
            <>
              <Textarea
                placeholder="Enter justification"
                value={justification || ""}
                onChange={(e) => handleJustificationChange(e.target.value)}
                bgColor={bgColorText}
              />
              <Text fontSize="sm" color="gray.500">
                {justification?.length || 0}/1000 Character max.
              </Text>
              <Flex mt={4} gap={4}>
                <Button colorScheme="blue" onClick={handleSaveJustification}>
                  Save
                </Button>
                <Button
                  variant="ghost"
                  colorScheme="blue"
                  onClick={() => setEditMode(false)}
                >
                  Cancel
                </Button>
              </Flex>
            </>
          ) : (
            <Flex flexDir="column" py={8} gap={4}>
              <Flex justifyContent="space-between" alignContent="flex-start">
                <Text>{justification}</Text>
                {!isReadMode && (
                  <IconButton
                    variant="ghost"
                    icon={<MdEdit size="24px" />}
                    aria-label="Edit Location"
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditMode(true);
                    }}
                    color="blue.600"
                  />
                )}
              </Flex>
              {justificationDate && (
                <Text textColor={textColor}>
                  {format(justificationDate, dateFormat)}
                </Text>
              )}
            </Flex>
          )}
        </Box>
      )}
    </Flex>
  );
};
