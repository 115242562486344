import {
  Box,
  Flex,
  Spacer,
  Text,
  TextProps,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC, ReactNode } from "react";

interface ScoreCardProps {
  title: string;
  value?: string | number;
  bgColor?: string;
  children?: ReactNode;
  childrenTitle?: ReactNode;
  textProps?: TextProps;
}

export const ScoreCard: FC<ScoreCardProps> = ({
  title,
  value,
  bgColor,
  children,
  childrenTitle,
  textProps,
}) => {
  const cardBg = useColorModeValue("white", "gray.700");
  const cardColor = useColorModeValue("gray.500", "gray.400");

  return (
    <Flex
      direction="column"
      align="center"
      p="6"
      bg={bgColor || cardBg}
      rounded="8px"
      shadow="base"
      color={cardColor}
      alignItems="flex-start"
      w="12em"
      h="12em"
    >
      <Text
        fontWeight="bold"
        textTransform="uppercase"
        letterSpacing="wide"
        {...textProps}
      >
        {title}
      </Text>
      {childrenTitle}
      <Spacer />
      <Box mt={3} textAlign="center">
        {value && (
          <Text
            fontSize="4xl"
            fontWeight="bold"
            lineHeight="1"
            pt={3}
            {...textProps}
          >
            {value}
          </Text>
        )}
        {children}
      </Box>
    </Flex>
  );
};
