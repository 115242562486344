import { ApiReportingCategory } from "@operations-hero/lib-api-client";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../components/auth/AuthProvider";
import { RootState } from "../store";
import { useCategoryUtils } from "../utils/categoryUtils";

export const useAllowedCategories = () => {
  const {
    policyMap,
    workflowMap,
    categoriesMap,
    categories: categorysLocalCache,
  } = useSelector((state: RootState) => state.localCache);
  const { isProductAdmin } = useAuthentication();
  const { findAllChildrenForNodesRecursive } = useCategoryUtils();

  const allowedCategories = useMemo(() => {
    if (isProductAdmin) {
      return categorysLocalCache;
    }

    let allCategoriesAllowed: boolean = false;
    let categoriesIds: string[] = [];

    Object.keys(workflowMap).forEach((workflow) => {
      if (allCategoriesAllowed) {
        return;
      }

      const policy = policyMap[workflow];
      const {
        admin,
        reviewer,
        technician,
        approver,
        viewer,
        contractor,
        requester,
      } = policy;

      if (admin) {
        allCategoriesAllowed = true;
        return;
      }

      const categoryRestrictedFields = [
        reviewer,
        technician,
        approver,
        viewer,
        contractor,
        requester,
      ];
      categoryRestrictedFields.forEach((x) => {
        if (allCategoriesAllowed === true) {
          return;
        }

        if (typeof x === "boolean") {
          if (x === true) {
            allCategoriesAllowed = x === true;
          }
          return;
        }
        if (!Array.isArray(x?.categories)) {
          return;
        }

        if (x.categories.length === 0) {
          allCategoriesAllowed = true;
        } else {
          categoriesIds.push(...x.categories);
        }
      });
    });

    if (allCategoriesAllowed) {
      return categorysLocalCache;
    }

    if (categoriesIds.length === 0) {
      return [];
    }

    const allowedCategories: ApiReportingCategory[] = [];
    categoriesIds = Array.from(new Set(categoriesIds));

    for (let key of categoriesIds) {
      const childAndFathercategoryIds = findAllChildrenForNodesRecursive([
        categoriesMap[key],
      ]);
      Array.from(childAndFathercategoryIds).map((cat) =>
        categoriesMap[cat.id].active
          ? allowedCategories.push(categoriesMap[cat.id])
          : null
      );
    }

    return allowedCategories;
  }, [
    isProductAdmin,
    categoriesMap,
    categorysLocalCache,
    policyMap,
    workflowMap,
    findAllChildrenForNodesRecursive,
  ]);

  return { allowedCategories };
};
