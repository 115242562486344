import {
  ApiBudgetWithFundingSources,
  ApiClient,
  ApiPagedResult,
  ApiPagingBudgetWithFundingSourceOptions,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { LoadingStatus } from "../types";

type LoadBudgetsParams = {
  apiClient: ApiClient;
  filters?: ApiPagingBudgetWithFundingSourceOptions & {
    total: number;
  };
};
export const loadBudgets = createAsyncThunk<
  ApiPagedResult<ApiBudgetWithFundingSources>,
  LoadBudgetsParams
>(
  "budgets/load",
  async ({ apiClient, filters: filtersToOverride }, thunkAPI) => {
    const { auth, budgetListSlice } = thunkAPI.getState() as RootState;
    const { currentAccount } = auth;

    const { filters } = budgetListSlice;
    const response = await apiClient.findBudgetsWithFundingSources(
      currentAccount.id,
      filtersToOverride ? filtersToOverride : filters
    );
    return response;
  }
);

export const updateFilters = createAsyncThunk(
  "budget-filters/update",
  (filters: Partial<BudgetListSliceProps["filters"]>, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { budgetListSlice } = state;
    const updated = {
      ...budgetListSlice.filters,
      ...filters,
    };
    return updated;
  }
);

type SortFilter = Pick<BudgetListFilters, "sort" | "direction">;
type UpdateSortFiltersParams = Partial<SortFilter>;

export const updateSortFilters = createAsyncThunk<
  SortFilter,
  UpdateSortFiltersParams
>("budgets-sort/update", async (delta, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const {
    filters: { sort, direction },
  } = state.budgetListSlice;
  const filtersToUpdate = {
    sort,
    direction,
  };
  if ("sort" in delta) {
    filtersToUpdate.sort = delta.sort;
  }

  if ("direction" in delta) {
    filtersToUpdate.direction = delta.direction;
  }

  return filtersToUpdate;
});

export const clearFilters = createAsyncThunk(
  "budgets-filters/clear",
  (params, thunkAPI) => {
    const clearedFilters = { ...initialState.filters };
    return clearedFilters;
  }
);

export type BudgetListFilters = ApiPagingBudgetWithFundingSourceOptions;

export type BudgetListSliceProps = {
  budgets: ApiBudgetWithFundingSources[];
  filters: BudgetListFilters;
  loadingStatus: LoadingStatus;
  total: number;
};

const initialState: BudgetListSliceProps = {
  budgets: [],
  loadingStatus: "idle",
  filters: {
    page: 1,
    pageSize: 20,
    includeInactive: false,
    sort: "name",
    direction: "asc",
    search: "",
  },
  total: 0,
};

const budgetListSlice = createSlice({
  name: "budget-list",
  initialState,
  reducers: {
    unload: (state) => {
      state.budgets = [];
      state.loadingStatus = "idle";
      state.filters = initialState.filters;
      state.total = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadBudgets.pending, (state, action) => {
      state.loadingStatus = "pending";
    });
    builder.addCase(loadBudgets.rejected, (state, action) => {
      state.loadingStatus = "rejected";
    });
    builder.addCase(loadBudgets.fulfilled, (state, action) => {
      state.loadingStatus = "fulfilled";
      const { data, total } = action.payload;
      state.budgets = data;
      state.filters = {
        ...state.filters,
      };
      state.total = total;
    });
    builder.addCase(updateFilters.fulfilled, (state, action) => {
      state.filters = action.payload;
    });
    builder.addCase(clearFilters.fulfilled, (state, action) => {
      state.filters = {
        ...action.payload,
      };
    });

    builder.addCase(updateSortFilters.fulfilled, (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    });
  },
});

export const { unload } = budgetListSlice.actions;
export default budgetListSlice.reducer;
