import {
  Button,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  StackItem,
  Text,
  useColorMode,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IoAddOutline } from "react-icons/io5";
import {
  getFormatedHour,
  hoursFormatRegex,
} from "../../pages/request-form/transactions/transactions-helper";
import { debounce } from "../../utils/debounce";

const MIN_HOUR = 0.25;
const MAX_HOUR = 23.75;
const HOUR_STEP = 0.25;

export enum HoursOperation {
  ADDITION = "ADDITION",
  SUBTRACTION = "SUBTRACTION",
  REPLACE = "REPLACE",
}

const hourButtons = [
  {
    label: "15 minutes",
    numberValue: 0.25,
  },
  {
    label: "30 minutes",
    numberValue: 0.5,
  },
  {
    label: "1 hour",
    numberValue: 1.0,
  },
  {
    label: "2 hours",
    numberValue: 2.0,
  },
  {
    label: "4 hours",
    numberValue: 4.0,
  },
];

enum TimeEnum {
  DAYS,
  HOURS,
  MINUTES,
}

const getTimeNumber = (time: string | null, type: TimeEnum): number => {
  if (time) {
    switch (type) {
      case TimeEnum.DAYS: {
        const days = time.replace("d", "");
        const daysDec = +days * 24;
        return daysDec;
      }
      case TimeEnum.HOURS: {
        const hour = time.replace("h", "");
        return +hour;
      }
      case TimeEnum.MINUTES: {
        const minutes = time.replace("m", "");
        const minutesDec = +minutes / 60;
        return minutesDec;
      }
      default:
        return 0;
    }
  }
  return 0;
};

const convertAndSave = (
  value: string,
  callbackToExecute: (value: number) => void,
  allowDays?: boolean
) => {
  const cleanValue = value.trim();
  const isValid = hoursFormatRegex.test(cleanValue);
  if (isValid) {
    let days: number = 0;
    const hourStr = cleanValue.match(/([01]?[0-9]|2[0-3])h/g);
    const minutesStr = cleanValue.match(/([0-5]?[0-9]m)/g);
    if (allowDays === true) {
      const dayStr = cleanValue.match(/^([0-9]{0,3})d/g);
      days = dayStr?.length ? getTimeNumber(dayStr[0], TimeEnum.DAYS) : 0;
    }
    const hours: number = hourStr?.length
      ? getTimeNumber(hourStr[0], TimeEnum.HOURS)
      : 0;
    const minutes: number = minutesStr?.length
      ? getTimeNumber(minutesStr[0], TimeEnum.MINUTES)
      : 0;
    const decimalNotationHour = days + hours + minutes;
    callbackToExecute(decimalNotationHour);
    return;
  }
  callbackToExecute(0);
};

const debouncedSave = debounce(convertAndSave, 250);

interface HoursSpinnerProps {
  value: number;
  min?: number;
  max?: number;
  step?: number;
  isDisabled?: boolean;
  placeholder?: string;
  allowDays?: boolean;
  maxLength?: number;
  inputMaxW?: number;
  showErrorMessage?: boolean;
  includeQuickButtons?: boolean;
  includeStepButtons?: boolean;
  onBlur?: (value: number) => void;
  getIsValid?: (value: boolean) => void;
  onChange?: (value: number, isValid?: boolean) => void;
}

const HoursSpinner: React.FC<HoursSpinnerProps> = ({
  value = 0,
  onChange,
  getIsValid,
  onBlur,
  min = MIN_HOUR,
  max = MAX_HOUR,
  step = HOUR_STEP,
  includeStepButtons,
  isDisabled,
  placeholder,
  allowDays,
  maxLength,
  inputMaxW,
  showErrorMessage,
  includeQuickButtons,
}) => {
  const [formatedHours, setFormatedHours] = useState(getFormatedHour(value));
  const [isValid, setIsValid] = useState(true);
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // The following variables are used because the chakra-ui colors not works for shadow css
  const redErrorColor = colorMode === "dark" ? "#FC8181" : "#E53E3E";
  const blueFocusColor = colorMode === "dark" ? "#63B3ED" : "#3182CE";
  const grayColor = colorMode === "dark" ? "#FFFFFF5C" : "#D0D4D9";

  const handleOnClickButton = useCallback(
    (newValue: number, operation: HoursOperation) => {
      if (onChange) {
        let result;
        switch (operation) {
          case HoursOperation.ADDITION: {
            const partial = value + newValue;
            result = partial >= MAX_HOUR ? MAX_HOUR : partial;
            break;
          }
          case HoursOperation.SUBTRACTION: {
            result = value - newValue;
            break;
          }
          case HoursOperation.REPLACE: {
            result = newValue;
            break;
          }
        }

        result && onChange(result);
        result && setFormatedHours(getFormatedHour(result));
      }
    },
    [onChange, value]
  );

  const handleOnInputChange = useCallback(
    (value: string) => {
      setFormatedHours(value);

      if (value && onChange) {
        debouncedSave(value, onChange, allowDays);
      }
    },
    [allowDays, onChange]
  );

  const handleOnBlur = useCallback(
    (value: string) => {
      if (value && onBlur) {
        debouncedSave(value, onBlur, allowDays);
      }
    },
    [allowDays, onBlur]
  );

  useEffect(() => {
    if (!formatedHours && value) {
      setFormatedHours(getFormatedHour(value));
    }
  }, [formatedHours, value]);

  useEffect(() => {
    const result = hoursFormatRegex.test(formatedHours);
    setIsValid(result);
    getIsValid && getIsValid(result);
  }, [formatedHours, getIsValid]);

  return (
    <Stack gap={2}>
      {includeQuickButtons && (
        <StackItem minW="100%">
          <QuickHourButtons
            value={value}
            handleOnClickButton={handleOnClickButton}
          />
        </StackItem>
      )}
      <FormControl isInvalid={!isValid}>
        <InputGroup
          size="sm"
          onFocus={onOpen}
          onBlur={onClose}
          maxW={inputMaxW || "100%"}
          shadow={
            isOpen
              ? isValid
                ? `0px 0px 0px 2px ${blueFocusColor}`
                : `0px 0px 0px 2px ${redErrorColor}`
              : isValid
                ? `0px 0px 0px 0.5px ${grayColor}`
                : `0px 0px 0px 2px ${redErrorColor}`
          }
          borderRadius={4}
        >
          {includeStepButtons && (
            <InputLeftElement
              children={
                <Button
                  ml={2}
                  mt={2}
                  size="md"
                  aria-placeholder="1h 30m"
                  borderLeftRadius={6}
                  disabled={isDisabled || value <= min}
                  borderRightRadius="none"
                  onClick={() =>
                    handleOnClickButton(step, HoursOperation.SUBTRACTION)
                  }
                  _focus={{
                    shadow: "none",
                  }}
                >
                  -
                </Button>
              }
            />
          )}
          <Input
            pr={includeStepButtons ? 12 : 6}
            size="md"
            border="none"
            borderRadius={6}
            value={formatedHours}
            textAlign="right"
            isDisabled={isDisabled}
            placeholder={placeholder}
            maxLength={maxLength ?? 7}
            _focus={{ shadow: "none" }}
            pl={!includeStepButtons ? 7 : 14}
            onBlur={(e: ChangeEvent<HTMLInputElement>) =>
              handleOnBlur(e.target.value)
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleOnInputChange(e.target.value)
            }
          />
          {includeStepButtons && (
            <InputRightElement
              children={
                <Button
                  mt={2}
                  mr={2}
                  size="md"
                  border="none"
                  borderRightRadius={5}
                  borderLeftRadius="none"
                  disabled={isDisabled || value >= max}
                  onClick={() =>
                    handleOnClickButton(step, HoursOperation.ADDITION)
                  }
                  _focus={{
                    shadow: "none",
                  }}
                >
                  +
                </Button>
              }
            />
          )}
        </InputGroup>
        {showErrorMessage && (
          <FormErrorMessage>Invalid time format</FormErrorMessage>
        )}
      </FormControl>
    </Stack>
  );
};

interface HourSpinnerButtonsProps {
  value: number;
  handleOnClickButton: (
    valueAsNumber: number,
    operation: HoursOperation
  ) => void;
}

const QuickHourButtons: FC<HourSpinnerButtonsProps> = ({
  value,
  handleOnClickButton,
}) => {
  return (
    <Wrap spacing={3} maxW={330} mt={2}>
      {hourButtons.map((item) => (
        <Button
          size="sm"
          key={item.label}
          variant="outline"
          colorScheme="blue"
          disabled={value >= MAX_HOUR}
          onClick={() =>
            handleOnClickButton(item.numberValue, HoursOperation.ADDITION)
          }
        >
          <Icon as={IoAddOutline} mr={2} />
          <Text>{item.label}</Text>
        </Button>
      ))}
    </Wrap>
  );
};

export default HoursSpinner;
