import {
  Badge,
  HStack,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiBudget,
  ApiBudgetWithFundingSources,
} from "@operations-hero/lib-api-client";
import moment from "moment";
import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CellProps } from "react-table";
import { FundingSourceBadge } from "../../../components/badges/FundingSourceBadge";
import {
  DataTable,
  DataTableColumn,
} from "../../../components/data-table/DataTable";
import { useScreenBreakpoints } from "../../../hooks/useScreenBreakpoints";
import { formatCurrency } from "../../../utils/formatCurrency";
import { BudgetCard } from "./BudgetCard";

const FISCAL_YEAR_FORMAT = "MMMM YYYY" as const;

export type BudgetsTableProps = {
  budgets: ApiBudgetWithFundingSources[];
};

export const BudgetTable: FC<BudgetsTableProps> = ({ budgets }) => {
  const navigate = useNavigate();

  const { isDesktop } = useScreenBreakpoints();
  const { colorMode } = useColorMode();
  const rowBg = useColorModeValue("gray.100", "blue.800");

  let columns = useMemo<DataTableColumn<ApiBudgetWithFundingSources>[]>(
    () => [
      {
        Header: "Budget Name",
        accessor: (budget) => budget,
        disableSortBy: true,
        width: 120,
        maxWidth: 120,
        Cell: ({
          value,
        }: CellProps<
          ApiBudgetWithFundingSources,
          ApiBudgetWithFundingSources
        >) => {
          return (
            <HStack w="full" gap={2}>
              {value.name ? (
                <Badge
                  borderStyle="solid"
                  borderColor={colorMode === "light" ? "gray.300" : "gray.600"}
                  borderWidth="thin"
                  background={colorMode === "light" ? "gray.50" : "transparent"}
                  isTruncated
                >
                  <Text
                    casing="none"
                    fontWeight={500}
                    fontSize="medium"
                    color={colorMode === "light" ? "gray.600" : "gray.300"}
                    isTruncated
                  >
                    {value.name}
                  </Text>
                </Badge>
              ) : (
                <Text
                  casing="none"
                  fontWeight="normal"
                  fontSize="medium"
                  color="gray.600"
                >
                  -
                </Text>
              )}
              {!value.active && <Badge colorScheme="gray">Inactive</Badge>}
            </HStack>
          );
        },
      },
      {
        Header: "Code",
        accessor: (budget) => budget,
        width: 70,
        maxWidth: 70,
        disableSortBy: true,
        Cell: ({
          value,
        }: CellProps<
          ApiBudgetWithFundingSources,
          ApiBudgetWithFundingSources
        >) => {
          return <Text>{value.code}</Text>;
        },
      },
      {
        Header: "Funds",
        accessor: (budget) => budget,
        disableSortBy: true,
        width: 90,
        maxWidth: 90,
        Cell: ({
          value,
        }: CellProps<
          ApiBudgetWithFundingSources,
          ApiBudgetWithFundingSources
        >) => {
          return (
            <Stack direction="row" flexWrap="wrap">
              {value.fundingSources.map((fs) => (
                <FundingSourceBadge
                  value={fs.source}
                  key={`fs::${fs.source.id}`}
                />
              ))}
            </Stack>
          );
        },
      },
      {
        Header: "Fiscal Year",
        width: 110,
        maxWidth: 110,
        accessor: (budget) => {
          let fiscalYearStart = null;
          let fiscalYearEnd = null;
          if (budget.fiscalYearStart)
            fiscalYearStart = moment(budget.fiscalYearStart).format(
              FISCAL_YEAR_FORMAT
            );
          if (budget.fiscalYearEnd)
            fiscalYearEnd = moment(budget.fiscalYearEnd).format(
              FISCAL_YEAR_FORMAT
            );
          if (!fiscalYearEnd || !fiscalYearStart) return "-";

          return `${fiscalYearStart} - ${fiscalYearEnd}`;
        },
        disableSortBy: true,
        Cell: ({ value }: CellProps<ApiBudgetWithFundingSources, string>) => {
          return <Text>{value}</Text>;
        },
      },
      {
        Header: "Total Funds",
        accessor: (budget) => budget,
        width: 60,
        maxWidth: 60,
        disableSortBy: true,
        Cell: ({
          value,
        }: CellProps<
          ApiBudgetWithFundingSources,
          ApiBudgetWithFundingSources
        >) => {
          return <Text>{formatCurrency(value.funds ?? 0)}</Text>;
        },
      },
      {
        Header: "Total Allocated",
        accessor: (budget) => budget,
        width: 60,
        maxWidth: 60,
        disableSortBy: true,
        Cell: ({
          value,
        }: CellProps<
          ApiBudgetWithFundingSources,
          ApiBudgetWithFundingSources
        >) => {
          return <Text>{formatCurrency(value.allocatedFunds)}</Text>;
        },
      },
      {
        Header: "Total Unallocated",
        accessor: (budget) => budget,
        width: 60,
        maxWidth: 60,
        disableSortBy: true,
        Cell: ({
          value,
        }: CellProps<
          ApiBudgetWithFundingSources,
          ApiBudgetWithFundingSources
        >) => {
          return <Text>{formatCurrency(value.unallocatedFunds)}</Text>;
        },
      },

      {
        Header: "Added",
        accessor: (budget) => {
          const added = moment(new Date(budget.created)).format("ll");
          return { added };
        },
        width: 60,
        maxWidth: 60,
        disableSortBy: true,
        Cell: ({
          value,
        }: CellProps<ApiBudgetWithFundingSources, { added: string }>) => {
          return <Text>{value.added}</Text>;
        },
      },
    ],
    [colorMode]
  );

  let cards = useMemo<DataTableColumn<ApiBudgetWithFundingSources>[]>(() => {
    return [
      {
        Header: "Col1",
        accessor: (budget) => budget,
        width: 200,

        Cell: ({
          value,
        }: CellProps<
          ApiBudgetWithFundingSources,
          ApiBudgetWithFundingSources
        >) => {
          return <BudgetCard budget={value} />;
        },
        boxProps: {
          borderStyle: "hidden",
        },
      },
    ];
  }, []);

  const onRowClick = useCallback(
    (budget: ApiBudget) => {
      navigate(`/planning/budgets/${budget.id}/details`);
    },
    [navigate]
  );

  return (
    <DataTable
      columns={isDesktop ? columns : cards}
      data={budgets}
      headerRest={{ fontSize: "sm" }}
      hiddenHeader={isDesktop ? false : true}
      onRowClick={onRowClick}
      rowProps={{
        cursor: "pointer",
        _hover: isDesktop
          ? {
              bg: rowBg,
            }
          : undefined,
      }}
      rest={{
        overflow: "hidden",
      }}
    />
  );
};
