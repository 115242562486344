import { AddIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  ApiInspection,
  ApiInspectionCategory,
  ApiInspectionCategoryGroup,
  ApiInspectionScore,
  ApiInspectionScoreConfig,
  ApiRequestPriority,
  ApiRequestStatus,
  ApiRequestType,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { CellProps } from "react-table";
import { useAuthentication } from "../../../../../components/auth/AuthProvider";
import { DateBadge } from "../../../../../components/badges/DateBadge";
import { StatusBadge } from "../../../../../components/badges/StatusBadge";
import { UserBadge } from "../../../../../components/badges/UserBadge";
import {
  DataTable,
  DataTableColumn,
} from "../../../../../components/data-table/DataTable";
import { RootState } from "../../../../../store";
import {
  setAutoCreateRequestData,
  setNewRequestDialogIsOpen,
} from "../../../../../store/new-request-form.slice";
import { InspectionRoomEntry } from "../../../../../store/planning-hq/inspections/details/inspection-details.slice";
import RequestRowAssignees from "../../../../requests/request-row/RequestRowAssignees";
import ResolveViolationModal from "./ResolveViolationModal";

export type ViolationsData = {
  config: ApiInspectionScoreConfig;
  score: ApiInspectionScore;
  room: InspectionRoomEntry;
  category: ApiInspectionCategory;
  group: ApiInspectionCategoryGroup;
};

const ViolationsDataTable: FC<{
  inspection: ApiInspection;
  data: ViolationsData[];
}> = ({ inspection, data }) => {
  const { currentUser, currentAccount } = useAuthentication();
  const dispatch = useDispatch();
  const {
    isOpen: isResolveViolationOpen,
    onOpen: onOpenResolveViolation,
    onClose: onCloseResolveViolation,
  } = useDisclosure();

  const { requestMap } = useSelector(
    (state: RootState) => state.inspectionDetailsSlice
  );

  const [violation, setViolation] = useState<ViolationsData | null>(null);

  const resolveViolation = useCallback(
    (violation: ViolationsData) => {
      setViolation(violation);
      onOpenResolveViolation();
    },
    [onOpenResolveViolation]
  );

  const getRequestToCreate = useCallback(
    (violation: ViolationsData) => {
      const summary = `Inspection Violation: ${violation.group.name} - ${violation.category.name}\nJustification: ${violation.score.justification}`;
      return {
        metadata: {},
        reason: null,
        assignees: [],
        projectId: null,
        estimatedCost: null,
        estimatedHours: null,
        workflow: "",
        requester: currentUser,
        reportingCategory: null,
        scheduledRequestId: null,
        inspectionId: inspection.id, // associate to the inspection
        inspectionScoreId: violation.score.id,
        summary,
        status: ApiRequestStatus.new,
        location: violation.room.location || null,
        type: ApiRequestType.corrective,
        priority: ApiRequestPriority.standard,
        scheduling: {
          due: null,
          completed: null,
          start: null,
        },
      };
    },
    [currentUser, inspection]
  );

  const createRequest = useCallback(
    (violation: ViolationsData) => {
      dispatch(
        setAutoCreateRequestData({
          autoCreateRequest: {
            shouldBold: false,
            shouldAutoCreate: false,
            requestToCreate: getRequestToCreate(violation),
          },
        })
      );
      dispatch(setNewRequestDialogIsOpen(true));
    },
    [dispatch, getRequestToCreate]
  );

  const roomColumns = useMemo<DataTableColumn<ViolationsData>[]>(() => {
    const columns: DataTableColumn<ViolationsData>[] = [
      {
        Header: "Rooms",
        accessor: (room) => room,
        disableSortBy: true,
        width: 150,
        Cell: ({ value }: CellProps<ViolationsData, ViolationsData>) => {
          return <Text>{value.room.location.name}</Text>;
        },
      },
      {
        Header: "Grade",
        width: 50,
        maxWidth: 50,
        accessor: (room) => room,
        disableSortBy: true,
        Cell: ({ value }: CellProps<ViolationsData, ViolationsData>) => {
          // TODO: make color configurable
          let colorScheme = "gray";
          switch (value.config.name.toLowerCase()) {
            case "d":
              colorScheme = "yellow";
              break;
            case "x":
              colorScheme = "red";
              break;
          }

          return <Tag colorScheme={colorScheme}>{value.config.name}</Tag>;
        },
      },
      {
        Header: "Description",
        accessor: (room) => room,
        disableSortBy: true,
        width: 350,
        Cell: ({ value }: CellProps<ViolationsData, ViolationsData>) => {
          const { isOpen, onToggle } = useDisclosure();
          return (
            <VStack w="100%" alignItems="flex-start" gap={1}>
              <Text fontWeight="bold">{value.category.name}</Text>
              <Text size="xs">{value.group.name}</Text>
              <Text
                w="100%"
                isTruncated={isOpen ? false : true}
                noOfLines={isOpen ? undefined : 2}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onToggle();
                }}
              >
                {value.score.justification}
              </Text>
            </VStack>
          );
        },
      },
      {
        Header: "Actions",
        accessor: (room) => room,
        disableSortBy: true,
        width: 250,
        Cell: ({ value }: CellProps<ViolationsData, ViolationsData>) => {
          // User actions to take on violations.
          if (!value.score.resolved) {
            return (
              <ButtonGroup spacing={0.5}>
                <Button
                  size="sm"
                  colorScheme="blue"
                  leftIcon={<AddIcon />}
                  onClick={() => {
                    createRequest(value);
                  }}
                  borderRightRadius={0}
                >
                  Request
                </Button>
                <Menu placement="bottom-end">
                  <MenuButton
                    as={IconButton}
                    icon={<ChevronDownIcon />}
                    size="sm"
                    colorScheme="blue"
                    borderLeftRadius={0}
                  ></MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        resolveViolation(value);
                      }}
                    >
                      Mark Resolved
                    </MenuItem>
                  </MenuList>
                </Menu>
              </ButtonGroup>
            );
          }

          // resolved by request
          if (value.score.resolved && value.score.requestId) {
            const request = requestMap[value.score.requestId];
            if (!request) {
              return null; // request not found, shouldn't happen
            }
            return (
              <VStack width="100%" alignItems="flex-start">
                <Link
                  as={RouterLink}
                  to={`/requests/${request.key}?accountId=${currentAccount.id}`}
                  target="_blank"
                  fontSize="sm"
                >
                  {requestMap[value.score.requestId].key}
                </Link>

                <RequestRowAssignees assignees={request.assignees} />

                <StatusBadge status={request.status} />
              </VStack>
            );
          }

          // manually resolved
          if (value.score.resolved && value.score.resolvedComment) {
            return (
              <VStack w="100%" alignItems="flex-start" gap={1}>
                {value.score.resolvedBy && (
                  <UserBadge value={value.score.resolvedBy} />
                )}
                <DateBadge
                  date={value.score.resolved}
                  showRelative={false}
                  showTimeTooltip={true}
                  rest={{ fontSize: "xs", color: "gray.500" }}
                />
                <Text fontSize="sm">
                  Resolution: {value.score.resolvedComment}
                </Text>
              </VStack>
            );
          }

          // shouldn't happen
          return null;
        },
      },
    ];

    return columns;
  }, [createRequest, resolveViolation, currentAccount, requestMap]);

  if (!data.length) {
    return null;
  }

  return (
    <>
      <DataTable
        columns={roomColumns}
        data={data}
        rest={{
          overflow: "hidden",
          width: "100%",
        }}
      />
      {/* modals */}
      {isResolveViolationOpen && violation && (
        <ResolveViolationModal
          isOpen={isResolveViolationOpen}
          onClose={onCloseResolveViolation}
          inspectionId={inspection.id}
          inspectionScoreId={violation.score.id}
        />
      )}
    </>
  );
};

export default ViolationsDataTable;
