import { FC, ReactNode, useMemo } from "react";
import {
  Products,
  ProductsContext,
  ProductsContextProps,
} from "./ProductContext";

type ProductsProviderProps = {
  children: ReactNode;
  product?: keyof typeof Products;
};

export const ProductsProvider: FC<ProductsProviderProps> = ({
  children,
  product,
}) => {
  const value: ProductsContextProps = useMemo(() => {
    return {
      product,
    };
  }, [product]);

  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  );
};
