import { Button, Divider, Flex, Text, VStack } from "@chakra-ui/react";
import {
  ApiAttachment,
  ApiLocation,
  ApiSpace,
  ApiSpaceSummary,
  ApiVenue,
  ApiVenueSummary,
  CreateApiSpace,
} from "@operations-hero/lib-api-client";
import FuzzySearch from "fuzzy-search";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { AccountSearchBox } from "../../../components/inputs/SearchBox";
import { TwoRowCarousel } from "../../../components/swiper-carousel/TwoRowsCarousel";
import { useShowToast } from "../../../hooks/showToast";
import { useLocationUtils } from "../../../utils/locationUtils";
import { sortArrayByDesc } from "../../../utils/sortArrayByDesc";
import { SpaceCardView, SpaceListView } from "./SpaceFormViews";

export type SpaceFormViewModeType = "card" | "list";

interface SpaceFormProps {
  onClose: () => void;
  values: ApiSpace[] | ApiSpaceSummary[];
  parent: ApiLocation | null;
  viewMode: SpaceFormViewModeType;
  cb: (spaces: ApiSpace[]) => void;
  includeCarousel?: boolean;
  venue?: ApiVenueSummary | null;
  action?: "create" | "get";
}

export const SpacesForm: FC<SpaceFormProps> = ({
  parent,
  onClose,
  values,
  cb,
  viewMode,
  includeCarousel,
  venue,
  action = "create",
}) => {
  const [locations, setLocations] = useState<ApiLocation[]>([]);
  const [availableLocations, setAvailableLocations] = useState<ApiLocation[]>(
    []
  );
  const [selectedLocations, setSelectedLocations] = useState<string[]>(
    values ? values.map((s: ApiSpace | ApiSpaceSummary) => s.id) : []
  );

  const [venueDetail, setVenueDetail] = useState<ApiVenue>();

  const showToast = useShowToast();

  const [attachments, setAttachments] = useState<
    Record<string, ApiAttachment | null>
  >({});

  const { findAllChildrenForNodes } = useLocationUtils();
  const { apiClient, currentAccount } = useAuthentication();

  const locationChildrens = useMemo(() => {
    if (parent) {
      const newLocationsChildres = findAllChildrenForNodes(
        [parent as ApiLocation],
        true
      );
      return newLocationsChildres;
    }
    return [];
  }, [findAllChildrenForNodes, parent]);

  const handleOnChangeCheckbox = useCallback(
    (id: string) => {
      const index = selectedLocations.findIndex((l) => l === id);
      const selectedLocCopy = [...selectedLocations];

      index === -1
        ? selectedLocCopy.push(id)
        : selectedLocCopy.splice(index, 1);
      setSelectedLocations(selectedLocCopy);
    },
    [selectedLocations]
  );

  const handleSelectAll = useCallback(() => {
    if (selectedLocations.length !== locations.length) {
      const ids = locations.map((loc) => loc.id);
      setSelectedLocations(ids);
      return;
    }
    setSelectedLocations([]);
  }, [locations, selectedLocations.length]);

  const mapSpaces = useCallback(() => {
    const newSpaces: CreateApiSpace[] = selectedLocations.map((loc) => {
      return { location: loc };
    });

    return newSpaces;
  }, [selectedLocations]);

  const handleAddSpaces = useCallback(async () => {
    if (action === "create") {
      const spaces = mapSpaces();
      const result = await Promise.all(
        spaces.map(
          async (space) =>
            await apiClient
              .createSpace(currentAccount.id, space)
              .then((res) => res)
              .catch(() => {
                showToast("error", "Something went wrong adding space");
              })
        )
      );

      cb(result as ApiSpace[]);
    }

    if (action === "get") {
      const result = (
        await apiClient.findSpaces(currentAccount.id, {
          ids: selectedLocations,
          includeQuestions: true,
        })
      ).data;

      cb(result);
    }
    onClose();
  }, [
    action,
    apiClient,
    cb,
    currentAccount.id,
    mapSpaces,
    onClose,
    selectedLocations,
    showToast,
  ]);

  const getAttachmentsFromLocations = useCallback(async () => {
    const att: Record<string, ApiAttachment | null> = {};
    await Promise.all(
      locationChildrens.map(async (loc) => {
        const res = await apiClient.getSpaceDefaultAttachment(
          currentAccount.id,
          loc.id
        );
        att[loc.id] = res || null;
        return res;
      })
    );

    setAttachments(att);
  }, [apiClient, currentAccount.id, locationChildrens]);

  const imagesUrls = useMemo(() => {
    if (!includeCarousel) return [];

    const values = Object.values(attachments)
      .filter((s) => s !== null)
      .map((s) => s?.url);

    return values as string[];
  }, [attachments, includeCarousel]);

  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(availableLocations, ["name", "address"], {
        sort: true,
      }),
    [availableLocations]
  );

  const onInputChange = useCallback(
    (value: string) => {
      if (!value) {
        setLocations(availableLocations);
        return value;
      }
      const foundLocations = fuzzySearch.search(value);
      setLocations([...foundLocations]);

      return value;
    },
    [fuzzySearch, availableLocations]
  );

  useEffect(() => {
    // for venue configuation all location children can
    if (!venue) {
      setAvailableLocations(locationChildrens);
      setLocations(locationChildrens);
      getAttachmentsFromLocations();
      return;
    }
    // select a space for a venue requires fetching the venue details
    if (!venueDetail || venueDetail.id !== venue.id) {
      apiClient.getVenue(currentAccount.id, venue.id).then((detail) => {
        setVenueDetail(detail);
        const venueSpaces = detail.spaces.map(
          (vs) => vs.location as ApiLocation
        );
        const sortedSpaces = venueSpaces.sort((a, b) =>
          sortArrayByDesc<ApiLocation>(a, b)
        );
        setAvailableLocations(sortedSpaces);
        setLocations(sortedSpaces);
        const attachmentMap = detail.spaces.reduce<
          Record<string, ApiAttachment | null>
        >((map, space) => {
          if (!space.defaultAttachment) {
            return map;
          }
          map[space.id] = space.defaultAttachment;
          return map;
        }, {});
        setAttachments(attachmentMap);
        // getAttachments();
      });
    }
  }, [
    apiClient,
    currentAccount.id,
    locationChildrens,
    venue,
    venueDetail,
    getAttachmentsFromLocations,
  ]);

  return (
    <VStack divider={<Divider />}>
      <AccountSearchBox
        isUniqueElement={false}
        onInputChange={onInputChange}
        searchPlaceholder="Search Spaces"
      />
      <Flex
        gap={4}
        flexWrap="wrap"
        justifyContent="space-between"
        w="100%"
        my={2}
      >
        {includeCarousel && imagesUrls.length > 0 && (
          <Flex w="100%" flexDir="column">
            <Flex w="80%" flexWrap="wrap" mx="auto">
              <TwoRowCarousel images={imagesUrls} />
            </Flex>
            <Divider my={4} />
          </Flex>
        )}
        {viewMode === "card" && (
          <SpaceCardView
            locations={locations}
            attachments={attachments}
            handleOnChangeCheckbox={handleOnChangeCheckbox}
            selectedLocations={selectedLocations}
          />
        )}
        {viewMode === "list" && (
          <SpaceListView
            locations={locations}
            attachments={attachments}
            handleOnChangeCheckbox={handleOnChangeCheckbox}
            selectedLocations={selectedLocations}
          />
        )}
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" w="100%">
        <Text
          cursor="pointer"
          color="blue.500"
          fontWeight="semibold"
          onClick={handleSelectAll}
        >
          {selectedLocations.length === locations.length
            ? "Clear All"
            : "Select All"}
        </Text>

        <Flex gap={4}>
          <Button
            size="sm"
            variant="outline"
            colorScheme="blue"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            colorScheme="blue"
            isDisabled={selectedLocations.length === 0}
            onClick={handleAddSpaces}
          >
            Add
            {selectedLocations.length > 0 && ` (${selectedLocations.length})`}
          </Button>
        </Flex>
      </Flex>
    </VStack>
  );
};
