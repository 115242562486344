import {
  ApiClient,
  ApiInspectionScore,
  ApiRequest,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { InspectionDetailsSliceProps } from "../inspection-details.slice";

type AddRequestToInspectionThunk = {
  response: {
    request: ApiRequest;
    score: ApiInspectionScore;
  };
  params: {
    apiClient: ApiClient;
    accountId: string;
    request: ApiRequest;
  };
  options: {
    rejectValue: { request: ApiRequest };
  };
};

export const addRequestToInspection = createAsyncThunk<
  AddRequestToInspectionThunk["response"],
  AddRequestToInspectionThunk["params"],
  AddRequestToInspectionThunk["options"]
>(
  "inspections-details/associate-request",
  async ({ apiClient, request, accountId }, { rejectWithValue }) => {
    if (!request.inspectionId || !request.inspectionScoreId) {
      return rejectWithValue({ request });
    }

    try {
      const score = await apiClient.resolveInspectionScore(
        accountId,
        request.inspectionId,
        request.inspectionScoreId,
        {
          requestId: request.id,
        }
      );
      return { request, score };
    } catch (error) {
      // Handle error and return a rejected value
      if (error instanceof Error) {
        return rejectWithValue({ request });
      }
      throw error; // Rethrow if it's not an instance of Error
    }
  }
);

export const addRequestToInspectionHandler = (
  builder: ActionReducerMapBuilder<InspectionDetailsSliceProps>
) => {
  builder.addCase(addRequestToInspection.pending, (state) => {});
  builder.addCase(addRequestToInspection.rejected, (state) => {});
  builder.addCase(addRequestToInspection.fulfilled, (state, action) => {
    const { score } = action.payload;
    if (!score) return;

    const roomId = score.locationId;
    const key = `${roomId}::${score.inspectionCategoryId}`;
    state.scores[key] = score;
    state.requestMap[action.payload.request.id] = action.payload.request;
  });
};
