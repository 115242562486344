import { Avatar, AvatarGroup, Box, Text } from "@chakra-ui/react";
import { ApiAssignee } from "@operations-hero/lib-api-client";
import { UserBadge } from "../../../components/badges/UserBadge";

const RequestRowAssignees = ({ assignees }: { assignees: ApiAssignee[] }) => {
  // no assignees case
  if (!assignees || assignees.length === 0) {
    return (
      <Text fontStyle={"italic"} color="gray.500" isTruncated>
        Unassigned
      </Text>
    );
  }

  // signle assignee case, user or group.
  if (assignees.length === 1) {
    if (assignees[0].type === "user") {
      return <UserBadge value={assignees[0].assignee} />;
    }
    return (
      <Box display="inline-flex" maxW="100%">
        <Avatar
          size="xs"
          src={assignees[0].assignee.logo ? assignees[0].assignee.logo : ""}
        />
        <Text pl={2} isTruncated>
          {assignees[0].assignee.name}
        </Text>
      </Box>
    );
  }

  // Multi-assignees case
  return (
    <AvatarGroup size="xs" max={4} justifyContent={["right", "right", "left"]}>
      {assignees.map((assignee) =>
        assignee.type === "group" ? (
          <Avatar
            key={assignee.assignee.id}
            name={assignee.assignee.name}
            src={assignee.assignee.logo ? assignee.assignee.logo : ""}
          />
        ) : (
          <Avatar
            key={assignee.assignee.id}
            src={assignee.assignee.profileImage}
            name={`${assignee.assignee.firstName} ${assignee.assignee.lastName}`}
          />
        )
      )}
    </AvatarGroup>
  );
};

export default RequestRowAssignees;
