import { useSelector } from "react-redux";
import { useAuthentication } from "../components/auth/AuthProvider";
import { useProductSubscriptions } from "../components/shell/AppShell";
import { RootState } from "../store";

export interface UserDashboards {
  label: string;
  value: string;
  path: string;
}

export const useAllowedDashboards = () => {
  const { isEventAdmin, isProductAdmin } = useAuthentication();
  const { hasEvents, hasRequests } = useProductSubscriptions();
  const { eventsHandler, isReviewer, isViewer, isAdmin } = useSelector(
    (state: RootState) => state.localCache
  );

  const { isVenueManager } = eventsHandler.venueManagers;
  const userDashboards: UserDashboards[] = [];

  if (hasRequests && (isReviewer || isAdmin || isViewer || isProductAdmin)) {
    userDashboards.push({
      label: "Requests",
      value: "requests",
      path: "/dashboard/herohq",
    });
  }

  if (hasEvents && (isEventAdmin || isVenueManager)) {
    userDashboards.push({
      label: "Events",
      value: "events",
      path: "/dashboard/eventhq",
    });
  }

  return { userDashboards };
};
