import { Box, Checkbox, useColorModeValue } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import Select, {
  SelectItemRenderer,
  SelectRenderer,
} from "react-dropdown-select";
import { AdditionalNumberBadge } from "../../../../components/badges/AdditionalNumberBadge";
import { StatusBadge } from "../../../../components/badges/StatusBadge";
import { LocationRoomStatus } from "../../../../store/planning-hq/inspections/details/inspection-details.slice";

export interface StatusFilterProps {
  value: (LocationRoomStatus | string)[];
  onChange: (value: LocationRoomStatus[]) => void;
  status?: LocationRoomStatus[];
  showDeclined?: boolean;
}

type ValueLabel = {
  value: LocationRoomStatus;
  label: LocationRoomStatus;
};

const ContentRenderer = ({ state }: SelectRenderer<ValueLabel>) => {
  return (
    <Box p={1}>
      {state.values.length === 0 && "All Statuses"}
      {state.values.length === 1 && (
        <StatusBadge status={state.values[0].value} />
      )}
      {state.values.length > 1 && (
        <>
          <StatusBadge status={state.values[0].value} />{" "}
          <AdditionalNumberBadge number={state.values.length - 1} />
        </>
      )}
    </Box>
  );
};

const ItemRenderer = ({ item, methods }: SelectItemRenderer<ValueLabel>) => (
  <Checkbox
    key={item.value}
    isChecked={methods.isSelected(item)}
    onChange={() => methods.addItem(item)}
    w="100%"
    p={2}
  >
    <StatusBadge status={item.value} />
  </Checkbox>
);

export const StatusRoomInspectionFilter = ({
  value,
  onChange,
  status,
  showDeclined = true,
}: StatusFilterProps) => {
  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const optStatus = useMemo(
    () => (status ? status : Object.values(LocationRoomStatus)),
    [status]
  );

  const options = useMemo(
    () =>
      optStatus.map<ValueLabel>((x) => ({
        value: x,
        label: x,
      })),
    [optStatus]
  );

  const internalValues = useMemo(() => {
    return value && value.length > 0
      ? options.filter((x) => value.includes(x.value))
      : [];
  }, [value, options]);

  const handleChange = useCallback(
    (values: ValueLabel[]) => {
      onChange(values && values.length ? values.map((x) => x.value) : []);
    },
    [onChange]
  );

  return (
    <Select
      multi
      options={options}
      className={themeClass}
      values={internalValues}
      onChange={handleChange}
      searchable={true}
      searchBy="value"
      valueField="value"
      labelField="label"
      dropdownGap={0}
      keepSelectedInList
      contentRenderer={ContentRenderer}
      dropdownHeight="300"
      itemRenderer={ItemRenderer}
    />
  );
};
