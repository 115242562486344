import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiInspection,
  ApiInspectionScoreConfig,
} from "@operations-hero/lib-api-client";
import { useCallback, useMemo } from "react";
import { MdEdit } from "react-icons/md";
import { RiDoorOpenLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import {
  InspectionFloorEntry,
  InspectionRoomEntry,
} from "../../../../store/planning-hq/inspections/details/inspection-details.slice";
import { RoomDataTable } from "./RoomDataTable";

interface FloorAccordionProps {
  data: SectionAndRoomsAccordianStateMap;
  inspection: ApiInspection;
  scoreConfigMap: Record<string, ApiInspectionScoreConfig>;
  onOpenAddRoom: () => void;
  setFloorToEdit: React.Dispatch<
    React.SetStateAction<SectionAndRoomsAccordianState | undefined>
  >;
}

export type SectionAndRoomsAccordianState = {
  section: InspectionFloorEntry;
  rooms: InspectionRoomEntry[];
};

export type SectionAndRoomsAccordianStateMap = Record<
  string,
  SectionAndRoomsAccordianState
>;

export const FloorAccordion: React.FC<FloorAccordionProps> = ({
  data,
  inspection,
  scoreConfigMap,
  onOpenAddRoom,
  setFloorToEdit,
}) => {
  const iconsColor = useColorModeValue("gray.500", "gray.300");
  const { pathname } = useLocation();

  const isInspectorView = useMemo(
    () => !pathname.includes("/account"),
    [pathname]
  );

  const handleEditFloor = useCallback(
    (floor: SectionAndRoomsAccordianState) => {
      setFloorToEdit(floor);
      onOpenAddRoom();
    },
    [onOpenAddRoom, setFloorToEdit]
  );

  return (
    <>
      {Object.values(data).map((accordianItem) => (
        <AccordionItem key={accordianItem.section.location.id}>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Flex align="center">
                <Heading size="md">
                  {accordianItem.section.location.name}
                </Heading>
                {!isInspectorView &&
                  accordianItem.section.location.id !== "all-rooms" &&
                  accordianItem.rooms.length > 0 && (
                    <IconButton
                      variant="ghost"
                      icon={<MdEdit />}
                      aria-label="Edit Floor"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditFloor(accordianItem);
                      }}
                      ml={2}
                      color={iconsColor}
                    />
                  )}
              </Flex>
              <Text>
                Inspected Rooms{" "}
                {`${accordianItem.section.inspectedRooms} out of ${accordianItem.section.totalRooms}`}
              </Text>{" "}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            {accordianItem.rooms.length === 0 && (
              <Flex flexDir="column" align="center" w="100%" gap={4}>
                <Icon as={RiDoorOpenLine} color="gray.400" w="32px" h="32px" />
                <Text textColor="gray.400">No rooms added</Text>
              </Flex>
            )}

            <RoomDataTable
              roomsData={accordianItem.rooms}
              inspection={inspection}
              scoreConfigMap={scoreConfigMap}
            />
          </AccordionPanel>
        </AccordionItem>
      ))}
    </>
  );
};
