import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  Icon,
  Link,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { RootState, useThunkDispatch } from "../../../store";
import {
  findProjectBudgets,
  getProject,
  unload,
} from "../../../store/planning-hq/project-page";
import { unloadProjectRequests } from "../../../store/planning-hq/requests/project-requests";
import { unloadProjectScheduling } from "../../../store/planning-hq/scheduling/scheduling.slice";
import { unloadRequestList } from "../../../store/request-list.slice";
import { unloadRequestsSlice } from "../../../store/requests.slice";
import { useAppShellStylingContext } from "../../requests/column-view/AppShellStylingContext";
import { ProjectAttachments } from "./ProjectAttachments";
import { ProjectDetails } from "./ProjectDetails";
import { Scheduling } from "./scheduling/Scheduling";

type ProjectTab = { label: string; index: number; value: string };
const tabs: ProjectTab[] = [
  { label: "Attachments", index: 0, value: "attachments" },
  { label: "Project details", index: 1, value: "details" },
  { label: "Scheduling", index: 2, value: "scheduling" },
];

export type ProjectRouteParams = {
  section: string;
  id: string;
};

export const ProjectPage = () => {
  const [tabIndex, setTabIndex] = useState<number>(-1);
  const { project } = useSelector((state: RootState) => state.projectPageSlice);

  const { stylingMode } = useAppShellStylingContext();
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { apiClient } = useAuthentication();
  const { section, id } = useParams<ProjectRouteParams>();

  const handleTabsChange = useCallback(
    (index: number) => {
      const tab = tabs.find((tab) => tab.index === index);
      if (!tab) return;
      setTabIndex(index);
      navigate(`/planning/projects/${id}/${tab.value.toLowerCase()}`);
    },
    [navigate, id]
  );

  useEffect(() => {
    if (tabIndex !== -1) return;
    const findTab = tabs.find((t) => t.value === section);
    if (!findTab) {
      navigate(`/planning/projects/${id}/details`);
      return;
    }
    setTabIndex(findTab.index);
  }, [section, navigate, tabIndex, id]);

  useEffect(() => {
    if (project != null || !id) return;

    thunkDispatch(
      getProject({
        apiClient,
        id: id,
      })
    );

    thunkDispatch(findProjectBudgets({ apiClient, projectId: id }));
  }, [project, apiClient, id, thunkDispatch]);

  useEffect(() => {
    return () => {
      dispatch(unload());
      dispatch(unloadProjectRequests());
      dispatch(unloadRequestList());
      thunkDispatch(unloadRequestsSlice());
      dispatch(unloadProjectScheduling());
    };
  }, [dispatch, thunkDispatch]);

  return (
    <Container
      gap={2}
      maxWidth="8xl"
      sx={
        stylingMode === "project.scheduling"
          ? {
              display: "flex",
              flexDir: "column",
              flex: "1",
              height: "100%",
            }
          : undefined
      }
      position="relative"
    >
      <Box display={["flex", "flex", "flex", "none"]} gap={2} flexDir="column">
        <Link
          as={RouterLink}
          gap={2}
          display="flex"
          color="blue.500"
          alignItems="center"
          to="/planning/projects"
          fontSize="small"
        >
          <Icon as={MdArrowBack} /> Back to Project List
        </Link>
        <Heading mr={4} fontSize="3xl">
          {project?.name}
        </Heading>
        <Text fontSize="sm" color="gray">
          {project?.id}
        </Text>
      </Box>
      <Tabs
        variant="unstyled"
        index={tabIndex}
        onChange={handleTabsChange}
        sx={
          stylingMode === "project.scheduling"
            ? {
                display: "flex",
                flexDir: "column",
                flex: "1",
                height: "100%",
                overflow: "hidden",
              }
            : undefined
        }
      >
        <Flex alignItems="end" gap="10" flexWrap="wrap">
          <Box
            display={["none", "none", "none", "flex"]}
            gap={2}
            flexDir="column"
          >
            <Link
              as={RouterLink}
              gap={2}
              display="flex"
              color="blue.500"
              alignItems="center"
              to="/planning/projects"
              fontSize="small"
            >
              <Icon as={MdArrowBack} /> Back to Project List
            </Link>
            <Heading mr={4} fontSize="3xl" isTruncated maxW={400}>
              {project?.name}
            </Heading>
            <Text fontSize="sm" color="gray">
              {project?.id}
            </Text>
          </Box>

          <Box position="relative">
            <TabList>
              {tabs.map((t) => {
                return (
                  <Tab key={t.label} _focus={{ boxShadow: "none" }}>
                    {t.label}
                  </Tab>
                );
              })}
            </TabList>
            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="blue.500"
              borderRadius="1px"
            />
          </Box>
        </Flex>

        <Divider my={2} />
        <TabPanels
          sx={
            stylingMode === "project.scheduling"
              ? {
                  display: "flex",
                  flexDir: "column",
                  flex: "1",
                  height: "100%",
                  overflowY: "hidden",
                }
              : undefined
          }
        >
          <TabPanel px={0}>
            <Container maxWidth={["8xl"]} px={0}>
              <ProjectAttachments />
            </Container>
          </TabPanel>
          <TabPanel p={0}>
            <Container maxWidth={["8xl"]} px={0}>
              <ProjectDetails />
            </Container>
          </TabPanel>
          <TabPanel
            px={0}
            pt={1}
            pb={0}
            sx={
              stylingMode === "project.scheduling"
                ? {
                    display: "flex",
                    flexDir: "column",
                    flex: "1",
                    height: "100%",
                    overflow: "hidden",
                  }
                : undefined
            }
          >
            <Scheduling />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};
