import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  ApiBudgetSummary,
  ApiEquipmentItemStatus,
  ApiInventoryItem,
  ApiInventoryItemStorageLocation,
  ApiInventoryType,
  ApiItemAdjustmentReason,
  ApiItemAdjustmentType,
  ApiLocationSummary,
  ApiRequest,
  ApiUnitOfMeasureSummary,
  ApiUserSummary,
  CreateApiInventoryItemAdjustment,
} from "@operations-hero/lib-api-client";
import { Form, Formik } from "formik";
import { FC, useCallback, useMemo } from "react";
import { MdQrCode2 } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { BudgetAutocompleteControl } from "../../../../components/form-helpers/BudgetAutocompleteControl";
import { DatePickerControl } from "../../../../components/form-helpers/DatePickerControl";
import { InventoryItemLocationSelectControl } from "../../../../components/form-helpers/InventoryItemLocationSelectControl";
import { LocationAutocompleteControl } from "../../../../components/form-helpers/LocationAutocompleteControl";
import { NumberInputControl } from "../../../../components/form-helpers/NumberInputControl";
import { ProjectBudgetsAutocompleteControl } from "../../../../components/form-helpers/ProjectBudgetsAutocompleteControl";
import { RequestAutocompleteControl } from "../../../../components/form-helpers/RequestAutocompleteControl";
import { TextEditorControl } from "../../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import { TextInputControl } from "../../../../components/form-helpers/TextInputControl";
import { UserAutocompleteControl } from "../../../../components/form-helpers/UserAutocompleteControl";
import { QrQuickScanModal } from "../../../../components/qr-quick-scan/QrQuickScanModal";
import { InventoryItemAutocomplete } from "../../../../components/selects/InventoryItemAutocomplete";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../../store";
import { loadInventoryItemCheckoutCheckin } from "../../../../store/inventory/inventory-item-checkout-checkin.slice";
import { reloadItemStorageLocations } from "../../../../store/inventory/inventory-item-form.slice";
import {
  loadInventoryItems,
  setWorkingItem,
} from "../../../../store/inventory/inventory-item-list.slice";
import { Products } from "../../../account-settings/location-list/LocationList";

export interface CheckoutCheckinFormValues {
  item: string;
  itemLocation: ApiInventoryItemStorageLocation;
  quantity: number;
  deliveryLocationId: ApiLocationSummary | null;
  type: ApiItemAdjustmentType;
  unitCost: number;
  units: ApiUnitOfMeasureSummary;
  requester: ApiUserSummary | null;
  date: Date;
  returnDate: Date | null;
  assignedTo: ApiUserSummary | null;
  notes: string | null;
  reasonType: ApiItemAdjustmentReason | null;
  request: ApiRequest | null;
  serialNumber: string | null;
  inventoryAdjustmentToCheckOut: string | null;
  status: ApiEquipmentItemStatus | null;
  bulkCheckinItems?: number[];
  budget: ApiBudgetSummary | null;
}

const CheckoutFormSchema = yup.object().shape({
  requester: yup.object().required("Requester is required").nullable(),
  item: yup.string().required("Item is required").nullable(),
  assignedTo: yup.object().required("Assignee is required").nullable(),
  date: yup.date().required("Date is required").nullable(),
  itemLocation: yup.object().required("Item Location is required").nullable(),
});

interface ChangeQuantityFormProps {
  itemProp?: ApiInventoryItem;
  request?: ApiRequest;
  onClose: () => void;
}

export const CheckoutForm: FC<ChangeQuantityFormProps> = ({
  itemProp,
  request,
  onClose,
}) => {
  const { apiClient, currentAccount, currentUser } = useAuthentication();
  const { workingItem: itemSlice } = useSelector(
    (state: RootState) => state.inventoryItemsListSlice
  );

  const workingItem = useMemo(() => {
    if (itemProp) return itemProp;
    return itemSlice;
  }, [itemProp, itemSlice]);
  const { item, includeInactiveLocations } = useSelector(
    (state: RootState) => state.inventoryItemSlice
  );

  const showToast = useShowToast();
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const {
    isOpen: isOpenQRItem,
    onClose: onCloseQRItem,
    onOpen: onOpenQRItem,
  } = useDisclosure();
  const {
    isOpen: isOpenQRSerialNumber,
    onClose: onCloseQRSerialNumber,
    onOpen: onOpenQRSerialNumber,
  } = useDisclosure();

  const qrColor = useColorModeValue("black", "white");

  const initialValues: CheckoutCheckinFormValues | null = useMemo(() => {
    if (!workingItem || !workingItem.storageLocations[0]) return null;
    const [firstLocation] = workingItem.storageLocations;

    const values: CheckoutCheckinFormValues = {
      item: workingItem.id,
      itemLocation: firstLocation,
      quantity: workingItem?.type === ApiInventoryType.checkout ? 1 : 0,
      deliveryLocationId: null,
      type: ApiItemAdjustmentType.issued,
      unitCost: workingItem.cost,
      units: workingItem.units,
      requester: currentUser,
      date: new Date(),
      returnDate: null,
      assignedTo: null,
      request: request ? request : null,
      notes: null,
      reasonType: ApiItemAdjustmentReason.checkout,
      inventoryAdjustmentToCheckOut: null,
      serialNumber: null,
      status: null,
      budget: null,
    };
    return values;
  }, [workingItem, currentUser, request]);

  const mapValues = useCallback(
    (formValues: CheckoutCheckinFormValues) => {
      const values: CreateApiInventoryItemAdjustment = {
        item: formValues.item,
        itemLocation: formValues.itemLocation.id,
        type: formValues.type,
        quantity: formValues.quantity,
        unitCost: formValues.unitCost,
        units: formValues.units,
        inventoryAdjustmentToCheckOut: null,
        date:
          typeof formValues.date === "string"
            ? formValues.date
            : formValues.date.toISOString(),
        returnDate: formValues.returnDate
          ? typeof formValues.returnDate === "string"
            ? formValues.returnDate
            : formValues.returnDate.toISOString()
          : null,
        notes: formValues.notes,
        requestId: formValues.request ? formValues.request.id : null,
        requester: formValues.requester
          ? formValues.requester.id
          : currentUser.id,
        purchaseOrder: null,
        invoiceNumber: null,
        serialNumber: formValues.serialNumber,
        assignedTo: formValues.assignedTo,
        budget: formValues.budget ? formValues.budget.id : null,
        supplier: null,
        reason: formValues.reasonType,
        deliveryLocationId: formValues.deliveryLocationId?.id ?? null,
        issuanceTransactionId: null,
        status: null,
        associatedAdjustmentId: null,
        bulkCheckinItems: null,
      };
      return values;
    },
    [currentUser.id]
  );

  const handleCreateAdjustment = useCallback(
    (values: CreateApiInventoryItemAdjustment) => {
      apiClient
        .createInventoryItemAdjustments(currentAccount.id, values)
        .then(() => {
          showToast("success", "Check Out was created successfully");
          thunkDispatch(
            loadInventoryItems({ apiClient, accountId: currentAccount.id })
          );

          if (item.id) {
            thunkDispatch(
              loadInventoryItemCheckoutCheckin({
                apiClient,
                accountId: currentAccount.id,
                itemId: item.id,
              })
            );
            thunkDispatch(
              reloadItemStorageLocations({
                apiClient,
                accountId: currentAccount.id,
                itemId: item.id,
                includeInactive: !includeInactiveLocations,
              })
            );
          }
          onClose();
        })
        .catch((res) => {
          showToast("error", res.response.data.message);
        });
    },
    [
      apiClient,
      currentAccount.id,
      onClose,
      showToast,
      thunkDispatch,
      includeInactiveLocations,
      item,
    ]
  );

  const handleOnSubmit = useCallback(
    (values: CheckoutCheckinFormValues) => {
      const newValues = mapValues(values);
      handleCreateAdjustment(newValues);
    },
    [handleCreateAdjustment, mapValues]
  );

  const handleQrItem = useCallback(
    (value: string) => {
      apiClient
        .findInventoryItem(currentAccount.id, {
          search: value,
          type: ApiInventoryType.checkout,
          pageSize: 1,
        })
        .then((response) => {
          if (response.data) {
            dispatch(setWorkingItem(response.data[0]));
          }
        });
      onCloseQRItem();
    },
    [apiClient, currentAccount.id, onCloseQRItem, dispatch]
  );

  const handleQrSerialNumber = useCallback(
    (value: string, setFieldValue: (field: string, value: string) => void) => {
      setFieldValue("serialNumber", value);
      onCloseQRSerialNumber();
    },
    [onCloseQRSerialNumber]
  );

  if (!initialValues || !workingItem)
    return (
      <Flex flexDir="column" gap={4}>
        <Alert status="warning">
          <AlertIcon />
          At least one storage location is required.
        </Alert>
        <Button
          size="sm"
          maxW="80px"
          variant="outline"
          colorScheme="blue"
          onClick={onClose}
        >
          Cancel
        </Button>
      </Flex>
    );

  return (
    <VStack alignItems="flex-start">
      <Box w="100%">
        <Formik
          onSubmit={handleOnSubmit}
          initialValues={initialValues}
          validationSchema={CheckoutFormSchema}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <Grid templateColumns="repeat(6, 1fr)" gap={4}>
                  <GridItem colSpan={6}>
                    <UserAutocompleteControl
                      value={values.requester}
                      name="requester"
                      label="Requested by"
                    />
                  </GridItem>

                  <GridItem colSpan={6}>
                    <FormLabel>Item</FormLabel>
                    <Flex alignItems="center">
                      <Box flex="1">
                        <InventoryItemAutocomplete
                          value={workingItem}
                          onChange={(item) => dispatch(setWorkingItem(item))}
                          type={[
                            ApiInventoryType.checkout,
                            ApiInventoryType.bulkCheckout,
                          ]}
                          isDisabled={Boolean(itemProp)}
                        />
                      </Box>
                      <Button
                        w={7}
                        ml={2}
                        onClick={onOpenQRItem}
                        color={qrColor}
                        onTouchEnd={onOpenQRItem}
                        zIndex={100}
                      >
                        <Icon h={7} w={7} as={MdQrCode2} />
                      </Button>
                    </Flex>
                  </GridItem>
                  {workingItem?.type === ApiInventoryType.checkout && (
                    <GridItem colSpan={6}>
                      <Flex alignItems="center">
                        <TextInputControl
                          name="serialNumber"
                          placeholder="Serial Number"
                          label="Serial Number"
                          value={values.serialNumber}
                        />

                        <Button
                          w={7}
                          mt={7}
                          ml={2}
                          onClick={onOpenQRSerialNumber}
                          color={qrColor}
                          onTouchEnd={onOpenQRSerialNumber}
                          zIndex={100}
                        >
                          <Icon h={7} w={7} as={MdQrCode2} />
                        </Button>
                      </Flex>
                    </GridItem>
                  )}

                  {workingItem?.type === ApiInventoryType.bulkCheckout && (
                    <GridItem colSpan={6}>
                      <Flex gap={2} alignItems="center">
                        <NumberInputControl
                          min={0}
                          name="quantity"
                          label="Quantity"
                          placeholder="Quantity"
                          value={values.quantity}
                          precision={2}
                        />
                        <Box>
                          <Text>Unit of Measure</Text>
                          <Input
                            mt={1}
                            h="2.75rem"
                            isDisabled={true}
                            type="text"
                            value={values.units.name}
                          />
                        </Box>
                      </Flex>
                    </GridItem>
                  )}

                  <GridItem colSpan={[6, 6, 3]}>
                    <InventoryItemLocationSelectControl
                      name="itemLocation"
                      value={values.itemLocation}
                      label="Storage Location"
                      inventoryItemId={workingItem?.id}
                    />
                  </GridItem>
                  <GridItem colSpan={[6, 6, 3]}>
                    <NumberInputControl
                      prefix="$"
                      label={"Cost (Optional)"}
                      name="unitCost"
                      value={workingItem?.cost}
                    />
                  </GridItem>

                  <GridItem colSpan={6}>
                    <UserAutocompleteControl
                      value={values.assignedTo}
                      name="assignedTo"
                      label="Assigned To"
                      assignSelf="Assign to me"
                    />
                  </GridItem>
                  <GridItem colSpan={6}>
                    <LocationAutocompleteControl
                      label="Deliver to"
                      name="deliveryLocationId"
                      value={values.deliveryLocationId}
                      productName={Products.InventoryHQ}
                    />
                  </GridItem>
                  <GridItem colSpan={[6, 6, 3]}>
                    <DatePickerControl
                      value={values.date}
                      name="date"
                      label="Date Issued"
                      placeholder="mm/dd/yy"
                    />
                  </GridItem>

                  <GridItem colSpan={[6, 6, 3]}>
                    <DatePickerControl
                      value={values.returnDate}
                      name="returnDate"
                      label="Return Date (Optional)"
                      placeholder="mm/dd/yy"
                    />
                  </GridItem>

                  {!request && (
                    <GridItem colSpan={6}>
                      <RequestAutocompleteControl
                        name="request"
                        value={values.request}
                        label="Work Order/Request Number (Optional)"
                        allowEmpty
                      />
                    </GridItem>
                  )}
                  <GridItem colSpan={6}>
                    {values.request && values.request.projectId ? (
                      <ProjectBudgetsAutocompleteControl
                        projectId={values.request.projectId}
                        value={values.budget}
                        label="Budget"
                        name="budget"
                        allowEmpty
                      />
                    ) : (
                      <BudgetAutocompleteControl
                        value={values.budget}
                        label="Budget"
                        name="budget"
                        allowEmpty
                      />
                    )}
                  </GridItem>
                  <GridItem colSpan={6}>
                    <TextEditorControl
                      value={values.notes}
                      name="notes"
                      label="Additional Note (Optional)"
                    />
                  </GridItem>

                  <GridItem colSpan={6}>
                    <Divider />
                  </GridItem>

                  <GridItem
                    colSpan={6}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Button
                      size="sm"
                      variant="outline"
                      colorScheme="blue"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button size="sm" colorScheme="blue" type="submit">
                      {workingItem.type === ApiInventoryType.bulkCheckout
                        ? "Bulk Check Out"
                        : "Check Out"}
                    </Button>
                  </GridItem>
                </Grid>
                {isOpenQRItem && (
                  <QrQuickScanModal
                    isOpen={isOpenQRItem}
                    onClose={onCloseQRItem}
                    onData={(data) => handleQrItem(data)}
                  />
                )}
                {isOpenQRSerialNumber && (
                  <QrQuickScanModal
                    isOpen={isOpenQRSerialNumber}
                    onClose={onCloseQRSerialNumber}
                    onData={(data) => handleQrSerialNumber(data, setFieldValue)}
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      </Box>
    </VStack>
  );
};
