import { createAsyncThunk, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ApiClient, ApiUserSummary } from "@operations-hero/lib-api-client";
import { LocalEventDetailsSliceState } from "./event-details.slice";

interface SaveEventFollowersThunkParams {
  apiClient: ApiClient;
  accountId: string;
  eventId: string;
  followers: ApiUserSummary[];
}

export const saveEventFollowers = createAsyncThunk(
  "events/saveFollowers",
  async (params: SaveEventFollowersThunkParams) => {
    const { apiClient, accountId, eventId, followers } = params;

    const updatedEvent = await apiClient.updateEvent(accountId, eventId, {
      followedBy: followers.map((f) => f.id),
    });

    return updatedEvent;
  }
);

export const eventFollowersHandler = (
  builder: ActionReducerMapBuilder<LocalEventDetailsSliceState>
) => {
  builder.addCase(saveEventFollowers.fulfilled, (state, action) => {
    if (state.event) {
      state.event.followedBy = action.payload.followedBy;
    }
  });
};
