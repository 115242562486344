import { FC, ReactNode } from "react";
import { Products } from "../products/ProductContext";
import { ProductsProvider } from "../products/ProductProvider";
import { LocationsProvider, LocationsProviderProps } from "./LocationsProvider";

type LocationsByProductProviderProps = {
  children: ReactNode;
  product?: keyof typeof Products;
} & Omit<LocationsProviderProps, "children">;

export const LocationsByProductProvider: FC<LocationsByProductProviderProps> = (
  props
) => {
  const { product, children, ...locationProps } = props;

  return (
    <ProductsProvider product={product}>
      <LocationsProvider {...locationProps}>{children}</LocationsProvider>
    </ProductsProvider>
  );
};
