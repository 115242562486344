import { Badge } from "@chakra-ui/react";
import { ApiInspection } from "@operations-hero/lib-api-client";
import { FC } from "react";

import { useInspectionRatingUtil } from "../../hooks/useInspectionRatingUtil";
import { ScoreCard } from "./ScoreCards";

interface RatingCardProps {
  inspection: ApiInspection;
}

export const RatingCard: FC<RatingCardProps> = ({ inspection }) => {
  const { percentage, ratingType, ratingColor } =
    useInspectionRatingUtil(inspection);

  return (
    <ScoreCard
      title="Rating:"
      value={`${percentage.toFixed(2)}%`}
      bgColor={ratingColor}
      textProps={{ textColor: "white" }}
      childrenTitle={
        <Badge
          variant="outline"
          fontSize="sm"
          colorScheme="white"
          color="white"
          border="1px solid white"
        >
          {ratingType}
        </Badge>
      }
    />
  );
};
