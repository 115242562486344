import { Button, ButtonProps, Icon } from "@chakra-ui/react";
import React from "react";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";

interface CustomLinkProps {
  labelText?: string;
  icon?: React.ElementType;
  to: string;
  invertedIconText?: boolean;
  //https://github.com/chakra-ui/chakra-ui/issues/2255
  isDisabled?: boolean;
  variant?: string;
  colorScheme?: string;
  props?: ButtonProps;
}

export const ButtonLink: React.FC<CustomLinkProps> = ({
  labelText = "Back",
  icon = MdArrowBack,
  to,
  invertedIconText = false,
  isDisabled,
  variant = "link",
  colorScheme = "blue",
  props,
}) => {
  const navigate = useNavigate();

  return (
    <Button
      variant={variant}
      colorScheme={colorScheme}
      onClick={() => navigate(to)}
      isDisabled={isDisabled}
      fontWeight="bold"
      gap={2}
      {...props}
    >
      {!invertedIconText ? (
        <>
          <Icon as={icon} /> {labelText}
        </>
      ) : (
        <>
          {labelText} <Icon as={icon} />{" "}
        </>
      )}
    </Button>
  );
};
