import {
  ApiClient,
  FindLocationAttachmentsOptions,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import {
  Attachment,
  mapApiAttachments,
} from "../../components/attachments/Attachments";

interface loadLocationAttachmentsThunkParams {
  apiClient: ApiClient;
  accountId: string;
  galleryId: string;
  filters?: Partial<FindLocationAttachmentsOptions>;
}

export interface locationAttachmentsSliceState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  data: Attachment[];
  total: number;
  filters: FindLocationAttachmentsOptions;
}

export const loadLocationAttachments = createAsyncThunk(
  "locations/attachments",
  async (params: loadLocationAttachmentsThunkParams, ThunkAPI) => {
    const { filters } = (ThunkAPI.getState() as RootState)
      .locationAttachmentsSlice;
    const { apiClient, galleryId, accountId } = params;
    const response = await apiClient.findGalleryAttachments(
      accountId,
      galleryId,
      filters
    );
    return { ...response, data: mapApiAttachments(response.data) };
  }
);

export const locationAttachmentsDefaultFilters: FindLocationAttachmentsOptions =
  {
    page: 1,
    pageSize: 20,
  };

export const locationAttachmentsSlice = createSlice({
  name: "location-attachments",
  initialState: {
    loading: "idle",
    data: [],
    total: 0,
    filters: locationAttachmentsDefaultFilters,
  } as locationAttachmentsSliceState,
  reducers: {
    unloadLocationAttachments: (state: locationAttachmentsSliceState) => {
      state.loading = "idle";
      state.data = [];
      state.total = 0;
      state.filters = locationAttachmentsDefaultFilters;
    },
    updateLocationAttachmentsFilters: (
      state: locationAttachmentsSliceState,
      action: PayloadAction<FindLocationAttachmentsOptions>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    updateData: (
      state: locationAttachmentsSliceState,
      action: PayloadAction<Attachment[]>
    ) => {
      state.data = action.payload;
    },
    updateActiveStatus(
      state: locationAttachmentsSliceState,
      action: PayloadAction<{ index: number; value: boolean }>
    ) {
      state.data[action.payload.index].active = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadLocationAttachments.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.loading = "succeeded";
      state.total = action.payload.total;
    });
    builder.addCase(loadLocationAttachments.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(loadLocationAttachments.rejected, (state) => {
      state.loading = "failed";
      state.data = [];
    });
  },
});

export const {
  unloadLocationAttachments,
  updateLocationAttachmentsFilters,
  updateData,
} = locationAttachmentsSlice.actions;

export default locationAttachmentsSlice.reducer;
