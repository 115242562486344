import { ApiInspection } from "@operations-hero/lib-api-client";
import { useMemo } from "react";
import { RatingType } from "../components/badges/RatingBadge";

export const useInspectionRatingUtil = (inspection: ApiInspection) => {
  const percentage = useMemo(
    () => (inspection.rating / inspection.ratingCeiling) * 100,
    [inspection.rating, inspection.ratingCeiling]
  );

  const ratingType = useMemo(() => {
    if (percentage >= 99) return RatingType.exemplary;
    if (percentage >= 90) return RatingType.good;
    if (percentage >= 75) return RatingType.fair;
    return RatingType.poor;
  }, [percentage]);

  const ratingColor = useMemo(() => {
    switch (ratingType) {
      case RatingType.exemplary:
        return "green.400";
      case RatingType.good:
        return "blue.400";
      case RatingType.fair:
        return "orange.300";
      case RatingType.poor:
        return "red.500";
      default:
        return "gray.500";
    }
  }, [ratingType]);

  return { percentage, ratingType, ratingColor };
};

export function getColor(index: number, total: number) {
  const startColor = [234, 179, 8]; // yellow.500
  const endColor = [249, 115, 22]; // orange.500
  const ratio = index / total;

  const color = startColor.map((start, i) =>
    Math.round(start + ratio * (endColor[i] - start))
  );

  return `rgb(${color.join(",")})`;
}
