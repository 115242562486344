import {
  Button,
  Flex,
  Icon,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC, useCallback, useEffect, useRef } from "react";
import { BiUndo } from "react-icons/bi";
import { MdArrowBack } from "react-icons/md";
import SignaturePad from "signature_pad";
import { useShowToast } from "../../hooks/showToast";
import { useConvertBase64ToImageFile } from "../../hooks/useConvertBase64ToFile";
import { ESignValues } from "./E-Sign";

interface ESignCanvaProps {
  fullname?: string;
  isLandScape?: boolean;
  handleOnGoBack?: () => void;
  getESignatureValues: (data: ESignValues) => void;
  border?: boolean;
}

export const ESignCanva: FC<ESignCanvaProps> = ({
  fullname,
  isLandScape,
  handleOnGoBack,
  getESignatureValues,
  border,
}) => {
  const showToast = useShowToast();
  const { getFileFromBase64 } = useConvertBase64ToImageFile();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const signaturePadRef = useRef<SignaturePad | null>(null);

  const mobileCanvasHeigth = isLandScape ? "74vh" : "86vh";
  const canvasWidth = useBreakpointValue({
    base: "100%",
    xs: "100%",
    sm: "100%",
    md: "100%",
    lg: "480px",
  });

  const canvasHeight = useBreakpointValue({
    base: mobileCanvasHeigth,
    xs: mobileCanvasHeigth,
    sm: mobileCanvasHeigth,
    md: mobileCanvasHeigth,
    lg: "240px",
  });

  const textColor = useColorModeValue("blue.500", "blue.300");

  const handleInitSignatureCanva = useCallback(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const signaturePad = new SignaturePad(canvas, {
        backgroundColor: "rgba(255, 255, 255, 0)",
        minWidth: 2,
        maxWidth: 3,
      });
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      signaturePadRef.current = signaturePad;
    }
  }, []);

  const handleOnContinue = (format = "image/png") => {
    if (!signaturePadRef.current || signaturePadRef.current.isEmpty()) {
      showToast("info", "Please provide you sign to continue");
      return;
    }
    const dataURL = signaturePadRef.current.toDataURL(format);
    const imageFile = getFileFromBase64(dataURL, fullname);

    getESignatureValues({ imageFile, imageURL: dataURL });
  };

  const handleClear = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  };

  const handleUndo = () => {
    if (signaturePadRef.current) {
      const currentValues = signaturePadRef.current.toData();
      currentValues.pop();

      signaturePadRef.current.fromData(currentValues);
    }
  };

  useEffect(() => {
    handleInitSignatureCanva();
  }, [handleInitSignatureCanva, handleOnGoBack]);

  return (
    <Flex direction="column" gap={4}>
      <Flex alignItems="center" justifyContent="center">
        {Boolean(handleOnGoBack) && (
          <Text
            left={5}
            display="flex"
            cursor="pointer"
            color={textColor}
            position="absolute"
            alignItems="center"
            onClick={handleOnGoBack}
          >
            <Icon as={MdArrowBack} />
            Back
          </Text>
        )}
        <Text>Draw your E-Signature</Text>
      </Flex>

      <Flex w="100%" justifyContent="center">
        <canvas
          height={canvasHeight}
          ref={canvasRef}
          style={
            border
              ? {
                  width: canvasWidth,
                  height: canvasHeight,
                  border: "1px solid #a1a1aa",
                }
              : {
                  width: canvasWidth,
                  height: canvasHeight,
                }
          }
        />
      </Flex>

      <Flex
        w="calc(100% - 48px)"
        bottom={4}
        justifyContent="space-between"
        borderTop="2px dashed #a1a1aa"
      >
        <Flex w="100%" justifyContent="space-between" mt={1}>
          <Flex>
            <Button
              gap={1}
              display="flex"
              variant="ghost"
              colorScheme="blue"
              alignItems="center"
              onClick={handleUndo}
            >
              <Icon as={BiUndo} />
              Undo
            </Button>
            <Button onClick={handleClear} colorScheme="blue" variant="ghost">
              Clear All
            </Button>
          </Flex>

          <Button
            colorScheme="blue"
            onClick={() => handleOnContinue("image/png")}
          >
            Continue
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
