import {
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  StackDivider,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import {
  addMonths,
  addWeeks,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { RiCalendar2Fill } from "react-icons/ri";
import { StyledDatePicker } from "../inputs/StyledDatePicker";

export type DateRange =
  | "thisWeek"
  | "nextWeek"
  | "thisMonth"
  | "nextMonth"
  | "custom"
  | "unset";

export const DateRangesValues: Record<string, string> = {
  thisWeek: "This Week (Sun - Sat)",
  nextWeek: "Next Week(Sun - Sat)",
  thisMonth: "This Month (1st- EOM)",
  nextMonth: "Next Month (1st- EOM)",
  custom: "Custom",
};

export const { custom, unset, ...predefinedRangesValues } = DateRangesValues;

export interface DatesFilterProps {
  dateTypes: Record<string, string>;
  dateFilters: Record<string, Array<string | null> | undefined>;
  defaultType: string;
  onChangeFilters: (
    filters: Record<string, Array<string | null> | undefined>,
    dateType: string,
    dateRangeType?: DateRange
  ) => void;
  predefinedRanges?: boolean;
  size?: string;
}

export const DatesFilter: FC<DatesFilterProps> = ({
  dateTypes,
  dateFilters,
  defaultType,
  onChangeFilters,
  predefinedRanges = true,
  size = "md",
}) => {
  const bgColor = useColorModeValue("white", "whiteAlpha.300");
  const [dateType, setDateType] = useState<string>(defaultType);
  const [dateRangeType, setDateRangeType] = useState<DateRange>();
  const [dateRanges, setDateRanges] = useState<Array<string | null>>();

  const [customStartDate, setCustomStartDate] = useState<Date | null>(null);
  const [customEndDate, setCustomEndDate] = useState<Date | null>(null);

  const getThisWeek = useMemo(() => {
    const today = new Date();
    const weekStartDate = startOfWeek(today).toISOString();
    const weekEndDate = endOfWeek(today).toISOString();
    return [weekStartDate, weekEndDate];
  }, []);

  const getNextWeek = useMemo(() => {
    const nextWeek = addWeeks(new Date(), 1);
    const weekStartDate = startOfWeek(nextWeek).toISOString();
    const weekEndDate = endOfWeek(nextWeek).toISOString();
    return [weekStartDate, weekEndDate];
  }, []);

  const getThisMonth = useMemo(() => {
    const today = new Date();
    const monthStartDate = startOfMonth(today).toISOString();
    const monthEndDate = endOfMonth(today).toISOString();
    return [monthStartDate, monthEndDate];
  }, []);

  const getNextMonth = useMemo(() => {
    const nextMonth = addMonths(new Date(), 1);
    const monthStartDate = startOfMonth(nextMonth).toISOString();
    const monthEndDate = endOfMonth(nextMonth).toISOString();
    return [monthStartDate, monthEndDate];
  }, []);

  const getRangeDates = useCallback(
    (range: DateRange) => {
      switch (range) {
        case "thisWeek":
          return getThisWeek;
        case "nextWeek":
          return getNextWeek;
        case "thisMonth":
          return getThisMonth;
        case "nextMonth":
          return getNextMonth;
      }
    },
    [getNextMonth, getNextWeek, getThisMonth, getThisWeek]
  );

  const handleOnChangeDateRangeType = useCallback(
    (value: string | string[]) => {
      if (Array.isArray(value)) return;
      setDateRangeType(value as DateRange);
    },
    []
  );

  const handleChangeDateType = useCallback((value: string | string[]) => {
    if (Array.isArray(value)) return;
    setDateType(value);
  }, []);

  const handleApplyCustomDates = useCallback(() => {
    const start = customStartDate ? customStartDate.toISOString() : "";
    const end = customEndDate ? customEndDate.toISOString() : "";
    const clearedFilters = { ...dateFilters };
    Object.keys(clearedFilters).forEach(
      (key) => (clearedFilters[key] = undefined)
    );

    clearedFilters[dateType] = [start, end];
    onChangeFilters(clearedFilters, dateType, dateRangeType);
  }, [
    customEndDate,
    customStartDate,
    dateFilters,
    dateType,
    onChangeFilters,
    dateRangeType,
  ]);

  const cleanValues = useCallback(() => {
    setDateRangeType("unset");
    setDateRanges(undefined);
    setCustomStartDate(null);
    setCustomEndDate(null);
    setDateType(defaultType);

    const clearedFilters = { ...dateFilters };
    Object.keys(clearedFilters).forEach(
      (key) => (clearedFilters[key] = undefined)
    );
    onChangeFilters(clearedFilters, dateType, dateRangeType);
  }, [dateFilters, onChangeFilters, defaultType, dateType, dateRangeType]);

  useEffect(() => {
    if (dateRangeType === "custom") return;
    if (dateRangeType && dateType) {
      const rangeValues = getRangeDates(dateRangeType);
      setDateRanges(rangeValues);
    }
  }, [dateRangeType, getRangeDates, dateType]);

  useEffect(() => {
    if (
      dateType &&
      dateRangeType === "custom" &&
      (customStartDate || customEndDate)
    )
      handleApplyCustomDates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateType]);

  useEffect(() => {
    if (dateType && dateRanges && dateRangeType !== "custom") {
      const clearedFilters = { ...dateFilters };
      Object.keys(clearedFilters).forEach(
        (key) => (clearedFilters[key] = undefined)
      );
      clearedFilters[dateType] = dateRanges;

      /** Clearing date range to avoid re-renders */
      setDateRanges(undefined);
      /** Updating filters */
      onChangeFilters(clearedFilters, dateType, dateRangeType);
    }
  }, [
    dateRangeType,
    dateType,
    getRangeDates,
    onChangeFilters,
    dateFilters,
    dateRanges,
  ]);

  useEffect(() => {
    if (!predefinedRanges) setDateRangeType("custom");
  }, [predefinedRanges]);

  return (
    <Menu closeOnSelect={false} flip={false} strategy="fixed">
      <MenuButton
        as={IconButton}
        icon={<Icon as={RiCalendar2Fill} />}
        aria-label="Field"
        bgColor={bgColor}
        colorScheme="blue"
        variant="outline"
        size={size}
        id="menu-button-relative"
      />
      <MenuList>
        <MenuOptionGroup
          title="Select Date Type"
          value={dateType}
          type="radio"
          onChange={handleChangeDateType}
        >
          {Object.keys(dateTypes).map((key) => (
            <MenuItemOption value={key} key={`dateType::${key}`}>
              {dateTypes[key]}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>

        <MenuDivider />

        {predefinedRanges ? (
          <MenuOptionGroup
            title="Select Date Range"
            value={dateRangeType}
            onChange={(value) => handleOnChangeDateRangeType(value)}
            type="radio"
          >
            {Object.keys(predefinedRangesValues).map((key) => (
              <MenuItemOption
                isDisabled={dateType === undefined}
                value={key}
                closeOnSelect
                key={`rangeType::${key}`}
              >
                {predefinedRangesValues[key]}
              </MenuItemOption>
            ))}
            <MenuItemOption isDisabled={dateType === undefined} value="custom">
              Custom
            </MenuItemOption>
            <VStack
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
            >
              <Flex px="3" alignItems="center" gap={2}>
                <StyledDatePicker
                  name="from-date-filter"
                  value={customStartDate || null}
                  isDisabled={dateRangeType !== "custom"}
                  onChange={(v) => setCustomStartDate(v)}
                />
                <Text mx={2}>To</Text>
                <StyledDatePicker
                  name="to-date-filter"
                  value={customEndDate || null}
                  isDisabled={dateRangeType !== "custom"}
                  onChange={(v) => setCustomEndDate(v)}
                />
              </Flex>
              <Flex width="full" justifyContent="space-evenly">
                <Button onClick={cleanValues} variant="ghost">
                  Clean
                </Button>
                <Button
                  isDisabled={dateRangeType !== "custom"}
                  onClick={handleApplyCustomDates}
                  colorScheme="blue"
                >
                  Apply
                </Button>
              </Flex>
            </VStack>
          </MenuOptionGroup>
        ) : (
          <MenuOptionGroup
            title="Select Date Range"
            value={dateRangeType}
            onChange={(value) => handleOnChangeDateRangeType(value)}
            type="radio"
          >
            <MenuItemOption isDisabled={dateType === undefined} value="custom">
              Custom
            </MenuItemOption>
            <VStack
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
            >
              <Flex px="3" alignItems="center" gap={2}>
                <StyledDatePicker
                  name="from-date-filter"
                  value={customStartDate || null}
                  isDisabled={dateRangeType !== "custom"}
                  onChange={(v) => setCustomStartDate(v)}
                />
                <Text mx={2}>To</Text>
                <StyledDatePicker
                  name="to-date-filter"
                  value={customEndDate || null}
                  isDisabled={dateRangeType !== "custom"}
                  onChange={(v) => setCustomEndDate(v)}
                />
              </Flex>
              <Flex width="full" justifyContent="space-evenly">
                <Button onClick={cleanValues} variant="ghost">
                  Clean
                </Button>
                <Button
                  isDisabled={dateRangeType !== "custom"}
                  onClick={handleApplyCustomDates}
                  colorScheme="blue"
                >
                  Apply
                </Button>
              </Flex>
            </VStack>
          </MenuOptionGroup>
        )}
      </MenuList>
    </Menu>
  );
};
