import { Text, TextProps } from "@chakra-ui/react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useDateTimeFormatter } from "../../../hooks/useIntlFormatter";
import { RootState } from "../../../store";
import { RelativeDateOptions } from "../../../store/transaction-list.slice";
import { DateFilter } from "../../requests/Requests";

export const RequestDateSelectionText = (props: TextProps) => {
  const { filters } = useSelector(
    (state: RootState) => state.projectBulkAddRequests
  );

  const { locale } = useSelector((state: RootState) => state.global);
  const { formatDateTime } = useDateTimeFormatter(locale);

  const dateRangeSelection = useMemo(() => {
    if (filters.date === null) return "No Date Filters Applied";
    const field = DateFilter[filters.date.field];
    let value = "";
    if (filters.date.type === "relative") {
      value = DateFilter[filters.date.value as RelativeDateOptions];
    } else {
      if (filters.date.isFiscalYear) {
        value = `${
          filters.date.fiscalYear && DateFilter[filters.date.fiscalYear]
        } from ${filters.date.value[0]} to ${filters.date.value[1]}`;
      } else {
        const [start, end] = [
          formatDateTime(new Date(filters.date.value[0] as string)).replace(
            /\//g,
            "-"
          ),
          formatDateTime(new Date(filters.date.value[1] as string)).replace(
            /\//g,
            "-"
          ),
        ];
        value = `Custom from ${start} to ${end}`;
      }
    }
    const res = `${field}:  ${value}`;
    return res;
  }, [filters.date, formatDateTime]);

  return (
    <Text size="sm" {...props}>
      {dateRangeSelection}
    </Text>
  );
};
