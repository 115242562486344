import React, { lazy } from "react";
import { Route } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { Products } from "../../../components/products/ProductContext";
import PortalUsersListPage from "../portal-users/PortalUsersListPage";
import { AccountWrapper } from "./AccountRoutesWrapper";

const EventSettings = lazy(() => import("../event-settings/EventSettings"));
const VenueList = lazy(() => import("../venue-list/VenueList"));
const VenueForm = lazy(() => import("../venue-form/VenueForm"));
const EventGroupList = lazy(() => import("../event-group-list/EventGroupList"));
const EventGroupForm = lazy(() => import("../event-group-form/EventGroupForm"));
const ServiceList = lazy(() => import("../event-services-list/ServiceList"));
const ServiceForm = lazy(() => import("../event-service-form/ServiceForm"));
const RateGroup = lazy(() => import("../rate-group/RateGroup"));
const RentableEquipment = lazy(
  () => import("../rentable-equipment/RentableEquipment")
);
const EventBlocks = lazy(() => import("../event-blocks/EventBlocks"));

const CommonQuestions = lazy(
  () => import("../common-questions/questions-list/CommonQuestionsList")
);
const CommonQuestionsForm = lazy(
  () => import("../common-questions/questions-form/CommonQuestionsForm")
);
const UpdateSpace = lazy(() => import("../../events/spaces/UpdateSpace"));
// const UpdateSpace = lazy(() => import("../events/spaces/UpdateSpace"));

export const useEventRoutes = (hasEvents: Boolean) => {
  const { isEventAdmin } = useAuthentication();

  if (!isEventAdmin || !hasEvents) return { routes: [] };

  const routes: React.ReactNode[] = [
    <Route
      path="event-settings"
      key="routes::events/event-settings"
      element={<AccountWrapper Element={EventSettings} />}
    />,
    <Route
      path="venues"
      key="routes::events/venues"
      element={<AccountWrapper Element={VenueList} />}
    />,
    <Route
      path="venues/:venueId"
      key="routes::events/venues/venueId"
      element={<AccountWrapper Element={VenueForm} product={Products.Events} />}
    />,
    <Route
      path="questions"
      key="routes::events/questions"
      element={<AccountWrapper Element={CommonQuestions} />}
    />,
    <Route
      path="questions/:questionId"
      key="routes::events/questions/questionId"
      element={<AccountWrapper Element={CommonQuestionsForm} />}
    />,
    <Route
      path="event-groups"
      key="routes::events/event-groups"
      element={<AccountWrapper Element={EventGroupList} />}
    />,
    <Route
      path="event-groups/:groupId"
      key="routes::events/event-groups/groupId"
      element={<AccountWrapper Element={EventGroupForm} />}
    />,
    <Route
      path="services"
      key="routes::events/services"
      element={<AccountWrapper Element={ServiceList} />}
    />,
    <Route
      path="services/:serviceId"
      key="routes::events/services/serviceId"
      element={<AccountWrapper Element={ServiceForm} />}
    />,

    <Route
      path="rate-groups"
      key="routes::events/rate-groups"
      element={<AccountWrapper Element={RateGroup} />}
    />,

    <Route
      path="rentable-equipment"
      key="routes::events/rentable-equipment"
      element={<AccountWrapper Element={RentableEquipment} />}
    />,

    <Route
      path="block-venues"
      key="routes::events/block-venues"
      element={<AccountWrapper Element={EventBlocks} />}
    />,
    <Route
      path="portal-users"
      key="routes::events/portal-users"
      element={<AccountWrapper Element={PortalUsersListPage} />}
    />,
    <Route
      path="/account/venues/:venueId/spaces/:spaceId"
      key="routes::events/update-spaces/spaceId"
      element={<AccountWrapper Element={UpdateSpace} />}
    />,
  ];

  return { routes };
};
