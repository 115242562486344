import React, { lazy } from "react";
import { Route } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { Inspection } from "../../planning-hq/inspection/Inspection";
import { InspectionDetails } from "../../planning-hq/inspection/InspectionDetails";
import { InspectionDetailsFloor } from "../../planning-hq/inspection/InspectionDetailsFloor";
import { InspectionReport } from "../../planning-hq/inspection/InspectionReport";
import { InspectionRoomForm } from "../../planning-hq/inspection/InspectionRoomForm";
import { AccountWrapper } from "./AccountRoutesWrapper";

const InspectionSettings = lazy(
  () => import("../../../pages/planning-hq/inspection/Inspections")
);

export const usePlanningRoutes = (hasPlannings: Boolean) => {
  const { isPlanningAdmin } = useAuthentication();

  if (!isPlanningAdmin || !hasPlannings) return { routes: [] };

  const routes: React.ReactNode[] = [
    <Route
      path="planning/inspection"
      key="routes::account/planning/inspection"
      element={<AccountWrapper Element={InspectionSettings} />}
    />,
    <Route
      path="planning/inspection/:id"
      key="routes::account/planning/inspection"
      element={<Inspection />}
    >
      <Route
        key="routes::account/planning/inspection/:id/details"
        path="details"
        element={<InspectionDetails />}
        index
      />
      <Route
        key="routes::account/planning/inspection/:id/report"
        path="report"
        element={<InspectionReport />}
      />
      <Route
        key="routes::account/planning/inspection/:id/details/:locationId"
        path="details/:locationId"
        element={<InspectionDetailsFloor />}
      />
      <Route
        key="routes::account/planning/inspection/:id/details/:locationId/room/:roomId"
        path="details/:locationId/room/:roomId"
        element={<InspectionRoomForm />}
      />
    </Route>,
  ];

  return { routes };
};
