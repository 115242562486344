import {
  ApiAsset,
  ApiBudget,
  ApiInventoryPurchaseOrder,
  ApiLocation,
  ApiVendor,
} from "@operations-hero/lib-api-client";
import * as yup from "yup";

export interface PurchaseOrderFormValues {
  name: string;
  unitCost: number;
  notes: string | null;
  asset: ApiAsset | null;
  budget: ApiBudget | null;
  requestedQuantity: number;
  supplier: ApiVendor | null;
  supplierURL: string | null;
  deliveryLocation: ApiLocation | null;
}

export const defaultInitialValues: PurchaseOrderFormValues = {
  name: "",
  unitCost: 0,
  notes: null,
  asset: null,
  budget: null,
  supplier: null,
  supplierURL: null,
  requestedQuantity: 0,
  deliveryLocation: null,
};

export const getInitialValues = (
  workingPurchaseOrder: ApiInventoryPurchaseOrder
) => {
  const [firstItem] = workingPurchaseOrder.items;

  return {
    name: firstItem.name,
    unitCost: firstItem.unitCost,
    requestedQuantity: firstItem.requestedQuantity,

    notes: workingPurchaseOrder.notes,
    asset: null,
    budget: workingPurchaseOrder.budget,
    supplier: workingPurchaseOrder.supplier,
    supplierURL: workingPurchaseOrder.supplierURL,
    deliveryLocation: workingPurchaseOrder.deliveryLocation,
  } as PurchaseOrderFormValues;
};

const domainRegex = /^(?:https:\/\/)?www\.[a-zA-Z0-9_.-]+\.com\/?$/;

export const purchaseOrderSchema = yup.object().shape({
  name: yup.string().required("Item Name is required").nullable(),
  unitCost: yup
    .number()
    .typeError("Must be a number")
    .min(1, "Must be more than zero")
    .required(),
  supplier: yup.object().required("Supplier is required").nullable(),
  supplierURL: yup
    .string()
    .trim()
    .matches(domainRegex, "Invalid Domain")
    .nullable(),
  requestedQuantity: yup
    .number()
    .typeError("Must be a number")
    .min(1, "Must be more than zero")
    .required(),
  deliveryLocation: yup
    .object()
    .required("Delivery Location is required")
    .nullable(),
});
