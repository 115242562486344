import {
  ApiInspection,
  ApiPagingInspectionOptions,
} from "@operations-hero/lib-api-client";
import { createSlice } from "@reduxjs/toolkit";
import { LoadingStatus } from "../../types";
import { clearFiltersHandler } from "./thunks/clearFilters";
import { importInspectionTemplateHandler } from "./thunks/importInspectionTemplate";
import { loadInspectionsHandler } from "./thunks/loadInspections";
import { updateFiltersHandler } from "./thunks/updateFilters";
import { updateSortFiltersHandler } from "./thunks/updateSortFilters";

export type InspectionListFilters = ApiPagingInspectionOptions;

export type InspectionListSliceProps = {
  inspections: ApiInspection[];
  hasConfigs: boolean;
  filters: InspectionListFilters;
  loadingStatus: LoadingStatus;
  total: number;
};

export const initialState: InspectionListSliceProps = {
  inspections: [],
  loadingStatus: "idle",
  hasConfigs: false,
  filters: {
    page: 1,
    pageSize: 20,
    showUnassigned: true,
    sort: "name",
    direction: "asc",
    search: "",
    status: [],
  },
  total: 0,
};

const inspectionListSlice = createSlice({
  name: "inspection-list",
  initialState,
  reducers: {
    unload: (state) => {
      state.inspections = [];
      state.loadingStatus = "idle";
      state.filters = initialState.filters;
      state.total = 0;
    },
  },
  extraReducers: (builder) => {
    loadInspectionsHandler(builder);
    updateFiltersHandler(builder);
    updateSortFiltersHandler(builder);
    clearFiltersHandler(builder);
    importInspectionTemplateHandler(builder);
  },
});

export const { unload } = inspectionListSlice.actions;
export default inspectionListSlice.reducer;
