import { ApiLocation } from "@operations-hero/lib-api-client";
import { createContext, useContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export type LocationsContextProps = {
  locations: ApiLocation[];
  locationsIds: string[];
  descendantsMap: { [key: string]: string[] };
  locationMap: { [key: string]: ApiLocation };
};

export const LocationsContext = createContext<LocationsContextProps | null>(
  null
);

export function useLocationsContext() {
  const value = useContext(LocationsContext);
  const { locations, descendantsMap, locationMap } = useSelector(
    (state: RootState) => state.localCache
  );
  if (!value)
    return {
      locations,
      descendantsMap,
      locationsIds: Object.keys(locationMap),
      locationMap,
    };
  return value;
}
