import { IconButton } from "@chakra-ui/react";
import { ApiFundingSource } from "@operations-hero/lib-api-client";
import { FC, useCallback } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { useAllocationsContext } from "../../../../../components/allocations/AllocationsContext";
type RemoveAllocationButtonProps = {
  index: number;
  onRemoveAllocation?: () => void;
};

export const RemoveAllocationButton: FC<RemoveAllocationButtonProps> = ({
  index,
  onRemoveAllocation,
}) => {
  const { allocations, setAllocations } =
    useAllocationsContext<ApiFundingSource>();

  const removeAllocation = useCallback(() => {
    const tempList = [...allocations];
    tempList.splice(index, 1);
    setAllocations(tempList);
    onRemoveAllocation && onRemoveAllocation();
  }, [allocations, onRemoveAllocation, index, setAllocations]);

  return (
    <IconButton
      aria-label="delete-allocation"
      size="md"
      onClick={removeAllocation}
      icon={<RiDeleteBinLine />}
      color="gray.500"
    />
  );
};
