import React, { lazy } from "react";
import { Route } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { LocationsByProductProvider as LocationsProvider } from "../../../components/locations/LocationsByProductProvider";
import useReviewersManagement from "../../../hooks/useReviewersManagement";
import { Products } from "../location-list/LocationList";
import SchedulesList from "../scheduled-request-list/ScheduleRequestList";
import { AccountWrapper } from "./AccountRoutesWrapper";

const FieldForm = lazy(() => import("../field-form/FieldForm"));
const FieldList = lazy(() => import("../field-list/FieldList"));
const SchemaForm = lazy(() => import("../schema-form/SchemaForm"));
const SchemaList = lazy(() => import("../schema-list/SchemaList"));
const TaskBooksForm = lazy(
  () => import("../taskbook/taskbook-form/TaskBookForm")
);
const Taskbook = lazy(() => import("../taskbook/taskbook-view/Taskbook"));
const TaskbookList = lazy(
  () => import("../taskbook/taskbook-list/TaskbookList")
);
const WorkflowList = lazy(() => import("../workflow-list/WorkflowList"));
const ReasonList = lazy(() => import("../reason-list/ReasonList"));
const HeroHQSettings = lazy(() => import("../herohq-settings/HeroHQSettings"));
const ReportingCategoryList = lazy(
  () => import("../reporting-category-list/ReportingCategoryList")
);
const WorkflowSettings = lazy(
  () => import("../workflow-settings/WorkflowSettings")
);

export const useRequestRoutes = (hasRequests: boolean) => {
  const product = Products.HeroHQ;
  const { isProductAdmin } = useAuthentication();

  const { showTaskbooksSchedules } = useReviewersManagement();

  if ((!hasRequests || !isProductAdmin) && !showTaskbooksSchedules)
    return { routes: [] };

  if ((!hasRequests || !isProductAdmin) && showTaskbooksSchedules)
    return {
      routes: [
        <Route
          path="taskbooks"
          key="routes::request/taskbooks"
          element={<AccountWrapper Element={TaskbookList} />}
        />,
        <Route
          path="taskbooks/new"
          key="routes::request/taskbooks/new"
          element={<AccountWrapper Element={TaskBooksForm} />}
        />,
        <Route
          path="taskbooks/:id"
          key="routes::request/taskbooks/id"
          element={<AccountWrapper Element={Taskbook} />}
        />,
        <Route
          path="taskbooks/edit/:id"
          key="routes::request/taskbooks/edit/id"
          element={<AccountWrapper Element={TaskBooksForm} />}
        />,
        <Route
          path="scheduled-requests"
          key="routes::request/scheduled-requests"
          element={<AccountWrapper Element={SchedulesList} />}
        />,
      ],
    };

  const routes: React.ReactNode[] = [
    <Route
      path="workflows"
      key="routes::request/worflows"
      element={
        <LocationsProvider product={product}>
          <WorkflowList />
        </LocationsProvider>
      }
    />,
    <Route
      path="workflows/:idOrSlug"
      key="routes::request/worflows/idOrSlug"
      element={
        <LocationsProvider product={product}>
          <WorkflowSettings />
        </LocationsProvider>
      }
    />,
    <Route
      path="reporting-categories"
      key="routes::request/reporting-categories"
      element={<ReportingCategoryList />}
    />,
    <Route
      path="reasons"
      key="routes::request/reasons"
      element={<AccountWrapper Element={ReasonList} />}
    />,
    <Route
      path="schemas"
      key="routes::request/schemas"
      element={<AccountWrapper Element={SchemaList} product={product} />}
    />,
    <Route
      path="schemas/:schemaId"
      key="routes::request/schemas/schemaId"
      element={<AccountWrapper Element={SchemaForm} product={product} />}
    />,
    <Route
      path="fields"
      key="routes::request/fields"
      element={<AccountWrapper Element={FieldList} product={product} />}
    />,
    <Route
      path="fields/:fieldId"
      key="routes::request/fields/fieldId"
      element={<AccountWrapper Element={FieldForm} product={product} />}
    />,
    <Route
      path="taskbooks"
      key="routes::request/taskbooks"
      element={<AccountWrapper Element={TaskbookList} />}
    />,
    <Route
      path="taskbooks/new"
      key="routes::request/taskbooks/new"
      element={<AccountWrapper Element={TaskBooksForm} />}
    />,
    <Route
      path="taskbooks/:id"
      key="routes::request/taskbooks/id"
      element={<AccountWrapper Element={Taskbook} product={product} />}
    />,
    <Route
      path="taskbooks/edit/:id"
      key="routes::request/taskbooks/edit/id"
      element={<AccountWrapper Element={TaskBooksForm} product={product} />}
    />,
    <Route
      path="scheduled-requests"
      key="routes::request/scheduled-requests"
      element={<AccountWrapper Element={SchedulesList} product={product} />}
    />,
    <Route
      path="herohq-settings"
      key="routes::request/herohq-settings"
      element={<AccountWrapper Element={HeroHQSettings} product={product} />}
    />,
  ];

  return { routes };
};
