import { Flex } from "@chakra-ui/react";
import { CreateApiUploadResult } from "@operations-hero/lib-api-client";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Attachment } from "../../../../components/attachments/Attachments";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import {
  ESign,
  ESignValues,
  SignModes,
} from "../../../../components/e-sign/E-Sign";
import { ESignatureViewer } from "../../../../components/e-sign/E-SignatureViewer";
import { ESignTrigger } from "../../../../components/e-sign/E-SignTrigger";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState } from "../../../../store";
import { setDeliverProps } from "../../../../store/planning-hq/inspections/details/inspection-details.slice";

export const SubmitInspectionESignSection: FC = () => {
  const dispatch = useDispatch();
  const showToast = useShowToast();
  const { apiClient, currentAccount } = useAuthentication();
  const { currentUser: user } = useAuthentication();

  const { eSignMode, signature, isSignCanvaOpen, signatureFullName } =
    useSelector((state: RootState) => state.inspectionDetailsSlice.submitProps);

  const handleOnChangeSignMode = useCallback(
    (value: SignModes) => {
      dispatch(setDeliverProps({ eSignMode: value }));
    },
    [dispatch]
  );

  const handleOnSaveESignName = useCallback(
    (value: string | null) => {
      dispatch(setDeliverProps({ signatureFullName: value }));
    },
    [dispatch]
  );

  const handleSetSignature = useCallback(
    async (value: ESignValues | null) => {
      if (!value || value.imageFile === null) {
        dispatch(setDeliverProps({ signature: null }));
        return;
      }
      try {
        const upload: CreateApiUploadResult =
          await apiClient.createUploadAndMoveAttachment(currentAccount.id, {
            file: value.imageFile,
          });
        const fileName = value.imageFile?.name || "";
        const name = `${fileName.split(".")[0]}-${new Date().getTime()}`;
        const newAttachment: Attachment = {
          file: value.imageFile,
          isNew: true,
          url: value.imageURL,
          uploadId: upload.id,
          type: value.imageFile.type,
          created: new Date().toISOString(),
          name,
        };
        dispatch(
          setDeliverProps({ signature: newAttachment, eSignMode: "off" })
        );
      } catch {
        showToast(
          "error",
          "something went wrong uploading your file, please try again!"
        );
      }
    },
    [apiClient, currentAccount.id, dispatch, showToast]
  );

  const handleOpenOrCloseSignCanva = useCallback(
    (value: boolean) => {
      dispatch(setDeliverProps({ isSignCanvaOpen: value }));
    },
    [dispatch]
  );

  return (
    <Flex w="100%" direction="column" gap={4}>
      {eSignMode === "off" && !signature && (
        <ESignTrigger
          handleOnOpenESignMode={() => handleOnChangeSignMode("fullname")}
        />
      )}

      {eSignMode !== "off" && !signature && (
        <Flex w="100%" direction="column" gap={6}>
          <ESign
            signMode={eSignMode}
            isOpenCanvas={isSignCanvaOpen}
            saveFullName={handleOnSaveESignName}
            handleOnSaveImage={handleSetSignature}
            setIsOpenCanvas={handleOpenOrCloseSignCanva}
            handleOnChangeSignMode={handleOnChangeSignMode}
            recipientName={`${user.firstName} ${user.lastName}`}
            border={true}
          />
        </Flex>
      )}

      {eSignMode === "off" && signature && (
        <Flex w="100%" direction="column" gap={6}>
          <ESignatureViewer
            base64Image={signature.url || ""}
            onDelete={() => handleSetSignature(null)}
            fullName={signatureFullName || `${user.firstName} ${user.lastName}`}
          />
        </Flex>
      )}
    </Flex>
  );
};
