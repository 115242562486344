import { Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import { FC } from "react";
import { NumberInputControl } from "../../../../components/form-helpers/NumberInputControl";
import { InventoryOrderItem } from "../../../../store/inventory/inventory-order-item-list.slice";
import { VerifyOrderItemControl } from "../inventory-placed-orders/VerifyOrderItemControl";
import { InventoryOrderValues } from "../InventoryOrderForm";

interface RecieveInventoryOrderRowItemFormProps {
  items: InventoryOrderItem[];
  values: InventoryOrderValues;
}

export const RecieveInventoryOrderRowItemForm: FC<
  RecieveInventoryOrderRowItemFormProps
> = ({ items, values }) => {
  const isDesktop = useBreakpointValue({ xs: false, sm: false, md: true });

  return (
    <>
      {items.map((item, index) => (
        <Flex
          key={`item-order:"${item.item.id}`}
          flexDir="column"
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Flex w="100%" flexDir="row">
            <Flex gap={2} w="42%" alignItems="center">
              <Image boxSize="40px" src={item.item.defaultImage?.url} />
              <Flex flexDir="column" justifyContent="center">
                <Text fontWeight="bold">{item.item.name}</Text>
                {item.item.identifiers.externalId && (
                  <Text>#{item.item.identifiers.externalId}</Text>
                )}
              </Flex>
            </Flex>
            <Flex w="19.3%" flexDir="column" alignItems="center" flexShrink={0}>
              <Text fontSize={isDesktop ? "sm" : "xs"}>Requested</Text>
              <Text
                mt={2}
                display="flex"
                alignItems="center"
                h="36px"
                fontWeight="bold"
              >
                {item.requested}
              </Text>
            </Flex>
            <Flex
              w="19.3%"
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              flexShrink={0}
            >
              <NumberInputControl
                name={`items[${index}].delivered`}
                value={item.delivered}
                label="Delivered"
                labelProps={{
                  fontSize: isDesktop ? "sm" : "xs",
                }}
                max={values.items[index].restBackorder}
                numberInputFieldProps={{
                  maxHeight: "36px",
                  paddingRight: "1rem",
                }}
                inputGroupProps={{ maxWidth: "68px" }}
              />
            </Flex>
            <Flex
              w="19.3%"
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              flexShrink={0}
            >
              <NumberInputControl
                name={`items[${index}].unitCost`}
                value={item.unitCost}
                label="Unit Cost"
                labelProps={{
                  fontSize: isDesktop ? "sm" : "xs",
                }}
                numberInputFieldProps={{
                  maxHeight: "36px",
                  paddingRight: "0.8rem",
                  paddingLeft: "0.8rem",
                }}
                inputGroupProps={{ maxWidth: "92px" }}
                precision={4}
              />
            </Flex>
          </Flex>

          <VerifyOrderItemControl
            name={`items[${index}]`}
            value={values.items[index]}
          />
        </Flex>
      ))}
    </>
  );
};
