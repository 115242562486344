import { Button, ButtonProps, useDisclosure } from "@chakra-ui/react";
import {
  ApiBudget,
  ApiProject,
  CreateApiProjectBudget,
} from "@operations-hero/lib-api-client";
import { FC, useCallback } from "react";
import { RiAddLine } from "react-icons/ri";
import { useAllocationsContext } from "../../../../../components/allocations/AllocationsContext";
import { useAuthentication } from "../../../../../components/auth/AuthProvider";
import { useShowToast } from "../../../../../hooks/showToast";
import { useThunkDispatch } from "../../../../../store";
import { findProjectBudgets } from "../../../../../store/planning-hq/project-page";
import { NewBudgetAllocationModal } from "./NewAllocationModal";

type AddAllocationButtonProps = {
  project: ApiProject;
  buttonProps?: ButtonProps;
};

export const AddAllocationButton: FC<AddAllocationButtonProps> = ({
  project,
  buttonProps,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const toast = useShowToast();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const { setAllocations } = useAllocationsContext<ApiBudget>();

  const thunkDispatch = useThunkDispatch();
  const handleNewAllocationSubmit = useCallback(
    async (allocation: CreateApiProjectBudget) => {
      const newAllocation = await apiClient
        .createProjectBudget(currentAccount.id, project.id, allocation)
        .then((response) => {
          if (!response.success) throw new Error("Funds not allocated");

          thunkDispatch(
            findProjectBudgets({
              apiClient,
              projectId: project.id,
            })
          )
            .unwrap()
            .then((response) => {
              setAllocations(
                response.map((alloc) => ({
                  source: {
                    ...(alloc.budget as ApiBudget),
                    unallocatedAmount: (alloc.budget as ApiBudget)
                      .unallocatedFunds,
                  },
                  amount: alloc.amountAllocated,
                }))
              );
            });
          toast("success", "Budget funds allocated successfully");
          return response;
        })
        .catch((e) => {
          toast("error", "An error occured while allocating funds");
        })
        .finally(() => {
          onClose();
        });
      return newAllocation;
    },
    [
      apiClient,
      project.id,
      currentAccount.id,
      onClose,
      setAllocations,
      thunkDispatch,
      toast,
    ]
  );

  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={<RiAddLine />}
        colorScheme="blue"
        variant="outline"
        size="sm"
        {...buttonProps}
      >
        Add Budget Funds
      </Button>

      <NewBudgetAllocationModal
        project={project}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleNewAllocationSubmit}
      />
    </>
  );
};
