import { Button, Grid, GridItem } from "@chakra-ui/react";
import {
  ApiInventoryPurchaseOrder,
  ApiLocation,
  ApiPurchaseOrderAttachmentType,
  ApiRequest,
} from "@operations-hero/lib-api-client";
import axios, { AxiosProgressEvent } from "axios";
import { Form, Formik, FormikProps } from "formik";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Attachment,
  Attachments,
  mapApiAttachments,
} from "../../../../components/attachments/Attachments";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { BudgetAutocompleteControl } from "../../../../components/form-helpers/BudgetAutocompleteControl";
import { LocationAutocompleteControl } from "../../../../components/form-helpers/LocationAutocompleteControl";
import { NumberInputControl } from "../../../../components/form-helpers/NumberInputControl";
import { ProjectBudgetsAutocompleteControl } from "../../../../components/form-helpers/ProjectBudgetsAutocompleteControl";
import { TextAreaControl } from "../../../../components/form-helpers/TextareaControl";
import { TextInputControl } from "../../../../components/form-helpers/TextInputControl";
import { VendorAutocompleteControl } from "../../../../components/form-helpers/VendorsAutocompleteControl";
import {
  defaultInitialValues,
  getInitialValues,
  PurchaseOrderFormValues,
  purchaseOrderSchema,
} from "./purchaseOrderFormHelper";

interface PurchaseOrderFormProps {
  isLoading?: boolean;
  handleOnCancel: () => void;
  workingPurchaseOrder?: ApiInventoryPurchaseOrder | null;
  hideButtons?: boolean;
  formikRef?: React.RefObject<FormikProps<PurchaseOrderFormValues>>;
  deliveryLocation?: ApiLocation | null;
  handleOnSave: (
    values: PurchaseOrderFormValues,
    initialValues: PurchaseOrderFormValues,
    attachments?: Attachment[],
    attachmentsToRemove?: Attachment[]
  ) => void;
  request?: ApiRequest | null;
}

export const PurchaseOrderForm: FC<PurchaseOrderFormProps> = ({
  formikRef,
  isLoading,
  hideButtons,
  handleOnSave,
  handleOnCancel,
  deliveryLocation,
  workingPurchaseOrder,
  request = null,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [attachmentsToRemove, setAttachmentsToRemove] = useState<Attachment[]>(
    []
  );
  const [associatedRequest, setAssociatedRequest] = useState<ApiRequest | null>(
    request
  );

  const initialValues = useMemo(() => {
    if (workingPurchaseOrder) {
      return getInitialValues(workingPurchaseOrder);
    }

    if (deliveryLocation) {
      return { ...defaultInitialValues, deliveryLocation };
    }

    return defaultInitialValues;
  }, [deliveryLocation, workingPurchaseOrder]);

  const handleOnSubmit = useCallback(
    (values: PurchaseOrderFormValues) => {
      handleOnSave(values, initialValues, attachments, attachmentsToRemove);
    },
    [attachments, attachmentsToRemove, handleOnSave, initialValues]
  );

  const addAttachment = useCallback(
    (att: Attachment) => {
      if (attachments.some((x) => x.uploadId === att.uploadId)) {
        return attachments;
      }
      const attachmentsCopy = [...attachments];
      attachmentsCopy.push(att);
      setAttachments(attachmentsCopy);
      return attachmentsCopy;
    },
    [attachments]
  );

  const updateAttachment = useCallback(
    (att: Attachment, attachments: Attachment[]) => {
      const index = attachments.findIndex(
        (x) => x.isNew === true && x.uploadId === att.uploadId
      );

      if (index !== -1) {
        const attachmentsCopy = [...attachments];
        attachmentsCopy[index] = att;
        setAttachments(attachmentsCopy);
      }
    },
    []
  );

  const handleNewFiles = useCallback(
    (files: Attachment[]) => {
      files.forEach((file) => {
        if (!file.file) {
          return;
        }

        apiClient
          .createUpload(currentAccount.id)
          .then((uploadResult) => {
            const newAttachment: Attachment = {
              ...file,
              isUploading: true,
              uploadId: uploadResult.id,
              progress: 0,
            };
            const allAttachments = addAttachment(newAttachment);
            return {
              attachment: newAttachment,
              upload: uploadResult,
              allAttachments,
            };
          })
          .then(async ({ attachment, upload, allAttachments }) => {
            return axios
              .put(upload.url, attachment.file, {
                headers: { "Content-Type": attachment.file!.type },
                onUploadProgress: (progressEvent: AxiosProgressEvent) =>
                  updateAttachment(
                    {
                      ...attachment,
                      progress: Math.round(
                        (progressEvent.loaded * 100) /
                          (progressEvent.total || 1)
                      ),
                    },
                    allAttachments
                  ),
              })
              .then(() => ({ attachment, upload, allAttachments }));
          })
          .then(({ attachment, upload, allAttachments }) => {
            const updated: Attachment = {
              ...attachment,
              isUploading: false,
              progress: undefined,
            };
            updateAttachment(updated, allAttachments);
          });
      });
    },
    [addAttachment, apiClient, currentAccount.id, updateAttachment]
  );

  const handleRemoveFile = useCallback(
    async (att: Attachment) => {
      const index = attachments.findIndex((x) => x.name === att.name);

      if (index === -1) {
        return;
      }

      if (workingPurchaseOrder && att.id && !att.isNew) {
        setAttachmentsToRemove([...attachmentsToRemove, att]);
      }

      const attachmentsCopy = [...attachments];
      attachmentsCopy.splice(index, 1);
      setAttachments(attachmentsCopy);
      return;
    },
    [attachments, attachmentsToRemove, workingPurchaseOrder]
  );

  const loadAssociatedRequest = useCallback(
    (associatedRequestKey: string) => {
      try {
        apiClient
          .getRequest(currentAccount.id, associatedRequestKey)
          .then((result) => setAssociatedRequest(result));
      } catch (e) {
        if (formikRef && formikRef.current) {
          formikRef.current
            .getFieldHelpers("budget")
            .setError("Not valid budget");
        }

        console.error(
          "error loading the associated request for purchase order"
        );
      }
    },
    [apiClient, currentAccount.id, formikRef]
  );

  useEffect(() => {
    if (workingPurchaseOrder) {
      apiClient
        .findInventoryPurchaseOrderAttachments(
          currentAccount.id,
          workingPurchaseOrder.id,
          { type: ApiPurchaseOrderAttachmentType.purchaseOrderItem }
        )
        .then((res) => {
          const storedAttachments = mapApiAttachments(res.data);
          setAttachments(storedAttachments);
        });
    }
  }, [apiClient, currentAccount.id, workingPurchaseOrder]);

  useEffect(() => {
    if (!workingPurchaseOrder) return;

    const { requestKey: associatedRequestKey } = workingPurchaseOrder;
    if (!associatedRequestKey) return;

    if (associatedRequest !== null) return;

    // load the associated request if a request was not provided
    loadAssociatedRequest(associatedRequestKey);
  }, [workingPurchaseOrder, associatedRequest, loadAssociatedRequest]);

  return (
    <Formik
      innerRef={formikRef}
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      validationSchema={purchaseOrderSchema}
    >
      {({ values }) => {
        return (
          <Form>
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
              <GridItem colSpan={12}>
                <TextInputControl
                  name="name"
                  label="Item Name"
                  value={values.name}
                />
              </GridItem>

              <GridItem colSpan={6}>
                <NumberInputControl
                  prefix="$"
                  label="Price"
                  name="unitCost"
                  value={values.unitCost}
                  showStepper={false}
                />
              </GridItem>

              <GridItem colSpan={6}>
                <NumberInputControl
                  label="Quantity"
                  showStepper={true}
                  name="requestedQuantity"
                  value={values.requestedQuantity}
                />
              </GridItem>

              <GridItem colSpan={12}>
                <VendorAutocompleteControl
                  isSupplier
                  isSupplierOnly
                  name="supplier"
                  label="Supplier"
                  value={values.supplier}
                />
              </GridItem>

              <GridItem colSpan={12}>
                <TextInputControl
                  name="supplierURL"
                  label="Supplier URL"
                  placeholder="www.grainger.com"
                  value={values.supplierURL}
                />
              </GridItem>

              <GridItem colSpan={12}>
                <LocationAutocompleteControl
                  name="deliveryLocation"
                  label="Delivery Location"
                  value={values.deliveryLocation}
                />
              </GridItem>

              <GridItem colSpan={12}>
                {associatedRequest && associatedRequest.projectId ? (
                  <ProjectBudgetsAutocompleteControl
                    name="budget"
                    label="Budget (Optional)"
                    value={values.budget}
                    projectId={associatedRequest.projectId}
                  />
                ) : (
                  <BudgetAutocompleteControl
                    name="budget"
                    label="Budget (Optional)"
                    value={values.budget}
                  />
                )}
              </GridItem>

              <GridItem colSpan={12}>
                <TextAreaControl
                  name="notes"
                  label="Notes (Optional)"
                  value={values.notes || ""}
                />
              </GridItem>

              <GridItem colSpan={12}>
                <Attachments
                  attachments={attachments}
                  onDeleteAttachment={handleRemoveFile}
                  onNewAttachments={handleNewFiles}
                />
              </GridItem>

              {!hideButtons && (
                <GridItem
                  colSpan={12}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Button
                    size="sm"
                    variant="ghost"
                    colorScheme="blue"
                    onClick={handleOnCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    type="submit"
                    isLoading={isLoading}
                  >
                    {workingPurchaseOrder ? "Save" : "Add"} Purchase Order
                  </Button>
                </GridItem>
              )}
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
