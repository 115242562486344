import { Checkbox, Flex, Heading, HStack, usePrevious } from "@chakra-ui/react";
import { ApiFrequency, ApiWeeksOfMonth } from "@operations-hero/lib-api-client";
import { SingleValue } from "chakra-react-select";
import { isEqual } from "date-fns";
import { FC, useCallback, useEffect, useReducer, useState } from "react";
import { ActionTypeEnum, CronReducer } from "./cron-helpers/CronHelper";
import {
  CronBoxOption,
  getInitialStateFromCronString,
} from "./cron-helpers/CronOptions";
import { RecurrenceSelect } from "./cron-selects/RecurrenceSelect";
import { RepeatsOnSelect } from "./cron-selects/RepeatOnSelect";
import { CronOptions } from "./CronBoxOptions";
import { CronNumberInput } from "./CronNumberInput";

export interface InitialCustomCronProps {
  initialCronExpression: string;
  initialFrecuency: ApiFrequency;
  initialExecuteEvery: number;
  weekOfMonth: ApiWeeksOfMonth;
}

export interface CronValues {
  cronExpression: string;
  frequency: ApiFrequency;
  executeEvery: number;
  weekOfMonth: ApiWeeksOfMonth;
}

interface CustomCronGeneratorProps {
  selectedStartDate: Date;
  initialCustomCronValues: InitialCustomCronProps;
  withWeeklyOptions: boolean;
  getCronExpression?: (cronValues: CronValues) => void;
  disabledDays?: string;
  allowedDays?: number[];
  type?: "standard" | "sliding";
}

export const CustomCronGenerator: FC<CustomCronGeneratorProps> = ({
  selectedStartDate,
  initialCustomCronValues,
  withWeeklyOptions: withWeklyOptions,
  getCronExpression,
  disabledDays,
  allowedDays,
  type = "standard",
}) => {
  const prevType = usePrevious(type);
  const prevSelectedDate = usePrevious(new Date(selectedStartDate));
  const [displayMonths, setDisplayMonths] = useState(
    !initialCustomCronValues?.initialCronExpression
      ?.split(" ")[3]
      ?.includes("*")
  );

  const [state, cronDispatch] = useReducer(
    CronReducer,
    getInitialStateFromCronString(initialCustomCronValues, selectedStartDate)
  );

  const handleToggleDay = useCallback((selectedDay: CronBoxOption) => {
    cronDispatch({ type: ActionTypeEnum.ToggleDay, payload: selectedDay });
  }, []);

  const handleToggleMonth = useCallback((selectedMonth: CronBoxOption) => {
    cronDispatch({
      type: ActionTypeEnum.ToggleMonth,
      payload: selectedMonth,
    });
  }, []);

  const handleChangeRecurrence = useCallback(
    (recurrenceOption: SingleValue<CronBoxOption>) => {
      cronDispatch({
        type: ActionTypeEnum.SetRecurrence,
        payload: {
          option: recurrenceOption,
          selectedDateStr: selectedStartDate
            ? selectedStartDate.toISOString()
            : null,
        },
      });
    },
    [selectedStartDate]
  );

  const handleOnChangeRepeatsOn = useCallback(
    (option: SingleValue<CronBoxOption>) => {
      cronDispatch({
        type: ActionTypeEnum.SetRepeatsOn,
        payload: {
          option,
          selectedDateStr: selectedStartDate
            ? selectedStartDate.toISOString()
            : null,
        },
      });
    },
    [selectedStartDate]
  );

  const handleOnChangeExecuteEvery = useCallback((value: number) => {
    cronDispatch({
      type: ActionTypeEnum.SetExecuteEvery,
      payload: value,
    });
  }, []);

  useEffect(() => {
    if (state.recurrence) {
      getCronExpression &&
        getCronExpression({
          cronExpression: state.cronExpression,
          frequency: state.recurrence.value as ApiFrequency,
          executeEvery: state.executeEvery,
          weekOfMonth: state.weekOfMonth,
        });
    }
  }, [
    state.cronExpression,
    state.recurrence,
    state.executeEvery,
    state.weekOfMonth,
    getCronExpression,
  ]);

  useEffect(() => {
    if (prevType && prevType !== type) {
      const recurrenceOption =
        type === "standard"
          ? { value: "weekly", label: "Week(s)" }
          : { value: "daily", label: "Day(s)" };

      cronDispatch({
        type: ActionTypeEnum.SetRecurrencePartial,
        payload: {
          option: recurrenceOption,
        },
      });
    }
  }, [prevType, selectedStartDate, type]);

  useEffect(() => {
    if (prevSelectedDate && !isEqual(prevSelectedDate, selectedStartDate)) {
      cronDispatch({
        type: ActionTypeEnum.SetCronHourMinuteAndWeekOfMonth,
        payload: {
          selectedDateStr: selectedStartDate.toISOString(),
          weekOfMonth: initialCustomCronValues.weekOfMonth,
        },
      });
    }
  }, [
    selectedStartDate,
    prevSelectedDate,
    initialCustomCronValues.initialCronExpression,
    initialCustomCronValues.weekOfMonth,
  ]);

  return (
    <Flex flexDir="column" gap={4} w="100%">
      <Flex
        justifyContent={[
          "flex-start",
          "flex-start",
          "flex-start",
          "space-between",
        ]}
        w="100%"
      >
        <Heading size="sm" fontWeight="semibold">
          Recurrence
        </Heading>
      </Flex>
      <Flex alignItems="flex-end" gap={4} w="100%" flexWrap="wrap">
        <CronNumberInput
          min={1}
          max={99}
          name="repeatEvery"
          label={type === "sliding" ? "Repeat After" : "Repeat Every"}
          value={state.executeEvery}
          numberInputProps={{ maxW: "90px" }}
          onChange={handleOnChangeExecuteEvery}
        />

        <RecurrenceSelect
          value={state.recurrence}
          onRecurrenceChange={handleChangeRecurrence}
        />

        {state.recurrence?.value === "monthly" && type === "standard" && (
          <Flex>
            <RepeatsOnSelect
              value={state.repeatsOn}
              withWeklyOptions={withWeklyOptions}
              selectedStartDate={selectedStartDate}
              handleChangeRepeat={handleOnChangeRepeatsOn}
            />
          </Flex>
        )}
      </Flex>

      {(state.recurrence?.value === "weekly" ||
        state.recurrence?.value === "monthly") &&
        type === "standard" && (
          <Flex flexDir="column" gap={4} w="100%">
            <CronOptions
              label="On days"
              options={state.days}
              enabledDay={disabledDays}
              allowedDays={allowedDays}
              onChangeOption={handleToggleDay}
            />
          </Flex>
        )}

      <Checkbox
        fontSize="xs"
        isChecked={displayMonths}
        onChange={() => setDisplayMonths(!displayMonths)}
      >
        Exclude months
      </Checkbox>

      {displayMonths && (
        <HStack
          flexDir="column"
          alignItems="flex-start"
          justifyItems="flex-start"
        >
          <CronOptions
            options={state.months}
            onChangeOption={handleToggleMonth}
          />
        </HStack>
      )}
    </Flex>
  );
};
