import {
  Avatar,
  AvatarGroup,
  Box,
  Divider,
  Flex,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ApiProject } from "@operations-hero/lib-api-client";
import { FC, useMemo } from "react";
import { StatusBadge } from "../../../../components/badges/StatusBadge";
import { UserBadge } from "../../../../components/badges/UserBadge";
import { LocaleDate } from "../../../../components/dates/LocaleDate";
type ProjectCardProps = { project: ApiProject };
export const ProjectCard: FC<ProjectCardProps> = ({ project }) => {
  const {
    start,
    end,
    supervisors,
    name,
    status,
    totalBudgets,
    funds,
    fundsRemaining,
    fundsSpent,
  } = project;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const durationInDays = useMemo(() => {
    const startDate = start ? new Date(start) : null;
    const endDate = end ? new Date(end) : null;
    let durationInDays: string | number = "";
    if (startDate && endDate) {
      const durationInTime = endDate.getTime() - startDate.getTime();
      durationInDays = Math.round(durationInTime / (1000 * 3600 * 24));
    }
    return durationInDays;
  }, [start, end]);

  const Assignee = () => {
    return (
      <Box w="fit-content">
        {supervisors.length === 0 && <Text> - </Text>}
        {supervisors.length === 1 && <UserBadge value={supervisors[0]} />}
        {supervisors.length > 1 && (
          <AvatarGroup max={3} size="xs" spacing={-1}>
            {supervisors.map((a) => (
              <Avatar
                key={a.id}
                src={a.profileImage}
                name={`${a.firstName} ${a.lastName}`}
              />
            ))}
          </AvatarGroup>
        )}
      </Box>
    );
  };
  return (
    <Flex
      w="full"
      boxShadow="md"
      borderRadius="md"
      align="stretch"
      p={4}
      flexDir="column"
      gap={2}
    >
      <Flex
        w="full"
        justifyContent="space-between"
        roundedTop="md"
        height="fit-content"
      >
        <Text colorScheme="blue" fontSize="md" fontWeight="bold" isTruncated>
          {name}
        </Text>
        <StatusBadge status={status} badgeProps={{ width: "fit-content" }} />
      </Flex>
      <Divider />
      <Flex w="full" justify="space-between" alignItems="center">
        <Assignee />
        <VStack w="fit-content" alignItems="end">
          <Text>{totalBudgets} budgets</Text>
        </VStack>
      </Flex>
      <Flex w="full" justify="space-between" alignItems="center">
        <Flex flexDir="column" height="full">
          <Text fontWeight="semibold">
            Total Funds:
            <Text as="span" fontWeight="normal" ml={1}>
              {formatter.format(funds)}
            </Text>
          </Text>
          <Text fontWeight="semibold">
            Spent:
            <Text as="span" fontWeight="normal" ml={1}>
              {formatter.format(fundsSpent)}
            </Text>
          </Text>
          <Text fontWeight="semibold">
            Remaning:
            <Text as="span" fontWeight="normal" ml={1}>
              {formatter.format(fundsRemaining)}
            </Text>
          </Text>
        </Flex>
        <Flex flexDir="column" height="full" alignItems="end">
          <Text fontWeight="semibold">
            Start:{" "}
            <Text as="span" fontWeight="normal" ml={1}>
              {start ? (
                <LocaleDate
                  date={start}
                  options={{
                    month: "long",
                    day: undefined,
                    year: "numeric",
                  }}
                />
              ) : (
                "-"
              )}
            </Text>
          </Text>
          <Text fontWeight="semibold">
            End:{" "}
            <Text as="span" fontWeight="normal" ml={1}>
              {end ? (
                <LocaleDate
                  date={end}
                  options={{
                    month: "long",
                    day: undefined,
                    year: "numeric",
                  }}
                />
              ) : (
                "-"
              )}
            </Text>
          </Text>
          <Text fontWeight="semibold">
            Duration:
            <Text as="span" fontWeight="normal" ml={1}>
              {typeof durationInDays === "number"
                ? `${durationInDays} days`
                : "-"}
            </Text>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
