import {
  ApiClient,
  ApiInspectionConfig,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../..";
import { InspectionListSliceProps } from "../inspection-list.slice";

type ImportInspectionTemplateParams = {
  apiClient: ApiClient;
  templateKey: string;
};

export const importInspectionTemplate = createAsyncThunk<
  ApiInspectionConfig,
  ImportInspectionTemplateParams
>(
  "inspection-template/import",
  async ({ apiClient, templateKey }, thunkAPI) => {
    const { auth } = thunkAPI.getState() as RootState;
    const { currentAccount } = auth;

    const config = await apiClient.importInspectionTemplate(
      currentAccount.id,
      templateKey
    );

    return config;
  }
);

export const importInspectionTemplateHandler = (
  builder: ActionReducerMapBuilder<InspectionListSliceProps>
) => {
  builder.addCase(importInspectionTemplate.fulfilled, (state, action) => {
    state.hasConfigs = true;
  });
};
