import { Flex, Heading, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { FC } from "react";
import { FaSignature } from "react-icons/fa";

interface ESignTriggerProps {
  handleOnOpenESignMode: () => void;
}

export const ESignTrigger: FC<ESignTriggerProps> = ({
  handleOnOpenESignMode,
}) => {
  const signBorderColor = useColorModeValue("blue.500", "blue.300");

  return (
    <Flex flexDir="column" gap={4} w="100%" onClick={handleOnOpenESignMode}>
      <Heading
        gap={2}
        size="md"
        display="flex"
        flexDir="column"
        fontWeight="bold"
      >
        E-Signature
        <Text fontSize="sm" fontWeight="normal">
          Order received by*
        </Text>
      </Heading>
      <Flex
        p={4}
        cursor="pointer"
        borderRadius={8}
        border="2px dashed"
        justifyContent="center"
        borderColor={signBorderColor}
      >
        <Flex gap={4} flexDir="row" alignItems="center">
          <Icon as={FaSignature} boxSize="32px" color={signBorderColor} />
          <Flex flexDir="column">
            <Text fontWeight="bold" color={signBorderColor}>
              Touch here to sign
            </Text>
            <Text>and confirm delivery order</Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
