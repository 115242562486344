import {
  Button,
  Icon,
  IconButton,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiInspection,
  ApiInspectionStatus,
} from "@operations-hero/lib-api-client";
import { FC, MouseEvent, useCallback, useMemo } from "react";
import { BsPrinter } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  InspectionRoomEntry,
  LocationRoomStatus,
} from "../../../../store/planning-hq/inspections/details/inspection-details.slice";

interface InspectionRoomButtonsProps {
  inspection: ApiInspection;
  room: InspectionRoomEntry;
}

export const InspectionRoomButtons: FC<InspectionRoomButtonsProps> = ({
  inspection,
  room,
}) => {
  const navigate = useNavigate();
  const { locationId, id } = useParams<{ locationId: string; id: string }>();
  const approveButtonColor = useColorModeValue("blue.600", "blue.200");
  const isLarge = useBreakpointValue({
    base: false,
    sm: false,
    md: false,
    lg: true,
    xl: true,
  });
  const { pathname } = useLocation();

  const isInspectorView = useMemo(
    () => !pathname.includes("/account"),
    [pathname]
  );

  const handleInspectClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (id && locationId) {
        navigate(`${pathname}/room/${room.location.id}`);
      }
    },
    [navigate, locationId, id, room, pathname]
  );

  if (!isInspectorView) {
    return (
      <Stack direction="row" spacing={2}>
        <Button
          variant="outline"
          display="flex"
          height="32px"
          padding="5px 18px"
          justifyContent="center"
          alignItems="center"
          gap="8px"
          borderRadius="4px"
          border="1px solid"
          borderColor={approveButtonColor}
          w="fit-content"
          size={isLarge ? "sm" : "xs"}
          onClick={handleInspectClick}
          isDisabled={room.status === LocationRoomStatus.pending}
        >
          <Text>View</Text>
        </Button>
        <IconButton
          size="sm"
          boxSize="32px"
          variant="outline"
          aria-label="print"
          colorScheme="blue"
          alignSelf="flex-end"
          icon={<Icon as={BsPrinter} />}
          isDisabled={room.status === LocationRoomStatus.pending}
        />
      </Stack>
    );
  }
  if (inspection.status === ApiInspectionStatus.submitted) {
    return (
      <IconButton
        size="sm"
        boxSize="32px"
        variant="outline"
        aria-label="print"
        colorScheme="blue"
        alignSelf="flex-end"
        icon={<Icon as={BsPrinter} />}
      />
    );
  }

  if (room.status === LocationRoomStatus.pending) {
    return (
      <Button
        variant="outline"
        display="flex"
        height="32px"
        padding="5px 18px"
        justifyContent="center"
        alignItems="center"
        gap="8px"
        borderRadius="4px"
        border="1px solid"
        borderColor={approveButtonColor}
        w="85px"
        size={isLarge ? "sm" : "xs"}
        onClick={handleInspectClick}
      >
        <Text>Inspect</Text>
      </Button>
    );
  }
  return (
    <Stack direction="row" spacing={2}>
      <Button
        variant="outline"
        display="flex"
        height="32px"
        padding="5px 18px"
        justifyContent="center"
        alignItems="center"
        gap="8px"
        borderRadius="4px"
        border="1px solid"
        borderColor={approveButtonColor}
        w="fit-content"
        size={isLarge ? "sm" : "xs"}
        onClick={handleInspectClick}
      >
        <Text>Edit</Text>
      </Button>
      <IconButton
        size="sm"
        boxSize="32px"
        variant="outline"
        aria-label="print"
        colorScheme="blue"
        alignSelf="flex-end"
        icon={<Icon as={BsPrinter} />}
      />
    </Stack>
  );
};
