export interface MenuType {
  route: string;
  text: string;
}

export const accountsMenu: MenuType[] = [
  { route: "/account/profile", text: "Account Profile" },
  {
    route: "/account/announcement",
    text: "System Announcement",
  },
  { route: "/account/users", text: "Users" },
  { route: "/account/user-groups", text: "Groups" },
  { route: "/account/locations", text: "Locations" },
  { route: "/account/locations-gallery", text: "Locations Gallery" },
  { route: "/account/assets", text: "Assets" },
  { route: "/account/budgets", text: "Budgets" },
  { route: "/account/vendors", text: "Vendors" },
];

export const requestHQMenu: MenuType[] = [
  {
    route: "/account/herohq-settings",
    text: "HeroHQ Settings",
  },
  { route: "/account/workflows", text: "Workflows" },
  { route: "/account/schemas", text: "Schemas" },
  { route: "/account/fields", text: "Fields" },
  { route: "/account/reasons", text: "Reasons" },
  {
    route: "/account/reporting-categories",
    text: "Categories",
  },

  {
    route: "/account/scheduled-requests",
    text: "Schedules",
  },
  { route: "/account/taskbooks", text: "Task Books" },
];

export const requestHQMenuReviewers: MenuType[] = [
  {
    route: "/account/scheduled-requests",
    text: "Schedules",
  },
  { route: "/account/taskbooks", text: "Task Books" },
];

export const eventsHQMenu: MenuType[] = [
  {
    route: "/account/event-settings",
    text: "Event Settings",
  },
  { route: "/account/venues", text: "Venues" },
  { route: "/account/questions", text: "Questions" },
  { route: "/account/services", text: "Services" },
  {
    route: "/account/rentable-equipment",
    text: "Equipment",
  },
  {
    route: "/account/event-groups",
    text: "Event Groups",
  },
  {
    route: "/account/rate-groups",
    text: "Rate Groups",
  },
  {
    route: "/account/block-venues",
    text: "Blocks",
  },
];

export const inventoryHQMenu: MenuType[] = [
  {
    route: "/inventory/settings",
    text: "Inventory Settings",
  },
  {
    route: "/inventory/locations",
    text: "Storage Locations",
  },
  {
    route: "/inventory/catalogs",
    text: "Catalogs",
  },
];
export const planningHQMenu: MenuType[] = [
  {
    route: "/account/planning/inspection",
    text: "Inspections",
  },
];
