import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiLocation,
  ApiLocationRoom,
  ApiLocationType,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { Field, FieldArray, Form, Formik } from "formik";
import { FC, useCallback, useMemo } from "react";
import { BsTrash } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { SelectControl } from "../../../components/form-helpers/SelectControl";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import { reloadLocations } from "../../../store/local-cache.slice";
import { useLocationUtils } from "../../../utils/locationUtils";
import { SectionAndRoomsAccordianState } from "../../planning-hq/inspection/components/FloorAccordion";

export type RoomProps = {
  locationId: string;
  inspectionId: string;
  onClose: () => void;
  reloadInspectionDetails?: () => void;
  initialData?: SectionAndRoomsAccordianState;
};

interface InitialValues {
  section: string | null;
  rooms: { id: string | null; name: string; squareFeet: number | null }[];
}

export const MiniRoomForm: FC<RoomProps> = ({
  locationId,
  inspectionId,
  onClose,
  reloadInspectionDetails,
  initialData,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const { locationMap } = useSelector((state: RootState) => state.localCache);
  const iconsColor = useColorModeValue("gray.500", "gray.300");
  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const { findFirstLevelChildren } = useLocationUtils();

  const options = useMemo(() => {
    if (locationId === "all-rooms") {
      return [];
    }
    return findFirstLevelChildren(locationId)
      .filter((loc) => loc.type === ApiLocationType.floor)
      .map((loc) => ({
        label: loc.name,
        value: loc.id,
      }));
  }, [locationId, findFirstLevelChildren]);

  const roomMock: ApiLocation = useMemo(
    () => ({
      id: "",
      name: "",
      squareFeet: null,
      type: ApiLocationType.room,
      colorId: "#FFFFFF",
      externalId: null,
      active: true,
      parent: null,
      phone: null,
      website: null,
      built: null,
      lastRenovated: null,
      capacity: null,
      labels: [],
      treePath: "",
      hiddenProducts: [],
    }),
    []
  );

  const initialValues: InitialValues = useMemo(
    () =>
      initialData
        ? {
            section:
              options.find(
                (val) => val.value === initialData.section.location.id
              )?.value ||
              options[0]?.value ||
              null,
            rooms: initialData.rooms.map((room) => ({
              id: room.location.id,
              name: room.location.name || "",
              squareFeet: (room.location as ApiLocationRoom).squareFeet || null,
            })),
          }
        : {
            section: null,
            rooms: [{ id: null, name: "", squareFeet: null }],
          },
    [options, initialData]
  );

  const saveLocation = useCallback(
    (values: InitialValues) => {
      const sectionPromises = values.rooms.map((room) => {
        if (room.name && room.id !== "all-rooms") {
          const roomPromise = room.id
            ? apiClient.updateLocation(currentAccount.id, room.id, {
                ...locationMap[room.id],
                name: room.name,
                //@ts-ignore
                squareFeet: room.squareFeet || 0,
              })
            : apiClient.createLocation(currentAccount.id, {
                ...roomMock,
                name: room.name,
                squareFeet: room.squareFeet,
                parent:
                  values.section || locationId === "all-rooms"
                    ? null
                    : locationId,
              });

          return roomPromise;
        }
        return Promise.resolve();
      });

      Promise.all(sectionPromises)
        .then(() => apiClient.updateRoomCount(currentAccount.id, inspectionId))
        .then(() => {
          showToast("success", "All rooms have been added successfully");
        })
        .catch((e) => {
          console.error(e);
          showToast("error", "Error Saving Rooms");
        })
        .finally(() => {
          thunkDispatch(reloadLocations({ apiClient, account: currentAccount }))
            .then(unwrapResult)
            .then(() => {
              if (reloadInspectionDetails) {
                reloadInspectionDetails();
              }
              onClose();
            });
        });
    },
    [
      apiClient,
      showToast,
      onClose,
      thunkDispatch,
      currentAccount,
      reloadInspectionDetails,
      roomMock,
      locationMap,
      locationId,
      inspectionId,
    ]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={saveLocation}>
      {({ values, setFieldValue }) => (
        <Form>
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            {locationMap[locationId].name}
          </Text>

          <FormControl mb={4}>
            <FormLabel>Section</FormLabel>
            <Field
              name="section"
              component={SelectControl}
              placeholder="Select Section (optional)"
              options={options}
              isDisabled={Boolean(initialData)}
              allowEmpty
            />
          </FormControl>
          <FieldArray name="rooms">
            {({ remove, push }) => (
              <Box>
                <FormLabel>Rooms</FormLabel>
                {values.rooms.map((room, index) => (
                  <Stack direction="row" spacing={4} key={index} mb={2}>
                    <Field
                      name={`rooms[${index}].name`}
                      as={Input}
                      placeholder="Room Name"
                      value={room.name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const newRooms = [...values.rooms];
                        newRooms[index].name = e.target.value;
                        setFieldValue("rooms", newRooms);
                      }}
                    />
                    <Field
                      name={`rooms[${index}].squareFeet`}
                      as={Input}
                      placeholder="Square Feet (Optional)"
                      value={room.squareFeet || ""}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const newRooms = [...values.rooms];
                        const parsedValue = parseFloat(e.target.value);
                        newRooms[index].squareFeet = isNaN(parsedValue)
                          ? null
                          : parsedValue;
                        setFieldValue("rooms", newRooms);
                      }}
                    />
                    <IconButton
                      icon={<BsTrash />}
                      onClick={() => {
                        remove(index);
                      }}
                      aria-label="remove-room"
                      color={iconsColor}
                      isDisabled={Boolean(room.id)}
                    />
                  </Stack>
                ))}
                <Button
                  variant="outline"
                  colorScheme="blue"
                  onClick={() => push({ name: "", squareFeet: null })}
                  mb={4}
                >
                  + Add Room
                </Button>
              </Box>
            )}
          </FieldArray>
          <Stack
            direction="row"
            spacing={4}
            justifyContent="space-between"
            mt={4}
          >
            <Button variant="outline" colorScheme="gray" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              type="submit"
              isDisabled={!values.rooms[0]?.name}
            >
              Save
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
