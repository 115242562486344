import { Divider, Flex, Square, Text } from "@chakra-ui/react";
import { ApiFundingSource } from "@operations-hero/lib-api-client";
import moment from "moment";
import { FC } from "react";
import { LocaleDate } from "../../../components/dates/LocaleDate";
import { formatCurrency } from "../../../utils/formatCurrency";

const FISCAL_YEAR_FORMAT = "MMMM YYYY" as const;

type FundingSourceCardProps = {
  fundingSource: ApiFundingSource;
};

export const FundingSourceCard: FC<FundingSourceCardProps> = ({
  fundingSource,
}) => {
  const {
    colorId,
    name,
    contactName,
    contactPosition,
    fiscalYearStart,
    fiscalYearEnd,
    funds,
    allocatedFunds,
    unallocatedFunds,
    updated,
  } = fundingSource;

  const fiscalYearFormatted = ` ${moment(new Date(fiscalYearStart)).format(
    FISCAL_YEAR_FORMAT
  )} - ${moment(new Date(fiscalYearEnd)).format(FISCAL_YEAR_FORMAT)} `;

  return (
    <Flex
      w="full"
      boxShadow="md"
      borderRadius="md"
      align="stretch"
      p={4}
      flexDir="column"
      gap={2}
    >
      <Flex w="full" gap={2} roundedTop="md" height="fit-content">
        <Square
          rounded="md"
          size="25px"
          bg={colorId}
          cursor={"pointer"}
          borderWidth="1px"
          borderStyle="solid"
          borderColor="blue.800"
        ></Square>
        <Text colorScheme="blue" fontSize="md" fontWeight="bold" isTruncated>
          {name}
        </Text>
      </Flex>
      <Divider />
      <Flex
        w="full"
        justify="space-between"
        alignItems="flex-start"
        flexDir="column"
      >
        <Text>{contactName}</Text>
        <Text color={"gray.500"}>{contactPosition}</Text>
      </Flex>
      <Flex w="full" justify="space-between" alignItems="center" wrap="wrap">
        <Flex flexDir="column" height="full">
          <Text fontWeight="semibold">
            Funds:
            <Text as="span" fontWeight="normal" ml={1}>
              {formatCurrency(funds)}
            </Text>
          </Text>
          <Text fontWeight="semibold">
            Total Allocated:
            <Text as="span" fontWeight="normal" ml={1}>
              {formatCurrency(allocatedFunds)}
            </Text>
          </Text>
          <Text fontWeight="semibold">
            Total Unallocated:
            <Text as="span" fontWeight="normal" ml={1}>
              {formatCurrency(unallocatedFunds)}
            </Text>
          </Text>
        </Flex>
        <Flex flexDir="column" height="full" alignItems="start">
          <Text fontWeight="semibold">
            Fiscal year:
            <Text as="span" fontWeight="normal" ml={1}>
              {fiscalYearFormatted}
            </Text>
          </Text>
          <Text fontWeight="semibold">
            Last updated:
            <Text as="span" fontWeight="normal" ml={1}>
              {updated && (
                <LocaleDate
                  date={updated}
                  options={{
                    month: "long",
                    day: undefined,
                    year: "numeric",
                  }}
                />
              )}
            </Text>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
