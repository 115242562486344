import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  ButtonGroup,
  Heading,
  ListItem,
  ModalFooter,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { EditorState } from "@operations-hero/react-draft-wysiwyg";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useAuthentication } from "../../../../../components/auth/AuthProvider";
import { RichTextEditorComments } from "../../../../../components/form-helpers/rich-text-editor/RichTextEditorComments";
import { useShowToast } from "../../../../../hooks/showToast";
import { useConvertMentions } from "../../../../../hooks/useConvertMentions";
import { updateResolvedViolation } from "../../../../../store/planning-hq/inspections/details/inspection-details.slice";
import { AccountModal } from "../../../../account-settings/account-modal/AccountModal";
import { convertToSave } from "../../../../request-form/comments/Comment";

export type ResolveViolationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  inspectionId: string;
  inspectionScoreId: string;
};

export default function ResolveViolationModal({
  isOpen,
  onClose,
  inspectionId,
  inspectionScoreId,
}: ResolveViolationModalProps) {
  const dispatch = useDispatch();
  const { apiClient, currentAccount } = useAuthentication();
  const showToast = useShowToast();
  const [isSaving, setIsSaving] = useState(false);
  const [commentString, setCommentString] = useState("");

  const { editorState: comment } = useConvertMentions({
    value: commentString,
    mentioned: [],
  });

  const handleSummaryBlur = useCallback((comment: EditorState) => {
    const response = convertToSave(comment);
    // set state only if needed
    setCommentString((prev) => {
      if (!response) return "";
      if (response && response.trim() === prev) return prev;
      return response.trim();
    });
  }, []);

  const resolveViolation = useCallback(() => {
    if (!commentString) {
      return;
    }

    setIsSaving(true);
    apiClient
      .resolveInspectionScore(
        currentAccount.id,
        inspectionId,
        inspectionScoreId,
        {
          resolvedComment: commentString,
        }
      )
      .then((result) => {
        showToast("success", "Violation saved successfully");
        dispatch(updateResolvedViolation(result));
        onClose();
      })
      .catch((error) => {
        console.error("Error resolving violation:");
        console.error(error);
        showToast("error", "Error saving violation");
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [
    commentString,
    onClose,
    apiClient,
    currentAccount,
    dispatch,
    inspectionId,
    inspectionScoreId,
    showToast,
  ]);

  return (
    <AccountModal
      isOpen={isOpen}
      onClose={onClose}
      title={"Resolve Outside the System"}
      titleProps={{ size: "md" }}
      contentProps={{ maxW: "lg" }}
      content={
        <VStack spacing={6} alignItems="flex-start" width="100%">
          <Alert status="warning">
            <AlertIcon />
            <AlertDescription>
              This violation will be managed outside the system
            </AlertDescription>
          </Alert>

          <UnorderedList spacing={4}>
            <ListItem>
              No additional records about its status or related processes will
              be stored in the system.
            </ListItem>

            <ListItem>
              The issue will remain visible on the inspection but will not be
              linked to system functionalities.
            </ListItem>
          </UnorderedList>

          <VStack width="100%" alignItems="flex-start">
            <Heading fontSize="md">Resolution</Heading>
            <RichTextEditorComments
              id="violation-comment"
              value={comment}
              onBlur={handleSummaryBlur}
              isReadOnly={isSaving}
            />
          </VStack>
        </VStack>
      }
      footer={
        <ModalFooter pt={2}>
          <ButtonGroup spacing={2} size="sm">
            <Button colorScheme="blue" variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={resolveViolation}
              isDisabled={isSaving}
              isLoading={isSaving}
            >
              Mark Resolved
            </Button>
          </ButtonGroup>
        </ModalFooter>
      }
    />
  );
}
