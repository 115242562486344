import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";

import { FC, useCallback, useEffect, useState } from "react";
import { useDeviceOrientation } from "../../hooks/useDeviceOrientation";
import { ESignaturePreview } from "./E-SignaturePreview";
import { ESignCanva } from "./E-SignCanvas";
import { useESignatureGenerator } from "./useESignatureGenerator";

export type SignModes = "fullname" | "draw" | "off";

export type ESignValues = {
  imageURL: string | null;
  imageFile: File | null;
};

interface ESignProps {
  isOpenCanvas: boolean;
  signMode: SignModes;
  recipientName?: string;
  setIsOpenCanvas: (value: boolean) => void;
  handleOnChangeSignMode: (value: SignModes) => void;
  saveFullName?: (values: string | null) => void;
  handleOnSaveImage: (values: ESignValues | null) => void;
  border?: boolean;
}

export const ESign: FC<ESignProps> = ({
  signMode,
  isOpenCanvas,
  recipientName,
  setIsOpenCanvas,
  handleOnSaveImage,
  saveFullName,
  handleOnChangeSignMode,
  border,
}) => {
  const { isLandscape } = useDeviceOrientation();
  const [signature, setSignature] = useState<ESignValues | null>(null);
  const [fullName, setFullName] = useState<string | undefined>(recipientName);

  const handleOnChangeFullName = useCallback(
    (value: string) => {
      setFullName(value);
      saveFullName && saveFullName(value);
      if (!value) {
        setSignature(null);
        saveFullName && saveFullName(null);
      }
    },
    [saveFullName]
  );

  const handleOnClearInput = useCallback(() => {
    setFullName("");
    setSignature(null);
  }, []);

  const handleOnChangeRadio = useCallback(
    (value: SignModes) => {
      handleOnChangeSignMode(value);
      setSignature(null);

      if (value === "draw") {
        setIsOpenCanvas(true);
      }

      if (value === "fullname") {
        setFullName(recipientName);
      }
    },
    [handleOnChangeSignMode, recipientName, setIsOpenCanvas]
  );

  const getESignatureValues = useCallback(
    (values: ESignValues) => {
      setSignature(values);
      setIsOpenCanvas(false);
    },
    [setIsOpenCanvas]
  );

  const handleOnGoBack = useCallback(() => {
    setIsOpenCanvas(false);
    handleOnChangeSignMode("fullname");
  }, [handleOnChangeSignMode, setIsOpenCanvas]);

  const onSaveSignature = useCallback(() => {
    if (!signature) {
      return;
    }
    handleOnSaveImage(signature);
  }, [handleOnSaveImage, signature]);

  return (
    <Flex gap={4} flexDir="column">
      {!isOpenCanvas && (
        <>
          <Text>Confirm your name and E-Signature</Text>

          <FormControl isInvalid={!fullName}>
            <FormLabel fontSize="sm">Full Name</FormLabel>
            <InputGroup>
              <Input
                value={fullName}
                placeholder="Full Name"
                onChange={(e) => handleOnChangeFullName(e.target.value)}
              />
              <InputRightElement cursor="pointer" onClick={handleOnClearInput}>
                <CloseIcon boxSize="12px" />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>Full Name is required</FormErrorMessage>
          </FormControl>

          <RadioGroup w="100%" value={signMode} onChange={handleOnChangeRadio}>
            <Stack direction="row" gap={16}>
              <Radio value="fullname">Sign with name</Radio>
              <Radio value="draw">Draw</Radio>
            </Stack>
          </RadioGroup>

          {(!isOpenCanvas || (signature && signature.imageURL)) && (
            <ESignaturePreview
              base64Image={signature ? signature.imageURL : null}
            />
          )}

          {signMode === "fullname" && !isOpenCanvas && (
            <ESignFromFullName
              fullName={fullName}
              setSignature={setSignature}
            />
          )}

          <Flex direction="column" gap={4} w="100%">
            <Divider />
            <Flex justifyContent="flex-end" gap={2}>
              <Button
                variant="outline"
                colorScheme="blue"
                onClick={() => handleOnChangeSignMode("off")}
              >
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                onClick={onSaveSignature}
                isDisabled={signature === null || signature.imageFile === null}
              >
                Add Signature
              </Button>
            </Flex>
          </Flex>
        </>
      )}

      {isOpenCanvas && signMode === "draw" && (
        <>
          {isLandscape && (
            <ESignCanva
              fullname={fullName}
              isLandScape={true}
              getESignatureValues={getESignatureValues}
              handleOnGoBack={handleOnGoBack}
              border={border}
            />
          )}

          {!isLandscape && (
            <ESignCanva
              fullname={fullName}
              getESignatureValues={getESignatureValues}
              handleOnGoBack={handleOnGoBack}
            />
          )}
        </>
      )}
    </Flex>
  );
};

interface ESignFromFullNameProps {
  fullName?: string;
  setSignature: (values: ESignValues | null) => void;
}

const ESignFromFullName: FC<ESignFromFullNameProps> = ({
  fullName,
  setSignature,
}) => {
  const { canvasRef, imageBase64, imageFile } =
    useESignatureGenerator(fullName);

  useEffect(() => {
    setSignature({ imageFile, imageURL: imageBase64 });
  }, [imageBase64, imageFile, setSignature]);

  return <canvas ref={canvasRef} style={{ display: "none" }} />;
};
