import {
  ApiLocation,
  ApiLocationSummary,
  ApiLocationType,
  CatalogPolicy,
  RestrictedPolicy,
  WorkflowPolicy,
} from "@operations-hero/lib-api-client";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { buildMap } from "./buildMap";

export type UserPolicy = boolean | RestrictedPolicy;

export const useLocationUtils = (
  allowedLocations?: ApiLocation[],
  allowedLocationsMap?: { [key: string]: ApiLocation }
) => {
  const {
    locationMap,
    locations: accountLocations,
    descendantsMap,
  } = useSelector((state: RootState) => state.localCache);

  const locationsLocalCache = useMemo(() => {
    if (allowedLocations && allowedLocations.length > 0)
      return allowedLocations;
    return accountLocations;
  }, [accountLocations, allowedLocations]);

  const locationHash = useMemo(() => {
    if (allowedLocationsMap) return allowedLocationsMap;
    if (allowedLocations && allowedLocations.length > 0)
      return buildMap(allowedLocations);
    return locationMap;
  }, [locationMap, allowedLocationsMap, allowedLocations]);

  const getChildrenId = useCallback(
    (values: ApiLocationSummary[]) => {
      let children: string[] = [];
      values.forEach((val) => {
        children.push(val.id);
        descendantsMap[val.id]?.forEach((c) => children.push(c));
      });

      return children;
    },
    [descendantsMap]
  );

  const locations = useMemo(() => {
    if (allowedLocations && allowedLocations.length > 0)
      return getChildrenId(allowedLocations).map((id) => locationHash[id]);
    else return locationsLocalCache;
  }, [allowedLocations, locationsLocalCache, getChildrenId, locationHash]);

  const findAllChildrenForNodes = useCallback(
    (values: ApiLocation[], excludeParents?: boolean) => {
      const locationsAndChildren: Record<string, ApiLocation> = {};

      for (let value of values) {
        if (!value) {
          continue;
        }
        locationsAndChildren[value.id] = value;

        // get decendants id and convert to location
        const decendants = descendantsMap[value.id] || [];

        decendants.forEach((loc) => {
          const location = locationHash[loc];
          if (!location) return;
          locationsAndChildren[loc] = location;
        });
      }

      if (excludeParents) {
        const parentsIds = values.map((loc) => loc.id);
        parentsIds.forEach((parentId) => {
          delete locationsAndChildren[parentId];
        });
      }
      return Object.values(locationsAndChildren);
    },
    [descendantsMap, locationHash]
  );

  const findFirstLevelChildren = useCallback(
    (parentId: string, locationId?: string) => {
      const children: Record<string, ApiLocation> = {};

      for (const child of Object.values(locationMap)) {
        if (child.parent === parentId && locationId !== child.id) {
          children[child.id] = child;
        }
      }

      return Object.values(children);
    },
    [locationMap]
  );
  const getUserPolicyLocationsWithChildrens = useCallback(
    (userPolicies: WorkflowPolicy | CatalogPolicy) => {
      if (userPolicies.admin) return Object.values(locationHash);

      const policiesCopy = { ...userPolicies } as Partial<
        WorkflowPolicy | CatalogPolicy
      >;
      delete policiesCopy.admin;

      const userAssigneedPolicies: UserPolicy[] = [];
      Object.values(policiesCopy).forEach((value) => {
        if (!!value) {
          userAssigneedPolicies.push(value as UserPolicy);
        }
      });

      if (userAssigneedPolicies.length === 0) return [];

      if (
        userAssigneedPolicies.some(
          (value) => typeof value === "boolean" && value === true
        )
      ) {
        return Object.values(locationHash);
      }

      const locations: Record<string, ApiLocationSummary> = {};
      let somePolicyHasAllLocationsAllowed = false;

      for (let value of userAssigneedPolicies) {
        if (typeof value === "boolean") {
          continue;
        }
        // all locations allowed, must be category restricted
        // no need to keep processing
        if (value.locations.length === 0) {
          somePolicyHasAllLocationsAllowed = true;
          break;
        }

        value.locations.forEach((loc) => {
          const location = locationHash[loc];
          if (!location || !location.active) {
            return;
          }
          locations[loc] = location;
          if (descendantsMap[loc]) {
            descendantsMap[loc].forEach((l) => {
              const location = locationHash[l];
              if (!location || !location.active) {
                return;
              }
              locations[l] = location;
            });
          }
        });
      }

      if (somePolicyHasAllLocationsAllowed) {
        return Object.values(locationHash);
      }

      return Object.values(locations);
    },
    [locationHash, descendantsMap]
  );

  const sortLocationsRecursive = useCallback(
    (locations: ApiLocation[], parentId: string | null): ApiLocation[] => {
      const nodes = locations
        .filter((x) => x.parent === parentId)
        .sort((a, b) => (a.name === b.name ? 0 : a.name > b.name ? 1 : -1));

      const sortedLocations = nodes.reduce<ApiLocation[]>((result, item) => {
        result.push(item);
        const children = sortLocationsRecursive(locations, item.id);
        result.push(...children);
        return result;
      }, []);

      return sortedLocations;
    },
    []
  );

  const sortLocations = useCallback(
    (locations: ApiLocation[]): ApiLocation[] => {
      const rootNodes = locations.filter(
        (x) => !locations.some((loc) => loc.id === x.parent)
      );

      const sortedLocations = rootNodes.reduce<ApiLocation[]>(
        (result, item) => {
          result.push(item);
          const children = sortLocationsRecursive(locations, item.id);
          result.push(...children);
          return result;
        },
        []
      );

      return sortedLocations;
    },
    [sortLocationsRecursive]
  );

  const getParentRegionLocation = useCallback(
    (locationId: string): ApiLocation | null => {
      const treePath = locationHash[locationId].treePath.split(".");

      const firstRegion = treePath.find(
        (id) => locationHash[id]?.type === ApiLocationType.region
      );

      return firstRegion ? locationHash[firstRegion] : null;
    },
    [locationHash]
  );

  const getTopLevelBuildingLocations = useCallback((): ApiLocation[] => {
    return Object.values(locationHash).filter((location) => {
      if (location.type !== ApiLocationType.building) {
        return false;
      }

      const parent = !location?.parent ? null : locationHash[location.parent];
      return !parent || (parent && parent.type === ApiLocationType.region);
    });
  }, [locationHash]);

  const getTopLevelRegionLocations = useCallback((): ApiLocation[] => {
    return Object.values(locationHash).filter((location) => {
      if (location.type !== ApiLocationType.region) {
        return false;
      }

      return !location.parent;
    });
  }, [locationHash]);

  return {
    getTopLevelRegionLocations,
    getTopLevelBuildingLocations,
    getChildrenId,
    findAllChildrenForNodes,
    locations,
    locationHash,
    getUserPolicyLocationsWithChildrens,
    findFirstLevelChildren,
    sortLocations,
    getParentRegionLocation,
  };
};

export function findLocationChildrenFunction(
  descendantsMap: {
    [key: string]: string[];
  },
  locations: string[],
  result: Set<string>
) {
  locations.forEach((loc) => {
    result.add(loc);
    descendantsMap[loc]?.forEach((l) => result.add(l));
  });
  return result;
}

export function getTopLevelBuildingLocationsFunction(
  locationHash: Record<string, ApiLocation>
): ApiLocation[] {
  return Object.values(locationHash).filter((location) => {
    if (location.type !== ApiLocationType.building) {
      return false;
    }

    const parent = !location?.parent ? null : locationHash[location.parent];
    return !parent || (parent && parent.type === ApiLocationType.region);
  });
}
