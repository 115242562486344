import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  HStack,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { FC, useCallback, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import { SaveDataObject } from "../../pages/requests/list-headers/RequestListActions";

interface SavedSearchesProps {
  savedSearchFilters: SaveDataObject[];
  handleOnClickLink: (filterObj: string, filterName?: string) => void;
  onDelete: (filtertoDelete: SaveDataObject) => void;
}

const MAX_FILTERS = 19;
const MIN_FILTERS = 0;

export const SavedSearches: FC<SavedSearchesProps> = ({
  savedSearchFilters,
  handleOnClickLink,
  onDelete,
}) => {
  const textColor = useColorModeValue("blue.500", "white");
  const [selectedFilterIndex, setSelectedFilterIndex] = useState<number | null>(
    null
  );

  const onConfirm = useCallback(
    (filter: SaveDataObject) => {
      onDelete(filter);
      setSelectedFilterIndex(null);
    },
    [onDelete]
  );

  return (
    <VStack width="100%" divider={<Divider py={2} />}>
      {savedSearchFilters && savedSearchFilters.length > 0 ? (
        savedSearchFilters
          .slice(MIN_FILTERS, MAX_FILTERS)
          .map((filter, index) => (
            <HStack key={index} w="100%">
              <Box flex="1">
                <Button
                  variant="link"
                  onClick={() => handleOnClickLink(filter.filter, filter.name)}
                  color={textColor}
                  _focus={{ boxShadow: "none" }}
                >
                  <Icon as={BsSearch} mr={2} />
                  <Text noOfLines={2} isTruncated>
                    {filter.name}
                  </Text>
                </Button>
              </Box>
              <Box>
                {selectedFilterIndex === index ? (
                  <ButtonGroup spacing="4" mt={4}>
                    <Button
                      colorScheme="red"
                      size="xs"
                      onClick={() => onConfirm(filter)}
                    >
                      Confirm
                    </Button>
                    <Button
                      size="xs"
                      onClick={() => setSelectedFilterIndex(null)}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                ) : (
                  <IconButton
                    variant="outline"
                    colorScheme="red"
                    aria-label="Delete Filter"
                    icon={<RiDeleteBinLine />}
                    onClick={() => setSelectedFilterIndex(index)}
                    mt={2}
                  />
                )}
              </Box>
            </HStack>
          ))
      ) : (
        <Text as="i">No filters saved</Text>
      )}
    </VStack>
  );
};
