import {
  ApiAsset,
  ApiFrequency,
  ApiLocationSummary,
  ApiProject,
  ApiRequestAssignee,
  ApiSchedule,
  ApiScheduledRequest,
  ApiTaskBook,
  ApiUserSummary,
  ApiWeeksOfMonth,
  ApiWorkflow,
  ApiWorkflowField,
  ApiWorkflowSchemaField,
} from "@operations-hero/lib-api-client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getTimeZoneIanaFormat } from "../../utils/timezone";
import { getScheduledRequestHandler } from "./thunks/get-scheduled-request.thunk";
import { initScheduledRequestHandler } from "./thunks/init-scheduled-request.thunk";
import { removeTaskBookAssetHandler } from "./thunks/remove-taskbook-assets.thunk";

export interface SheculedRequestTaskbooksProps extends ApiTaskBook {
  isNew: boolean;
}

export interface SheculedRequestAssetsProps extends ApiAsset {
  isNew: boolean;
}

export type ScheduleType = "standard" | "sliding";
export interface ScheduledRequestFormExtraProps {
  showProject: boolean;
  showTaskbooks: boolean;
  isProjectRequired: boolean;
  visibleFields: ApiWorkflowField[];
  schemaFields: ApiWorkflowSchemaField[];
}

const defaultExtraProps: ScheduledRequestFormExtraProps = {
  showProject: false,
  showTaskbooks: false,
  isProjectRequired: false,
  visibleFields: [] as ApiWorkflowField[],
  schemaFields: [] as ApiWorkflowSchemaField[],
};

export interface ScheduleProps
  extends Omit<
    ApiSchedule,
    | "id"
    | "created"
    | "createdBy"
    | "updated"
    | "updatedBy"
    | "bullKey"
    | "count"
    | "frequency"
    | "lastTimeExecuted"
  > {
  id: string | null;
  isNew: boolean;
  startDate: string | null;
  frequency: ApiFrequency;
}

export interface ScheduledRequestProps
  extends Pick<
    ApiScheduledRequest,
    | "reason"
    | "summary"
    | "estimatedCost"
    | "estimatedHours"
    | "reportingCategory"
    | "generateRequestPerAsset"
    | "generateOnlyWhenRequestsAreClosed"
    | "generateByCompletionDate"
    | "startDaysAfterCreate"
    | "dueDaysAfterStart"
    | "requestsGenerated"
    | "lastRequestCreated"
    | "lastRequestId"
    | "lastRequestKey"
    | "budget"
  > {
  id: string | undefined;
  name: string;
  project: ApiProject | null;
  workflow: ApiWorkflow | undefined;
  requester: ApiUserSummary | undefined;
  location: ApiLocationSummary | undefined;
  assignees: ApiRequestAssignee[] | undefined;
}

export interface ScheduleRequestSliceState {
  isLoading: boolean;
  willRequestRepeat: boolean;
  schedule: ScheduleProps;
  scheduledRequestAssets: SheculedRequestAssetsProps[];
  isAddingAsset: boolean;
  scheduledRequestTaskBooks: SheculedRequestTaskbooksProps[];
  scheduledRequest: ScheduledRequestProps;
  scheduledRequestCopy: ScheduledRequestProps | null;
  extraProps: ScheduledRequestFormExtraProps;
}

export const initialStateValues: ScheduleRequestSliceState = {
  schedule: {
    isNew: false,
    id: null,
    startDate: "",
    endDate: null,
    frequency: "monthly",
    executeEvery: 1,
    timeZone: getTimeZoneIanaFormat(),
    cron: "",
    nextRuns: [],
    weekOfMonth: null,
    firstTimeExecuted: null,
  },
  isLoading: false,
  willRequestRepeat: true,
  isAddingAsset: false,
  scheduledRequest: {
    id: undefined,
    name: "",
    summary: "",
    location: undefined,
    assignees: undefined,
    reportingCategory: null,
    reason: null,
    workflow: undefined,
    estimatedHours: null,
    estimatedCost: null,
    requester: undefined,
    dueDaysAfterStart: 0,
    startDaysAfterCreate: 0,
    generateRequestPerAsset: false,
    generateOnlyWhenRequestsAreClosed: false,
    generateByCompletionDate: false,
    requestsGenerated: 0,
    lastRequestCreated: null,
    lastRequestId: null,
    lastRequestKey: null,
    budget: null,
    project: null,
  },
  scheduledRequestTaskBooks: [],
  scheduledRequestAssets: [],
  scheduledRequestCopy: null,
  extraProps: { ...defaultExtraProps },
};

export const scheduleRequestFormSlice = createSlice({
  name: "scheduled-request",
  initialState: initialStateValues,
  reducers: {
    addScheduledRequest: (
      state: ScheduleRequestSliceState,
      action: PayloadAction<ScheduledRequestProps>
    ) => {
      state.scheduledRequest = action.payload;
    },

    addScheduledRequestTaskBooks: (
      state: ScheduleRequestSliceState,
      action: PayloadAction<ApiTaskBook>
    ) => {
      state.scheduledRequestTaskBooks.unshift({
        ...action.payload,
        isNew: true,
      });
    },

    addScheduledRequestAssets: (
      state: ScheduleRequestSliceState,
      action: PayloadAction<ApiAsset>
    ) => {
      state.isAddingAsset = true;
      const index = state.scheduledRequestAssets.findIndex(
        (asset) => asset.id === action.payload.id
      );
      if (index === -1) {
        state.scheduledRequestAssets.unshift({
          ...action.payload,
          isNew: true,
        });
      }
      state.isAddingAsset = false;
    },

    addSchedule: (
      state: ScheduleRequestSliceState,
      action: PayloadAction<ScheduleProps>
    ) => {
      state.schedule = { ...action.payload, id: null };
    },

    setWillRequestRepeat: (
      state: ScheduleRequestSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.willRequestRepeat = action.payload;
    },

    setGenerateOnlyWhenRequestsAreClosed: (
      state: ScheduleRequestSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.scheduledRequest.generateOnlyWhenRequestsAreClosed = action.payload;
    },
    setGenerateByCompletationDate: (
      state: ScheduleRequestSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.scheduledRequest.generateByCompletionDate = action.payload;
    },
    setGenerateRequestPerAsset: (
      state: ScheduleRequestSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.scheduledRequest.generateRequestPerAsset = action.payload;
    },

    setStartDaysAfterCreate: (
      state: ScheduleRequestSliceState,
      action: PayloadAction<number>
    ) => {
      state.scheduledRequest.startDaysAfterCreate = action.payload;
    },

    setDueDaysAfterStart: (
      state: ScheduleRequestSliceState,
      action: PayloadAction<number>
    ) => {
      state.scheduledRequest.dueDaysAfterStart = action.payload;
    },

    setScheduleStartDate: (
      state: ScheduleRequestSliceState,
      action: PayloadAction<string>
    ) => {
      state.schedule.startDate = action.payload;
    },

    setScheduleEndDate: (
      state: ScheduleRequestSliceState,
      action: PayloadAction<string | null>
    ) => {
      state.schedule.endDate = action.payload;
    },
    setScheduleRequestWorkflow: (
      state: ScheduleRequestSliceState,
      action: PayloadAction<ApiWorkflow | undefined>
    ) => {
      state.scheduledRequest.workflow = action.payload;
    },
    setScheduleCronExpression: (
      state: ScheduleRequestSliceState,
      action: PayloadAction<{
        cron: string;
        frequency: ApiFrequency;
        executeEvery: number;
        weekOfMonth: ApiWeeksOfMonth;
      }>
    ) => {
      state.schedule.cron = action.payload.cron;
      state.schedule.executeEvery = action.payload.executeEvery;
      state.schedule.frequency = action.payload.frequency;
      state.schedule.weekOfMonth = action.payload.weekOfMonth;
    },

    removeNewScheduledRequestTaskBook: (
      state: ScheduleRequestSliceState,
      action: PayloadAction<string>
    ) => {
      const index = state.scheduledRequestTaskBooks.findIndex(
        (taskbook) => taskbook.id === action.payload
      );
      index !== -1 && state.scheduledRequestTaskBooks.splice(index, 1);
    },

    removeNewScheduledRequestAsset: (
      state: ScheduleRequestSliceState,
      action: PayloadAction<string>
    ) => {
      const index = state.scheduledRequestAssets.findIndex(
        (asset) => asset.id === action.payload
      );
      index !== -1 && state.scheduledRequestAssets.splice(index, 1);
    },

    setScheduleRequestFormExtraProps: (
      state: ScheduleRequestSliceState,
      action: PayloadAction<ScheduledRequestFormExtraProps>
    ) => {
      state.extraProps = action.payload;
    },

    unloadSchedulesForm: (state: ScheduleRequestSliceState) => {
      state.isLoading = false;
      state.schedule = {
        isNew: false,
        id: null,
        startDate: "",
        endDate: null,
        frequency: "monthly",
        executeEvery: 0,
        timeZone: null,
        cron: "",
        nextRuns: [],
        weekOfMonth: null,
        firstTimeExecuted: null,
      };
      state.scheduledRequest = {
        id: undefined,
        name: "",
        summary: "",
        location: undefined,
        assignees: undefined,
        reportingCategory: null,
        reason: null,
        workflow: undefined,
        estimatedHours: null,
        estimatedCost: null,
        requester: undefined,
        dueDaysAfterStart: 0,
        startDaysAfterCreate: 0,
        generateRequestPerAsset: false,
        generateOnlyWhenRequestsAreClosed: false,
        generateByCompletionDate: false,
        requestsGenerated: 0,
        lastRequestCreated: null,
        lastRequestId: null,
        lastRequestKey: null,
        budget: null,
        project: null,
      };
      state.willRequestRepeat = true;
      state.scheduledRequestAssets = [];
      state.scheduledRequestTaskBooks = [];
      state.scheduledRequestCopy = null;
      state.extraProps = { ...defaultExtraProps };
    },
  },

  extraReducers: (builder) => {
    initScheduledRequestHandler(builder);

    getScheduledRequestHandler(builder);

    removeTaskBookAssetHandler(builder);
  },
});

export const {
  addSchedule,
  setScheduleEndDate,
  setScheduleRequestWorkflow,
  addScheduledRequest,
  unloadSchedulesForm,
  setScheduleStartDate,
  setWillRequestRepeat,
  setDueDaysAfterStart,
  setStartDaysAfterCreate,
  setScheduleCronExpression,
  addScheduledRequestAssets,
  setGenerateRequestPerAsset,
  addScheduledRequestTaskBooks,
  removeNewScheduledRequestAsset,
  removeNewScheduledRequestTaskBook,
  setGenerateOnlyWhenRequestsAreClosed,
  setGenerateByCompletationDate,
  setScheduleRequestFormExtraProps,
} = scheduleRequestFormSlice.actions;

export default scheduleRequestFormSlice.reducer;
