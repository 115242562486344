import {
  Box,
  BoxProps,
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Progress,
  Radio,
  Spacer,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AiOutlineFileExcel,
  AiOutlineFilePdf,
  AiOutlineFileWord,
} from "react-icons/ai";
import { IoDocumentOutline, IoTrashOutline } from "react-icons/io5";
import { MdCheck, MdClose, MdOutlineEdit } from "react-icons/md";
import { getPreviewUrl } from "../../utils/getImagePreviewUrl";
import { DownloadButton } from "../buttons/DownloadButton";
import { SparkleWithAnimation } from "../icons/Sparkle";
import { ZoomableImageAttachment } from "../images/ZoomableImageAttachment";
import { Attachment } from "./Attachments";

export interface AttachmentFileCardProps {
  progress?: number;
  onDelete?: () => void;
  isDisabled?: boolean;
  displayActions?: boolean;
  defaultPhoto?: Attachment;
  setDefaultPhoto?: (value: Attachment) => void;
  canEditLabel?: boolean;
  attachment: Attachment;
  showData?: boolean;
  showThumbnailEdit?: boolean;
  onUpdateAttachment?: (attachment: Attachment, label: string) => void;
  boxProps?: BoxProps;
  hideDownloadButton?: boolean;
  isViewOnly?: boolean;
}

export const AttachmentFileCard = ({
  attachment,
  defaultPhoto,
  setDefaultPhoto,
  progress,
  onDelete,
  isDisabled,
  displayActions = true,
  canEditLabel,
  showData = true,
  onUpdateAttachment,
  showThumbnailEdit,
  boxProps,
  hideDownloadButton,
  isViewOnly,
}: AttachmentFileCardProps) => {
  const { created, file, name, type, url, label } = attachment;
  const footerBgColor = useColorModeValue("whiteAlpha.700", "blackAlpha.700");
  const inputBgColor = useColorModeValue("white", "transparent");

  const [displayUrl, setDisplayUrl] = useState(url);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [labelValue, setLabelvalue] = useState(label || "");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isImageBlob = useMemo(() => file && url == null, [file, url]);
  const isImage = useMemo(() => (type || "").indexOf("image/") > -1, [type]);
  const isPdf = useMemo(
    () => (type || "").indexOf("application/pdf") > -1,
    [type]
  );
  const isDoc = useMemo(
    () =>
      (type || "").indexOf(
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) > -1 ||
      (type || "").indexOf("application/msword") > -1 ||
      (type || "").indexOf("application/x-abiword") > -1,
    [type]
  );
  const isExcel = useMemo(
    () =>
      (type || "").indexOf(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) > -1 || (type || "").indexOf("application/vnd.ms-excel") > -1,
    [type]
  );

  const isRadioChecked = useMemo(() => {
    if (!showThumbnailEdit) return false;
    const defaultPhotoId = defaultPhoto?.id;
    const defaultPhotoUploadId = defaultPhoto?.uploadId;
    const attachmentId = attachment?.id;
    const attachmentUploadId = attachment?.uploadId;
    if (defaultPhotoId && attachmentId) {
      return defaultPhotoId === attachmentId;
    } else if (defaultPhotoUploadId && attachmentUploadId) {
      return defaultPhotoUploadId === attachmentUploadId;
    } else {
      return false;
    }
  }, [
    attachment?.id,
    attachment?.uploadId,
    defaultPhoto?.id,
    defaultPhoto?.uploadId,
    showThumbnailEdit,
  ]);

  const { iconToDisplay, colorToDisplay } = isPdf
    ? { iconToDisplay: AiOutlineFilePdf, colorToDisplay: "red.500" }
    : isExcel
      ? { iconToDisplay: AiOutlineFileExcel, colorToDisplay: "green.500" }
      : isDoc
        ? { iconToDisplay: AiOutlineFileWord, colorToDisplay: "blue.500" }
        : { iconToDisplay: IoDocumentOutline, colorToDisplay: "gray.400" };

  const overlayButtonProps = useColorModeValue(
    { background: "gray.200", color: "black", hoverBackground: "gray.300" },
    { background: "gray.200", color: "black", hoverBackground: "gray.300" }
  );

  const handleRemoveAttachment: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((e) => {
      e.stopPropagation();
      setIsDeleting(true);
    }, []);

  const handleDeleteCancel: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((e) => {
      e.stopPropagation();
      setIsDeleting(false);
    }, []);

  const handleConfirmDelete: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        e.stopPropagation();
        if (onDelete) onDelete();
        setIsDeleting(false);
      },
      [onDelete]
    );

  const handleFileClick = useCallback(() => {
    // Docs
    if (!url && !file) {
      return;
    }

    if (url) {
      window.open(url, "_blank");
      return;
    }

    if (file) {
      const newUrl = URL.createObjectURL(file);
      window.open(newUrl, "_blank");
    }
  }, [file, url]);

  const handleThumbnail = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (setDefaultPhoto) {
        setDefaultPhoto(attachment);
      }
    },
    [attachment, setDefaultPhoto]
  );

  const handleUpdateAttachmentLabel: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((e) => {
      e.stopPropagation();
      setIsEditing(true);
    }, []);

  const handleConfirmUpdate: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        e.stopPropagation();
        onUpdateAttachment && onUpdateAttachment(attachment, labelValue);
        setLabelvalue("");
        setIsEditing(false);
      },
      [attachment, labelValue, onUpdateAttachment]
    );

  const handleCancelUpdate: React.MouseEventHandler<HTMLButtonElement> =
    useCallback((e) => {
      e.stopPropagation();
      setIsEditing(false);
    }, []);

  const handleOnChangeAttachmentLabel = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      setLabelvalue(e.target.value);
    },
    []
  );

  const handleOnlickAttachment = useCallback(
    (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      if (displayUrl && isImage) {
        onOpen();
        return;
      }
      handleFileClick();
    },
    [displayUrl, handleFileClick, isImage, onOpen]
  );

  useEffect(() => {
    if (isImageBlob && file) {
      setDisplayUrl(URL.createObjectURL(file));
    }
    return () => {
      if (isImageBlob && url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [isImageBlob, url, file]);

  return (
    <Box
      maxW={"100%"}
      maxH={"100%"}
      w={"170px"}
      h={"170px"}
      position="relative"
      boxShadow={"md"}
      onClick={handleOnlickAttachment}
      cursor={"pointer"}
      {...boxProps}
    >
      <Box
        display="flex"
        position="relative"
        maxW="100%"
        width="100%"
        height="100%"
        maxH="100%"
        overflow="hidden"
        borderRadius={"md"}
      >
        {displayUrl && isImage ? (
          <Image
            id={attachment.uploadId}
            src={getPreviewUrl(displayUrl)}
            alt={name}
            cursor="pointer"
            transform="translate(-50%, -50%)"
            minH="100%"
            minW="100%"
            position="absolute"
            boxSizing="border-box"
            left="50%"
            top="50%"
            objectFit="cover"
          />
        ) : (
          <Box
            maxW="100%"
            position="absolute"
            boxSizing="border-box"
            left="40%"
            top="40%"
          >
            <Icon
              as={iconToDisplay}
              width={10}
              height={10}
              color={colorToDisplay}
            />
          </Box>
        )}
        {/* Absolutes positioned elements after image so we dont have to set z-index */}
        {progress == null && onDelete ? (
          <Box
            position="absolute"
            width={
              isDeleting || (!hideDownloadButton && displayUrl && isImage)
                ? "100%"
                : undefined
            }
            display={isDeleting ? "flex" : undefined}
            gap={2}
            justifyContent={isDeleting ? "space-between" : undefined}
            top="0"
            right="0"
            height="40"
            p={1}
          >
            {!isDeleting &&
              !isEditing &&
              !isDisabled &&
              displayActions &&
              setDefaultPhoto && (
                <HStack w="100%" justifyContent={"space-between"}>
                  {!hideDownloadButton &&
                    displayUrl &&
                    isImage &&
                    !showThumbnailEdit && (
                      <DownloadButton
                        url={displayUrl}
                        name={name}
                        buttonProps={{
                          bgColor: overlayButtonProps.background,
                          color: overlayButtonProps.color,
                          _hover: {
                            background: overlayButtonProps.hoverBackground,
                          },
                        }}
                      />
                    )}

                  {showThumbnailEdit && (
                    <>
                      <Box
                        bg="whiteAlpha.700"
                        borderRadius="md"
                        p={1}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleThumbnail(e);
                        }}
                      >
                        <Radio
                          colorScheme="blue"
                          isChecked={isRadioChecked}
                          onClick={handleThumbnail}
                        >
                          Thumbnail
                        </Radio>
                      </Box>
                      <Spacer />
                    </>
                  )}
                  <Button
                    size="sm"
                    aria-label="remove attachment"
                    onClick={handleRemoveAttachment}
                    bgColor={overlayButtonProps.background}
                    color={overlayButtonProps.color}
                    _hover={{
                      background: overlayButtonProps.hoverBackground,
                    }}
                  >
                    <Icon as={IoTrashOutline} />
                  </Button>

                  {canEditLabel && (
                    <Button
                      size="sm"
                      aria-label="update attachment label"
                      onClick={handleUpdateAttachmentLabel}
                      bgColor={overlayButtonProps.background}
                      color={overlayButtonProps.color}
                      _hover={{
                        background: overlayButtonProps.hoverBackground,
                      }}
                    >
                      <Icon as={MdOutlineEdit} />
                    </Button>
                  )}
                </HStack>
              )}

            {isEditing && (
              <Flex w="100%" gap={6}>
                <Button
                  size="xs"
                  colorScheme="blue"
                  onClick={handleConfirmUpdate}
                >
                  <Icon as={MdCheck} />
                </Button>
                <Spacer />
                <Button
                  size="xs"
                  onClick={handleCancelUpdate}
                  bgColor={overlayButtonProps.background}
                  color={overlayButtonProps.color}
                  _hover={{
                    background: overlayButtonProps.hoverBackground,
                  }}
                >
                  <Icon as={MdClose} />
                </Button>
              </Flex>
            )}

            {isDeleting && (
              <Flex w="100%">
                <Button
                  size="xs"
                  colorScheme="red"
                  onClick={handleConfirmDelete}
                >
                  <Icon as={MdCheck} />
                </Button>
                <Spacer />
                <Button
                  size="xs"
                  onClick={handleDeleteCancel}
                  bgColor={overlayButtonProps.background}
                  color={overlayButtonProps.color}
                  _hover={{
                    background: overlayButtonProps.hoverBackground,
                  }}
                >
                  <Icon as={MdClose} />
                </Button>
              </Flex>
            )}
          </Box>
        ) : null}
        {progress != null ? (
          <Box position="absolute" bottom="52px" width="100%" height="10px">
            <Progress value={progress}></Progress>
          </Box>
        ) : null}

        {showData && (
          <Box
            position="absolute"
            bottom="0"
            width="100%"
            backgroundColor={isEditing ? "transparent" : footerBgColor}
            fontSize="xs"
            p={1}
          >
            {isEditing ? (
              <Input
                autoFocus
                value={labelValue}
                bgColor={inputBgColor}
                onClick={(e: MouseEvent<HTMLInputElement>) => {
                  e.stopPropagation();
                }}
                onChange={handleOnChangeAttachmentLabel}
              />
            ) : (
              <>
                <HStack isTruncated={true}>
                  <Text>{label || name}</Text>
                  {attachment.scannedByAi && (
                    <Box bg="whiteAlpha.700" borderRadius="md" pt={1} pr={2}>
                      <SparkleWithAnimation />
                    </Box>
                  )}
                </HStack>
                <Text isTruncated={true}>
                  {new Date(created).toLocaleString()}
                </Text>
              </>
            )}
          </Box>
        )}
        {!hideDownloadButton && displayUrl && isImage && showThumbnailEdit && (
          <DownloadButton
            url={displayUrl}
            name={name}
            boxProps={{
              position: "absolute",
              bottom: "0",
              width: "100%",
              p: 1,
            }}
            buttonProps={{
              bgColor: overlayButtonProps.background,
              color: overlayButtonProps.color,
              _hover: {
                background: overlayButtonProps.hoverBackground,
              },
              float: "right",
            }}
          />
        )}
        {isViewOnly && displayUrl && isImage && (
          <DownloadButton
            url={displayUrl}
            name={name}
            boxProps={{
              position: "absolute",
              width: "100%",
              p: 1,
            }}
            buttonProps={{
              bgColor: overlayButtonProps.background,
              color: overlayButtonProps.color,
              _hover: {
                background: overlayButtonProps.hoverBackground,
              },
              float: "right",
            }}
          />
        )}
      </Box>
      {isOpen && displayUrl && isImage && (
        <Modal isOpen={isOpen} onClose={onClose} size="full" isCentered>
          <ModalOverlay bg="rgba(0, 0, 0, 0.8)" />{" "}
          <ModalContent
            w="full"
            h="full"
            m={0}
            p={0}
            position="relative"
            bg="transparent"
          >
            <ModalCloseButton zIndex="overlay" color="white" />
            <ModalBody
              p={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              h="full"
              overflow="hidden"
            >
              <ZoomableImageAttachment name={name} url={displayUrl} />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};
