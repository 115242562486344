import { Box, Button, Divider, HStack, Input, Text } from "@chakra-ui/react";
import { FC, useState } from "react";

interface SearchFormProps {
  onClose: () => void;
  onSave: (searchName: string) => void;
  placeholder?: string;
}
export const SearchForm: FC<SearchFormProps> = ({
  onClose,
  onSave,
  placeholder = "Ex. Saved Search",
}) => {
  const [searchName, setSearchName] = useState("");

  return (
    <>
      <Text color="gray.400">
        You can re-run these searches quickly in the future by clicking on{" "}
        <Text as="span" fontWeight="bold">
          "My searches"
        </Text>
      </Text>

      <Text mt="5">Name this search: </Text>
      <Input
        value={searchName}
        onChange={(e) => setSearchName(e.target.value)}
        placeholder={placeholder}
        size="sm"
        mt="2"
      />
      <Box mt={6}>
        <Divider />
      </Box>
      <HStack justifyContent="space-between" width="100%" marginTop={6}>
        <Button variant="ghost" mr={3} onClick={onClose}>
          Close
        </Button>
        <Button colorScheme="blue" onClick={() => onSave(searchName)}>
          Save
        </Button>
      </HStack>
    </>
  );
};
