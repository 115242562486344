import { Box } from "@chakra-ui/react";
import { Select, SingleValue } from "chakra-react-select";
import { FC, useMemo } from "react";
import { getCustomSelectComponents } from "../../selects/select-overrides";
import {
  CronBoxOption,
  getRepeatsOnOptions,
} from "../cron-helpers/CronOptions";

interface RepeatSelectProps {
  handleChangeRepeat: (repeatOption: SingleValue<CronBoxOption>) => void;
  value: CronBoxOption | null;
  selectedStartDate: Date;
  withWeklyOptions?: boolean;
}

export const RepeatsOnSelect: FC<RepeatSelectProps> = ({
  value,
  handleChangeRepeat,
  selectedStartDate,
  withWeklyOptions,
}) => {
  const options = useMemo(
    () =>
      getRepeatsOnOptions(selectedStartDate.toISOString(), withWeklyOptions),
    [selectedStartDate, withWeklyOptions]
  );

  const components = useMemo(getCustomSelectComponents, []);

  return (
    <Box>
      <Select
        options={options}
        value={value}
        onChange={handleChangeRepeat}
        components={components}
      />
    </Box>
  );
};
