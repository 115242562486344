import {
  Box,
  Button,
  Icon,
  StackItem,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FC } from "react";
import { MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";

interface FilterBarProps {
  collapseLabel?: string;
  children?: React.ReactNode;
}

export const FilterBar: FC<FilterBarProps> = ({ children, collapseLabel }) => {
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });
  const filterBarColor = useColorModeValue("blue.50", "blue.900");
  const { isOpen, onToggle } = useDisclosure();

  if (!children) {
    return null;
  }

  if (isMobile) {
    return (
      <VStack w="100%" bgColor={filterBarColor}>
        {isOpen && (
          <Box w="100%" p={2}>
            {children}
          </Box>
        )}
        <Button
          alignSelf="flex-end"
          onClick={onToggle}
          variant="none"
          rightIcon={
            <Icon
              boxSize="18px"
              verticalAlign="middle"
              as={isOpen ? MdOutlineExpandLess : MdOutlineExpandMore}
            />
          }
        >
          {isOpen ? "Hide " : "Show "}
          {collapseLabel || "search and filters"}
        </Button>
      </VStack>
    );
  }

  /* Desktop / Tablet */
  return (
    <StackItem
      display="flex"
      w="100%"
      minH="50px"
      alignItems="center"
      bgColor={filterBarColor}
      mt={2}
    >
      <Box w="100%" p={2}>
        {children}
      </Box>
    </StackItem>
  );
};
