import { Badge, Box, Text, useBreakpointValue } from "@chakra-ui/react";
import { ApiInspection } from "@operations-hero/lib-api-client";
import { useMemo } from "react";
import { useInspectionRatingUtil } from "../../hooks/useInspectionRatingUtil";

export enum RatingType {
  poor = "POOR", // red.500 0 - 75%
  fair = "FAIR", // orange.300 75% - 90%
  good = "GOOD", // blue.400 90% - 99%
  exemplary = "EXEMPLARY", // green.400 99% - 100%
}

interface RatingBadgeProps {
  inspection: ApiInspection;
}

export const RatingBadge: React.FC<RatingBadgeProps> = ({ inspection }) => {
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });

  const { percentage, ratingType } = useInspectionRatingUtil(inspection);

  const colorScheme = useMemo(() => {
    switch (ratingType) {
      case RatingType.poor:
        return "red";
      case RatingType.fair:
        return "orange";
      case RatingType.good:
        return "blue";
      case RatingType.exemplary:
        return "green";
      default:
        return "gray";
    }
  }, [ratingType]);

  return (
    <Box display="flex" alignItems="center">
      <Text mr={2}>{`${percentage.toFixed(2)} %`}</Text>
      <Badge
        variant="outline"
        fontSize={isTablet ? "xs" : "sm"}
        colorScheme={colorScheme}
      >
        {ratingType}
      </Badge>
    </Box>
  );
};
