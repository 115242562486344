import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../../store";
import {
  handleSetIsOpenPurchseOrderRemoveModal,
  handleSetWorkingPurchaseOrder,
} from "../../../../store/request-form/request-form.slice";
import { removeInventoryPurchaseOrder } from "../../../../store/request-form/thunks/inventoryPurchaseOrders.thunk";
import { AccountModal } from "../../../account-settings/account-modal/AccountModal";

export const RemovePurchaseOrderModal: FC = () => {
  const dispatch = useDispatch();
  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();

  const { apiClient, currentAccount } = useAuthentication();
  const { workingPurchaseOrder, isOpenRemoveModal } = useSelector(
    (state: RootState) => state.requestForm.inventoryPurchaseOrders
  );

  const handleOnClose = useCallback(() => {
    dispatch(handleSetIsOpenPurchseOrderRemoveModal(false));
    dispatch(handleSetWorkingPurchaseOrder(null));
  }, [dispatch]);

  const handleOnRemovePurchaseOrder = useCallback(() => {
    if (!workingPurchaseOrder) {
      return;
    }

    thunkDispatch(
      removeInventoryPurchaseOrder({
        apiClient,
        accountId: currentAccount.id,
        id: workingPurchaseOrder.id,
      })
    )
      .then(unwrapResult)
      .then(() => {
        showToast("success", "Purchase Order was removed successfully");
      })
      .catch(() => {
        showToast(
          "error",
          "Something went wrong removing purchase order, please try again!"
        );
      })
      .finally(() => {
        handleOnClose();
      });
  }, [
    apiClient,
    currentAccount.id,
    handleOnClose,
    showToast,
    thunkDispatch,
    workingPurchaseOrder,
  ]);

  if (!isOpenRemoveModal) {
    return null;
  }

  return (
    <AccountModal
      isOpen={true}
      title={<Heading size="md">Delete Purchase Request?</Heading>}
      onClose={handleOnClose}
      content={
        <Flex flexDir="column" gap={4}>
          <Text>This action be undone.</Text>
          <Flex gap={2} w="100%" justifyContent="flex-end">
            <Button size="sm" onClick={handleOnClose}>
              Cancel
            </Button>
            <Button
              size="sm"
              colorScheme="red"
              onClick={handleOnRemovePurchaseOrder}
            >
              Delete
            </Button>
          </Flex>
        </Flex>
      }
    />
  );
};
