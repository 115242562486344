import { Container } from "@chakra-ui/react";
import { FC, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { useThunkDispatch } from "../../../store";
import { unload } from "../../../store/account-users.slice";
import { loadInspectionsDetails } from "../../../store/planning-hq/inspections/details/thunks/loadInspectionsDetails";

export const Inspection: FC = () => {
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const { id } = useParams<{ id: string }>();
  const { apiClient } = useAuthentication();

  const reloadInspectionDetails = useCallback(() => {
    if (!id) return;
    thunkDispatch(
      loadInspectionsDetails({
        apiClient,
        inspectionId: id,
      })
    );
  }, [thunkDispatch, apiClient, id]);

  useEffect(() => {
    reloadInspectionDetails();
  }, [reloadInspectionDetails]);

  useEffect(() => {
    return () => {
      dispatch(unload());
    };
  }, [dispatch, id]);

  return (
    <Container
      gap={2}
      maxWidth="8xl"
      sx={{
        display: "flex",
        flexDir: "column",
        flex: "1",
        height: "100%",
      }}
      position="relative"
    >
      <Outlet />
    </Container>
  );
};
