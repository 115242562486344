import React, { useState, ChangeEvent, FocusEvent } from "react";
import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";

interface CostInputProps {
  id: string;
  value: number;
  onChange: (value: number) => void;
  isInvalid: boolean;
}

const CustomCostInput: React.FC<CostInputProps> = ({
  id,
  value,
  onChange,
  isInvalid,
}) => {
  const [inputValue, setInputValue] = useState<string>();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    const parsedValue = parseFloat(event.target.value);
    if (!isNaN(parsedValue)) {
      const formattedValue = parsedValue.toFixed(2);
      setInputValue(formattedValue);
      onChange(parsedValue);
    } else {
      setInputValue(value.toFixed(2));
    }
  };

  return (
    <InputGroup>
      <InputLeftAddon maxW={9} fontSize="sm" paddingLeft="2px">
        Cost
      </InputLeftAddon>
      <Input
        id={id}
        textAlign="right"
        type="text"
        placeholder="Unit Cost"
        value={inputValue}
        pl={0.5}
        pr={0.5}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        isInvalid={isInvalid}
      />
    </InputGroup>
  );
};

export default CustomCostInput;
