import { ApiLocation } from "@operations-hero/lib-api-client";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Products } from "../../pages/account-settings/location-list/LocationList";
import { RootState } from "../../store";
import { useHeroHqAllowedLocations } from "./useAllowedHeroHQLocations";

export const useLocations = (
  product?: keyof typeof Products,
  hideLocationCb?: (location: ApiLocation) => boolean,
  hideLocationChildren?: boolean,
  sorted: "tree" | "alphabetical" = "alphabetical",
  includeInactive: boolean = false
) => {
  const {
    locationsByProduct,
    locationMap: globalLocationMap,
    descendantsMap: globalDescendantMap,
    locations: globalLocationList,
  } = useSelector((root: RootState) => root.localCache);

  const { getAllowedAndVisibleLocationsIds } = useHeroHqAllowedLocations();

  const descendantsMap = useMemo(() => {
    if (!product) return globalDescendantMap;
    return locationsByProduct[product].descendantsMap;
  }, [product, locationsByProduct, globalDescendantMap]);

  const getLocationIds = useCallback(() => {
    if (product === Products.HeroHQ) return getAllowedAndVisibleLocationsIds();

    if (product === Products.Events)
      return locationsByProduct.Events.visibleLocationIds;

    if (product === Products.InventoryHQ)
      return locationsByProduct.InventoryHQ.visibleLocationIds;

    return Object.keys(globalLocationMap);
  }, [
    getAllowedAndVisibleLocationsIds,
    product,
    globalLocationMap,
    locationsByProduct,
  ]);

  const sortAndHydrateRecursively = useCallback(
    (
      children: string[],
      parent: ApiLocation,
      acc: ApiLocation[],
      accIds: string[],
      accMap: { [key: string]: ApiLocation }
    ) => {
      // Filtering and sorting this level root nodes
      let nodes: ApiLocation[] = [];
      children.forEach((x) => {
        if (
          x !== "" &&
          x !== parent.id &&
          globalLocationMap[x].parent === parent.id
        ) {
          nodes.push(globalLocationMap[x]);
        }
      });

      // ! recursion exit condition: no elements left to sort
      if (nodes.length === 0) return;

      const sortedNodes = nodes.sort((a, b) =>
        a.name === b.name ? 0 : a.name > b.name ? 1 : -1
      );

      sortedNodes.forEach((loc) => {
        let shouldHideLocation = false;
        if (hideLocationCb) {
          shouldHideLocation = hideLocationCb(loc);
        }

        if (!includeInactive) {
          shouldHideLocation = loc.active === false;
        }

        if (shouldHideLocation === false) {
          acc.push(loc);
          accIds.push(loc.id);
          accMap[loc.id] = loc;
        }

        if (hideLocationChildren) {
          return;
        }

        const children = descendantsMap[loc.id];

        sortAndHydrateRecursively(children, loc, acc, accIds, accMap);
      });
    },
    [
      descendantsMap,
      globalLocationMap,
      hideLocationCb,
      hideLocationChildren,
      includeInactive,
    ]
  );

  /**
   * We're using a useCallback so we can control when it runs.
   * If we use a useMemo it will calculate the value on every render of the component that uses this hook
   */
  const getLocations = useCallback((): {
    locations: ApiLocation[];
    locationsIds: string[];
    locationMap: { [key: string]: ApiLocation };
  } => {
    const allLocationIds = getLocationIds();

    if (sorted === "alphabetical") {
      if (!product)
        return {
          locations: globalLocationList,
          locationsIds: allLocationIds,
          locationMap: globalLocationMap,
        };

      const { locations, locationMap, locationsIds } = allLocationIds.reduce<{
        locations: ApiLocation[];
        locationMap: { [key: string]: ApiLocation };
        locationsIds: string[];
      }>(
        (result, loc) => {
          const location = globalLocationMap[loc];
          if (!includeInactive && location.active) {
            result.locations.push(globalLocationMap[loc]);
            result.locationMap[loc] = globalLocationMap[loc];
            result.locationsIds.push(loc);
          }
          return result;
        },
        { locations: [], locationMap: {}, locationsIds: [] }
      );

      return {
        locations,
        locationsIds,
        locationMap,
      };
    }

    // Filtering and sorting root nodes
    let rootNodes: ApiLocation[] = [];
    allLocationIds.forEach((x) => {
      const isParent = globalLocationMap[x].parent === null;
      if (isParent) {
        rootNodes.push(globalLocationMap[x]);
        return;
      }
      const isParentInList = allLocationIds.some(
        (loc) => globalLocationMap[loc].id === globalLocationMap[x].parent
      );

      // an orphan node will be a root node
      if (!isParentInList) {
        rootNodes.push(globalLocationMap[x]);
      }
    });

    const sortedRootNodes = rootNodes.sort((a, b) =>
      a.name === b.name ? 0 : a.name > b.name ? 1 : -1
    );

    let sortedLocations: ApiLocation[] = [];
    let sortedLocationIds: string[] = [];
    let locationMap: { [key: string]: ApiLocation } = {};

    sortedRootNodes.forEach((parentLoc) => {
      let shouldHideLocation = false;
      if (hideLocationCb) {
        shouldHideLocation = hideLocationCb(parentLoc);
      }

      if (!includeInactive) {
        shouldHideLocation = parentLoc.active === false;
      }

      if (shouldHideLocation === false) {
        sortedLocations.push(parentLoc);
        sortedLocationIds.push(parentLoc.id);
        locationMap[parentLoc.id] = parentLoc;
      }

      if (hideLocationChildren) {
        return;
      }

      const children = descendantsMap[parentLoc.id];
      sortAndHydrateRecursively(
        children,
        parentLoc,
        sortedLocations,
        sortedLocationIds,
        locationMap
      );
    });

    return {
      locations: sortedLocations,
      locationsIds: sortedLocationIds,
      locationMap,
    };
  }, [
    sortAndHydrateRecursively,
    descendantsMap,
    globalLocationMap,
    getLocationIds,
    hideLocationCb,
    globalLocationList,
    hideLocationChildren,
    includeInactive,
    product,
    sorted,
  ]);

  return {
    descendantsMap,
    getLocationIds,
    getLocations,
  };
};
