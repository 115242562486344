import {
  ApiAccountSettings,
  ApiAsset,
  ApiClient,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SaveDataObject } from "../pages/requests/list-headers/RequestListActions";
import { buildMap } from "../utils/buildMap";

const ASSET_LIST_FILTERS = "asset-list-filters";

export interface GetAssetsThunkParams {
  apiClient: ApiClient;
  accountId: string;
  filters: AssetListFilters;
}

export interface UpdatedSavedFilterProps {
  apiClient: ApiClient;
  accountId: string;
  savedFilters: ApiAccountSettings[];
}

export const loadAssets = createAsyncThunk(
  "assets/load",
  async (params: GetAssetsThunkParams, thunkAPI) => {
    const { apiClient, accountId, filters } = params;

    const response = await apiClient.findAssets(accountId, filters);

    const settings = await apiClient.getCurrentUserSettings(accountId, [
      ASSET_LIST_FILTERS,
    ]);
    const userSavedFilters = settings[ASSET_LIST_FILTERS] || [];

    return { response, userSavedFilters };
  }
);

export const updatedSavedFilters = createAsyncThunk(
  "events-list/update-saved-filters",
  async ({ apiClient, accountId, savedFilters }: UpdatedSavedFilterProps) => {
    return apiClient.updateCurrentUserSettings(accountId, {
      "asset-list-filters": savedFilters,
    });
  }
);

export interface AssetListFilters {
  pageSize: number;
  page: number;
  includeInactive: boolean;
  search: string;
  categories: string[];
  locations: string[];
}
export interface AssetListSliceState {
  data: ApiAsset[];
  total: number;
  queryStringFilter: string;
  filters: AssetListFilters;
  assetsMap: { [key: string]: ApiAsset };
  savedSearchFilters: SaveDataObject[];
}

export const DEFAULT_ASSET_FILTERS: AssetListFilters = {
  page: 1,
  pageSize: 20,
  search: "",
  includeInactive: false,
  categories: [],
  locations: [],
};

export const assetListSlice = createSlice({
  name: "assetList",
  initialState: {
    data: [],
    assetsMap: {},
    total: 0,
    queryStringFilter: "",
    filters: { ...DEFAULT_ASSET_FILTERS },
    savedSearchFilters: [],
  } as AssetListSliceState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.filters.page = action.payload;
    },
    updateFilters: (
      state,
      { payload }: PayloadAction<Partial<AssetListFilters>>
    ) => {
      if (payload.search !== undefined && Object.keys(payload).length === 1) {
        payload.page = 1;
      }
      state.filters = {
        ...state.filters,
        ...payload,
      };

      state.queryStringFilter = btoa(JSON.stringify(state.filters));
    },
    unload: (state) => {
      state.data = [];
      state.total = 0;
      state.queryStringFilter = "";
      state.filters = { ...DEFAULT_ASSET_FILTERS };
      state.savedSearchFilters = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadAssets.fulfilled, (state, action) => {
      const { data, total, options } = action.payload.response;
      state.filters.page = options.page || 1;
      state.data = data;
      state.total = total;
      state.assetsMap = buildMap(data);
      state.savedSearchFilters = action.payload.userSavedFilters as [];
    });
    builder.addCase(updatedSavedFilters.fulfilled, (state, action) => {
      state.savedSearchFilters = action.payload[ASSET_LIST_FILTERS] as [];
    });
  },
});

export const { unload, updateFilters, setCurrentPage } = assetListSlice.actions;

export default assetListSlice.reducer;
