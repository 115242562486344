import { FormControl, FormErrorMessage, Text } from "@chakra-ui/react";
import { ApiBudget } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import { useAllocationsContext } from "../../../../../components/allocations/AllocationsContext";
import {
  BudgetAllocation,
  BudgetAllocationSource,
} from "../../../../../components/allocations/types";
import { useAuthentication } from "../../../../../components/auth/AuthProvider";
import { BudgetWithFundingSourcesAutocomplete } from "../../../../../components/selects/BudgetWithFundingSourcesAutocomplete";
import { formatCurrency } from "../../../../../utils/formatCurrency";

type AllocationBudgetControlProps = {
  value: BudgetAllocation["source"] | null;
  index: number;
  onAllocationChange?: () => void;
};

export const AllocationBudgetControl: FC<AllocationBudgetControlProps> = ({
  value,
  index,
  onAllocationChange,
}) => {
  const { allocations, setAllocations } = useAllocationsContext<ApiBudget>();

  const { currentAccount, apiClient } = useAuthentication();

  // eslint-disable-next-line
  const [_, meta] = useField(`allocations.${index}.source`);
  const { submitCount } = useFormikContext();

  const [defaultOptions, setDefaultOptions] = useState<Array<ApiBudget>>();

  const updateAllocation = useCallback(
    (source: ApiBudget | null) => {
      let sourceToUpdate: BudgetAllocationSource = null;

      if (source) {
        sourceToUpdate = {
          ...source,
          unallocatedAmount: source.unallocatedFunds,
        };
      }

      const tempList: BudgetAllocation[] = [...allocations];
      tempList[index] = {
        ...tempList[index],
        source: sourceToUpdate,
      };
      setAllocations(tempList);
      onAllocationChange && onAllocationChange();
    },
    [allocations, onAllocationChange, index, setAllocations]
  );

  useEffect(() => {
    apiClient
      .findBudgetsWithFundingSources(currentAccount.id, {
        pageSize: 10,
        excludeIds: allocations
          .map((alloc) => alloc.source?.id)
          .filter(Boolean) as string[],
        includeFullyAllocated: false,
      })
      .then((response) => {
        setDefaultOptions(response.data);
      });
  }, [allocations, apiClient, currentAccount.id]);

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <BudgetWithFundingSourcesAutocomplete
        value={value}
        onChange={updateAllocation}
        defaultOptions={defaultOptions}
        loadOptionParams={{
          excludeIds: allocations
            .map((alloc) => alloc.source?.id)
            .filter((value): value is string => Boolean(value)),
          includeFullyAllocated: false,
        }}
      />
      {!Boolean(meta.error) && value && (
        <Text
          ml={4}
          fontWeight="bold"
          fontSize="small"
        >{`Available funds ${formatCurrency(value?.unallocatedFunds ?? 0)}`}</Text>
      )}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
