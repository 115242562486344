import {
  Button,
  Flex,
  Heading,
  Icon,
  ModalFooter,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiInventoryPurchaseOrderItemStatus,
  ApiLocation,
  ApiVendor,
  CreateApiInventoryPurchaseOrder,
  CreateApiInventoryPurhaseOrderItem,
  UpdateApiInventoryPurchaseOrder,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { FormikProps } from "formik";
import { FC, useCallback, useRef } from "react";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Attachment } from "../../../../components/attachments/Attachments";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../../store";
import {
  handleSetIsOpenPurchseOrderForm,
  handleSetWorkingPurchaseOrder,
} from "../../../../store/request-form/request-form.slice";
import {
  createInventoryPurchaseOrder,
  updateInventoryPurchaseOrder,
} from "../../../../store/request-form/thunks/inventoryPurchaseOrders.thunk";
import { getChangedPropsFromObject } from "../../../../utils/compareObjects";
import { AccountModal } from "../../../account-settings/account-modal/AccountModal";
import { PurchaseOrderForm } from "../../../inventory/inventory-purchase-orders/purchase-order-forms/PurchaseOrderForm";
import { PurchaseOrderFormValues } from "../../../inventory/inventory-purchase-orders/purchase-order-forms/purchaseOrderFormHelper";
import { RequestPurchaseOrderList } from "./RequestPurchaseOrderList";

export const PurchaseOrderSection: FC = () => {
  const dispatch = useDispatch();
  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const formikRef = useRef<FormikProps<PurchaseOrderFormValues>>(null);

  const { apiClient, currentAccount } = useAuthentication();
  const { unitOfMeasureEach } = useSelector(
    (state: RootState) => state.localCache
  );
  const { request, inventoryPurchaseOrders } = useSelector(
    (state: RootState) => state.requestForm
  );
  const { isPurchaseOrderFormOpen, isLoading, workingPurchaseOrder } =
    inventoryPurchaseOrders;

  const modalFootersBgColor = useColorModeValue("white", "gray.700");

  const handleOnOpenPurchaseOrderForm = useCallback(() => {
    dispatch(handleSetIsOpenPurchseOrderForm(true));
  }, [dispatch]);

  const handleOnClosePurchaseOrderForm = useCallback(() => {
    dispatch(handleSetIsOpenPurchseOrderForm(false));
    dispatch(handleSetWorkingPurchaseOrder(null));
  }, [dispatch]);

  const handleMapItems = useCallback(
    (values: PurchaseOrderFormValues) => {
      const newItem: CreateApiInventoryPurhaseOrderItem = {
        name: values.name,
        cost: values.unitCost,
        unitOfMeasure: unitOfMeasureEach?.id || "",
        requestedQuantity: values.requestedQuantity,
        status: ApiInventoryPurchaseOrderItemStatus.pending,
      };
      return newItem;
    },
    [unitOfMeasureEach?.id]
  );

  const handleMapNewInventoryPurchaseOrder = useCallback(
    (values: PurchaseOrderFormValues) => {
      if (!request) {
        return;
      }
      const newItem = handleMapItems(values);
      const newPurchaseOrder: CreateApiInventoryPurchaseOrder = {
        items: [newItem],
        requestKey: request.key,
        supplier: values.supplier as ApiVendor,
        deliveryLocation: values.deliveryLocation as ApiLocation,
      };

      if (values.budget) {
        newPurchaseOrder.budget = values.budget;
      }
      if (values.asset) {
        newPurchaseOrder.asset = values.asset.id;
      }
      if (values.supplierURL) {
        newPurchaseOrder.supplierURL = values.supplierURL;
      }
      if (values.notes) {
        newPurchaseOrder.notes = values.notes;
      }

      return newPurchaseOrder;
    },
    [handleMapItems, request]
  );

  const handleOnCreatePurchaseOrder = useCallback(
    async (
      values: PurchaseOrderFormValues,
      attachmentsToCreate?: Attachment[]
    ) => {
      const newValues = handleMapNewInventoryPurchaseOrder(values);
      if (!newValues) {
        return;
      }

      await thunkDispatch(
        createInventoryPurchaseOrder({
          apiClient,
          attachmentsToCreate,
          accountId: currentAccount.id,
          purchaseOrder: newValues,
        })
      )
        .then(unwrapResult)
        .then((result) => {
          showToast(
            "success",
            `Purchase Request ${result.purchaseOrderNumber} was added successfully`
          );
        })
        .catch(() => {
          showToast(
            "error",
            "Something went wrong adding your purchase order, please try again!"
          );
        })
        .finally(() => {
          handleOnClosePurchaseOrderForm();
        });
    },
    [
      apiClient,
      currentAccount.id,
      handleMapNewInventoryPurchaseOrder,
      handleOnClosePurchaseOrderForm,
      showToast,
      thunkDispatch,
    ]
  );

  const handleOnUpdatePurchaseOrder = useCallback(
    async (
      values: PurchaseOrderFormValues,
      initialValues: PurchaseOrderFormValues,
      attachmentsToCreate?: Attachment[],
      attachmentsToRemove?: Attachment[]
    ) => {
      const { workingPurchaseOrder } = inventoryPurchaseOrders;
      if (!workingPurchaseOrder) {
        return;
      }
      const changedValues = getChangedPropsFromObject(initialValues, values);

      if (
        Object.keys(changedValues).length === 0 &&
        attachmentsToCreate?.length === 0 &&
        attachmentsToRemove?.length === 0
      ) {
        return;
      }

      let purchaseOrderToUpdate: UpdateApiInventoryPurchaseOrder = {};
      if (
        changedValues["name"] ||
        changedValues["unitCost"] ||
        changedValues["requestedQuantity"]
      ) {
        const newItems = handleMapItems(values);
        purchaseOrderToUpdate.items = [newItems];
        delete changedValues.name;
        delete changedValues.unitCost;
        delete changedValues.requestedQuantity;
      }

      purchaseOrderToUpdate = { ...purchaseOrderToUpdate, ...changedValues };

      await thunkDispatch(
        updateInventoryPurchaseOrder({
          apiClient,
          attachmentsToCreate,
          attachmentsToRemove,
          accountId: currentAccount.id,
          purchaseOrder: purchaseOrderToUpdate,
          purchaseOrderId: workingPurchaseOrder.id,
        })
      )
        .then(unwrapResult)
        .then((result) => {
          showToast(
            "success",
            `Purchase Request ${result.purchaseOrderNumber} was updated successfully`
          );
        })
        .catch(() => {
          showToast(
            "error",
            "Something went wrong updating your purchase order, please try again!"
          );
        })
        .finally(() => {
          handleOnClosePurchaseOrderForm();
        });
    },
    [
      apiClient,
      currentAccount.id,
      handleMapItems,
      handleOnClosePurchaseOrderForm,
      inventoryPurchaseOrders,
      showToast,
      thunkDispatch,
    ]
  );

  const handleOnSavePurchaseOrder = useCallback(
    async (
      values: PurchaseOrderFormValues,
      initialValues: PurchaseOrderFormValues,
      attachments?: Attachment[],
      attachmentsToRemove?: Attachment[]
    ) => {
      const attachmentsToCreate = attachments?.filter((att) => att.isNew);
      if (inventoryPurchaseOrders.workingPurchaseOrder) {
        handleOnUpdatePurchaseOrder(
          values,
          initialValues,
          attachmentsToCreate,
          attachmentsToRemove
        );
        return;
      }

      handleOnCreatePurchaseOrder(values, attachmentsToCreate);
    },
    [
      handleOnCreatePurchaseOrder,
      handleOnUpdatePurchaseOrder,
      inventoryPurchaseOrders.workingPurchaseOrder,
    ]
  );

  return (
    <Flex w="100%" flexDir="column" gap={4}>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading size="md">Non-Stock Items</Heading>
        <Button
          size="sm"
          colorScheme="blue"
          variant="outline"
          onClick={handleOnOpenPurchaseOrderForm}
        >
          <Icon as={MdAdd} mr={1} />
          Purchase Request
        </Button>
      </Flex>

      <Flex>
        <RequestPurchaseOrderList />
      </Flex>

      {isPurchaseOrderFormOpen && request && (
        <AccountModal
          closeOnOverlayClick={false}
          isOpen={isPurchaseOrderFormOpen}
          title={<PurchaseOrderFormTitle />}
          onClose={handleOnClosePurchaseOrderForm}
          content={
            <PurchaseOrderForm
              hideButtons
              formikRef={formikRef}
              isLoading={isLoading}
              handleOnSave={handleOnSavePurchaseOrder}
              handleOnCancel={handleOnClosePurchaseOrderForm}
              deliveryLocation={request.location as ApiLocation}
              workingPurchaseOrder={
                inventoryPurchaseOrders.workingPurchaseOrder
              }
              request={request}
            />
          }
          footer={
            <ModalFooter
              w="100%"
              zIndex={1}
              bottom={0}
              paddingTop={2}
              position="sticky"
              bgColor={modalFootersBgColor}
              justifyContent="space-between"
            >
              <Button
                size="sm"
                variant="ghost"
                colorScheme="blue"
                onClick={handleOnClosePurchaseOrderForm}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                colorScheme="blue"
                isLoading={isLoading}
                onClick={() => {
                  formikRef &&
                    formikRef.current &&
                    formikRef.current.submitForm();
                }}
              >
                {workingPurchaseOrder ? "Save" : "Add"} Purchase Request
              </Button>
            </ModalFooter>
          }
        />
      )}
    </Flex>
  );
};

const PurchaseOrderFormTitle: FC = () => {
  return (
    <Flex flexDir="column" gap={4}>
      <Heading size="lg" fontWeight="bold">
        Purchase Request
      </Heading>
      <Text opacity={0.8} fontSize="sm">
        This order will be processed after administrator approval.
      </Text>
    </Flex>
  );
};
