import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import {
  ApiBudget,
  ApiBudgetSummary,
  ApiProject,
} from "@operations-hero/lib-api-client";
import { FC, Fragment } from "react";
import { BudgetAllocationProvider } from "../../../../../components/allocations/BudgetsAllocationsProvider";
import { Allocation } from "../../../../../components/allocations/types";
import { BudgetBadge } from "../../../../../components/badges/BudgetBadge";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import { AddAllocationButton } from "./AddAllocationButton";

type BudgetAllocationsProps = {
  project: ApiProject;
  allocations: Allocation<ApiBudget | ApiBudgetSummary>[];
  isDisabled?: boolean;
};

export const BudgetAllocations: FC<BudgetAllocationsProps> = ({
  project,
  allocations,
  isDisabled = false,
}) => {
  const { colorMode } = useColorMode();

  return (
    <BudgetAllocationProvider
      initialAllocations={allocations as Allocation<ApiBudget>[]}
    >
      {({ allocations, totalAllocated }) => {
        return (
          <>
            <HStack w="full" justify="space-between">
              <Text>Budgets</Text>
              <AddAllocationButton
                project={project}
                buttonProps={{ isDisabled: isDisabled }}
              />
            </HStack>
            <VStack alignSelf="center" gap={3} w="full" justify="stretch">
              <Grid templateColumns="repeat(3, 1fr)" gap={4} w="full">
                {allocations.length === 0 ? (
                  <GridItem colSpan={3}>
                    <Text
                      color="gray.500"
                      fontStyle="italic"
                      justifySelf="center"
                    >
                      Add funds from budgets
                    </Text>
                  </GridItem>
                ) : (
                  allocations.map((allocation, index) => (
                    <Fragment
                      key={`budget-allocation::${allocation.source?.id ?? index}`}
                    >
                      <GridItem alignContent="center">
                        {allocation.source && (
                          <>
                            <BudgetBadge
                              value={allocation.source}
                              codeProps={{
                                fontWeight: "semibold",
                                fontSize: "sm",
                              }}
                              nameProps={{ fontSize: "md" }}
                            />
                            <Text
                              fontWeight="bold"
                              fontSize="small"
                              color="gray.500"
                              pl="20px"
                              ml={3}
                            >{`Available funds ${formatCurrency(allocation.source.unallocatedFunds ?? 0)}`}</Text>
                          </>
                        )}
                      </GridItem>
                      <GridItem alignContent="center">
                        <Box justifySelf="center">
                          <ArrowForwardIcon boxSize="5" />
                        </Box>
                      </GridItem>
                      <GridItem px="6" alignContent="center">
                        <Text justifySelf="end">
                          {formatCurrency(allocation.amount ?? 0)}
                        </Text>
                      </GridItem>
                    </Fragment>
                  ))
                )}
              </Grid>
            </VStack>
            <VStack
              w="full"
              backgroundColor={colorMode === "light" ? "gray.50" : "gray.700"}
              justify="stretch"
              alignItems="end"
              py="4"
              px="6"
              rounded="md"
              border={colorMode === "light" ? "none" : "solid"}
              borderWidth="thin"
              borderColor="gray.600"
            >
              <Text
                fontSize="large"
                fontWeight="bold"
              >{`${formatCurrency(totalAllocated)}`}</Text>
              <Text>Total Amount Allocated</Text>
            </VStack>
          </>
        );
      }}
    </BudgetAllocationProvider>
  );
};
