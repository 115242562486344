import { SimpleGrid, Skeleton } from "@chakra-ui/react";

export const InspectionsSkeleton = () => {
  const nColumns = 1;
  const nRows = 4;
  const skeletonCells = [...Array(nColumns * nRows)].map((_, i) => (
    <Skeleton height="90px" w="full" key={i} />
  ));
  return (
    <>
      <SimpleGrid
        columns={nColumns}
        spacingX="15px"
        spacingY="5px"
        overflowX="hidden"
        gap={5}
        templateColumns={`repeat(${nColumns}, 1fr)`}
      >
        {skeletonCells}
      </SimpleGrid>
    </>
  );
};
