import {
  ApiClient,
  ApiHours,
  UpdateApiEventSettings,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import {
  Attachment,
  mapApiAttachments,
} from "../components/attachments/Attachments";
import { DayHours } from "../components/weekly-time-picker/WeeklyTimePicker";
import { ContactInfoValues } from "../pages/account-settings/event-settings/ContactInfo";
import { PublicPortalFormValues } from "../pages/account-settings/event-settings/PublicPortalSettings";

export interface InitEventSettings {
  apiClient: ApiClient;
  accountId: string;
}

export const initEventSettings = createAsyncThunk(
  "event-settings/init",
  async (params: InitEventSettings, thunAPI) => {
    const { apiClient, accountId } = params;

    const [settings, attachments] = await Promise.all([
      apiClient.getEventSettings(accountId),
      apiClient.findPublicPortalAttachments(accountId),
    ]);
    return { settings, attachments };
  }
);

export type UpdateEventSettingsFields =
  | "isEnablePublicPortal"
  | "domain"
  | "defaultVenueHours"
  | "allowInternalUserSubmitEvents"
  | "allowAllStandardUsersToViewInternalEventGroups"
  | "allowAllStandardUsersToViewExternalEventGroups"
  | "allowInternalUsersViewCalendar"
  | "limitAllEventsByVenueHours"
  | "showConflictsOnlyForApprovedEvents"
  | "services"
  | "monthsInAdvance"
  | "eventConfirmationReminder"
  | "enableInvoicesForEvents"
  | "invoiceTerms"
  | "taxRate"
  | "invoiceHeaderText"
  | "invoiceFooterText"
  | "portalDefaultRateGroup"
  | "requireAttendees"
  | "publicPortalMinimumDaysInAdvance"
  | "requireGroupApproval";

export interface UpadateEventSettingsProps extends InitEventSettings {
  payload: { value: UpdateApiEventSettings; type: UpdateEventSettingsFields };
}

export const updateEventSettings = createAsyncThunk(
  "event-settings/update",
  async (params: UpadateEventSettingsProps, thunkAPI) => {
    const { apiClient, accountId, payload } = params;
    const response = await apiClient.updateEventSettings(accountId, {
      ...payload.value,
    });

    return { param: payload, response };
  }
);

export interface ContactInfoParams extends InitEventSettings {
  payload: ContactInfoValues;
}
export const updateEventSettingsContactInfo = createAsyncThunk(
  "event-settings/update-contact-info",
  async (params: ContactInfoParams) => {
    const { apiClient, accountId, payload } = params;
    const response = await apiClient.updateEventSettings(accountId, {
      ...payload,
    });

    return { response };
  }
);

interface UpdatePublicPortalInfoParams extends InitEventSettings {
  payload: PublicPortalFormValues;
}
export const updateEventPortalPublicInfo = createAsyncThunk(
  "event-settings/update-public-portal-info",
  async (params: UpdatePublicPortalInfoParams, thunkAPI) => {
    const { apiClient, accountId, payload } = params;
    const response = await apiClient.updateEventSettings(accountId, {
      ...payload,
    });

    return { response };
  }
);

export const uploadPublicPortalAttachments = createAsyncThunk(
  "event-settings/upload-public-portal-attachments",
  async (params: InitEventSettings, thunkAPI) => {
    const { apiClient, accountId } = params;

    const { publicPortalAttachments } = (thunkAPI.getState() as RootState)
      .eventSettingsSlice;

    const filteredAttachments = publicPortalAttachments.filter(
      (att) => att.isNew && att.uploadId !== undefined
    );

    if (filteredAttachments.length === 0) thunkAPI.rejectWithValue({});

    const response = await Promise.all(
      filteredAttachments.map((att) =>
        apiClient.createPublicPortalAttachments(accountId, {
          uploadId: att.uploadId || "",
          name: att.name,
          label: att.label,
        })
      )
    );
    return response;
  }
);

interface UpdateEventSettingsSlice extends UpdateApiEventSettings {
  isLoading: boolean;
  publicPortalAttachments: Attachment[];
}

const initialState: UpdateEventSettingsSlice = {
  domain: "",
  isLoading: true,
  isEnablePublicPortal: false,
  requireGroupApproval: false,
  allowInternalUserSubmitEvents: false,
  allowAllStandardUsersToViewInternalEventGroups: false,
  allowAllStandardUsersToViewExternalEventGroups: false,
  allowInternalUsersViewCalendar: false,
  limitAllEventsByVenueHours: false,
  defaultVenueHours: {} as DayHours,
  monthsInAdvance: null,
  eventConfirmationReminder: undefined,
  enableInvoicesForEvents: false,
  invoiceTerms: null,
  taxRate: undefined,
  invoiceContactName: null,
  invoiceContactPhone: null,
  invoiceContactEmail: null,
  invoiceContactAddress1: null,
  invoiceContactAddress2: null,
  invoiceContactState: null,
  invoiceContactCity: null,
  invoiceContactPostalCode: null,
  publicPortalAttachments: [],
  invoiceHeaderText: null,
  invoiceFooterText: null,
  publicPortalTerms: null,
  requireAttendees: false,
  portalDefaultRateGroup: null,
  publicPortalMinimumDaysInAdvance: null,
};

export const eventSettingsSlice = createSlice({
  name: "event-settings",
  initialState: initialState,
  reducers: {
    unloadEventSettings: (state: UpdateEventSettingsSlice) => {
      state.isLoading = true;
      state.domain = initialState.domain;
      state.defaultVenueHours = initialState.defaultVenueHours;
      state.isEnablePublicPortal = initialState.isEnablePublicPortal;
      state.requireGroupApproval = initialState.requireGroupApproval;
      state.monthsInAdvance = initialState.monthsInAdvance;
      state.limitAllEventsByVenueHours =
        initialState.limitAllEventsByVenueHours;
      state.eventConfirmationReminder = initialState.eventConfirmationReminder;
      state.enableInvoicesForEvents = initialState.enableInvoicesForEvents;
      state.invoiceTerms = initialState.invoiceTerms;
      state.taxRate = initialState.taxRate;
      state.invoiceHeaderText = initialState.invoiceHeaderText;
      state.publicPortalTerms = initialState.publicPortalTerms;
      state.requireAttendees = initialState.requireAttendees;
      state.portalDefaultRateGroup = initialState.portalDefaultRateGroup;
    },

    updateDomain: (
      state: UpdateApiEventSettings,
      action: PayloadAction<string>
    ) => {
      state.domain = action.payload;
    },

    updateLocalDefaultHours: (
      state: UpdateApiEventSettings,
      action: PayloadAction<ApiHours>
    ) => {
      state.defaultVenueHours = action.payload;
    },

    updateIsEnablePublicPortal: (
      state: UpdateApiEventSettings,
      action: PayloadAction<boolean>
    ) => {
      state.isEnablePublicPortal = action.payload;
    },
    updateRequireGroupApproval: (
      state: UpdateApiEventSettings,
      action: PayloadAction<boolean>
    ) => {
      state.requireGroupApproval = action.payload;
    },
    updateDefaultServices: (
      state: UpdateApiEventSettings,
      action: PayloadAction<string[]>
    ) => {
      state.services = action.payload;
    },

    updateMonthsInAdvance: (
      state: UpdateApiEventSettings,
      action: PayloadAction<number>
    ) => {
      state.monthsInAdvance = action.payload;
    },

    updateAdvanceNoticeDays: (
      state: UpdateApiEventSettings,
      action: PayloadAction<number>
    ) => {
      state.eventConfirmationReminder = {
        enable: state.eventConfirmationReminder?.enable
          ? state.eventConfirmationReminder?.enable
          : false,
        advanceNoticeDays: action.payload,
      };
    },
    updateInvoiceTerms: (
      state: UpdateApiEventSettings,
      action: PayloadAction<number | null>
    ) => {
      state.invoiceTerms = action.payload;
    },
    updateTaxRate: (
      state: UpdateApiEventSettings,
      action: PayloadAction<number | undefined>
    ) => {
      state.taxRate = action.payload;
    },
    updatePublicPortalAttachments: (
      state: UpdateEventSettingsSlice,
      action: PayloadAction<{ attachments: Attachment[] }>
    ) => {
      state.publicPortalAttachments = action.payload.attachments;
    },
    updateHeaderText: (
      state: UpdateApiEventSettings,
      action: PayloadAction<string | null>
    ) => {
      state.invoiceHeaderText = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(initEventSettings.fulfilled, (state, action) => {
      const { settings, attachments } = action.payload;
      const formatedAttachments = mapApiAttachments(attachments.data);

      state.isEnablePublicPortal = settings.isEnablePublicPortal;
      state.requireGroupApproval = settings.requireGroupApproval;
      state.domain = settings.domain;
      state.defaultVenueHours = settings.defaultVenueHours;
      state.allowInternalUserSubmitEvents =
        settings.allowInternalUserSubmitEvents;
      state.allowAllStandardUsersToViewInternalEventGroups =
        settings.allowAllStandardUsersToViewInternalEventGroups;
      state.allowAllStandardUsersToViewExternalEventGroups =
        settings.allowAllStandardUsersToViewExternalEventGroups;
      state.allowInternalUsersViewCalendar =
        settings.allowInternalUsersViewCalendar;
      state.limitAllEventsByVenueHours = settings.limitAllEventsByVenueHours;
      state.showConflictsOnlyForApprovedEvents =
        settings.showConflictsOnlyForApprovedEvents;
      state.isLoading = false;
      const services = settings.services.map((service) => service.id);
      state.services = services;
      state.monthsInAdvance = settings.monthsInAdvance;
      state.eventConfirmationReminder = settings.eventConfirmationReminder;
      state.enableInvoicesForEvents = settings.enableInvoicesForEvents;
      state.invoiceTerms = settings.invoiceTerms;
      state.taxRate = settings.taxRate;
      state.invoiceContactName = settings.invoiceContactName;
      state.invoiceContactPhone = settings.invoiceContactPhone;
      state.invoiceContactEmail = settings.invoiceContactEmail;
      state.invoiceContactAddress1 = settings.invoiceContactAddress1;
      state.invoiceContactAddress2 = settings.invoiceContactAddress2;
      state.invoiceContactState = settings.invoiceContactState;
      state.invoiceContactCity = settings.invoiceContactCity;
      state.invoiceContactPostalCode = settings.invoiceContactPostalCode;
      state.publicPortalAttachments = formatedAttachments;
      state.publicPortalIntro = settings.publicPortalIntro;
      state.publicPortalContactEmail = settings.publicPortalContactEmail;
      state.publicPortalContactPhone = settings.publicPortalContactPhone;
      state.invoiceHeaderText = settings.invoiceHeaderText;
      state.invoiceFooterText = settings.invoiceFooterText;
      state.portalDefaultRateGroup = settings.portalDefaultRateGroup;
      state.publicPortalTerms = settings.publicPortalTerms;
      state.requireAttendees = settings.requireAttendees;
      state.publicPortalMinimumDaysInAdvance =
        settings.publicPortalMinimumDaysInAdvance;
    });
    builder.addCase(initEventSettings.rejected, (state) => {
      state.isLoading = initialState.isLoading;
      state.domain = initialState.domain;
      state.defaultVenueHours = initialState.defaultVenueHours;
      state.allowInternalUserSubmitEvents =
        initialState.allowInternalUserSubmitEvents;
      state.allowAllStandardUsersToViewInternalEventGroups =
        initialState.allowAllStandardUsersToViewInternalEventGroups;
      state.allowAllStandardUsersToViewExternalEventGroups =
        initialState.allowAllStandardUsersToViewExternalEventGroups;
      state.allowInternalUsersViewCalendar =
        initialState.allowInternalUsersViewCalendar;
      state.limitAllEventsByVenueHours =
        initialState.limitAllEventsByVenueHours;
      state.showConflictsOnlyForApprovedEvents =
        initialState.showConflictsOnlyForApprovedEvents;
      state.isEnablePublicPortal = initialState.isEnablePublicPortal;
      state.requireGroupApproval = initialState.requireGroupApproval;
      state.services = initialState.services;
      state.monthsInAdvance = initialState.monthsInAdvance;
      state.eventConfirmationReminder = initialState.eventConfirmationReminder;
      state.enableInvoicesForEvents = initialState.enableInvoicesForEvents;
      state.invoiceTerms = initialState.invoiceTerms;
      state.taxRate = initialState.taxRate;
      state.invoiceContactName = initialState.invoiceContactName;
      state.invoiceContactPhone = initialState.invoiceContactPhone;
      state.invoiceContactEmail = initialState.invoiceContactEmail;
      state.invoiceContactAddress1 = initialState.invoiceContactAddress1;
      state.invoiceContactAddress2 = initialState.invoiceContactAddress2;
      state.invoiceContactState = initialState.invoiceContactState;
      state.invoiceContactCity = initialState.invoiceContactCity;
      state.invoiceContactPostalCode = initialState.invoiceContactPostalCode;
      state.publicPortalIntro = initialState.publicPortalIntro;
      state.publicPortalContactEmail = initialState.publicPortalContactEmail;
      state.publicPortalContactPhone = initialState.publicPortalContactPhone;
      state.invoiceHeaderText = initialState.invoiceHeaderText;
      state.portalDefaultRateGroup = initialState.portalDefaultRateGroup;
      state.requireAttendees = initialState.requireAttendees;
    });

    builder.addCase(updateEventSettings.fulfilled, (state, action) => {
      const { payload } = action;
      const { param, response } = payload;

      //@ts-ignore
      state[param.type] = response[param.type] as any;
    });

    builder.addCase(
      updateEventSettingsContactInfo.fulfilled,
      (state, action) => {
        const { payload } = action;
        const { response } = payload;
        state.invoiceContactName = response.invoiceContactName;
        state.invoiceContactPhone = response.invoiceContactPhone;
        state.invoiceContactEmail = response.invoiceContactEmail;
        state.invoiceContactAddress1 = response.invoiceContactAddress1;
        state.invoiceContactAddress2 = response.invoiceContactAddress2;
        state.invoiceContactState = response.invoiceContactState;
        state.invoiceContactCity = response.invoiceContactCity;
        state.invoiceContactPostalCode = response.invoiceContactPostalCode;
        state.invoiceHeaderText = response.invoiceHeaderText;
      }
    );

    builder.addCase(updateEventPortalPublicInfo.fulfilled, (state, action) => {
      const { response } = action.payload;
      state.domain = response.domain;
      state.monthsInAdvance = response.monthsInAdvance;
      state.publicPortalIntro = response.publicPortalIntro;
      state.isEnablePublicPortal = response.isEnablePublicPortal;
      state.requireGroupApproval = response.requireGroupApproval;
      state.publicPortalContactEmail = response.publicPortalContactEmail;
      state.publicPortalContactPhone = response.publicPortalContactPhone;
      state.portalDefaultRateGroup = response.portalDefaultRateGroup;
      state.publicPortalTerms = response.publicPortalTerms;
      state.publicPortalMinimumDaysInAdvance =
        response.publicPortalMinimumDaysInAdvance;
      state.requireAttendees = response.requireAttendees;
    });

    builder.addCase(
      uploadPublicPortalAttachments.fulfilled,
      (state, action) => {
        const attachments = action.payload;
        const formatedAttachments = mapApiAttachments(attachments);
        const currentAttachments = state.publicPortalAttachments.filter(
          (at) => !at.isNew
        );

        state.publicPortalAttachments = [
          ...currentAttachments,
          ...formatedAttachments,
        ];
      }
    );
  },
});

export const {
  unloadEventSettings,
  updateIsEnablePublicPortal,
  updateDomain,
  updateLocalDefaultHours,
  updateDefaultServices,
  updateMonthsInAdvance,
  updateAdvanceNoticeDays,
  updateInvoiceTerms,
  updateTaxRate,
  updatePublicPortalAttachments,
  updateRequireGroupApproval,
} = eventSettingsSlice.actions;

export default eventSettingsSlice.reducer;
