import {
  ApiClient,
  ApiInspection,
  ApiInspectionConfig,
  ApiInspectionScore,
  ApiInspectionScoreConfig,
  ApiLocation,
  ApiLocationFloor,
  ApiLocationType,
  ApiRequest,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../..";
import {
  calculateBuildingStatus,
  calculateRoomStatus,
} from "../../../../../utils/calcalateStatusInspection";
import {
  InspectionBuildingMap,
  InspectionBuildingToFloorsMap,
  InspectionDetailsSliceProps,
  InspectionFloorEntry,
  InspectionFloorToRoomsMap,
  InspectionRoomContextMap,
  InspectionRoomEntry,
  LocationRoomStatus,
  LocationSubBuildingStatus,
} from "../inspection-details.slice";

export type ScoresRecord = Record<string, ApiInspectionScore>;

type LoadInspectionsDetailsParams = {
  apiClient: ApiClient;
  inspectionId: string;
};

type LoadInspectionsDetailsResult = {
  inspection: ApiInspection;
  inspectionConfig: ApiInspectionConfig;
  scores: ScoresRecord;
  scoreConfigMap: Record<string, ApiInspectionScoreConfig>;
  buildingMap: InspectionBuildingMap;
  buildingToFloorsMap: InspectionBuildingToFloorsMap;
  floorToRoomsMap: InspectionFloorToRoomsMap;
  roomContextMap: InspectionRoomContextMap;
  requestMap: Record<string, ApiRequest>;
};

export const loadInspectionsDetails = createAsyncThunk<
  LoadInspectionsDetailsResult,
  LoadInspectionsDetailsParams
>("inspections-details/load", async ({ apiClient, inspectionId }, thunkAPI) => {
  const { auth, localCache } = thunkAPI.getState() as RootState;
  const { currentAccount } = auth;

  const { locationMap, descendantsMap } = localCache;

  const inspection = await apiClient.getInspection(
    currentAccount.id,
    inspectionId
  );

  const inspectionConfig = await apiClient.getInspectionConfig(
    currentAccount.id,
    inspection.configId
  );

  const scoresArray = await apiClient.findInspectionsScore(
    currentAccount.id,
    inspection.id
  );

  const totalCategories = inspectionConfig.categoryGroups.reduce(
    (acc, group) => acc + group.categories.length,
    0
  );

  const allRequestIds: string[] = [];

  const scores: ScoresRecord = scoresArray.reduce((acc, score) => {
    const key = `${score.locationId}::${score.inspectionCategoryId}`;
    acc[key] = score;
    if (score.requestId) {
      allRequestIds.push(score.requestId);
    }
    return acc;
  }, {} as ScoresRecord);

  const requestMap: Record<string, ApiRequest> = {};
  if (allRequestIds.length) {
    let totalRequests = 0;
    let page = 0;
    do {
      page += 1;
      const requests = await apiClient.findRequests(currentAccount.id, {
        idsOrKeys: allRequestIds,
        page,
        pageSize: 100,
      });
      if (totalRequests === 0) {
        totalRequests = requests.total;
      }
      for (const request of requests.data) {
        if (!request.inspectionId || request.inspectionId !== inspection.id) {
          continue;
        }
        requestMap[request.id] = request;
      }
    } while (page * 100 < totalRequests);
  }

  const scoreConfigMap = inspectionConfig.scores.reduce(
    (acc, scoreConfig) => {
      acc[scoreConfig.id] = scoreConfig;
      return acc;
    },
    {} as Record<string, ApiInspectionScoreConfig>
  );

  // the list of locations represents one fo the following:
  // - Sub building/warehouse and their children count as an inspection building
  // - the inspection location (self) (if it has rooms or areas not in a sub building)
  const selfAndDescendantsIds = descendantsMap[inspection.location.id] || [];
  const selfAndDescendantsHydrated: Record<string, ApiLocation> = {};

  // this is a tricky bit here
  // the idea for inspectable location types is to map them to building organizational sections.
  // to achieve this each item walks up its ancestors to the first "section" to map
  // if there is no section add a special "Inspectable Locations section"
  // lastly each section's list of locations should be sorted and counted;
  const buildingsMap = selfAndDescendantsIds.reduce<
    Record<string, ApiLocation>
  >((result, id) => {
    // map to location
    const hydrated = locationMap[id];
    if (!hydrated) {
      return result;
    }

    selfAndDescendantsHydrated[hydrated.id] = hydrated;
    // filter for buildings or warehouse
    if (
      hydrated.type === ApiLocationType.building ||
      hydrated.type === ApiLocationType.warehouse
    ) {
      result[hydrated.id] = hydrated;
    }
    return result;
  }, {});

  const buildingMap: InspectionBuildingMap = {};
  const buildingToFloorsMap: InspectionBuildingToFloorsMap = {};
  const floorToRoomsMap: InspectionFloorToRoomsMap = {};
  const roomContextMap: InspectionRoomContextMap = {};

  for (let building of Object.values(buildingsMap)) {
    const buildingEntry = (buildingMap[building.id] = buildingMap[
      building.id
    ] || {
      location: building,
      inspectedRooms: 0,
      totalRooms: 0,
      sections: 0,
      violations: {},
      status: LocationSubBuildingStatus.pending,
    });

    // prevent looking up self node again
    const buildingDescendants =
      building.id === inspection.location.id
        ? selfAndDescendantsIds
        : descendantsMap[building.id] || [];

    let floorsMap = (buildingToFloorsMap[building.id] =
      buildingToFloorsMap[building.id] ?? {});

    for (let id of buildingDescendants) {
      const hydrated = selfAndDescendantsHydrated[id];

      // not sure how this lookup would miss, but buildings are unnested from above and should be ignored
      if (!hydrated) {
        continue;
      }

      // First, add all floors to the floorsMap to count it and render if have no rooms even
      if (hydrated.type === ApiLocationType.floor) {
        const parentFloorId = hydrated.id;

        let floorEntry = floorsMap[parentFloorId];

        if (!floorEntry && buildingEntry.location.id === hydrated.parent) {
          buildingEntry.sections += 1;
          floorEntry = floorsMap[parentFloorId] = {
            location: hydrated,
            inspectedRooms: 0,
            totalRooms: 0,
          };
        }
      }

      // nested types outside of these are ignored
      if (
        hydrated.type !== ApiLocationType.room &&
        hydrated.type !== ApiLocationType.area &&
        hydrated.type !== ApiLocationType.storage &&
        hydrated.type !== ApiLocationType.field
      ) {
        continue;
      }
      // remove the building from the treePath and remove self from the treePath
      // this is to reduce the number of lookups we need to do to figure out which section it belongs to
      const minimalAncestors = hydrated.treePath
        .replace(building.treePath, "")
        .replace(`${id}.`, "")
        .split(".")
        .filter(Boolean)
        .reverse();

      // this maybe a top level building with a nested building coming up in the loop we should ignore it if any buildings are a minimal ancestor
      // since the minimal ancestor remove the current building nodes, a building in the remaining nodes is a nested building
      // if there is no nested building lests decide what floor it belongs to
      let hasNestedBuildingAncestor = false;
      let parentFloorId = "";
      // these are combined to have 1 loop over ancestors and in turn less hash lookups
      for (const ancestorId of minimalAncestors) {
        if (hasNestedBuildingAncestor) {
          // prevents further lookups in the ancestory
          continue;
        }

        const hydratedAncestor = selfAndDescendantsHydrated[ancestorId];
        if (!hydratedAncestor) {
          continue;
        }

        if (
          hydratedAncestor.type === ApiLocationType.building ||
          hydratedAncestor.type === ApiLocationType.warehouse
        ) {
          hasNestedBuildingAncestor = true;
          continue;
        }

        if (!parentFloorId && hydratedAncestor.type === ApiLocationType.floor) {
          parentFloorId = hydratedAncestor.id;
        }
      }

      // This location is part of a sub building, it will be picked up there.
      if (hasNestedBuildingAncestor) {
        continue;
      }

      // if there are no defined floors it will be put into the all-rooms fake floor.
      parentFloorId = parentFloorId || "all-rooms";

      let floorEntry = floorsMap[parentFloorId];

      // create the floor to room map, update the building entry for the new section
      if (!floorEntry) {
        buildingEntry.sections += 1;
        if (parentFloorId === "all-rooms") {
          floorEntry = floorsMap[parentFloorId] = generateAllRoomsFloor();
        } else {
          const parentFloor = selfAndDescendantsHydrated[
            parentFloorId
          ] as ApiLocationFloor;
          floorEntry = floorsMap[parentFloorId] = {
            location: parentFloor,
            inspectedRooms: 0,
            totalRooms: 0,
          };
        }
      }

      // setup room map on the floor
      const roomMap = (floorToRoomsMap[parentFloorId] =
        floorToRoomsMap[parentFloorId] || {});

      const roomScores = Object.keys(scores).filter((key) =>
        key.includes(hydrated.id)
      );
      // TODO: refactor to nested maps;
      const totalNumericalValue = roomScores.reduce(
        (sum, key) => sum + (scores[key].numericalValue || 0),
        0
      );

      const violations = roomScores.reduce(
        (acc, key) => {
          const score = scores[key];
          if (!score.scoreConfigId) return acc;

          const scoreConfig = scoreConfigMap[score.scoreConfigId];
          if (scoreConfig && scoreConfig.justificationRequired) {
            acc[score.scoreConfigId] = (acc[score.scoreConfigId] || 0) + 1;
          }
          return acc;
        },
        {} as Record<string, number>
      );

      const entry: InspectionRoomEntry = {
        location: hydrated,
        violations,
        totalNumericalValue,
        status: calculateRoomStatus(hydrated.id, scores, totalCategories),
      };

      roomMap[hydrated.id] = entry;
      roomContextMap[hydrated.id] = {
        building: buildingEntry,
        floor: floorEntry,
        room: entry,
      };

      // add up the stats while we go
      if (entry.status === LocationRoomStatus.completed) {
        buildingEntry.inspectedRooms += 1;
        floorEntry.inspectedRooms += 1;
      }

      buildingEntry.totalRooms += 1;
      floorEntry.totalRooms += 1;

      for (const [scoreConfigId, count] of Object.entries(violations)) {
        buildingEntry.violations[scoreConfigId] =
          (buildingEntry.violations[scoreConfigId] || 0) + count;
      }
    }

    // final sweep to check the building status
    buildingEntry.status = calculateBuildingStatus(
      building.id,
      buildingToFloorsMap,
      floorToRoomsMap
    );
  }

  return {
    inspection,
    inspectionConfig,
    scores,
    scoreConfigMap,
    buildingMap,
    buildingToFloorsMap,
    floorToRoomsMap,
    roomContextMap,
    requestMap,
  };
});

export const loadInspectionsDetailsHandler = (
  builder: ActionReducerMapBuilder<InspectionDetailsSliceProps>
) => {
  builder.addCase(loadInspectionsDetails.pending, (state) => {
    state.loadingStatus = "pending";
  });
  builder.addCase(loadInspectionsDetails.rejected, (state) => {
    state.loadingStatus = "rejected";
  });
  builder.addCase(loadInspectionsDetails.fulfilled, (state, action) => {
    state.loadingStatus = "fulfilled";
    const {
      inspection,
      inspectionConfig,
      scores,
      scoreConfigMap,
      buildingMap,
      buildingToFloorsMap,
      floorToRoomsMap,
      roomContextMap,
      requestMap,
    } = action.payload;
    state.inspection = inspection;
    state.inspectionConfig = inspectionConfig;
    state.scores = scores;
    state.totalScores = Object.keys(scores).length;
    state.scoreConfigMap = scoreConfigMap;
    state.buildingMap = buildingMap;
    state.buildingToFloorsMap = buildingToFloorsMap;
    state.floorToRoomsMap = floorToRoomsMap;
    state.roomContextMap = roomContextMap;
    state.requestMap = requestMap;
  });
};

export const generateAllRoomsFloor = (): InspectionFloorEntry => ({
  location: {
    id: "all-rooms",
    type: ApiLocationType.floor,
    name: "Inspectable Rooms",
    active: true,
    colorId: "",
    externalId: "",
    hiddenProducts: [],
    labels: [],
    parent: null,
    treePath: "",
    phone: "",
    website: "",
  },
  inspectedRooms: 0,
  totalRooms: 0,
});
