import React, { lazy, useMemo } from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { RootState } from "../../../store";
import { InventoryPlacedOrders } from "../../inventory/inventory-orders/inventory-placed-orders/InventoryPlacedOrders";
import InventoryOrder from "../../inventory/inventory-orders/InventoryOrders";
import { InventoryWrapper } from "./InventoryRoutesWrapper";

const InventorySettings = lazy(
  () => import("../inventory-settings/InventorySettings")
);

const CatalogPage = lazy(() => import("../inventory-settings/CatalogPage"));
const CatalogSettings = lazy(
  () => import("../inventory-settings/CatalogSettings/CatalogSettings")
);

const InventoryItem = lazy(
  () =>
    import("../../inventory/inventory-items/inventory-item-form/InventoryItem")
);
const InventoryItemsList = lazy(
  () =>
    import("../../inventory/inventory-items/inventory-item-list/IntentoryItems")
);
const InventoryLocations = lazy(
  () => import("../../inventory/inventory-locations/InventoryLocations")
);
const InventoryRequest = lazy(
  () => import("../../inventory/inventory-request/InventoryRequest")
);

export const useInventoryRoutes = (hasInventory: boolean) => {
  const { isInventoryAdmin, isInventoryStandard } = useAuthentication();

  const { isCatalogAdmin, isCatalogIssuer } = useSelector(
    (state: RootState) => state.localCache
  );

  const hasItemsAccess = useMemo(
    () => isInventoryAdmin || isCatalogAdmin || isCatalogIssuer,
    [isInventoryAdmin, isCatalogAdmin, isCatalogIssuer]
  );

  if (!hasInventory && !isInventoryStandard) return { routes: [] };

  const routes: React.ReactNode[] = [
    <Route
      path="requests"
      key="routes::inventory/requests"
      element={<InventoryWrapper Element={InventoryItemsList} />}
    />,
    <Route
      path="new-request"
      key="routes::invetory/new-request"
      element={<InventoryWrapper Element={InventoryRequest} />}
    />,
    <Route
      path="orders"
      key="routes::inventory/orders"
      element={<InventoryWrapper Element={InventoryOrder} />}
    />,
  ];

  if (hasItemsAccess) {
    routes.push(
      <Route
        path="checkouts"
        key="routes::inventory/checkouts"
        element={<InventoryWrapper Element={InventoryItemsList} />}
      />,
      <Route
        path="log"
        key="routes::inventory/log"
        element={<InventoryWrapper Element={InventoryItemsList} />}
      />,
      <Route
        path="placed-orders"
        key="routes::inventory/placed-orders"
        element={<InventoryWrapper Element={InventoryPlacedOrders} />}
      />,
      <Route
        path="items"
        key="routes::inventory/items"
        element={<InventoryWrapper Element={InventoryItemsList} />}
      />,
      <Route
        path="items/:id"
        key="routes::inventory/items/id"
        element={<InventoryWrapper Element={InventoryItem} />}
      />
      // <Route
      //   path="purchase-orders"
      //   key="routes::inventory/purchase-orders"
      //   element={<InventoryWrapper Element={InventoryItemsList} />}
      // />
    );
  }

  if (isInventoryAdmin) {
    routes.push(
      <Route
        path="settings"
        key="routes::inventory/settings"
        element={<InventoryWrapper Element={InventorySettings} />}
      />,
      <Route
        path="catalogs"
        key="routes::inventory/catalogs"
        element={<InventoryWrapper Element={CatalogPage} />}
      />,
      <Route
        path="catalogs/:id"
        key="routes::inventory/catalogs/id"
        element={<InventoryWrapper Element={CatalogSettings} />}
      />,
      <Route
        path="locations"
        key="routes::inventory/locations"
        element={<InventoryWrapper Element={InventoryLocations} />}
      />,
      <Route
        path="purchase-orders"
        key="routes::inventory/purchase-orders"
        element={<InventoryWrapper Element={InventoryItemsList} />}
      />
    );
  }

  return { routes };
};
