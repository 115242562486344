import { Button, ModalFooter } from "@chakra-ui/react";
import {
  ApiProject,
  CreateApiProjectBudget,
} from "@operations-hero/lib-api-client";
import { FormikProps } from "formik";
import { FC, useCallback, useRef, useState } from "react";
import { AccountModal } from "../../../../account-settings/account-modal/AccountModal";
import {
  NewBudgetAllocationForm,
  NewBudgetAllocationProps,
} from "./NewAllocationForm";

export type NewBudgetAllocationModalProps = {
  project: ApiProject;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    budget: CreateApiProjectBudget
  ) => Promise<void | { success: boolean }>;
};

export const NewBudgetAllocationModal: FC<NewBudgetAllocationModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  project,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const newAllocationFormRef =
    useRef<FormikProps<NewBudgetAllocationProps>>(null);

  const handleSubmit = useCallback(() => {
    if (newAllocationFormRef.current) {
      setIsSubmitting(true);
      newAllocationFormRef.current.submitForm().then(() => {
        setIsSubmitting(false);
        if (newAllocationFormRef.current?.isValid) onClose();
      });
    }
  }, [onClose]);

  return !isOpen ? null : (
    <AccountModal
      size="xl"
      title={"Allocate funds"}
      closeButton={false}
      isOpen={isOpen}
      onClose={onClose}
      content={
        <NewBudgetAllocationForm
          project={project}
          onSubmit={onSubmit}
          formRef={newAllocationFormRef}
        />
      }
      footer={
        <ModalFooter gap={2}>
          <Button variant="outline" size="sm" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme={"blue"}
            size="sm"
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            Allocate funds
          </Button>
        </ModalFooter>
      }
    />
  );
};
