import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiLocation,
  ApiLocationType,
  CreateApiLocation,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { Field, FieldArray, Form, Formik } from "formik";
import React, { FC, useCallback, useMemo } from "react";
import { BsTrash } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import { reloadLocations } from "../../../store/local-cache.slice";
import { ObjectWithId } from "../../../utils/getId";

const LocationFormSchema = yup.object().shape({
  name: yup.string().required(),
});
export type LocationAndSectionProps = {
  locationId: string;
  onClose: () => void;
  reloadInspectionDetails?: () => void;
  initialData?: ApiLocation & { sections: { id?: string; name: string }[] };
};

export const MiniLocationAndSectionForm: FC<LocationAndSectionProps> = ({
  locationId,
  onClose,
  reloadInspectionDetails,
  initialData,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const { locationMap } = useSelector((state: RootState) => state.localCache);
  const iconsColor = useColorModeValue("gray.500", "gray.300");
  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();
  const reactLocation = useLocation();
  const initialValues = useMemo(
    () =>
      initialData || {
        id: "",
        name: "",
        squareFeet: null,
        sections: [{ name: "" }],
        type: ApiLocationType.building,

        colorId: "#FFFFFF",
        externalId: null,
        active: true,
        parent: locationId,
        phone: null,
        website: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        postalCode: null,
        county: null,
        latitude: null,
        longitude: null,
        built: null,
        lastRenovated: null,

        capacity: null,
        enrollment: null,
        labels: [],
        treePath: "",
        hiddenProducts: [],
      },
    [locationId, initialData]
  );

  const saveLocation = useCallback(
    (
      values: CreateApiLocation & { sections: { id?: string; name: string }[] }
    ) => {
      const savePromise = initialData
        ? apiClient.updateLocation(currentAccount.id, initialData.id, {
            ...locationMap[initialData.id],
            name: values.name,
            //@ts-ignore
            squareFeet: values.squareFeet,
          })
        : apiClient.createLocation(
            currentAccount.id,
            values as CreateApiLocation
          );

      savePromise
        .then((res) => {
          showToast(
            "success",
            `Location: ${values.name} has been added successfully`
          );

          const sectionPromises = values.sections.map((section) => {
            if (section.name && section.id !== "all-rooms") {
              const sectionPromise = section.id
                ? apiClient.updateLocation(currentAccount.id, section.id, {
                    ...locationMap[section.id],
                    name: section.name,
                  })
                : apiClient.createLocation(currentAccount.id, {
                    ...values,
                    name: section.name,
                    type: ApiLocationType.floor,
                    parent: res.id,
                  });

              return sectionPromise.then(() => {
                showToast(
                  "success",
                  `Section: ${section.name} has been added successfully`
                );
              });
            }
            return Promise.resolve();
          });

          return Promise.all(sectionPromises);
        })
        .catch(() => {
          showToast("error", "Error Saving Location");
        })
        .finally(() => {
          thunkDispatch(reloadLocations({ apiClient, account: currentAccount }))
            .then(unwrapResult)
            .then(() => {
              if (
                reactLocation.pathname.includes("inspection") &&
                reloadInspectionDetails
              ) {
                reloadInspectionDetails();
              }
              onClose();
            });
        });
    },
    [
      apiClient,
      showToast,
      onClose,
      thunkDispatch,
      currentAccount,
      reactLocation,
      reloadInspectionDetails,
      initialData,
      locationMap,
    ]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LocationFormSchema}
      onSubmit={saveLocation}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            {locationMap[locationId].name}
          </Text>
          <FormControl mb={4}>
            <FormLabel>Name</FormLabel>
            <Field name="name" as={Input} placeholder="Location Name" />
          </FormControl>
          <FormControl w="50%" mb={4}>
            <FormLabel>Square Feet</FormLabel>
            <Field name="squareFeet" as={Input} placeholder="Square Feet" />
          </FormControl>
          {/*    https://formik.org/docs/api/fieldarray */}
          <FieldArray name="sections">
            {({ remove, push }) => (
              <Box>
                <FormLabel>Sections</FormLabel>
                {values.sections.map((section, index) => (
                  <Stack direction="row" spacing={4} key={index} mb={2}>
                    <Field
                      name={`sections[${index}].name`}
                      as={Input}
                      placeholder="Add Section (Optional)"
                      value={section.name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const newSections = [...values.sections];
                        newSections[index].name = e.target.value;
                        setFieldValue("sections", newSections);
                      }}
                    />
                    <IconButton
                      icon={<BsTrash />}
                      onClick={() => {
                        remove(index);
                      }}
                      aria-label="remove-section"
                      color={iconsColor}
                      isDisabled={Boolean((section as ObjectWithId)?.id)}
                    />
                  </Stack>
                ))}
                <Button
                  variant="outline"
                  colorScheme="blue"
                  onClick={() => push({ name: "" })}
                  mb={4}
                >
                  + Add Section
                </Button>
              </Box>
            )}
          </FieldArray>
          <Stack
            direction="row"
            spacing={4}
            justifyContent="space-between"
            mt={4}
          >
            <Button variant="outline" colorScheme="gray" onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" type="submit" isDisabled={!values.name}>
              Save
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
