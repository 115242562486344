import {
  Box,
  Button,
  Center,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Spinner,
  Stack,
  StackItem,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiAsset,
  ApiAssignee,
  ApiBudgetSummary,
  ApiLocation,
  ApiLocationReference,
  ApiLocationSummary,
  ApiProject,
  ApiReportingCategory,
  ApiRequest,
  ApiRequestPriority,
  ApiRequestStatus,
  ApiRequestType,
  ApiUserSummary,
  ApiWorkflow,
  ApiWorkflowFieldDataType,
  ApiWorkflowReasonSummary,
  ApiWorkflowReportingCategorySummary,
  ApiWorkflowSchemaField,
  CreateApiRequest,
  WorkflowPolicy,
} from "@operations-hero/lib-api-client";
import { SchemaRulesEngine } from "@operations-hero/lib-rule-engine";
import axios, { AxiosProgressEvent } from "axios";
import { convertFromRaw, EditorState, RawDraftContentState } from "draft-js";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { MdQrCode2 } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormikObserver } from "../../hooks/formikObserver";
import { useShowToast } from "../../hooks/showToast";
import { useAllowedCategories } from "../../hooks/useAllowedCategories";
import { useIsInModal } from "../../hooks/useIsInModal";
import { AssetCard } from "../../pages/account-settings/asset-list/AssetCard";
import { Products } from "../../pages/account-settings/location-list/LocationList";
import { AssetSearchAutocomplete } from "../../pages/request-form/assets/AssetSearchAutocomplete";
import { convertToSave } from "../../pages/request-form/comments/Comment";
import { RootState } from "../../store";
import {
  setFormikModal,
  setIsSubmitting,
} from "../../store/formik-modal.slice";
import {
  addAsset,
  addAttachment,
  removeAsset,
  removeAttachment,
  setShouldAutoCreate,
  unloadForm,
  updateAttachment,
} from "../../store/new-request-form.slice";
import { useCategoryUtils } from "../../utils/categoryUtils";
import { SETTING_USER_ALLOW_LAST_LOCATION_NEW_REQUESTS } from "../../utils/emailSettingUtils";
import { getId } from "../../utils/getId";
import { getVisibleFields } from "../../utils/getVisibleFields";
import { useLocationUtils } from "../../utils/locationUtils";
import { Attachment, Attachments } from "../attachments/Attachments";
import { useAuthentication } from "../auth/AuthProvider";
import { AssigneeAutocompleteControl } from "../form-helpers/AssigneeAutocompleteControl";
import { BudgetAutocompleteControl } from "../form-helpers/BudgetAutocompleteControl";
import { CustomFieldInputControl } from "../form-helpers/CustomFieldInputControl";
import { DatePickerControl } from "../form-helpers/DatePickerControl";
import { EstimatedExpensesControl } from "../form-helpers/EstimatedExpensesControl";
import { EstimatedHoursControl } from "../form-helpers/EstimatedHoursControl";
import FocusError from "../form-helpers/FocusError";
import { LocationAutocompleteControl } from "../form-helpers/LocationAutocompleteControl";
import { ProjectAutocompleteControl } from "../form-helpers/ProjectAutocompleteControl";
import { ProjectBudgetsAutocompleteControl } from "../form-helpers/ProjectBudgetsAutocompleteControl";
import { ReasonAutocompleteControl } from "../form-helpers/ReasonAutocompleteControl";
import { ReportingCategoryAutocompleteControl } from "../form-helpers/ReportingCategoryAutocompleteControl";
import { RequesterAutocompleteControl } from "../form-helpers/RequesterAutocompleteControl";
import { RichTextEditorComments } from "../form-helpers/rich-text-editor/RichTextEditorComments";
import { LoadingText } from "../loading-text/LoadingText";
import { QrQuickScanModal } from "../qr-quick-scan/QrQuickScanModal";
import { LocationAutocomplete } from "../selects/LocationAutocomplete";
import { ProjectAutocomplete } from "../selects/ProjectAutocomplete";
import { ReportingCategoryAutocomplete } from "../selects/ReportingCategoryAutocomplete";
import { WorkflowAutocomplete } from "../selects/WorkflowAutocomplete";
import { useCreateNewRequestFromTaskbookQR } from "./new-request-form-helper/useCreateNewRequest";
import { useNewRequestFormSchema } from "./new-request-form-helper/useNewRequestSchema";
import { useProjectField } from "./useProjectField";

const PROJECT_DATE_FORMAT = "MMM DD, YYYY";

const projectDatesInfoMessage = (project: ApiProject) => {
  let formattedStart = "-";
  let formattedEnd = "-";

  if (project.start)
    formattedStart = moment(project.start).format(PROJECT_DATE_FORMAT);
  if (project.end)
    formattedEnd = moment(project.end).format(PROJECT_DATE_FORMAT);
  const message = `From ${formattedStart} to ${formattedEnd}`;
  return message;
};

export interface NewRequestFormContext {
  workflow: ApiWorkflow;
  schemaFields: ApiWorkflowSchemaField[];
  rulesEngine: SchemaRulesEngine;
  policy: WorkflowPolicy;
}

export interface NewRequestFormProps {
  context?: NewRequestFormContext;
  onSave: (request: ApiRequest) => void;
  onCancel: () => void;
  handleWorkflowChange: (workflow: ApiWorkflow | null) => void;
  hideWorkflow?: boolean;
}

export const NewRequestForm = ({
  context,
  onCancel,
  onSave,
  handleWorkflowChange,
  hideWorkflow,
}: NewRequestFormProps) => {
  const { autoCreateRequest } = useSelector(
    (state: RootState) => state.newRequestForm
  );

  const isDisabled = useMemo(() => {
    if (!autoCreateRequest) {
      return false;
    }
    return autoCreateRequest.shouldAutoCreate;
  }, [autoCreateRequest]);

  return (
    <>
      {!hideWorkflow && (
        <Box
          width="100%"
          position={!context ? "absolute" : "inherit"}
          pb={8}
          paddingRight={!context ? 12 : 0}
          zIndex="100"
        >
          <FormControl>
            <FormLabel>Workflow</FormLabel>
            <WorkflowAutocomplete
              workflow={context ? context.workflow : null}
              onChange={handleWorkflowChange}
              allowEmpty={true}
              isDisabled={isDisabled}
            />
          </FormControl>
        </Box>
      )}

      <Collapse in={Boolean(context)}>
        {context && (
          <NewRequestFormBody
            context={context}
            onCancel={onCancel}
            onSave={onSave}
          />
        )}
      </Collapse>
    </>
  );
};

export interface NewRequestFormBodyProps {
  context: NewRequestFormContext;
  onSave: (request: ApiRequest) => void;
  onCancel: () => void;
}

const NewRequestFormBody = ({
  context,
  onCancel,
  onSave,
}: NewRequestFormBodyProps) => {
  const { currentUser, currentAccount, apiClient, isProductAdmin } =
    useAuthentication();
  const { allowedCategories } = useAllowedCategories();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showToast = useShowToast();
  const isInModal = useIsInModal();
  const ref = useRef<FormikProps<CreateApiRequest>>(null);

  const { locationMap, userSettings, categoriesMap, isContractorOnly } =
    useSelector((state: RootState) => state.localCache);
  const attachments = useSelector(
    (state: RootState) => state.newRequestForm.attachments
  );
  const assets = useSelector((state: RootState) => state.newRequestForm.assets);
  const {
    autoCreateRequest,
    locationId: locationIdFromState,
    reportingCategoryId: categoryIdFromState,
    taskbook,
  } = useSelector((state: RootState) => state.newRequestForm);

  const [isUploading, setIsUploading] = useState(false);
  const [summary, setSummary] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [summaryToSave, setSummaryToSave] = useState<string | undefined>();
  const [isSummaryInvalid, setInvalidSummary] = useState(false);
  const [assetToAdd, setAssetToAdd] = useState<ApiAsset | null>(null);
  const { getChildrenId, getUserPolicyLocationsWithChildrens } =
    useLocationUtils();
  const { findAllChildrenForNodesRecursive } = useCategoryUtils();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const qrColor = useColorModeValue("black", "white");
  const [showAssetSection, setShowAssetSection] = useState(false);
  const [categoriesIds, setCategoriesIds] = useState<string[]>();

  const { value: initProject, source } = useProjectField();
  const [selectedProject, setSelectedProject] = useState<ApiProject | null>(
    initProject
  );

  const displayValue = useBreakpointValue({
    base: "inline-block",
    sm: "inline-flex",
  });

  const allowedLocations = useMemo(() => {
    const { admin } = context.policy;

    if (
      isProductAdmin ||
      admin ||
      context.workflow.allowUsersToSubmitRequests
    ) {
      return [];
    }

    const restrictedLocations = getUserPolicyLocationsWithChildrens(
      context.policy
    );

    return restrictedLocations.map((loc) => loc.id);
  }, [context, isProductAdmin, getUserPolicyLocationsWithChildrens]);

  const defaultLocation = useMemo(() => {
    const lastLocationId = userSettings[
      SETTING_USER_ALLOW_LAST_LOCATION_NEW_REQUESTS
    ]
      ? localStorage.getItem(`${currentAccount.id}-request-location`)
      : null;

    // Pick a default location for the request in the following order.
    // 1. Location from the state
    // 2. Last location used from local storage
    // 3. Only location allowed
    // 4. Only location available
    let defaultLocationCandidates: (string | null)[] = [
      locationIdFromState,
      lastLocationId,
      allowedLocations.length === 1 ? allowedLocations[0] : null,
      Object.keys(locationMap).length === 1
        ? Object.keys(locationMap)[0]
        : null,
    ];

    let result: ApiLocation | null = null;
    for (let id of defaultLocationCandidates) {
      if (!id) continue;

      const location = locationMap[id];
      if (!location || !location.active) continue;

      if (allowedLocations.length > 0 && !allowedLocations.includes(id)) {
        continue;
      }

      result = location;
      break;
    }

    return result;
  }, [
    currentAccount,
    locationMap,
    locationIdFromState,
    allowedLocations,
    userSettings,
  ]);

  const [assetLocationFilter, setAssetLocationFilter] =
    useState<ApiLocationSummary | null>(defaultLocation || null);

  const assetSearchLocationIds = useMemo(() => {
    if (!assetLocationFilter) {
      return undefined;
    }

    const childrens = getChildrenId([assetLocationFilter]);
    return childrens.length > 0 ? childrens : undefined;
  }, [assetLocationFilter, getChildrenId]);

  const [category, setCategory] =
    useState<ApiWorkflowReportingCategorySummary>();

  const autoCreateRequestInitialValues = useMemo(() => {
    if (!autoCreateRequest || !autoCreateRequest.requestToCreate) {
      return;
    }
    const workflow =
      autoCreateRequest.requestToCreate.workflow || context.workflow;

    const newSummaryText = autoCreateRequest.requestToCreate.summary;

    const baseTextLength = 24;
    const boldLength = newSummaryText.length - baseTextLength;
    const rawContent: RawDraftContentState = {
      blocks: [
        {
          key: `${getId(workflow)}`,
          text: autoCreateRequest.requestToCreate.summary,
          type: "unstyled",
          inlineStyleRanges: autoCreateRequest.shouldBold
            ? [{ offset: baseTextLength, length: boldLength, style: "BOLD" }]
            : [],
          depth: 0,
          entityRanges: [],
        },
      ],
      entityMap: {},
    };

    const contentState = convertFromRaw(rawContent);
    const newEditorState = EditorState.createWithContent(contentState);
    const summaryText = convertToSave(newEditorState);

    setSummary(newEditorState);
    setSummaryToSave(summaryText);

    const newFormValues: CreateApiRequest = {
      ...autoCreateRequest.requestToCreate,
      workflow: workflow,
    };

    return newFormValues;
  }, [autoCreateRequest, context.workflow]);

  const initialValues = useMemo<CreateApiRequest>(() => {
    const initialCategory = categoryIdFromState
      ? categoriesMap[categoryIdFromState]
      : null;

    return {
      assignees: [],
      scheduling: {
        start: null,
        due: null,
        completed: null,
      },
      location: defaultLocation || null,
      metadata: {},
      priority: ApiRequestPriority.standard,
      reportingCategory: initialCategory,
      reason: null,
      requester: currentUser,
      status: ApiRequestStatus.new,
      summary: "",
      type: ApiRequestType.corrective,
      workflow: context.workflow,
      estimatedCost: null,
      estimatedHours: null,
      scheduledRequestId: null,
      projectId: initProject?.id ?? null,
    };
  }, [
    categoryIdFromState,
    categoriesMap,
    defaultLocation,
    currentUser,
    context.workflow,
    initProject?.id,
  ]);

  const {
    visibleFields,
    touchedFields,
    validationSchema,
    checkUserCanCreateRequest,
    validateNewRequestFields,
  } = useNewRequestFormSchema(context, initialValues);
  const { autoCreateNewRequest } = useCreateNewRequestFromTaskbookQR();

  const customVisibleFields = useMemo(() => {
    const fields = visibleFields.filter(
      (field) => field.dataType !== ApiWorkflowFieldDataType.system
    );

    const res = context.schemaFields.filter((sf) =>
      fields.some((fi) => fi.id === sf.field.id)
    );

    return res.sort((a, b) => {
      if (!a.position) return 1;
      if (!b.position) return -1;
      if (a.position < b.position) return -1;
      if (a.position > b.position) return 1;
      return 0;
    });
  }, [visibleFields, context.schemaFields]);

  const {
    showCategory,
    showStartDate,
    showDueDate,
    showAssets,
    showReason,
    showAssignTo,
    showProject,
    showBudgetOnRequests,
    showEstimatedCost,
    showEstimatedLabor,
  } = useMemo(() => {
    return getVisibleFields(visibleFields);
  }, [visibleFields]);

  const handleFormikSubmit = useCallback(
    (
      values: CreateApiRequest,
      formikHelpers: FormikHelpers<CreateApiRequest>
    ) => {
      const isEmpty = !summary.getCurrentContent().hasText();
      isEmpty && !isSummaryInvalid && setInvalidSummary(isEmpty);
      let redirectUrl: string | undefined = undefined;

      if (isEmpty) {
        return;
      }

      // the initialValues does not set workflow property,
      // this ensures the selected workflow is used.
      isInModal && dispatch(setIsSubmitting(true));
      const newValues = { ...values };

      let projectId: string | null = null;
      if (source === "planning.scheduling" && initProject) {
        projectId = initProject.id;
      } else {
        //@ts-ignore
        projectId = newValues.projectId?.id ?? null;
      }

      const payload: CreateApiRequest = {
        ...newValues,
        summary: summaryToSave ? summaryToSave : "",
        workflow: context.workflow,
        projectId: projectId,
        budget: newValues.budget ? getId(newValues.budget) : undefined,
        estimatedCost: newValues.estimatedCost,
      };

      return apiClient
        .createRequest(currentAccount.id, payload)
        .then((newRequest) => {
          return attachments.length === 0
            ? newRequest
            : Promise.all(
                attachments.map((a) =>
                  apiClient.createRequestAttachment(
                    currentAccount.id,
                    newRequest.key,
                    {
                      uploadId: a.uploadId!,
                      name: a.name,
                    }
                  )
                )
              ).then(() => newRequest);
        })
        .then((newRequest) => {
          return assets.length === 0
            ? newRequest
            : Promise.all(
                assets.map((a) =>
                  apiClient.createRequestAsset(
                    currentAccount.id,
                    newRequest.key,
                    {
                      assetId: a.id,
                    }
                  )
                )
              ).then(() => newRequest);
        })
        .then((newRequest) => {
          if (taskbook.length > 0) {
            return apiClient
              .createRequestTaskbook(currentAccount.id, newRequest.id, {
                ids: taskbook.map((t) => t.id),
              })
              .then((response) => {
                redirectUrl = `/requests/${newRequest.key}/books/${response[0].id}`;
                return newRequest;
              });
          }
          return newRequest;
        })
        .then((newRequest) => {
          if (newRequest.location) {
            localStorage.setItem(
              `${currentAccount.id}-request-location`,
              newRequest.location.id
            );
          }
          onSave(newRequest);
        })
        .catch(() => {
          showToast("error", "Something went wrong while creating new request");
        })
        .finally(() => {
          isInModal && dispatch(setIsSubmitting(false));
          if (Boolean(autoCreateRequest) && redirectUrl) {
            navigate(redirectUrl);
          }
        });
    },
    [
      summary,
      isSummaryInvalid,
      isInModal,
      dispatch,
      source,
      initProject,
      summaryToSave,
      context.workflow,
      apiClient,
      currentAccount.id,
      attachments,
      assets,
      taskbook,
      onSave,
      showToast,
      autoCreateRequest,
      navigate,
    ]
  );

  const handleNewFiles = useCallback(
    (files: Attachment[]) => {
      // start upload process
      files.forEach((file) => {
        if (!file.file) {
          return;
        }

        // Get a signed url
        // -- store uploadId for later use on the attachment
        // upload to signed url
        // -- set uploading false
        apiClient
          .createUpload(currentAccount.id)
          .then((uploadResult) => {
            const newAttachment: Attachment = {
              ...file,
              isUploading: true,
              uploadId: uploadResult.id,
              progress: 0,
            };
            dispatch(addAttachment(newAttachment));
            return { attachment: newAttachment, upload: uploadResult };
          })
          .then(({ attachment, upload }) => {
            return axios
              .put(upload.url, attachment.file, {
                headers: { "Content-Type": attachment.file!.type },
                onUploadProgress: (progressEvent: AxiosProgressEvent) =>
                  dispatch(
                    updateAttachment({
                      ...attachment,
                      progress: Math.round(
                        (progressEvent.loaded * 100) /
                          (progressEvent.total || 1)
                      ),
                    })
                  ),
              })
              .then(() => ({ attachment, upload }));
          })
          .then(({ attachment, upload }) => {
            const updated: Attachment = {
              ...attachment,
              isUploading: false,
              progress: undefined,
            };
            dispatch(updateAttachment(updated));
          });
      });
    },
    [apiClient, currentAccount, dispatch]
  );

  const handleRemoveFile = useCallback(
    (attachment: Attachment) => {
      dispatch(removeAttachment(attachment));
    },
    [dispatch]
  );

  const handleRemoveAssetFactory = useCallback(
    (a: ApiAsset) => () => {
      dispatch(removeAsset(a));
    },
    [dispatch]
  );

  const handleSummaryBlur = useCallback((summary: EditorState) => {
    setSummary(summary);
    const isEmpty = !summary.getCurrentContent().hasText();
    setInvalidSummary(isEmpty);
    const response = convertToSave(summary);
    setSummaryToSave(response);
    // addding the value of summary to formik for form validation
    if (ref.current) ref.current.setFieldValue("summary", response, true);
  }, []);

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const assetToAddOnChange = useCallback(
    (asset: ApiAsset | null) => {
      if (!asset) {
        setAssetToAdd(null);
        return;
      }
      setAssetToAdd(asset);

      if (assets.find((d) => d.id === asset.id)) {
        showToast("error", "Asset already added");
        setAssetToAdd(null);
        return;
      }
      setAssetToAdd(null);
      dispatch(addAsset(asset));
    },
    [showToast, assets, dispatch]
  );

  const handleOnChangeAssetLocationFilter = useCallback(
    (newLocation: ApiLocationSummary | null) => {
      setAssetLocationFilter(newLocation);
    },
    []
  );

  const handleOnChangeCategory = useCallback(
    (value: ApiWorkflowReportingCategorySummary | null) => {
      if (!value) {
        setCategory(undefined);
        setCategoriesIds(undefined);
        return;
      }
      setCategory(value);
      const categoryChilds = value
        ? findAllChildrenForNodesRecursive([value.id])
        : new Set<ApiReportingCategory>();
      const categories = Array.from(categoryChilds);
      setCategoriesIds(categories ? categories.map((cat) => cat.id) : []);
    },
    [findAllChildrenForNodesRecursive]
  );

  const handleQrData = useCallback(
    (externalIds: string) => {
      apiClient
        .findAssets(currentAccount.id, { externalIds: [externalIds] })
        .then((result) => {
          assetToAddOnChange &&
            result.data.forEach((rData) => {
              assetToAddOnChange(rData);
            });
        });

      onClose();
    },
    [onClose, apiClient, currentAccount.id, assetToAddOnChange]
  );

  useEffect(() => {
    setIsUploading(attachments.some((a) => a.isUploading === true));
  }, [attachments]);

  useEffect(
    () => () => {
      dispatch(unloadForm());
    },
    [dispatch]
  );

  const handleOnChangeForm = useCallback(
    async (
      values: CreateApiRequest,
      setFieldValue: any,
      isSubmitting: boolean,
      handleSubmit: any
    ) => {
      if (!isInModal) {
        return;
      }
      dispatch(
        setFormikModal({ values, isSubmitting, handleSubmit, isUploading })
      );
    },
    [dispatch, isInModal, isUploading]
  );

  useEffect(() => {
    // we trigger form validation if the workflow has changed
    if (ref.current) ref.current.validateForm();
  }, [context.workflow]);

  const autoCreate = useCallback(async () => {
    try {
      if (
        !autoCreateRequest ||
        !autoCreateRequest.shouldAutoCreate ||
        !autoCreateRequest.requestToCreate
      ) {
        throw new Error(
          "Your QR code contains incomplete data. Please complete it and try again!"
        );
      }
      const canUserCreateRequest = checkUserCanCreateRequest(
        autoCreateRequest.requestToCreate.workflow
      );

      if (!canUserCreateRequest) {
        throw new Error(
          "You cannot create requests for the workflow associated with this QR code!"
        );
      }

      const areValuesValid = validateNewRequestFields(
        autoCreateRequest.requestToCreate as CreateApiRequest
      );
      if (!areValuesValid) {
        throw new Error(
          "Some fields are missing. Please fill them and try again!"
        );
      }
      await autoCreateNewRequest();
    } catch (error) {
      if (error instanceof Error) {
        showToast("error", error.message, undefined, undefined, 5000);
      }
      await dispatch(setShouldAutoCreate(false));
    }
  }, [
    autoCreateNewRequest,
    autoCreateRequest,
    checkUserCanCreateRequest,
    dispatch,
    showToast,
    validateNewRequestFields,
  ]);

  const handleAutoCreate = useCallback(() => {
    if (!autoCreateRequest) {
      return;
    }

    const { shouldAutoCreate, requestToCreate } = autoCreateRequest;

    if (requestToCreate && shouldAutoCreate) {
      autoCreate();
    }
  }, [autoCreate, autoCreateRequest]);

  useEffect(() => {
    if (!autoCreateRequest) {
      return;
    }
    handleAutoCreate();
  }, [autoCreate, autoCreateRequest, handleAutoCreate]);

  const areFieldsDisabled = useMemo(() => {
    if (!autoCreateRequest) {
      return false;
    }
    return autoCreateRequest.shouldAutoCreate;
  }, [autoCreateRequest]);

  const initialTouchedFields = useMemo(() => {
    if (!autoCreateRequest?.requestToCreate || !touchedFields) {
      return undefined;
    }

    return touchedFields;
  }, [autoCreateRequest?.requestToCreate, touchedFields]);

  return (
    <Box px={1}>
      {autoCreateRequest && autoCreateRequest.shouldAutoCreate && (
        <Center
          w="100%"
          height="45vh"
          position="absolute"
          bgColor="transparent"
          flexDirection="column"
        >
          <Spinner />
          <LoadingText text="We are creating your request, please wait a moment" />
        </Center>
      )}
      <Formik
        onSubmit={handleFormikSubmit}
        initialValues={autoCreateRequestInitialValues || initialValues}
        validationSchema={validationSchema}
        innerRef={ref}
        validateOnChange
        enableReinitialize={true}
        validateOnMount={true}
        initialTouched={initialTouchedFields}
      >
        {({ values, errors, submitCount, isSubmitting }) => (
          <Form>
            <FormikObserver cb={handleOnChangeForm} />
            <Grid
              templateColumns={[
                "repeat(2, 1fr)",
                null,
                "repeat(6, 1fr)",
                "repeat(6, 1fr)",
              ]}
              verticalAlign="center"
              mb={2}
              gap={6}
            >
              {showProject && (
                <GridItem colSpan={[2, 2, 6, 6]}>
                  {source === "planning.scheduling" ? (
                    <FormControl id="projectId">
                      <FormLabel>Project</FormLabel>
                      <ProjectAutocomplete
                        value={initProject}
                        onChange={() => {}}
                        isDisabled={true}
                        isClearable={false}
                      />
                    </FormControl>
                  ) : (
                    <ProjectAutocompleteControl
                      name="projectId"
                      label="Project"
                      isDisabled={areFieldsDisabled}
                      value={
                        values.projectId
                          ? ({ id: values.projectId } as ApiProject)
                          : null
                      }
                      onChange={(value) => {
                        setSelectedProject(value);
                      }}
                      allowEmpty={true}
                    />
                  )}
                </GridItem>
              )}

              <GridItem colSpan={[2, null, showCategory ? 3 : 6]}>
                <RequesterAutocompleteControl
                  name="requester"
                  value={values.requester as ApiUserSummary}
                  label="Requester"
                  workflow={values.workflow as ApiWorkflow}
                  location={values.location as ApiLocationSummary}
                  isDisabled={areFieldsDisabled}
                />
              </GridItem>

              {showCategory && (
                <GridItem colSpan={[2, null, 3]}>
                  <ReportingCategoryAutocompleteControl
                    workflow={context.workflow}
                    label="Category"
                    name="reportingCategory"
                    // @ts-ignore
                    value={values.reportingCategory}
                    isDisabled={areFieldsDisabled}
                    allowedCategories={allowedCategories.map(getId)}
                  />
                </GridItem>
              )}

              {showAssignTo && (
                <GridItem colSpan={[2, null, 3]}>
                  <AssigneeAutocompleteControl
                    workflow={context.workflow}
                    value={values.assignees as ApiAssignee[]}
                    name="assignees"
                    label="Assign To"
                    isDisabled={areFieldsDisabled}
                  />
                </GridItem>
              )}

              {showReason && !isContractorOnly && (
                <GridItem colSpan={[2, null, 3]}>
                  <ReasonAutocompleteControl
                    workflow={context.workflow}
                    value={values.reason as ApiWorkflowReasonSummary}
                    name="reason"
                    label="Reason"
                    isDisabled={areFieldsDisabled}
                  />
                </GridItem>
              )}

              {showBudgetOnRequests && (
                <GridItem colSpan={[2, null, 3]}>
                  {values.projectId ? (
                    <ProjectBudgetsAutocompleteControl
                      value={(values.budget as ApiBudgetSummary) ?? null}
                      name="budget"
                      label="Budget"
                      // @ts-ignore
                      projectId={(values.projectId as ApiProject).id}
                      isDisabled={areFieldsDisabled}
                    />
                  ) : (
                    <BudgetAutocompleteControl
                      value={(values.budget as ApiBudgetSummary) ?? null}
                      name="budget"
                      label="Budget"
                      isDisabled={areFieldsDisabled}
                    />
                  )}
                </GridItem>
              )}

              {showEstimatedCost && (
                <GridItem colSpan={[2, null, 3]}>
                  <EstimatedExpensesControl
                    value={values.estimatedCost}
                    name="estimatedCost"
                    label="Estimated Cost"
                    isDisabled={areFieldsDisabled}
                  />
                </GridItem>
              )}

              {showEstimatedLabor && (
                <GridItem colSpan={[2, null, 3]}>
                  <EstimatedHoursControl
                    value={values.estimatedHours}
                    name="estimatedHours"
                    label="Estimated Labor Hours"
                    isDisabled={areFieldsDisabled}
                  />
                </GridItem>
              )}

              <GridItem colSpan={[2, null, 6]}>
                <LocationAutocompleteControl
                  allowedLocations={allowedLocations}
                  label="Location"
                  name="location"
                  // @ts-ignore
                  value={values.location}
                  productName={Products.HeroHQ}
                  isDisabled={areFieldsDisabled}
                />
              </GridItem>
              <GridItem colSpan={[2, null, 6]}>
                <RichTextEditorComments
                  id="comment-edit"
                  value={summary}
                  label={"Description/Summary"}
                  onBlur={handleSummaryBlur}
                  isInvalid={!!errors.summary && submitCount > 0}
                  invalidMessage={submitCount > 0 ? errors.summary : undefined}
                  isReadOnly={areFieldsDisabled}
                />
              </GridItem>
              {/* Custom Fields */}
              {customVisibleFields && customVisibleFields.length ? (
                <GridItem colSpan={[2, null, 6]}>
                  <Grid templateColumns={["repeat(2, 1fr)"]}>
                    {customVisibleFields.map((schemaField) => {
                      return (
                        <GridItem
                          colSpan={[2, null, 3]}
                          pb={[3, null, 3]}
                          key={schemaField.field.id}
                          display="flex"
                          alignItems="flex-end"
                        >
                          <CustomFieldInputControl
                            value={
                              values.metadata[schemaField.field.key] || null
                            }
                            name={`metadata.${schemaField.field.key}`}
                            field={schemaField.field}
                            isDisabled={areFieldsDisabled}
                          />
                          {/* <CustomFieldInput
                      field={field}
                      value={metadata[field.key]}
                      onChange={handleCustomFieldChange(field)}
                    /> */}
                        </GridItem>
                      );
                    })}
                  </Grid>
                </GridItem>
              ) : null}

              {(showStartDate || showDueDate) && (
                <>
                  {showStartDate && (
                    <GridItem colSpan={[2, null, 3, 3]}>
                      <DatePickerControl
                        label="Start"
                        value={values.scheduling.start}
                        name="scheduling.start"
                        showTime={true}
                        isDisabled={areFieldsDisabled}
                        hasError={Boolean(errors.scheduling?.start)}
                      />
                    </GridItem>
                  )}
                  {showDueDate && (
                    <GridItem colSpan={[2, null, 3, 3]}>
                      <DatePickerControl
                        label="Due"
                        value={values.scheduling.start}
                        name="scheduling.due"
                        showTime={true}
                        isDisabled={areFieldsDisabled}
                        hasError={Boolean(errors.scheduling?.due)}
                      />
                    </GridItem>
                  )}
                  {selectedProject && (
                    <GridItem colSpan={[2, 2, 6]}>
                      <Text color="gray.600">
                        Project duration:
                        <Text as="strong" ml={1}>
                          {projectDatesInfoMessage(selectedProject)}
                        </Text>
                      </Text>
                    </GridItem>
                  )}
                </>
              )}

              <GridItem colSpan={[2, null, 6]} pb={[3, null, 3]}>
                <Attachments
                  attachments={attachments}
                  onDeleteAttachment={handleRemoveFile}
                  onNewAttachments={handleNewFiles}
                />
              </GridItem>

              {isUploading ? (
                <GridItem colSpan={[2, null, 6, 6]} pb={[3, null, 3]}>
                  <Text>Uploading files.</Text>
                </GridItem>
              ) : null}

              {taskbook.length > 0 && (
                <GridItem
                  colSpan={[2, 2, 6]}
                  pb={[3, null, 3]}
                  gap={4}
                  display="flex"
                  flexDir="column"
                >
                  <Divider />
                  <Flex direction="column" gap={2}>
                    <Heading size="md" mb={3}>
                      Taskbook(s)
                    </Heading>
                    <Text>{taskbook[0].name}</Text>
                  </Flex>
                  <Divider />
                </GridItem>
              )}

              {(showAssets || assetToAdd) && (
                <GridItem colSpan={[2, null, 6, 6]} pb={[3, null, 6]}>
                  <HStack width="100%" justifyContent="space-between">
                    <Heading size="md" mb={3}>
                      Add Asset(s)
                    </Heading>
                    <HStack>
                      <Button
                        colorScheme="blue"
                        variant="outline"
                        leftIcon={<Icon as={BsPlus} />}
                        onClick={() => {
                          setShowAssetSection(true);
                        }}
                      >
                        Add Asset
                      </Button>
                      <Button
                        onClick={onOpen}
                        color={qrColor}
                        onTouchEnd={onOpen}
                        zIndex={100}
                        w={7}
                      >
                        <Icon h={7} w={7} as={MdQrCode2} />
                      </Button>
                    </HStack>
                    <QrQuickScanModal
                      isOpen={isOpen}
                      onClose={onClose}
                      onData={handleQrData}
                    />
                  </HStack>

                  {showAssetSection && (
                    <Stack w="100%" spacing={4}>
                      <StackItem w="100%">
                        <FormLabel>Location</FormLabel>
                        <LocationAutocomplete
                          menuPlacement="top"
                          value={
                            (assetLocationFilter as ApiLocationReference) ||
                            null
                          }
                          onChange={handleOnChangeAssetLocationFilter}
                        />
                      </StackItem>
                      <StackItem width="100%" display={displayValue} gap={4}>
                        <Box width={["100%", "50%"]} pb={[4, "unset"]}>
                          <ReportingCategoryAutocomplete
                            menuPlacement="top"
                            value={category || null}
                            onChange={handleOnChangeCategory}
                          />
                        </Box>
                        <Box width={["100%", "50%"]}>
                          <AssetSearchAutocomplete
                            menuPlacement="top"
                            value={assetToAdd}
                            onChange={assetToAddOnChange}
                            locations={assetSearchLocationIds}
                            categories={categoriesIds}
                          />
                        </Box>
                      </StackItem>
                    </Stack>
                  )}
                </GridItem>
              )}
              {showAssetSection && assets && assets.length === 0 && (
                <GridItem colSpan={[2, null, 6, 6]} px={2}>
                  Please, choose asset(s)
                </GridItem>
              )}
              {assets && assets.length > 0 && (
                <GridItem colSpan={[2, null, 6, 6]} pb={[3, null, 3]}>
                  <Stack spacing={4}>
                    <Heading size="md" mb={3}>
                      Asset(s)
                    </Heading>
                    {assets.map((a) => (
                      <AssetCard asset={a} key={`new-request-asset::${a.id}`}>
                        <Button onClick={handleRemoveAssetFactory(a)}>
                          Remove
                        </Button>
                      </AssetCard>
                    ))}
                  </Stack>
                </GridItem>
              )}
              {!isInModal && (
                <>
                  <GridItem colSpan={[1, null, 3, 3]} py={[3, null, 3]}>
                    <Button
                      variant="outline"
                      isDisabled={isSubmitting}
                      onClick={handleCancel}
                      size="lg"
                      p={3}
                    >
                      Cancel
                    </Button>
                  </GridItem>
                  <GridItem
                    colSpan={[1, null, 3, 3]}
                    pb={[3, null, 3]}
                    textAlign="right"
                  >
                    <Button
                      type="submit"
                      variant="solid"
                      colorScheme="blue"
                      isDisabled={isSubmitting || isUploading}
                      size="lg"
                      isLoading={isSubmitting}
                    >
                      Save Request
                    </Button>
                  </GridItem>
                </>
              )}
            </Grid>
            <FocusError />
          </Form>
        )}
      </Formik>
    </Box>
  );
};
