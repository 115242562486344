import {
  Button,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiInspection,
  ApiInspectionStatus,
} from "@operations-hero/lib-api-client";
import { FC, MouseEvent, useCallback } from "react";
import { AccountModal } from "../../../account-settings/account-modal/AccountModal";
import { InspectionForm } from "../InspectionForm";

export const InspectionStatusTitle: Record<ApiInspectionStatus, string> = {
  pending: "Edit",
  started: "View",
  completed: "View",
  submitted: "View",
};

interface InspectionButtonsProps {
  inspection: ApiInspection;
}
export const InspectionButtons: FC<InspectionButtonsProps> = ({
  inspection,
}) => {
  const approveButtonColor = useColorModeValue("blue.600", "blue.200");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const isLarge = useBreakpointValue({
    base: false,
    sm: false,
    md: false,
    lg: true,
    xl: true,
  });

  const handleOnOpenChangeStatusModal = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (
        inspection.status !== ApiInspectionStatus.pending &&
        inspection.id !== "new"
      )
        return;
      e.preventDefault();
      e.stopPropagation();
      onOpen();
    },
    [onOpen, inspection.id, inspection.status]
  );

  return (
    <>
      <Stack direction="row">
        <Button
          variant="outline"
          display="flex"
          height="32px"
          padding="5px 18px"
          justifyContent="center"
          alignItems="center"
          gap="8px"
          borderRadius="4px"
          border="1px solid"
          borderColor={approveButtonColor}
          w="85px"
          size={isLarge ? "sm" : "xs"}
          onClick={(e) => {
            handleOnOpenChangeStatusModal(e);
          }}
        >
          <Text>
            {inspection.id !== "new"
              ? InspectionStatusTitle[inspection.status]
              : "Assign"}
          </Text>
        </Button>
      </Stack>

      {isOpen && (
        <AccountModal
          isOpen={isOpen}
          onClose={onClose}
          title={
            inspection.status === ApiInspectionStatus.pending &&
            inspection.id === "new"
              ? "Assign Inspector"
              : "Edit Assign Inspector"
          }
          contentProps={{ maxW: "xl" }}
          content={<InspectionForm inspection={inspection} onClose={onClose} />}
        />
      )}
    </>
  );
};
