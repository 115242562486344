import { Button, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import {
  ApiInspection,
  ApiInspectionConfig,
  CreateApiInspection,
} from "@operations-hero/lib-api-client";
import { Form, Formik } from "formik";
import moment from "moment";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { DatePickerControl } from "../../../components/form-helpers/DatePickerControl";
import { UserAutocompleteControl } from "../../../components/form-helpers/UserAutocompleteControl";
import { useShowToast } from "../../../hooks/showToast";
import { useThunkDispatch } from "../../../store";

import { loadInspections } from "../../../store/planning-hq/inspections/list/thunks/loadInspections";
import { useAvailableFiscalYear } from "../funding-source/useAvailableFiscalYears";
import { inspectorSchema } from "./inspector.schema";

export type InspectionFormParams = {
  code: string | null;
};

type InspectionFormProps = {
  inspection: ApiInspection;
  onClose: () => void;
};

export const InspectionForm: FC<InspectionFormProps> = ({
  inspection,
  onClose,
}) => {
  const { apiClient, currentAccount } = useAuthentication();
  const { ranges } = useAvailableFiscalYear(false);

  const showToast = useShowToast();
  const thunkDispatch = useThunkDispatch();

  const [inspectionConfig, setInspectionConfig] =
    useState<ApiInspectionConfig[]>();
  const initialValues: ApiInspection = useMemo(() => {
    return {
      ...inspection,
    };
  }, [inspection]);

  const fiscalYearStart = useMemo(() => {
    return moment(
      new Date(ranges[1]?.start || inspection.fiscalYearStart)
    ).format("MMMM YYYY");
  }, [ranges, inspection.fiscalYearStart]);

  const fiscalYearEnd = useMemo(() => {
    return moment(new Date(ranges[1]?.end || inspection.fiscalYearEnd)).format(
      "MMMM YYYY"
    );
  }, [ranges, inspection.fiscalYearEnd]);

  const handleCreateUpdateInspector = useCallback(
    (values: ApiInspection) => {
      if (!inspectionConfig) return;

      if (values.id === "new") {
        const inspection: CreateApiInspection = {
          ...values,
          inspector: values.inspector?.id || null,
          location: values.location.id,
          configId: inspectionConfig[0].id, //how do We send the configuration choosed when We assign inspector?
          fiscalYearStart,
          fiscalYearEnd,
        };

        apiClient
          .createInspection(currentAccount.id, inspection)
          .then(() => {
            showToast("success", "Inspection was created successfully");
            thunkDispatch(loadInspections({ apiClient }));
          })
          .catch((res) => {
            showToast("error", res.response.data.message);
          });
        onClose();
        return;
      }

      const inspection = {
        ...values,
        configId: inspectionConfig[0].id, //how do We send the configuration choosed when We assign inspector?
        fiscalYearStart,
        fiscalYearEnd,
      };
      apiClient
        .updateInspection(currentAccount.id, inspection.id, inspection)
        .then(() => {
          showToast("success", "Inspection was updated successfully");
          thunkDispatch(loadInspections({ apiClient }));
        })
        .catch((res) => {
          showToast("error", res.response.data.message);
        });

      onClose();
      return;
    },
    [
      apiClient,
      currentAccount.id,
      onClose,
      showToast,
      thunkDispatch,
      inspectionConfig,
      fiscalYearStart,
      fiscalYearEnd,
    ]
  );

  useEffect(() => {
    apiClient
      .findInspectionsConfig(currentAccount.id)
      .then((res) => setInspectionConfig(res.data));
  }, [apiClient, currentAccount.id]);

  return (
    <Stack flex={1} justifyContent="flex-start" gap={2}>
      <Text>{initialValues.location.name}</Text>
      <Text>Fiscal Year {`${fiscalYearStart} -  ${fiscalYearEnd}`}</Text>
      <Formik
        initialValues={initialValues}
        onSubmit={handleCreateUpdateInspector}
        validationSchema={inspectorSchema}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <Grid templateColumns="repeat(6, 1fr)" gap={4}>
              <GridItem colSpan={6}>
                <UserAutocompleteControl
                  value={values.inspector}
                  name="inspector"
                  label="Inspector"
                />
              </GridItem>

              <GridItem colSpan={[6, 6, 3]}>
                <DatePickerControl
                  value={values.dueDate}
                  name="dueDate"
                  label="Due Date"
                  placeholder="Select date"
                />
              </GridItem>

              <GridItem
                colSpan={6}
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  size="sm"
                  variant=""
                  colorScheme="blue"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  colorScheme="blue"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  {inspection.id === "new" ? "Assign" : "Save"}
                </Button>
              </GridItem>
            </Grid>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};
