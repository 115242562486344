import { Box, Flex, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { Draggable } from "@fullcalendar/interaction";
import { ApiRequest } from "@operations-hero/lib-api-client";
import { FC, useEffect, useRef } from "react";
import { RiCoinLine } from "react-icons/ri";
import { priorityColorMap } from "../../../../../components/badges/PriorityBadge";
import { RequestKeyBadge } from "../../../../../components/badges/RequestKeyBadge";
import { StatusBadge } from "../../../../../components/badges/StatusBadge";
import { AssigneesBadge } from "../../../components/AssigneeBadge";

type RequestItemProps = {
  request: ApiRequest;
  draggable?: boolean;
};

export const RequestItem: FC<RequestItemProps> = ({
  request,
  draggable = true,
}) => {
  const { status } = request;

  const elemRef = useRef(null);

  useEffect(() => {
    if (!elemRef.current) return;
    new Draggable(elemRef.current, {
      itemSelector: ".fc-event",
      eventData() {
        return {
          title: request.key,
          ...request,
          duration: {
            days: 3,
          },
          overlap: false,
        };
      },
    });
  }, [request]);

  return (
    <Flex
      w="full"
      border="1px solid"
      borderColor="gray.300"
      borderRadius="md"
      p={2}
      gap={4}
      fontSize="smaller"
      ref={elemRef}
      className={draggable ? "fc-event" : ""}
      cursor={draggable ? "pointer" : "not-allowed"}
    >
      <Flex
        flexDir="column"
        align="stretch"
        gap={2}
        flexShrink="100px"
        flexBasis="100px"
      >
        <StatusBadge
          status={status}
          badgeProps={{ w: "100%", textAlign: "center", fontSize: "xs" }}
        />
        <VStack
          flex={1}
          justify="stretch"
          align="stretch"
          backgroundColor={priorityColorMap[request.priority]}
          p={2}
          pt={4}
          gap={2}
          borderRadius="md"
          w="100%"
          alignItems="center"
        >
          <RequestKeyBadge
            request={request}
            fontSize="sm"
            fontWeight="bold"
            color="white"
          />
          <Box backgroundColor="white" borderRadius="sm" mx={2} w="100%">
            <Text
              fontSize="xs"
              fontWeight="bold"
              color={priorityColorMap[request.priority]}
              textAlign="center"
            >
              {request.priority.toUpperCase()}
            </Text>
          </Box>
        </VStack>
      </Flex>
      <Flex flexDir="column" flex={1} gap={1}>
        <AssigneesBadge
          assignees={request.assignees}
          avatarProps={{
            borderWidth: "1px",
            size: "xs",
            borderColor: "white",
          }}
        />
        <Text fontWeight="bold" color="blue.500">
          {request.type.toUpperCase()}
        </Text>
        <Text noOfLines={1}>{request.summary}</Text>
        <HStack color="gray" align="center">
          <Icon as={RiCoinLine} boxSize="5" />
          <Text>{`Cost: $${request.estimatedCost ?? 0}`}</Text>
        </HStack>
      </Flex>
    </Flex>
  );
};
