import {
  Box,
  Divider,
  Flex,
  FormLabel,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC, useCallback, useMemo, useState } from "react";
import { MdInfoOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { CronNumberInput } from "../../../components/cron/CronNumberInput";
import {
  CronValues,
  CustomCronGenerator,
} from "../../../components/cron/CustomCron";
import { StyledDatePicker } from "../../../components/inputs/StyledDatePicker";
import { RootState } from "../../../store";
import {
  ScheduleType,
  setDueDaysAfterStart,
  setGenerateByCompletationDate,
  setGenerateOnlyWhenRequestsAreClosed,
  setScheduleCronExpression,
  setScheduleEndDate,
  setScheduleStartDate,
  setStartDaysAfterCreate,
} from "../../../store/scheduled-request-form/schedule-request-form.slice";

interface ScheduleFormProps {
  editStatus: string;
  activeStep: number;
  totalSteps: number;
}

const DATE_FORMAT = "EEEE, MMM-dd-yyyy, 'at' h:mm aa";

export const ScheduleForm: FC<ScheduleFormProps> = ({
  editStatus,
  activeStep,
  totalSteps,
}) => {
  const { schedule, scheduledRequest, isLoading } = useSelector(
    (state: RootState) => state.scheduleRequestForm
  );
  const [endRadioValue, setEndRadioValue] = useState(
    schedule.endDate === null ? 0 : 1
  );
  const dispatch = useDispatch();
  const cronGeneratorBgColor = useColorModeValue("gray.50", "whiteAlpha.200");
  const iconsColor = useColorModeValue("gray.400", "white");

  const handleOnChangeEndRadio = useCallback((value: string) => {
    setEndRadioValue(+value);
  }, []);

  const handleOnChangeGenerateNewOnlyIsClosed = useCallback(
    (value: string) => {
      dispatch(setGenerateOnlyWhenRequestsAreClosed(value.toString() === "1"));
    },
    [dispatch]
  );

  const handleOnChangeStartOrDueDays = useCallback(
    (value: number, name?: string) => {
      name === "beginDate" && dispatch(setStartDaysAfterCreate(value));
      name === "dueDate" && dispatch(setDueDaysAfterStart(value));
    },
    [dispatch]
  );

  const handleOnChangeStartDate = useCallback(
    (date: Date | null) => {
      if (date !== null) {
        dispatch(setScheduleStartDate(date.toISOString()));
      }
    },
    [dispatch]
  );

  const handleOnChangeEndDate = useCallback(
    (date: Date | null) => {
      dispatch(setScheduleEndDate(date ? date.toISOString() : null));
    },
    [dispatch]
  );

  const handleGenerateByCompletationDateChange = useCallback(
    (value: ScheduleType) => {
      if (value === "sliding") {
        handleOnChangeGenerateNewOnlyIsClosed("1");
        dispatch(setGenerateByCompletationDate(true));
        return;
      }
      dispatch(setGenerateByCompletationDate(false));
    },
    [dispatch, handleOnChangeGenerateNewOnlyIsClosed]
  );

  const getCronExpression = useCallback(
    ({ cronExpression, executeEvery, frequency, weekOfMonth }: CronValues) => {
      dispatch(
        setScheduleCronExpression({
          cron: cronExpression,
          executeEvery,
          frequency,
          weekOfMonth,
        })
      );
    },
    [dispatch]
  );

  const initialCronValues = useMemo(() => {
    return {
      initialCronExpression: schedule.cron,
      initialExecuteEvery: schedule.executeEvery,
      initialFrecuency: schedule.frequency,
      weekOfMonth: schedule.weekOfMonth,
    };
  }, [
    schedule.cron,
    schedule.executeEvery,
    schedule.frequency,
    schedule.weekOfMonth,
  ]);

  const startDateValue = useMemo(() => {
    if (!schedule || !schedule.startDate) {
      return null;
    }

    return new Date(schedule.startDate);
  }, [schedule]);

  const scheduleType = useMemo(() => {
    return scheduledRequest.generateByCompletionDate ? "sliding" : "standard";
  }, [scheduledRequest.generateByCompletionDate]);

  return (
    <Flex flexDir="column" gap={6}>
      <RadioGroup
        value={scheduleType}
        onChange={handleGenerateByCompletationDateChange}
      >
        <Stack direction={["column", "column", "row"]} w="100%">
          <Radio value="standard" spacing={4}>
            <Flex alignItems="center">
              Standard Schedule
              <Popover
                gutter={12}
                trigger="hover"
                arrowPadding={8}
                arrowSize={12}
                placement="top-start"
              >
                <PopoverTrigger>
                  <Icon
                    ml={1}
                    boxSize="18px"
                    as={MdInfoOutline}
                    color={iconsColor}
                  />
                </PopoverTrigger>
                <PopoverContent
                  mt={-10}
                  color="white"
                  bgColor="gray.900"
                  w={["65%", "65%", "100%"]}
                >
                  <PopoverArrow />
                  <PopoverBody>
                    Exact schedule based on criteria below
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>
          </Radio>
          <Divider
            height="24px"
            orientation="vertical"
            display={["none", "none", "block"]}
          />
          <Radio value="sliding" spacing={4}>
            <Flex alignItems="center">
              Sliding Schedule
              <Popover
                gutter={12}
                trigger="hover"
                arrowSize={12}
                placement="top-start"
              >
                <PopoverTrigger>
                  <Icon
                    ml={1}
                    boxSize="18px"
                    as={MdInfoOutline}
                    color={iconsColor}
                  />
                </PopoverTrigger>
                <PopoverContent
                  mt={-10}
                  color="white"
                  bgColor="gray.900"
                  w={["65%", "65%", "100%"]}
                >
                  <PopoverArrow />
                  <PopoverBody>
                    Sliding Schedule Next run based on previous completion date
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>
          </Radio>
        </Stack>
      </RadioGroup>

      <Box w="100%">
        <FormLabel fontSize="sm">Begin schedule on</FormLabel>
        <StyledDatePicker
          showTime={true}
          name="startDate"
          format={DATE_FORMAT}
          value={startDateValue}
          onChange={handleOnChangeStartDate}
        />
      </Box>

      <Flex w="100%" flexDir="column">
        <FormLabel fontWeight="bold">Start</FormLabel>
        <Flex
          p={4}
          w="100%"
          borderRadius={6}
          border="1px solid"
          borderColor="whiteAlpha.300"
          bgColor={cronGeneratorBgColor}
        >
          {!isLoading && (
            <CustomCronGenerator
              initialCustomCronValues={initialCronValues}
              withWeeklyOptions={true}
              selectedStartDate={new Date(schedule.startDate || "")}
              getCronExpression={getCronExpression}
              type={scheduleType}
            />
          )}
        </Flex>
      </Flex>

      <Flex w="100%" flexDir="column" gap={2}>
        <FormLabel fontWeight="bold">End</FormLabel>
        <RadioGroup
          value={`${endRadioValue}`}
          onChange={handleOnChangeEndRadio}
        >
          <Stack direction="column">
            <Radio value="0" my={2} borderColor="gray.400">
              <Text>Never, does not end</Text>
            </Radio>
            <Radio value="1" borderColor="gray.400">
              <Box display="flex" flexDir="row" alignItems="center">
                <Text as="span" mr={4}>
                  On
                </Text>
                <StyledDatePicker
                  name="endDate"
                  value={schedule?.endDate || null}
                  onChange={handleOnChangeEndDate}
                  isDisabled={endRadioValue !== 1}
                />
              </Box>
            </Radio>
          </Stack>
        </RadioGroup>
      </Flex>

      <Flex
        gap={4}
        w="100%"
        flexDir="row"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <Flex w={["100%", "100%", "48%"]} gap={4}>
          <CronNumberInput
            min={0}
            name="beginDate"
            label="Work start"
            numberInputProps={{ maxW: "90px" }}
            onChange={handleOnChangeStartOrDueDays}
            value={scheduledRequest.startDaysAfterCreate}
            defaultValue={scheduledRequest.startDaysAfterCreate}
            rightTextComponent={<Text>Days after request is created</Text>}
          />
        </Flex>

        <Flex w={["100%", "100%", "48%"]} gap={4}>
          <CronNumberInput
            min={0}
            name="dueDate"
            label="Due Date"
            numberInputProps={{ maxW: "90px" }}
            onChange={handleOnChangeStartOrDueDays}
            value={scheduledRequest.dueDaysAfterStart}
            defaultValue={scheduledRequest.dueDaysAfterStart}
            rightTextComponent={<Text>Days after start date</Text>}
          />
        </Flex>
      </Flex>

      {scheduleType === "standard" && (
        <Flex w="100%" flexDir="column">
          <Text my={2}>
            Should a request be generated if the previous schedule hasn't been
            completed?
          </Text>
          <RadioGroup
            onChange={handleOnChangeGenerateNewOnlyIsClosed}
            value={
              scheduledRequest.generateOnlyWhenRequestsAreClosed ? "1" : "0"
            }
          >
            <Radio value="0" mr={4} borderColor="gray.400">
              Yes
            </Radio>
            <Radio value="1" borderColor="gray.400">
              No
            </Radio>
          </RadioGroup>
        </Flex>
      )}

      <Divider my={2} />
    </Flex>
  );
};
