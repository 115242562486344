import {
  Alert,
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Switch,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { ApiComment } from "@operations-hero/lib-api-client";
import {
  ChangeEvent,
  FC,
  PropsWithChildren,
  useCallback,
  useState,
} from "react";
import { ImEye, ImEyeBlocked } from "react-icons/im";
import { useAuthentication } from "../auth/AuthProvider";
import { UserBadge } from "../badges/UserBadge";
import {
  DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS,
  LocaleDate,
} from "../dates/LocaleDate";

type CommentVisibility = "private" | "public";
type CommentHeaderProps = {
  showVisibilityOpts?: boolean;
  onVisibilityChange: (value: CommentVisibility) => void;
  workingComment?: ApiComment;
  privateAlert?: string;
  publicAlert?: string;
  showAlert?: boolean;
};

export const DefaultMessages = {
  PRIVATE_MESSAGE: "Visibility for this comment is limited",
  PUBLIC_MESSAGE: "This comment will be visible to everybody",
} as const;

export const CommentHeader: FC<PropsWithChildren<CommentHeaderProps>> = ({
  showVisibilityOpts = true,
  onVisibilityChange,
  workingComment,
  privateAlert,
  publicAlert,
  showAlert = true,
}) => {
  const { currentUser } = useAuthentication();
  const newComment: Pick<ApiComment, "comment" | "createdBy" | "isPublic"> & {
    updated?: string;
  } = {
    comment: "",
    createdBy: currentUser,
    isPublic: true,
  };

  const { createdBy, isPublic, updated } = workingComment ?? newComment;

  const [visibility, setVisibility] = useState<CommentVisibility>(
    isPublic ? "public" : "private"
  );

  const alertBgColor = useColorModeValue("orange.200", "orange.600");

  const handleOnChangeVisibility = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = !e.target.checked;
      setVisibility(value ? "public" : "private");
      onVisibilityChange(value ? "public" : "private");
    },
    [onVisibilityChange]
  );

  return (
    <Flex flexDir="column">
      <HStack justify="space-between">
        <VStack align="stretch" alignItems="start">
          <UserBadge value={createdBy} />
          {updated && (
            <Text color="gray.500" fontSize="xs" as="span">
              <LocaleDate
                date={updated}
                options={DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS}
              />
            </Text>
          )}
        </VStack>

        {showVisibilityOpts && (
          <Box>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="isPublic" mb="0">
                {visibility === "private" ? (
                  <Icon
                    verticalAlign="-2px"
                    as={ImEyeBlocked}
                    display="inline"
                    mr={2}
                  />
                ) : (
                  <Icon
                    verticalAlign="-2px"
                    as={ImEye}
                    display="inline"
                    mr={2}
                  />
                )}
                {"Private"}
              </FormLabel>

              <Switch
                isChecked={visibility === "private"}
                onChange={handleOnChangeVisibility}
              />
            </FormControl>
          </Box>
        )}
      </HStack>
      {showVisibilityOpts && showAlert && (
        <Alert
          my={2}
          py={1}
          status="warning"
          borderRadius={"md"}
          bgColor={alertBgColor}
          justifyContent="center"
        >
          {visibility === "private"
            ? privateAlert ?? DefaultMessages.PRIVATE_MESSAGE
            : publicAlert ?? DefaultMessages.PUBLIC_MESSAGE}
        </Alert>
      )}
    </Flex>
  );
};
