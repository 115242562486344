import { ApiLocation } from "@operations-hero/lib-api-client";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../components/auth/AuthProvider";
import { RootState } from "../store";
import { useLocationUtils } from "../utils/locationUtils";

export const useAllowedLocations = () => {
  const {
    policyMap,
    workflowMap,
    locationMap,
    locations: locationsLocalCache,
  } = useSelector((state: RootState) => state.localCache);
  const { isProductAdmin } = useAuthentication();
  const { getChildrenId } = useLocationUtils();

  const allowedLocations = useMemo(() => {
    if (isProductAdmin) {
      return locationsLocalCache;
    }

    let allLocationsAllowed: boolean = false;
    let locationsIds: string[] = [];

    // Check each workflow policy for restrictions
    Object.keys(workflowMap).forEach((workflow) => {
      // no need to check extra workflows if the user is eligible for other locations
      if (allLocationsAllowed) {
        return;
      }

      const policy = policyMap[workflow];
      const {
        admin,
        reviewer,
        technician,
        approver,
        viewer,
        contractor,
        requester,
      } = policy;

      if (admin) {
        allLocationsAllowed = true;
        return;
      }

      const locationRestrictedFields = [
        reviewer,
        technician,
        approver,
        viewer,
        contractor,
        requester,
      ];
      locationRestrictedFields.forEach((x) => {
        // we found a premissive role in the policy already
        if (allLocationsAllowed === true) {
          return;
        }

        // no-restriction policies are stored in a boolean format i.e `reviewer: true`
        if (typeof x === "boolean") {
          if (x === true) {
            allLocationsAllowed = x === true;
          }
          return;
        }
        // not a bool not an array, this is bad
        if (!Array.isArray(x?.locations)) {
          return;
        }

        // empty array is assumed "All"
        if (x.locations.length === 0) {
          allLocationsAllowed = true;
        } else {
          locationsIds.push(...x.locations);
        }
      });
    });

    // prevent tree traversal if not needed.
    if (allLocationsAllowed) {
      return locationsLocalCache;
    }

    // not sure how we get here
    if (locationsIds.length === 0) {
      return [];
    }

    const allowedLocations: ApiLocation[] = [];
    // unique up the ids we need to fetch
    locationsIds = Array.from(new Set(locationsIds));

    for (let key of locationsIds) {
      const childAndFatherLocationIds = getChildrenId([locationMap[key]]);
      childAndFatherLocationIds.map((id) =>
        locationMap[id].active ? allowedLocations.push(locationMap[id]) : null
      );
    }

    return allowedLocations;
  }, [
    isProductAdmin,
    locationMap,
    locationsLocalCache,
    policyMap,
    workflowMap,
    getChildrenId,
  ]);

  return { allowedLocations };
};
