import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { ApiLocationType } from "@operations-hero/lib-api-client";
import { Select } from "chakra-react-select";
import { useCallback, useMemo } from "react";

import { LocationTypeIconMap } from "../badges/LocationBadge";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomOptionsProps,
  SingleValueSelect,
} from "./select-overrides-types";

export interface LocationTypeSelectProps {
  value: ApiLocationType;
  onChange?: (status: ApiLocationType) => void;
  isDisabled?: boolean;
}

type ValueLabel = {
  value: ApiLocationType;
  label: ApiLocationType;
};

const CustomOption = ({ value }: { value: ValueLabel }) => {
  return (
    <HStack w="100%" pl={2} alignItems="center">
      <Icon as={LocationTypeIconMap[value.value as ApiLocationType]} mr={2} />
      <Text>{value.value.toUpperCase()}</Text>
    </HStack>
  );
};

const CustomSingleValueComponent = (props: SingleValueSelect<ValueLabel>) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <HStack w="100%" p={0} alignItems="center">
        <Icon as={LocationTypeIconMap[data.value as ApiLocationType]} mr={2} />
        <Text>{data.value.toUpperCase()}</Text>
      </HStack>
    </Box>
  );
};

export const LocationTypeSelect = ({
  value,
  onChange,
  isDisabled,
}: LocationTypeSelectProps) => {
  const CustomOptionComponent = createOptionComponent(CustomOption);

  const options: ValueLabel[] = useMemo(
    () =>
      Object.values(ApiLocationType).map((x) => ({
        value: x,
        label: x,
      })),
    []
  );

  const handleChange = useCallback(
    (item: ValueLabel | null) => {
      if (onChange && item) onChange(item.value);
    },
    [onChange]
  );

  const components = useMemo(
    () => ({
      ...getCustomSelectComponents(),
      SingleValue: (props: SingleValueSelect<ValueLabel>) =>
        CustomSingleValueComponent(props),
      Option: (props: CustomOptionsProps<ValueLabel>) =>
        CustomOptionComponent(props),
    }),
    [CustomOptionComponent]
  );

  return (
    <Select
      defaultValue={options.find((x) => x.value === value)}
      options={options}
      onChange={handleChange}
      chakraStyles={commonStyles}
      components={components}
      isDisabled={isDisabled}
    />
  );
};
