import {
  ApiClient,
  ApiInventoryPurchaseOrder,
  ApiPagingInventoryPurchaseOrderOptions,
  ApiPurchaseOrderAttachmentType,
  CreateApiInventoryPurchaseOrder,
  CreateApiPurchaseOrderAttachment,
  UpdateApiInventoryPurchaseOrder,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { Attachment } from "../../../components/attachments/Attachments";
import { RequestSliceState } from "../request-form.slice";

export interface InventoryPurchaseOrders {
  page: number;
  total: number;
  pageSize: number;
  isLoading: boolean;
  isOpenRemoveModal: boolean;
  isPurchaseOrderFormOpen: boolean;
  data: ApiInventoryPurchaseOrder[];
  workingPurchaseOrder: ApiInventoryPurchaseOrder | null;
}

export const DEFAULT_INVENTORY_PURCHASE_ORDER: InventoryPurchaseOrders = {
  page: 1,
  total: 0,
  data: [],
  pageSize: 10,
  isLoading: false,
  isOpenRemoveModal: false,
  workingPurchaseOrder: null,
  isPurchaseOrderFormOpen: false,
};

export interface LoadInventoryPurchaseOrdersThunkParams {
  apiClient: ApiClient;
  accountId: string;
  requestKey: string;
  options: ApiPagingInventoryPurchaseOrderOptions;
}

export const loadInventoryPurchaseOrders = createAsyncThunk(
  "requests/loadInventoryPurchaseOrders",
  async (params: LoadInventoryPurchaseOrdersThunkParams, thunkAPI) => {
    const { apiClient, accountId, requestKey } = params;
    const state = thunkAPI.getState() as RootState;
    const { pageSize, page } = state.requestForm.inventoryPurchaseOrders;
    const purchaseOrders = await apiClient.findInventoryPurchaseOrders(
      accountId,
      { pageSize, page, requestKey }
    );

    return purchaseOrders;
  }
);

export const loadInventoryPurchaseOrdersHandler = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(loadInventoryPurchaseOrders.fulfilled, (state, action) => {
    state.inventoryPurchaseOrders.data = action.payload.data;
    state.inventoryPurchaseOrders.total = action.payload.total;
    state.inventoryPurchaseOrders.page = action.payload.options.page || 1;
  });
};

export interface CreateInventoryPurchaseOrdersThunkParams {
  accountId: string;
  apiClient: ApiClient;
  purchaseOrder: CreateApiInventoryPurchaseOrder;
  attachmentsToCreate?: Attachment[];
}

export const createInventoryPurchaseOrder = createAsyncThunk(
  "requests/createInventoryPurchaseOrder",
  async (params: CreateInventoryPurchaseOrdersThunkParams, thunkAPI) => {
    const { apiClient, accountId, purchaseOrder, attachmentsToCreate } = params;

    const newPurchaseOrder = await apiClient.createInventoryPurchaseOrder(
      accountId,
      purchaseOrder
    );

    if (attachmentsToCreate) {
      const [firstItem] = newPurchaseOrder.items;
      const newAttachments = attachmentsToCreate.filter((att) => att.isNew);

      const attachments = newAttachments.map(
        (att) =>
          ({
            name: att.name,
            uploadId: att.uploadId,
            purchaseOrderItemId: firstItem.id,
            type: ApiPurchaseOrderAttachmentType.purchaseOrderItem,
          }) as CreateApiPurchaseOrderAttachment
      );

      await Promise.all(
        attachments.map((att) =>
          apiClient.createInventoryPurchaseOrderAttachment(
            accountId,
            newPurchaseOrder.id,
            att
          )
        )
      );
    }

    return newPurchaseOrder;
  }
);

export const createInventoryPurchaseOrderHandler = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(createInventoryPurchaseOrder.fulfilled, (state, action) => {
    state.inventoryPurchaseOrders.data.push(action.payload);
    state.inventoryPurchaseOrders.total =
      +state.inventoryPurchaseOrders.total + 1;
    state.inventoryPurchaseOrders.isLoading = false;
  });

  builder.addCase(createInventoryPurchaseOrder.pending, (state) => {
    state.inventoryPurchaseOrders.isLoading = true;
  });
};

export interface UpdateInventoryPurchaseOrdersThunkParams {
  accountId: string;
  apiClient: ApiClient;
  purchaseOrderId: string;
  purchaseOrder: UpdateApiInventoryPurchaseOrder;
  attachmentsToCreate?: Attachment[];
  attachmentsToRemove?: Attachment[];
}

export const updateInventoryPurchaseOrder = createAsyncThunk(
  "requests/updateInventoryPurchaseOrder",
  async (params: UpdateInventoryPurchaseOrdersThunkParams, thunkAPI) => {
    const {
      apiClient,
      accountId,
      purchaseOrder,
      purchaseOrderId,
      attachmentsToCreate,
      attachmentsToRemove,
    } = params;

    const updatedPurchaseOrder = await apiClient.updateInventoryPurchaseOrder(
      accountId,
      purchaseOrderId,
      purchaseOrder
    );

    if (attachmentsToCreate && attachmentsToCreate.length > 0) {
      const [firstItem] = updatedPurchaseOrder.items;
      const newAttachments = attachmentsToCreate.filter((att) => att.isNew);

      const attachments = newAttachments.map(
        (att) =>
          ({
            name: att.name,
            uploadId: att.uploadId,
            purchaseOrderItemId: firstItem.id,
            type: ApiPurchaseOrderAttachmentType.purchaseOrderItem,
          }) as CreateApiPurchaseOrderAttachment
      );

      await Promise.all(
        attachments.map((att) =>
          apiClient.createInventoryPurchaseOrderAttachment(
            accountId,
            updatedPurchaseOrder.id,
            att
          )
        )
      );
    }

    if (attachmentsToRemove && attachmentsToRemove.length > 0) {
      await Promise.all(
        attachmentsToRemove.map((att) =>
          apiClient.deleteInventoryPurchaseOrderAttachment(
            accountId,
            updatedPurchaseOrder.id,
            att.id || ""
          )
        )
      );
    }

    return updatedPurchaseOrder;
  }
);

export const updateInventoryPurchaseOrderHandler = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(updateInventoryPurchaseOrder.fulfilled, (state, action) => {
    const index = state.inventoryPurchaseOrders.data.findIndex(
      (purchaseOrder) => purchaseOrder.id === action.payload.id
    );

    if (index !== -1) {
      state.inventoryPurchaseOrders.data[index] = action.payload;
    }
    state.inventoryPurchaseOrders.isLoading = false;
  });

  builder.addCase(updateInventoryPurchaseOrder.pending, (state) => {
    state.inventoryPurchaseOrders.isLoading = true;
  });
};

export interface RemoveInventoryPurchaseOrdersThunkParams {
  apiClient: ApiClient;
  accountId: string;
  id: string;
}

export const removeInventoryPurchaseOrder = createAsyncThunk(
  "requests/reomveInventoryPurchaseOrder",
  async (params: RemoveInventoryPurchaseOrdersThunkParams, thunkAPI) => {
    const { apiClient, accountId, id } = params;

    await apiClient.removeInventoryPurchaseOrder(accountId, id);
    return id;
  }
);

export const removeInventoryPurchaseOrdersHandler = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(removeInventoryPurchaseOrder.fulfilled, (state, action) => {
    const index = state.inventoryPurchaseOrders.data.findIndex(
      (purchaseOrder) => purchaseOrder.id === action.payload
    );

    if (index !== -1) {
      state.inventoryPurchaseOrders.data.splice(index, 1);
    }
  });
};
