import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { ApiVenueSummary } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { useCallback } from "react";
import { EventFormValues } from "../../pages/events/event-form/EventForm";
import { EventServiceData } from "../../pages/events/event-form/form-components/ServicesMulticheck";
import { VenueAutocomplete } from "../selects/VenueAutocomplete";

export interface VenueAutocompleteControlProps {
  label?: string;
  name: string;
  value: ApiVenueSummary | null;
  isDisabled?: boolean;
  cleanValuesNames?: string[];
  options?: ApiVenueSummary[];
  uses?: string[];
  onSelect?: () => void;
}

export const VenueAutocompleteControl = ({
  name,
  value,
  label,
  isDisabled,
  cleanValuesNames,
  options,
  uses,
  onSelect,
}: VenueAutocompleteControlProps) => {
  const { submitCount, setFieldValue, values } =
    useFormikContext<EventFormValues>();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
  });

  const handleOnChange = useCallback(
    (venue: ApiVenueSummary | null) => {
      helper.setTouched(true);
      helper.setValue(venue);
      if (cleanValuesNames) {
        cleanValuesNames.forEach((element) => {
          if (element === "services") {
            setFieldValue(
              element,
              {} as Record<string, EventServiceData>,
              false
            );
          } else {
            setFieldValue(element, [], false);
          }
        });
      }
      if (onSelect) {
        onSelect();
      }
    },
    [cleanValuesNames, helper, setFieldValue, onSelect]
  );

  return (
    <FormControl isInvalid={!values.venue && (meta.touched || submitCount > 0)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <VenueAutocomplete
        {...field}
        onChange={handleOnChange}
        name={name}
        isDisabled={isDisabled}
        options={options}
        uses={uses}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
