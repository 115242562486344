import {
  Flex,
  FlexProps,
  IconButton,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC } from "react";
import { VscTrash } from "react-icons/vsc";

interface ESigntaureViewProps {
  fullName: string;
  base64Image: string;
  onDelete?: () => void;
  showText?: boolean;
  mainFlexProp?: FlexProps;
  secondFlexProp?: FlexProps;
}
export const ESignatureViewer: FC<ESigntaureViewProps> = ({
  onDelete,
  fullName,
  base64Image,
  showText = true,
  mainFlexProp,
  secondFlexProp,
}) => {
  const signBorderColor = useColorModeValue("gray.400", "blue.300");

  return (
    <Flex
      w="100%"
      direction="column"
      justifyContent="center"
      alignItems="center"
      {...mainFlexProp}
    >
      <Flex w="full" justifyContent="space-between">
        {showText && <Text>Order received by</Text>}
        {Boolean(onDelete) && (
          <IconButton
            aria-label="remove"
            size="sm"
            icon={<VscTrash />}
            onClick={onDelete}
          />
        )}
      </Flex>
      <Flex
        direction="column"
        justifyContent="center"
        borderBottom="2px dashed"
        borderColor={signBorderColor}
        {...secondFlexProp}
      >
        <Image src={base64Image} objectFit="contain" w="100%" />
      </Flex>
      <Text opacity={0.7}>{fullName}</Text>
    </Flex>
  );
};
