import { HStack, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { ApiInspection } from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import { FC } from "react";
import { UserBadge } from "../../../../../components/badges/UserBadge";

const dateFormat = "MMMM dd, yyyy 'at' hh:mm a";

interface InspectionSubmitNotesProps {
  inspection: ApiInspection;
}

export const InspectionSubmitNotes: FC<InspectionSubmitNotesProps> = ({
  inspection,
}) => {
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("gray.500", "white");

  return (
    <Stack
      direction="column"
      bgColor={bgColor}
      p={8}
      borderRadius={8}
      borderColor={borderColor}
      borderWidth={1}
      w="100%"
    >
      <HStack>
        {inspection.inspector && <UserBadge value={inspection.inspector} />}
        {inspection.updated && (
          <Text textColor={textColor}>
            {format(inspection.updated, dateFormat)}
          </Text>
        )}
      </HStack>
      <HStack justifyContent="space-between" alignContent="flex-start">
        <Text>{inspection.submitNotes}</Text>
      </HStack>
    </Stack>
  );
};
