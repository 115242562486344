import {
  ApiInspection,
  ApiInspectionScore,
  ApiInspectionScoreConfig,
  ApiInspectionStatus,
} from "@operations-hero/lib-api-client";
import {
  InspectionBuildingMap,
  InspectionBuildingToFloorsMap,
  InspectionFloorToRoomsMap,
  InspectionRoomContextMap,
  LocationRoomStatus,
  LocationSubBuildingStatus,
  ScoresRecord,
} from "../store/planning-hq/inspections/details/inspection-details.slice";

export function calculateRoomStatus(
  roomId: string,
  scores: ScoresRecord,
  totalCategories: number
): LocationRoomStatus {
  const roomScores = Object.keys(scores).filter((key) => key.includes(roomId));

  switch (roomScores.length) {
    case 0:
      return LocationRoomStatus.pending;
    case totalCategories:
      return LocationRoomStatus.completed;
    default:
      return LocationRoomStatus.inProgress;
  }
}

export function calculateBuildingStatus(
  buildingId: string,
  buildingToFloorsMap: InspectionBuildingToFloorsMap,
  floorToRoomsMap: InspectionFloorToRoomsMap
): LocationSubBuildingStatus {
  let hasPending = false;
  let hasComplete = false;
  for (let floor of Object.values(buildingToFloorsMap[buildingId] || {})) {
    for (let room of Object.values(floorToRoomsMap[floor.location.id] || {})) {
      // any inProgress means its still in progress no need to check anything else
      if (room.status === LocationRoomStatus.inProgress) {
        return LocationSubBuildingStatus.inProgress;
      }
      if (room.status === LocationRoomStatus.pending) {
        hasPending = true;
        continue;
      }

      if (room.status === LocationRoomStatus.completed) {
        hasComplete = true;
      }
    }
  }

  //if its first building in progress inspection goes to started
  if (hasPending) {
    return hasComplete
      ? LocationSubBuildingStatus.inProgress
      : LocationSubBuildingStatus.pending;
  }

  // every element was completed, 0 pendings and 0 inProgress
  return LocationSubBuildingStatus.completed;
}

export function updateRoomAndBuildingToProgressStatus(
  roomId: string,
  roomContext: InspectionRoomContextMap,
  floorToRoomsMap: InspectionFloorToRoomsMap,
  buildingMap: InspectionBuildingMap,
  inspection: ApiInspection,
  previousScore?: ApiInspectionScore,
  updatedScore?: ApiInspectionScore,
  scoreConfigMap?: Record<string, ApiInspectionScoreConfig>
) {
  //if its first score and before has no score, room and building go to inProgress
  const buildingId = roomContext[roomId]?.building.location.id;
  const floorId = roomContext[roomId]?.floor.location.id;

  const room =
    floorToRoomsMap["all-rooms"]?.[roomId] ??
    floorToRoomsMap[floorId]?.[roomId];

  if (!room) return;
  const building = buildingMap[buildingId];
  // Room was pending, this means that room is first time inspected
  if (room.status === LocationRoomStatus.pending) {
    room.status = LocationRoomStatus.inProgress;

    if (inspection.status === ApiInspectionStatus.pending) {
      inspection.status = ApiInspectionStatus.started;
    }

    if (building && building.status === LocationSubBuildingStatus.pending) {
      building.status = LocationSubBuildingStatus.inProgress;
    }
  }

  //update violations
  if (!scoreConfigMap) return;

  if (previousScore && previousScore.scoreConfigId) {
    const previousScoreConfig = scoreConfigMap[previousScore.scoreConfigId];
    if (previousScoreConfig?.justificationRequired) {
      room.violations[previousScore.scoreConfigId] =
        (room.violations[previousScore.scoreConfigId] || 0) - 1;
      if (room.violations[previousScore.scoreConfigId] <= 0) {
        delete room.violations[previousScore.scoreConfigId];
      }

      if (building) {
        building.violations[previousScore.scoreConfigId] =
          (building.violations[previousScore.scoreConfigId] || 0) - 1;
        if (building.violations[previousScore.scoreConfigId] <= 0) {
          delete building.violations[previousScore.scoreConfigId];
        }
      }
    }
  }

  if (!updatedScore || !updatedScore.scoreConfigId) return;
  const scoreConfig = scoreConfigMap[updatedScore.scoreConfigId];
  if (scoreConfig?.justificationRequired) {
    room.violations[updatedScore.scoreConfigId] =
      (room.violations[updatedScore.scoreConfigId] || 0) + 1;

    if (building) {
      building.violations[updatedScore.scoreConfigId] =
        (building.violations[updatedScore.scoreConfigId] || 0) + 1;
    }
  }
}
