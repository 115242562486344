import { Flex } from "@chakra-ui/react";
import {
  ApiInspection,
  ApiInspectionConfig,
  ApiInspectionScore,
  ApiInspectionScoreConfig,
  ApiInspectionStatus,
} from "@operations-hero/lib-api-client";
import { FC } from "react";
import { ESignatureViewer } from "../../../../../components/e-sign/E-SignatureViewer";
import { InspectionSubmitNotes } from "./InspectionSubmitNotes";
import { TotalScoreDetails } from "./TotalScoreDetails";

interface InspectionNotesAndSignProps {
  inspection: ApiInspection;
  inspectionConfig: ApiInspectionConfig;
  scoreConfigMap: Record<string, ApiInspectionScoreConfig>;
  scores: Record<string, ApiInspectionScore>;
  showRatingCard: boolean;
}

export const InspectionReportSection: FC<InspectionNotesAndSignProps> = ({
  inspection,
  inspectionConfig,
  scoreConfigMap,
  scores,
  showRatingCard,
}) => {
  return (
    <>
      {(inspection.status === ApiInspectionStatus.completed ||
        inspection.status === ApiInspectionStatus.submitted) && (
        <TotalScoreDetails
          inspection={inspection}
          inspectionConfig={inspectionConfig}
          scoreConfigMap={scoreConfigMap}
          scores={scores}
          showRatingCard={showRatingCard}
        />
      )}
      {inspection.status === ApiInspectionStatus.submitted &&
        inspection.submitNotes && (
          <InspectionSubmitNotes inspection={inspection} />
        )}
      {inspection.status === ApiInspectionStatus.submitted &&
        inspection.eSign &&
        inspection.inspector && (
          <Flex w="100%" direction="column" mt={8} gap={6}>
            <ESignatureViewer
              base64Image={inspection.eSign.url || ""}
              fullName={`${inspection.inspector.firstName} ${inspection.inspector.lastName}`}
              showText={false}
              mainFlexProp={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
              secondFlexProp={{ w: "260px", h: "90px" }}
            />
          </Flex>
        )}
    </>
  );
};
