import { EventInput } from "@fullcalendar/react";
import { ResourceInput } from "@fullcalendar/resource-common";
import {
  ApiClient,
  ApiEventAccountOccurrenceOptions,
  ApiEventStatus,
  ApiLocation,
  ApiRequest,
  ApiRequestsQueryFilter,
  ApiRequestStatus,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addHours, subHours } from "date-fns";
import { RootState } from ".";
import { CalendarType, CalendarView } from "../pages/calendar/calendar-helpers";
import {
  CalendarEventsQueryStringFilter,
  CalendarRequestQueryStringFilter,
  CalendarViewFilters,
} from "../pages/calendar/requests/filters/DefaultFilter";
import {
  formatUTCToText,
  localMidnightToUTC,
} from "../utils/localMidnightToUTC";

interface LoadCalendarThunkParams {
  apiClient: ApiClient;
  accountId: string;
}

interface LoadRequest extends LoadCalendarThunkParams {
  filter: CalendarRequestQueryStringFilter;
  quickFilter?: ApiRequestsQueryFilter;
}

export const getCalendarDates = (
  request: ApiRequest
): { start: Date; end: Date } => {
  if (!request.scheduling.start && !request.scheduling.due) {
    const start = new Date(request.created);
    const end = addHours(start, 1);
    return { start, end };
  }

  if (request.scheduling.start && !request.scheduling.due) {
    const start = new Date(request.scheduling.start);
    const end = addHours(start, 1);
    return { start, end };
  }

  if (!request.scheduling.start && request.scheduling.due) {
    const end = new Date(request.scheduling.due);
    const start = subHours(end, 1);
    return { start, end };
  }

  return {
    start: new Date(request.scheduling.start || ""),
    end: new Date(request.scheduling.due || ""),
  };
};

const personFilterMap = (filter: CalendarRequestQueryStringFilter) => {
  const { persons } = filter;
  if (persons.length === 0) return undefined;
  const assignee = persons
    .filter((item) => item.type === "assignee")
    .map((item) =>
      typeof item.groupOrUser === "string"
        ? item.groupOrUser
        : item.groupOrUser.assignee.id
    );
  const requester = persons
    .filter((item) => item.type === "requester")
    .map((item) =>
      typeof item.groupOrUser === "string"
        ? item.groupOrUser
        : item.groupOrUser.assignee.id
    );
  return { assignee, requester };
};

export const loadRequests = createAsyncThunk(
  "calendar/load-requests",
  async (params: LoadRequest, ThunkAPI) => {
    const { apiClient, accountId, filter, quickFilter } = params;

    let newPage = 1;
    let newTotal = 0;
    let pageSize = 100;
    const results: EventInput[] = [];
    const filterMap = {
      ...filter,
      persons: undefined,
      params: undefined,
      startEvent: undefined,
      start: undefined,
      calendarDates: filter.start,
      ...personFilterMap(filter),
    };

    do {
      const requests = await apiClient.findRequests(accountId, {
        page: newPage,
        pageSize,
        ...filterMap,
        quickFilter,
        status: filter.status.length
          ? filter.status
          : Object.values(ApiRequestStatus).filter(
              (status) =>
                status !== ApiRequestStatus.review &&
                status !== ApiRequestStatus.closed &&
                status !== ApiRequestStatus.canceled
            ),
      });

      newTotal = requests.total;
      const resources = requests.data.map<EventInput>((x) => {
        const { start, end } = getCalendarDates(x);
        return {
          id: `request::${x.id}`,
          title: `${x.key} ${x.status.toUpperCase()} ${x.summary}`,
          start: start.toISOString(),
          end: end.toISOString(),
          resourceIds:
            filter.params.view === CalendarView.location
              ? [`location::${x.location?.id}`]
              : x.assignees.length === 0
                ? ["assignee::unassigned"]
                : x.assignees.map((x) => `assignee::${x.assignee.id}`),
          editable: ![
            ApiRequestStatus.canceled,
            ApiRequestStatus.closed,
          ].includes(x.status),
          extendedProps: x,
        };
      });
      results.push(...resources);
      newPage++;
    } while (newPage <= Math.ceil(newTotal / pageSize));

    return { results };
  }
);

const fetchLocationResources = async (allowedLocations: ApiLocation[]) => {
  const parentNodes = allowedLocations.filter((l) => l.parent == null);

  const calculateNode = (loc: ApiLocation): ResourceInput => {
    return {
      id: `location::${loc.id}`,
      title: loc.name,
      extendedProps: loc,
      children: allowedLocations
        .filter((n) => n.parent === loc.id)
        .map((n) => calculateNode(n)),
    };
  };

  if (parentNodes.length === 0) {
    return allowedLocations.map((x) => calculateNode(x));
  }

  const response = parentNodes.map((x) => calculateNode(x));
  return response;
};

const fetchAssigneeResources = async (
  apiClient: ApiClient,
  accountId: string,
  workflowFilterValues: string[]
) => {
  const results: ResourceInput[] = [
    {
      id: `assignee::unassigned`,
      title: "Unassigned",
      order: 1,
    },
  ];
  const assignees = await apiClient.findUserAndGroupsRequestFilter(accountId, {
    typeToSearch: "assignee",
    workflowIdsOrSlugs:
      workflowFilterValues.length > 0 ? workflowFilterValues : undefined,
  });

  assignees.forEach((item) => {
    const newItem =
      item.type === "group"
        ? {
            id: `assignee::${item.assignee.id}`,
            title: item.assignee.name,
            extendedProps: item,
          }
        : {
            id: `assignee::${item.assignee.id}`,
            title: `${item.assignee.firstName} ${item.assignee.lastName}`,
            extendedProps: item,
          };
    results.push(newItem);
  });

  return results;
};

interface LoadRequestResourcesParams extends LoadCalendarThunkParams {
  filter: CalendarRequestQueryStringFilter;
  allowedLocations: ApiLocation[];
  viewFromObject?: boolean;
}
export const loadRequestResources = createAsyncThunk(
  "calendar/load-resources",
  async (params: LoadRequestResourcesParams, ThunkAPI) => {
    const { apiClient, accountId, filter, allowedLocations, viewFromObject } =
      params;
    const { currentView } = (ThunkAPI.getState() as RootState).calendar;
    const view = viewFromObject ? filter.params.view : currentView.view;
    if (view === CalendarView.location) {
      const result = await fetchLocationResources(allowedLocations);
      return result;
    } else if (view === CalendarView.assignee) {
      const result =
        (await fetchAssigneeResources(apiClient, accountId, filter.workflow)) ||
        [];
      return result;
    }
    return [];
  }
);

interface LoadEventsParams {
  accountId: string;
  apiClient: ApiClient;
  filter: CalendarEventsQueryStringFilter;
  colors: {
    pending: string;
    confirmed: string;
  };
}

const loadVenueSpacesBlocks = async (
  accountId: string,
  apiClient: ApiClient,
  { calendarDates }: { calendarDates: string[] }
) => {
  let pageSize = 100;
  let currentPage = 1;
  let newTotal = 0;

  let resources: EventInput[] = [];

  do {
    const response = await apiClient.findVenuesOrSpacesBlocked(accountId, {
      pageSize,
      page: currentPage,
      calendarDates,
    });

    const { options, total } = response;
    currentPage = options.page || 1;
    newTotal = total;

    response.data.forEach((be, idx) => {
      const title = `Blocked ${be.type} ${
        "venue" in be ? be.venue.name : be.space.location.name
      }`;
      const { datesToBlock } = be;
      const newEvents = datesToBlock.map((dates, idx) => {
        const newStart = new Date(dates.start).toISOString();
        const aux = new Date(dates.end);
        aux.setHours(23, 59);
        const newEnd = aux.toISOString();
        return {
          id: `event::${title}::${idx}`,
          title: title,
          start: newStart,
          end: newEnd,
          editable: true,
          extendedProps: {
            ...be,
            start: newStart,
            end: newEnd,
            isBlockedItem: true,
            datesToBlock: [{ start: newStart, end: newEnd }],
          },
        };
      });
      resources.push(...newEvents);
    });
  } while (newTotal >= pageSize * currentPage);
  return resources;
};

export const loadEvents = createAsyncThunk(
  "calendar/load-event-occurrences",
  async (params: LoadEventsParams, ThunkAPI) => {
    const { page, pageSize, total } = (ThunkAPI.getState() as RootState)
      .calendar;
    const { apiClient, accountId, filter, colors } = params;

    const firstDayOfTheMonth = new Date();
    firstDayOfTheMonth.setDate(1);

    let newPage = page;
    let newTotal = total;
    const results: EventInput[] = [];
    const calendarDates = [
      localMidnightToUTC(new Date(formatUTCToText(filter.start[0]))),
      localMidnightToUTC(new Date(formatUTCToText(filter.start[1]))),
    ];
    const filterMap: ApiEventAccountOccurrenceOptions = {
      calendarDates,
      status: filter.status,
      group: filter.eventGroups,
      venue: filter.venues,
      spaces: filter.spaces,
      services: filter.services,
      equipments: filter.equipments,
      pageSize,
    };

    do {
      newPage++;
      const eventOccurrences = await apiClient.findEventAccountOccurrences(
        accountId,
        {
          page: newPage,
          isForCalendar: true,
          ...filterMap,
          status: filter.status.length
            ? filter.status
            : Object.values(ApiEventStatus).filter(
                (st) => st !== ApiEventStatus.declined
              ),
        }
      );

      newTotal = eventOccurrences.total;
      const resources = eventOccurrences.data.map((eo) => {
        return {
          id: `event::${eo.id}`,
          title: eo.event.name,
          borderColor:
            eo.event.status === ApiEventStatus.confirmed
              ? colors.confirmed
              : colors.pending,
          start: eo.start,
          end: eo.end,
          editable: true,
          extendedProps: { ...eo, isEvent: true },
        };
      });
      results.push(...resources);
    } while (newTotal >= newPage * pageSize);

    const blockedVenueOrSpaces = await loadVenueSpacesBlocks(
      accountId,
      apiClient,
      {
        calendarDates,
      }
    );
    return { results, blockedVenueOrSpaces };
  }
);

export type PrintMode = "idle" | "full" | "slim";

interface CalendarSliceState {
  page: number;
  total: number;
  pageSize: number;
  loading: boolean;
  data: EventInput[];
  resources: ResourceInput[];
  isOpenPrintModal: PrintMode;
  isOpenPrintOptionsModal: boolean;
  currentView: CalendarViewFilters;
}

const INITIAL_VALUES: CalendarSliceState = {
  page: 0,
  total: 0,
  pageSize: 100,
  data: [],
  resources: [],
  loading: false,
  isOpenPrintModal: "idle",
  isOpenPrintOptionsModal: false,
  currentView: {
    view: CalendarView.calendar,
    type: CalendarType.dayGridMonth,
    date: new Date().toISOString(),
  },
};

export const calendar = createSlice({
  name: "calendar",
  initialState: {
    ...INITIAL_VALUES,
  },
  reducers: {
    unloadCalendarSlice: (state: CalendarSliceState) => {
      state = { ...INITIAL_VALUES };
    },

    setCalendarLoading: (
      state: CalendarSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },

    setIsOpenPrintModal: (
      state: CalendarSliceState,
      action: PayloadAction<PrintMode>
    ) => {
      state.isOpenPrintModal = action.payload;
    },

    setIsOpenPrintOptionsModal: (
      state: CalendarSliceState,
      action: PayloadAction<boolean>
    ) => {
      state.isOpenPrintOptionsModal = action.payload;
    },

    setCalendarView: (
      state: CalendarSliceState,
      action: PayloadAction<CalendarViewFilters>
    ) => {
      state.currentView = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadRequests.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.results;
    });

    builder.addCase(loadRequestResources.fulfilled, (state, action) => {
      state.resources = action.payload;
    });

    builder.addCase(loadEvents.fulfilled, (state, action) => {
      const newData = [
        ...action.payload.results,
        ...action.payload.blockedVenueOrSpaces,
      ];
      state.loading = false;
      state.data = newData;
    });
  },
});

export const {
  unloadCalendarSlice,
  setCalendarLoading,
  setIsOpenPrintModal,
  setIsOpenPrintOptionsModal,
  setCalendarView,
} = calendar.actions;

export default calendar.reducer;
