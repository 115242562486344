import { InfoIcon } from "@chakra-ui/icons";
import {
  Alert,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { UserBadge } from "../../../../components/badges/UserBadge";
import { ButtonLink } from "../../../../components/buttons/LinkButton";
import { AutoGrowTextarea } from "../../../../components/inputs/AutoGrowTextarea";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../../store";
import {
  DEFAULT_SUBMIT_VALUES,
  setDeliverProps,
} from "../../../../store/planning-hq/inspections/details/inspection-details.slice";
import { submitInspection } from "../../../../store/planning-hq/inspections/details/thunks/submitInspection";
import { SimpleFieldText } from "../InspectionDetails";
import { SubmitInspectionESignSection } from "./SubmitInspectionESignSection";

export const SubmitInspection: FC = () => {
  const { inspection } = useSelector(
    (state: RootState) => state.inspectionDetailsSlice
  );
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const showToast = useShowToast();
  const navigate = useNavigate();
  const { apiClient, currentAccount } = useAuthentication();
  const { submitNotes, signature } = useSelector(
    (state: RootState) => state.inspectionDetailsSlice.submitProps
  );

  const handleOnSubmitInspection = useCallback(() => {
    if (!inspection) return;
    thunkDispatch(
      submitInspection({
        apiClient,
        accountId: currentAccount.id,
        inspectionId: inspection.id,
      })
    )
      .then(unwrapResult)
      .then(() => {
        showToast(
          "success",
          `Inspection for ${inspection?.location.name} was submitted successfully`
        );
      })
      .catch(() => {
        showToast(
          "error",
          `Something went wrong while submitting Inspection for ${inspection?.location.name} please try again!`
        );
      })
      .finally(() => {
        dispatch(setDeliverProps({ ...DEFAULT_SUBMIT_VALUES }));
        navigate(`/planning/inspection`);
      });
  }, [
    apiClient,
    currentAccount.id,
    dispatch,
    showToast,
    thunkDispatch,
    inspection,
    navigate,
  ]);

  const handleOnChangeSubmitNotes = useCallback(
    (value: string) => {
      if (!value) {
        dispatch(setDeliverProps({ submitNotes: null }));
        return;
      }
      dispatch(setDeliverProps({ submitNotes: value }));
    },
    [dispatch]
  );

  if (!inspection) {
    return null;
  }

  return (
    <VStack w="100%" gap={8} alignItems="flex-start">
      {/* back button */}
      <Flex align="center" mb={5}>
        <ButtonLink to={`/planning/inspection/${inspection.id}/details`} />
      </Flex>
      {/* title row */}

      <Heading flex={1} aria-label="sign-to-submit">
        Sign to Submit
      </Heading>

      {inspection.inspector && (
        <SimpleFieldText
          label="Inspector:"
          value={<UserBadge value={inspection.inspector} />}
        />
      )}

      <SubmitInspectionESignSection />

      <Flex w="100%" flexDir="column" gap={4}>
        <FormControl>
          <FormLabel>Inspectors comments and rating explanation:</FormLabel>
          <AutoGrowTextarea
            value={submitNotes}
            placeHolder="Write text here..."
            onChange={(value) => handleOnChangeSubmitNotes(value)}
          />
        </FormControl>
      </Flex>

      <Alert gap={2} status="info">
        <InfoIcon />
        <Text mr={2}>
          By selecting 'Confirm & Submit', the report will be finalized
        </Text>
      </Alert>

      <Flex justify="flex-end" w="100%">
        <Button
          colorScheme="blue"
          isDisabled={signature === null}
          onClick={handleOnSubmitInspection}
        >
          Confirm & Submit
        </Button>
      </Flex>
    </VStack>
  );
};
