import { EventContentArg } from "@fullcalendar/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import moment from "moment";
import { FC, useMemo } from "react";
import { LargeTimelineItem } from "./TimelineItem.lg";
import { BaseTimelineItem } from "./TimelineItem.md";
import { ViewType } from "./types";
type RequestTimelineItemProps = { args: EventContentArg };

export const RequestTimelineItem: FC<RequestTimelineItemProps> = ({ args }) => {
  const request = args.event.extendedProps as ApiRequest;
  const viewType = args.view.type;

  const durationInDays = useMemo(() => {
    let diffInDays = 6;
    const { start, end } = args.event;
    if (!start || !end) return diffInDays;
    diffInDays = moment(end).diff(start, "d");
    return diffInDays;
  }, [args]);

  if (viewType === ViewType.MONTHLY) {
    return <LargeTimelineItem request={request} />;
  }

  if (viewType === ViewType.YEARLY) {
    if (durationInDays < 10) {
      return <BaseTimelineItem request={request} />;
    }
    return <LargeTimelineItem request={request} />;
  }

  if (viewType === ViewType.WEEKLY) {
    if (durationInDays < 3) {
      return <BaseTimelineItem request={request} />;
    }
    return <LargeTimelineItem request={request} />;
  }

  return null;
};
