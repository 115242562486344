import { Flex, HStack } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { FC, useState } from "react";
import { useNavActionItems } from "../../../../hooks/useNavActionItems";
import { useNavAllowedProductSearch } from "../../../../hooks/useNavAllowedProductSearch";
import { CalendarButton } from "../quick-actions/CalendarButton";
import { CreateMenu } from "../quick-actions/CreateMenu";
import { QAButton } from "../quick-actions/QAButton";
import { QRButton } from "../quick-actions/QRButton";
import { ReportsButton } from "../quick-actions/ReportsButton";
import { SearchAutocomplete } from "../quick-actions/SearchAutocomplete";

type NavItemsProps = {
  mediaQueries: boolean[];
};
export const NavActionItems: FC<NavItemsProps> = ({ mediaQueries }) => {
  const [isDesktopSm, isDesktopMd, isDesktopLg] = mediaQueries;
  const [expanded, setIsExpanded] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const { allowedProductSearch } = useNavAllowedProductSearch();
  const { hasCalendar, hasReports } = useNavActionItems();

  return (
    <Flex alignItems="center" gap={3} flex={1} w="min-content">
      <motion.div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          overflowY: "clip",
          height: "40px",
          alignItems: "center",
          minHeight: "60px",
          width: "100%",
        }}
        initial={false}
        animate={{
          flexBasis: expanded ? "0%" : "97%",
        }}
        transition={{
          duration: 0.2,
        }}
      >
        <AnimatePresence
          onExitComplete={() => {
            setIsExpanded(true);
          }}
        >
          {showButtons && (
            <motion.div
              style={{ width: "100%" }}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{
                duration: 0.2,
              }}
            >
              <HStack w="full" justifyContent="flex-end" gap={4}>
                {isDesktopSm && (
                  <>
                    <CreateMenu mediaQueries={[false]} />
                    {hasCalendar && <CalendarButton />}
                    {hasReports && <ReportsButton />}
                    <QAButton />
                    <QRButton />
                  </>
                )}
                {isDesktopMd && (
                  <>
                    <CreateMenu mediaQueries={[false, false, true]} />
                    {hasCalendar && <CalendarButton />}
                    {hasReports && <ReportsButton />}
                    <QAButton />
                    <QRButton />
                  </>
                )}

                {isDesktopLg && (
                  <>
                    <CreateMenu mediaQueries={[false, false, true]} />
                    {hasCalendar && <CalendarButton />}
                    {hasReports && <ReportsButton />}
                    <QAButton />
                    <QRButton />
                  </>
                )}
              </HStack>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
      <motion.div
        initial={false}
        animate={{
          flexBasis: expanded ? "100%" : "3%",
        }}
        transition={{
          duration: 0.2,
        }}
        onAnimationComplete={() => {
          if (!expanded) setShowButtons(true);
        }}
      >
        <SearchAutocomplete
          onSearchClick={() => {
            setShowButtons(false);
          }}
          allowedProductSearch={allowedProductSearch}
          isSearchBoxExpanded={expanded}
          onBlur={() => {
            setIsExpanded(false);
          }}
        />
      </motion.div>
    </Flex>
  );
};
