import {
  Divider,
  Flex,
  HStack,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC } from "react";
import { FaPrint } from "react-icons/fa";
import { formatCurrency } from "../../utils/formatCurrency";
import { formatDecimalHours } from "../../utils/formatDecimalHours";

interface HoursCostSectionProps {
  onPrint: () => void;
  totalHours: number;
  expensesCost: number;
  laborCost: number;
  inventoryCost: number;
  inventoryPurchaseOrderExpense: number;
}

export const HoursCostSection: FC<HoursCostSectionProps> = ({
  onPrint,
  totalHours,
  expensesCost,
  laborCost,
  inventoryCost,
  inventoryPurchaseOrderExpense,
}) => {
  const bgColor = useColorModeValue("blue.50", "blue.900");

  return (
    <Flex
      p={4}
      flexDir="column"
      borderRadius="md"
      bgColor={bgColor}
      marginBottom={[0, 0, 0, 4]}
    >
      <HStack justifyContent="space-between">
        <Text fontWeight="bold">Costs</Text>
        <Icon as={FaPrint} onClick={onPrint} _hover={{ cursor: "pointer" }} />
      </HStack>
      <Divider marginBottom={2} />
      <HStack
        justify="flex-end"
        justifyContent="space-between"
        width="100%"
        marginBottom={4}
      >
        <Text fontWeight="bold">Total Labor:</Text>
        <Text>{formatDecimalHours(totalHours)}</Text>
      </HStack>
      <HStack justify="flex-end" justifyContent="space-between" width="100%">
        <Text fontWeight="bold">Expenses Cost:</Text>
        <Text>{formatCurrency(expensesCost)}</Text>
      </HStack>
      <HStack justify="flex-end" justifyContent="space-between" width="100%">
        <Text fontWeight="bold">Request Purchases:</Text>
        <Text>{formatCurrency(inventoryPurchaseOrderExpense)}</Text>
      </HStack>
      <HStack justify="flex-end" justifyContent="space-between" width="100%">
        <Text fontWeight="bold">Labor Cost:</Text>
        <Text>{formatCurrency(laborCost)}</Text>
      </HStack>
      <HStack justify="flex-end" justifyContent="space-between" width="100%">
        <Text fontWeight="bold">Inventory Cost:</Text>
        <Text>{formatCurrency(inventoryCost)}</Text>
      </HStack>
      <Divider marginY={2} />
      <HStack justify="flex-end" justifyContent="space-between" width="100%">
        <Text fontWeight="bold">Total Cost:</Text>
        <Text>
          {formatCurrency(
            expensesCost +
              laborCost +
              inventoryCost +
              inventoryPurchaseOrderExpense
          )}
        </Text>
      </HStack>
    </Flex>
  );
};
