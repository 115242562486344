import { Box, BoxProps, Container } from "@chakra-ui/react";
import {
  Children,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { StepsButtons } from "./StepsButtons";

export interface HorizontalStepsProps {
  progress: number;
  activeStep: number;
  gotoStep: (index: number) => () => void;
  saveCallback?: (currentStep: number) => void;
  setTotalSteps: Dispatch<SetStateAction<number>>;
  getStepState: (index: number) => "complete" | "incomplete" | "active";
  disableStepButtons?: boolean;
  children: React.ReactNode;
}

export const HorizontalSteps: FC<HorizontalStepsProps> = ({
  children,
  progress,
  activeStep,
  gotoStep,
  getStepState,
  setTotalSteps,
  saveCallback,
  disableStepButtons,
}) => {
  const [childrens, setChildrens] = useState<ReactNode[]>();

  useEffect(() => {
    childrens && setTotalSteps(childrens.length);
  }, [setTotalSteps, childrens]);

  useEffect(() => {
    setChildrens(Children.toArray(children));
  }, [children]);

  return (
    <Container m={0} minW="100%">
      {childrens && (
        <>
          <StepsButtons
            activeStep={activeStep}
            totalSteps={childrens}
            progressValue={progress}
            getState={getStepState}
            handleSetActiveStep={gotoStep}
            saveCallback={saveCallback}
            disableStepButtons={disableStepButtons}
          />
          {childrens[activeStep - 1]}
        </>
      )}
    </Container>
  );
};

export const HorizontalStepItem = (props: BoxProps) => {
  return <Box {...props}>{props.children}</Box>;
};
